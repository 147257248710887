import styled from 'styled-components';

export const ConcurrentUserInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;

export const ConcurrentUserImg = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 180px;
    }

    @media screen and (max-width: 1024px) {
        img {
            width: 100px;
        }
    }

    @media screen and (max-width: 650px) {
        img {
            width: 70px;
        }
    }

    @media screen and (max-height: 500px) and (orientation: landscape) {
        img {
            width: 40px;
        }
    }

`;

export const TextArea = styled.div`
    width: 100%;
    height: 25%;
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    h1 {
        font-size: 1.8rem;
        text-align: center;
        width: 50.5rem;
        max-width: 90%;
    }


    @media screen and (max-width: 768px) {
        p {
            font-size: 0.5rem;
        }

        h1 {
            font-size: 0.85rem;
        }
    }

    @media screen and (max-height: 500px) and (orientation: landscape) {
        margin-top: 20px;

        h1 {
            font-size: 0.7rem;
        }
    }

`;

export const WatchHereButton = styled.button`
    display: flex;
    align-items: center;
    justify-content:center;        
    min-height: 40px;
    border: 1px solid #ff985f;
    width: 30%;
    height: 15%;
    margin-top: 2rem;
    color:#ff985f;
    padding: 8px;
    background: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 0.21px;
    border-radius: 8px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    

    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.03);
    } 

    

    @media(max-width: 920px){
        width: 90%; 
        font-size: 10px;
        max-width: 321px;
    }

    @media screen and (max-height: 500px) and (orientation: landscape) {
        margin-top: 10px;
        font-size: 9px;
        padding: 4px;
        min-height: 30px;
    }
`;
