import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from './styles';

const Loading = () => (
    <Container className="loading">
        <CircularProgress size={70} disableShrink />
    </Container>
);

export default Loading;
