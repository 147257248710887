import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import {TermsContainer} from './styles';
import Notify from '../../../utils/notification';

const changeState = param => !param;

const VoucherTerms = ({setCurrentStep}) => {
  const [termsOfUsage, SetTermsOfUsage] = useState(false);
  const [termsOfPrivacy, SetTermsOfPrivacy] = useState(false);
  const { t } = useTranslation();
  const handleVoucherTerms = () => {
    if (termsOfUsage && termsOfPrivacy) {
      setCurrentStep(1);
    } else {
      Notify.warn(t('VOUCHER', {context: 'TERMS_VALIDATION'}))
    }
  }

  const handlerTermsAccept = () => {
    SetTermsOfUsage(changeState);
  }

  const handlerTermsPrivacyAccept = () => {
    SetTermsOfPrivacy(changeState);
  }

  return (
      <TermsContainer>
          <h1>{t('VOUCHER', {context: 'TERMS_TITLE'})}</h1>
          <div className="border" />
          <div className='terms'>
              {(t('VOUCHER', {context: 'TERMS', returnObjects: true })).map((paragraph, index) => {
                return <p key={index}>{paragraph}</p>
              })}
          </div>  
              <div className="checkbox">
                  <Checkbox
                      checked={termsOfUsage}
                      onChange={handlerTermsAccept}
                      name="terms of usage"
                      color="primary"
                  />
                  <p onClick={handlerTermsAccept}>{t('VOUCHER', {context: 'TERMS_ACEPPT'})}</p>
              </div>
              <div className="checkbox">
                  <Checkbox
                      checked={termsOfPrivacy}
                      onChange={handlerTermsPrivacyAccept}
                      name="terms of adoption and privacy"
                      color="primary"
                  />
                  <p onClick={handlerTermsPrivacyAccept}>
                      {t('VOUCHER', {context: 'ACEPPT_TERMS_PRIVACY_PT1'})}
                      <span>{t('VOUCHER', {context: 'ACEPPT_TERMS_PRIVACY_PT2'})}</span>
                  </p>
              </div>
          <button onClick={handleVoucherTerms}>{t('VOUCHER', {context: 'NEXT_STEP_BUTTON'})}</button>
      </TermsContainer>
  )
}

export default VoucherTerms;