import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PasswordContainer } from './styles';
import Notify from '../../../utils/notification';
import { confirmPassword } from '../../../store/modules/vouchers/actions';

const VoucherPassword = ({username, voucherId}) => {
  const dispatch = useDispatch();
  const passRef = useRef(null);
  const confirmPassRef = useRef(null);
  const { t } = useTranslation();

  const handleVoucherPassword = () => {
      if (passRef.current.value.length <= 5) {
          Notify.warn(t('VOUCHER', {context: 'PASSWORD_MIN'}));
      }
      else if(passRef.current.value === confirmPassRef.current.value) {
          dispatch(confirmPassword(username, passRef.current.value, voucherId))
      } else {
          Notify.warn(t('VOUCHER', {context: 'PASSWORD_SAME'}));
      }
  }
  return (
      <PasswordContainer>
          <h1>{t('VOUCHER', {context: 'PASSWORD_CREATION_TITLE'})}</h1>
          <div className="border" />
          <div className='password'>
              <div className="password-input">
                  <div>
                      <label>{t('VOUCHER', {context: 'PASSWORD_LABEL'})}</label>
                      <input minLength="5" required ref={passRef} type="password"></input>
                  </div>
                  <div>
                      <label>{t('VOUCHER', {context: 'PASSWORD_CONFIRM_LABEL'})}</label>
                      <input minLength="5" required ref={confirmPassRef} type="password"></input>
                  </div>
              </div>
              <button onClick={handleVoucherPassword}>{t('VOUCHER', {context: 'FINALIZE_REGISTRATION'})}</button>
          </div>  
      </PasswordContainer>
  )
}

export default VoucherPassword