import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import { finishQuiz } from '../../../store/modules/rooms/actions';
import Notify from '../../../utils/notification';
import {
    QuestionContainerChatMode,
    QuestionContainerHeader,
    QuestionContentChatMode,
    QuestionTitleWrapper,
    QuestionTitle,
    QuestionListChatMode,
    QuestionFooter,
    FooterButton,
    QuestionItemChatMode,
    QuestionContainer,
    QuestionContent,
    QuestionList,
    QuestionItem,
    QuestionTitleChatMode,
    QuestionFooterChatMode,
} from './styles';
import langs from '../../../assets/i18n/constants';

function MultipleQuiz({ values, chatMode = false }) {
    const { release, id: quizId, question } = values;
    const [questions, setQuestions] = useState(values.questions);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [page, setPage] = useState(0);
    const lastPage = questions.length - 1;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    function handleSelectAnswer(answer, question) {
        setSelectedAnswer(answer);
        setSelectedQuestion(question);
    }

    async function submitAnswer(answer, question) {
        try {
            await api.answerQuizWithGroups(quizId, [{ id: question.id, answer }]);
            setQuestions(state => state.map(item => (item.id === question.id ? { ...item, answered: answer } : item)));

            if (page >= lastPage) {
                dispatch(finishQuiz());
            }
        } catch (error) {
            Notify.warn(error?.response?.data?.error_description || 'Falha ao responder pergunta!');
        }
    }

    function previewsPage() {
        if (page > 0) {
            setPage(state => state - 1);
            setSelectedAnswer('');
            setSelectedQuestion('');
        }
    }

    function nexPage() {
        if ((page <= lastPage) && selectedAnswer && selectedQuestion && !questions[page]?.answered) {
            submitAnswer(selectedAnswer, selectedQuestion);
        }
        if (page < lastPage) {
            setPage(state => state + 1);
            setSelectedAnswer('');
            setSelectedQuestion('');
        } else {
            dispatch(finishQuiz());
        }
    }

    if (chatMode) {
        return (
            <QuestionContainerChatMode>
                <QuestionContainerHeader>
                    <h5>{t(langs.QUIZ_HEADER)}</h5>
                </QuestionContainerHeader>
                <QuestionContentChatMode>
                    <div className="fix-padding">
                        <QuestionTitleWrapper>
                            <small> {page + 1} {t(langs.OF)} {lastPage + 1} </small> {t(langs.QUESTIONS)}
                            <QuestionTitleChatMode>{questions[page]?.question}</QuestionTitleChatMode>
                        </QuestionTitleWrapper>
                        <QuestionListChatMode>
                            {
                                questions[page].answers?.map((answer, index) => (
                                    <QuestionItemChatMode
                                        disabled={questions[page]?.answered}
                                        className={(selectedAnswer === answer || questions[page]?.answered === answer) ? 'selected' : ''}
                                        onClick={() => handleSelectAnswer(answer, questions[page])}
                                        key={index}
                                    >
                                        {answer}
                                    </QuestionItemChatMode>

                                ))
                            }
                        </QuestionListChatMode>
                    </div>
                    <QuestionFooterChatMode>
                        <FooterButton onClick={previewsPage} disabled={!page} className={page > 0 ? 'skip' : ''}>
                            {t(langs.PREVIOUSPAGE)}
                        </FooterButton>
                        <FooterButton onClick={nexPage} className="skip">
                            {page === lastPage && selectedAnswer ? t(langs.FINISHPAGE) : questions[page]?.answered || selectedAnswer ? t(langs.NEXTPAGE) : t(langs.SKIP)}
                        </FooterButton>
                    </QuestionFooterChatMode>
                </QuestionContentChatMode>
            </QuestionContainerChatMode>
        );
    }

    return (
        release && (
            <QuestionContainer>
                <QuestionContainerHeader>
                    <h5> {t(langs.QUIZ_HEADER)} </h5>
                </QuestionContainerHeader>
                <QuestionContent>
                    <div className="question-list-holder">
                        <QuestionTitleWrapper>
                            <small>{page + 1}  {t(langs.OF)} {lastPage + 1} {t(langs.QUESTIONS)}</small>
                            <QuestionTitle>{questions[page]?.question}</QuestionTitle>
                        </QuestionTitleWrapper>
                        <QuestionTitle>{question}</QuestionTitle>
                        <QuestionList>
                            {
                                questions[page].answers?.map((answer, index) => (
                                    <QuestionItem
                                        key={index}
                                        disabled={questions[page]?.answered}
                                        className={`answer-button ${(selectedAnswer === answer || questions[page]?.answered === answer) ? 'selected' : ''}`}
                                        onClick={() => handleSelectAnswer(answer, questions[page])}
                                    >
                                        {answer}
                                    </QuestionItem>
                                ))
                            }
                        </QuestionList>
                    </div>
                    <QuestionFooter>
                        <FooterButton onClick={previewsPage} disabled={!page} className={page > 0 ? 'skip' : ''}> {t(langs.PREVIOUSPAGE)} </FooterButton>
                        <FooterButton onClick={nexPage} className="skip"> {page === lastPage && selectedAnswer ? t(langs.FINISHPAGE) : questions[page]?.answered || selectedAnswer ? t(langs.NEXTPAGE) : t(langs.SKIP)} </FooterButton>
                    </QuestionFooter>
                </QuestionContent>
            </QuestionContainer>

        )
    );
}

export default React.memo(MultipleQuiz);
