import {
    all, put, takeLatest, select, takeLeading, delay, call,
} from 'redux-saga/effects';

import history from '../../../services/history';
import api from '../../../services/api';
import * as roomActions from './actions';
import { defaultErrorHandler } from '../../utilities';
import actions from '../../../utils/actions';
import { translate } from '../../../utils/functions';
import Notify from '../../../utils/notification';
import ChatSocket from '../../../services/ChatSocket';

function getProducerToken() {
    return new URLSearchParams(window.location.search).get('token');
}

function* getRooms() {
    try {
        const { channels } = yield api.getRooms();
        yield put(roomActions.setRooms(channels));
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('CHANNEL_FAILREQUESTROOMS') });
        console.log('getRooms error', error, message);
    }
}

function* getRoomPriorityVideoUrl({ payload }) {
    try {
        const { video_url, socketPath, technology } = yield api.getRoomPriorityVideoUrl(payload, getProducerToken());
        yield put(roomActions.setRoomPriorityVideoUrlSuccess(video_url, socketPath, technology));
    } catch (error) {
        yield put({ type: actions.ROOM_VIDEO_PRIORITY_REQUEST_FAIL });
        const message = defaultErrorHandler(error, {
            default: translate('CHANNEL_FAILREQUESTROOMVIDEOPRIORITY'),
            404: translate('CHANNEL_ROOMNOTFOUND'),
        });
        console.log('getRoomPriorityVideoUrl error', error, message);
    }
}

function* getRoom({ payload: { roomID, getPriority } }) {
    try {
        const room = yield api.getRoom(roomID, getProducerToken());
        yield put(roomActions.setRoom(room));
        if (getPriority) {
            yield call(getRoomPriorityVideoUrl, { payload: roomID });
        }
        if (!ChatSocket.enteredChat) {
            yield put(roomActions.enterChat(roomID));
        }
    } catch (error) {
        if (error.response) {
            yield put({ type: actions.ROOM_FAIL });
            switch (error.response.status) {
                case 403: {
                    if (error?.response?.data?.dataTermUrl) {
                        const { courseId, roomId, dataTermUrl } = error.response.data;
                        history.push(`/terms/${courseId}/${roomId}/${encodeURIComponent(dataTermUrl)}`);
                    } else {
                        history.push('/noPermission');
                    }
                    break;
                }
                case 404: {
                    history.replace('/home');
                    defaultErrorHandler(error, { default: translate('CHANNEL_FAILREQUESTROOMS') });
                    break;
                }
                default:
                    break;
            }
        }
    }
}

function* getRoomEdit({ payload: roomID }) {
    try {
        const room = yield api.getRoomEdit(roomID);
        yield put(roomActions.setEditRoom(room));
    } catch (error) {
        yield put({ type: actions.ROOM_FAIL });
        const message = defaultErrorHandler(error, {
            default: translate('CHANNEL_FAILREQUESTROOM'),
            404: translate('CHANNEL_ROOMNOTFOUND'),
        });
        console.log('getRoom error', error, message);

        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* editRoom({ payload }) {
    try {
        yield api.editRoom(payload.room, payload.id);
        yield put({ type: 'USER_ROOMS_REQUEST' });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* createRoom({ payload }) {
    try {
        yield api.createRoom(payload);
        yield put({ type: 'USER_ROOMS_REQUEST' });
    } catch (error) {
        yield put({ type: actions.ROOM_FAIL });
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* exitRoom() {
    try {
        const selectedRoom = yield select(state => state.room.selectedRoom);
        if (selectedRoom && selectedRoom.id) {
            yield put(roomActions.exitChat(selectedRoom.id));
        }
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('FAIL_EXITROOM') });
        console.log('exitRoom error', error, message);
    } finally {
        yield put(roomActions.clearSelected());
    }
}

function* activeGoLive({ payload: roomId }) {
    try {
        yield api.activeGoLive(roomId);
        yield put({ payload: roomId, type: actions.ROOM_EDIT_SELECT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* activeEndLive({ payload: roomId }) {
    try {
        yield api.activeEndLive(roomId);
        yield put({ payload: roomId, type: actions.ROOM_EDIT_SELECT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* isRoomLive({ payload: roomId }) {
    try {
        const live_status = yield api.isRoomLive(roomId);
        yield put({ payload: live_status, type: actions.ROOM_SET_STATUS_ROOM });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomEngagement({ payload: roomId }) {
    try {
        const data = yield api.getRoomEngagement(roomId);
        yield put({ payload: data, type: actions.ROOM_SET_ENGAGEMENT });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomEventLog({ payload: roomId }) {
    try {
        const { data } = yield api.getRoomEventLog(roomId);
        yield put({ payload: data, type: actions.ROOM_SET_EVENTLOG });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomGraph({ payload }) {
    try {
        const { roomID, startDate, endDate } = payload;
        const { data } = yield api.getRoomGraph(roomID, startDate, endDate);
        yield put({ payload: data, type: actions.ROOM_SET_GRAPH });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* getRoomGeolocalization({ payload }) {
    try {
        const { roomID, startDate, endDate } = payload;
        const data = yield api.getRoomGeolocalization(roomID, startDate, endDate);
        yield put({ payload: data, type: actions.ROOM_SET_GEOLOCALIZATION });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    history.replace('/home');
                    break;
                default:
                    break;
            }
        }
    }
}

function* sendMessage({ payload: { roomID, message, type } }) {
    try {
        if (type === 1) { // questions
            yield api.sendQuestion(roomID, message);
        } else if (type === 2) { //
            yield api.sendMessageMonitorChat(roomID, message);
        } else if (type === 3) {
            yield api.sendMessageTeamChat(roomID, message);
        } else {
            yield api.sendMessage(roomID, message);
        }
    } catch (error) {
        if (error.response && error.response.status === 403) {
            // fake message
            const { roomMessages } = yield select(({ room }) => room);
            const { name } = yield select(({ login }) => login.user);
            const fakeMessage = {
                roomId: roomID,
                messageId: new Date().getUTCMilliseconds(),
                messageText: message,
                messageSender: name,
                type: 'roomMessage',
                colorIndex: 1,
            };
            const messages = [...roomMessages];
            messages.unshift(fakeMessage);
            yield put({ type: 'ROOM_ADD_MESSAGE', payload: messages });
        } else {
            defaultErrorHandler(error, {
                default: translate('WARNINGS_MESSAGES_FAIL'),
                400: translate('WARNINGS_MESSAGES_NOTALLOWED'),
                404: translate('WARNINGS_MESSAGES_NOTFOUND'),
                429: translate('WARNINGS_MESSAGES'),
            });
        }
    }
}

function* enterChat({ payload: roomID }) {
    try {
        if (!ChatSocket.enteredChat) {
            const chatUserType = yield api.enterChat(roomID);
            yield put({ type: actions.ROOM_SET_CHAT_USER_TYPE, payload: { chatUserType } });
            ChatSocket.enteredChat = true;
        }
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_ENTERROOM'),
            403: translate('PERMISSION_ENTERCHAT'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* exitChat({ payload: roomID }) {
    try {
        yield api.exitChat(roomID);
        yield put(roomActions.setChatTabs(null));
        ChatSocket.enteredChat = false;
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_EXITROOM'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* replyMessage({ payload: { roomID, text, originalMessageId } }) {
    try {
        yield api.sendReply(roomID, text, originalMessageId);
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('WARNINGS_MESSAGES_FAIL'),
            403: translate('WARNINGS_MESSAGES_PERMISSIONS'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* roomPinMessage({ payload: { roomID, messageID } }) {
    try {
        yield api.pinMessage(roomID, messageID);
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_PINMESSAGE'),
            403: translate('PERMISSION_PINMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* roomUnpinMessage({ payload: { roomID, messageID } }) {
    try {
        yield api.unpinMessage(roomID, messageID);
    } catch (error) {
        defaultErrorHandler(error, {
            default: translate('FAIL_UNPINMESSAGE'),
            403: translate('PERMISSION_UNPINMESSAGE'),
            404: translate('WARNINGS_MESSAGES_NOTFOUND'),
        });
    }
}

function* setChatEnableRoom({ payload }) {
    try {
        const { selectedRoom } = yield select(({ room }) => room);
        if (selectedRoom.id === payload.room.roomId) {
            yield put(
                {
                    type: actions.ROOM_CHAT_SET_CHAT_ENABLE_ROOM,
                    payload: { isChatEnabled: payload.room.enabled },
                },
            );
        }
    } catch (e) {
        console.log('set enable chat', JSON.stringify(e));
    }
}

function* handleRoomRequests(action) {
    if (action.type === 'ROOM_REQUEST') {
        yield getRoom(action);
    } else if (action.type === 'ROOM_EXIT') {
        yield exitRoom(action);
    }
}

function* setTermOfUsage({ payload }) {
    try {
        const { courseId, userId, roomId } = payload;
        yield api.setUserTerm(courseId, userId);
        history.push(`/course/${roomId}`);
    } catch (error) {
        console.log('set term of usage', JSON.stringify(error));
    }
}

function* setChatEnabled({ payload }) {
    try {
        const { roomID, chatEnabled } = payload;
        yield api.setChatEnabled(roomID, chatEnabled);
    } catch (error) {
        console.log('set chat enabled', JSON.stringify(error));
    }
}

function* getQuizInfo({ payload: quizId }) {
    try {
        const { questions } = yield api.getQuizInfo(quizId);
        yield put(roomActions.setQuiz({ ...questions[0], release: true }));
    } catch (error) {
        if (error.response) {
            defaultErrorHandler(error, { default: error?.response?.data?.error_description || 'Falha ao carregar Quiz!' });
        } else {
            defaultErrorHandler(error, { default: translate('QUIZ_FAILED') });
        }
    }
}

function* answerQuiz({ payload: { questionId, answer } }) {
    try {
        yield api.answerQuiz(questionId, answer);
        yield delay(1000);
        yield put(roomActions.setQuiz(null));
    } catch (error) {
        yield put(roomActions.setQuiz(null));
        if (error.response) {
            defaultErrorHandler(error, { default: error?.response?.data?.error_description || 'Falha ao responder Pergunta' });
        } else {
            defaultErrorHandler(error, { default: translate('QUIZ_FAILED') });
        }
    }
}

function* finishQuizWithGroups() {
    yield put(roomActions.setQuiz(null));
}

function* blockUser({ payload: { roomId, userId } }) {
    try {
        yield api.blockUser(roomId, userId);
        Notify.success(translate('USER_BLOCKED'));
    } catch (error) {
        if (error.response.status === 403) {
            defaultErrorHandler(error, { default: translate('BLOCK_USER_NOT_ALLOWED') });
            return;
        }
        defaultErrorHandler(error, { default: translate('FAIL_BLOCK_USER') });
    }
}

export default all([
    takeLatest('ROOMS_REQUEST', getRooms),
    takeLatest(['ROOM_REQUEST', 'ROOM_EXIT'], handleRoomRequests),
    takeLatest('ROOM_SEND_MESSAGE', sendMessage),
    takeLatest('ROOM_ENTER_CHAT', enterChat),
    takeLeading('ROOM_EXIT_CHAT', exitChat),
    takeLatest(actions.ROOM_EDIT_SELECT, getRoomEdit),
    takeLatest(actions.ROOM_IS_LIVE, isRoomLive),
    takeLatest(actions.ROOM_REPLY_MESSAGE, replyMessage),
    takeLatest(actions.ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM, setChatEnableRoom),
    takeLatest(actions.ROOM_CREATE, createRoom),
    takeLatest(actions.ROOM_EDIT, editRoom),
    takeLatest(actions.ROOM_VIDEO_PRIORITY_REQUEST, getRoomPriorityVideoUrl),
    takeLatest(actions.ROOM_GOLIVE, activeGoLive),
    takeLatest(actions.ROOM_ENDLIVE, activeEndLive),
    takeLatest(actions.ROOM_ENGAGEMENT, getRoomEngagement),
    takeLatest(actions.ROOM_EVENTLOG, getRoomEventLog),
    takeLatest(actions.ROOM_GRAPH, getRoomGraph),
    takeLatest(actions.ROOM_GEOLOCALIZATION, getRoomGeolocalization),
    takeLatest(actions.ROOM_PIN_MESSAGE, roomPinMessage),
    takeLatest(actions.ROOM_UNPIN_MESSAGE, roomUnpinMessage),
    takeLatest(actions.ROOM_SET_CHAT_ENABLED, setChatEnabled),
    takeLatest(actions.ACCEPT_TERMS_OF_USAGE, setTermOfUsage),
    takeLatest(actions.GET_QUIZ, getQuizInfo),
    takeLatest(actions.ANSWER_QUIZ, answerQuiz),
    takeLatest(actions.FINISH_QUIZ, finishQuizWithGroups),
    takeLatest(actions.CHAT_BLOCK_USER, blockUser),
]);
