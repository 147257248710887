import actions from '../../../utils/actions';

const DEFAULT_STATE = { videos: [] };

export default function roomReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
        case actions.ROOM_SET_PROGRESS:
            return { ...state, videos: payload };

        default:
            return state;
    }
}
