import React from 'react';

import { useTranslation } from 'react-i18next';
import KopaLogo from '../../assets/kopa-logo.svg';
import Correct from '../../assets/correct.svg';
import { Container, MessageCointaine, LinkButton } from './styles';

export default function SuccessRecover() {
    const { t } = useTranslation();

    return (
        <Container>
            <img
                src={KopaLogo}
                alt="KopaLogo"
                className="logo"
            />
            <MessageCointaine>
                <img
                    className="correct-image"
                    src={Correct}
                    alt="KopaLogo"
                />
                <h1 className="alert-title">{t('SUCCESS')}!</h1>
                <h3 className="alert-subtitle">{t('RECOVERPASSWORD', { context: 'SUCCESS' })}</h3>
                <LinkButton to="/">
                    <button
                        type="submit"
                    > {t('LOGIN')}
                    </button>
                </LinkButton>
            </MessageCointaine>

        </Container>

    );
}
