import React from 'react';
import { useTranslation } from 'react-i18next';
import languageConstants from '../../../assets/i18n/constants';
import i18n from '../../../assets/i18n/i18n';
import { RankingPlaceContainer } from './styles';
import podiumIcon from '../../../assets/podium-icon.svg';
import useRanking from '../../../hooks/useRanking';

export default function RankingPlace({ onClick }) {
    const { t } = useTranslation();
    const { user: me } = useRanking();
    return (
        me?.rank
            ? (
                <RankingPlaceContainer onClick={onClick} data-tour="tour__ranking">
                    <img src={podiumIcon} alt="Pódio" />
                    <div className="info">
                        <h4>{t(languageConstants.YOU)} {t(languageConstants.ARE_IN)} {me.rank}{i18n.language === 'pt-BR' ? 'º' : ''} {t(languageConstants.RANKING_POSITION)}</h4>
                        <small>{t(languageConstants.VIEW_RANKING)}</small>
                    </div>
                </RankingPlaceContainer>
            ) : <></>
    );
}
