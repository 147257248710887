import styled from 'styled-components';

export const Header = styled.div`
    display: flex;    
    height: 55px;  
    border-radius: 8px;
    background-color: #131327;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-top: 20px;
    img {
        margin-right: 15px;
    }
    h5 {
        color: #FFF;
        font-weight: bold;
        text-transform: uppercase;
        span {
            color: #f18541;
        }
    }
`;

export const TableContainer = styled.div`
    width: 100%;    
    border-radius: 8px;
    background-color: #212032;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    color: #ffffff;
    max-width: 100%;
    margin: 20px 0;
    border-radius: 8px;
    overflow: hidden;

    th {
        background-color: #131327;
        padding: 12px 0.4em;
        text-transform: uppercase;
        font-size: clamp(13px, 0.4em + 0.8vw, 1em);
        &:first-child {
            padding-left: 18px;
        }
    }

    tr {
        cursor: pointer;
        background-color: #212032;
        &:nth-child(even) {
            background-color: #242339;
        }

        &:hover {
            background-color: #2f2e3e;
        }        

        td {
            padding: 10px 0.5em;            
            font-size: clamp(13px, 0.4em + 0.8vw, 1em);
        }
    }   

    .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
    }  
`;
