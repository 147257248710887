import actions from '../../../utils/actions';

export const verifyVoucher = (id, redirect = false) => ({
    type: actions.VOUCHER_VERIFY,
    payload: { id, redirect },
});

export const registerVoucherUser = (payload) => ({
    type: actions.VOUCHER_REGISTER_USER,
    payload,
});

export const verifyEmail = (payload) => ({
    type: actions.VOUCHER_VERIFY_EMAIL,
    payload,
});

export const resendEmail = (email) => ({
    type: actions.VOUCHER_RESEND_VERIFY,
    payload: email,
});

export const confirmPassword = (username, password, voucherId) => ({
    type: actions.VOUCHER_CONFIRM_PASSWORD,
    payload: { username, password, voucherId },
});

export const associateVoucher = (username, password, voucherId) => ({
    type: actions.VOUCHER_ASSOCIATE,
    payload: { username, password, voucherId },
});

export const associateRegisteredVoucher = (voucherId, renew) => ({
    type: actions.VOUCHER_ASSOCIATE_REGISTERED,
    payload: { voucherId, renew },
});

export const clearVoucherAssociation = () => ({ type: actions.VOUCHER_CLEAR_ASSOCIATION });
