import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function getSkeletonWidth() {
    const { innerWidth: windowWidth } = window;

    if (windowWidth <= 410) {
        return (windowWidth - 20); // (tamanho da tela) - (margens laterais)
    }

    if (windowWidth > 410 && windowWidth <= 800) {
        return (windowWidth / 2 - 20); // (tamanho da tela) / (numero de colunas) - (margens laterais)
    }

    return windowWidth / 3 - 60; // (tamanho da tela) / (numero de colunas) - (margens laterais e menu lateral)
}

export default function VideoCardSkeleton() {
    const [skeletonWidth, setSkeletonWidth] = useState(getSkeletonWidth());

    useEffect(() => {
        function handleResize() {
            const skeletonSize = getSkeletonWidth();

            setSkeletonWidth(skeletonSize);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d">
            <Skeleton width={skeletonWidth} height={202} count={10} style={{ margin: 10 }} />
        </SkeletonTheme>
    );
}
