import { createGlobalStyle } from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';

export default createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus{
  outline: 0;
}

html, body, #root {
  height: 100%;
  background-color: #282b3a;
}

body {
  -webkit-font-smoothing: antialiased !important;
}

button {
  cursor: pointer;
}

body, input, button {
  font: 14px 'Open Sans', sans-serif;
}

a{
  text-decoration: none;
}

ul{
  list-style: none
}

svg.onboardingOverlay {
  opacity: 50%;
}

.--pulse {
    &:hover {
        animation: pulse 1s infinite;
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,172,71, 0.3);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(255,67,141, 0.3);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,67,141, 0.3);
        }
        }
        @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(255,172,71, 0.3);
            box-shadow: 0 0 0 0 rgba(255,172,71, 0.3);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(255,67,141, 0.3);
            box-shadow: 0 0 0 10px rgba(255,67,141, 0.3);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(255,67,141, 0.3);
            box-shadow: 0 0 0 0 rgba(255,67,141, 0.3);
        }
    }
  }


  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #1b1d2b;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5555;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
    

`;
