import React, { useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf/dist/umd/entry.webpack';
import { TermContainer } from './styles';
import AcceptForm from './acceptForm';
import useSize from '../../hooks/useSize';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function userSelector({ login }) {
    return { user: login.user };
}

const Terms = () => {
    const [numPagesPdf, setNumPagesPdf] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { courseId, roomId, termUrl } = useParams();
    const { user } = useSelector(userSelector, shallowEqual);
    const { t } = useTranslation();
    const file = useMemo(() => (decodeURIComponent(termUrl)), [termUrl]);
    const size = useSize();

    function onDocumentLoadSuccess({ numPages }) {
        if (numPagesPdf !== numPages) {
            setNumPagesPdf(numPages);
        }
    }

    return (
        <TermContainer className="termContent-box">
            <section>
                <div className="pdf-container">
                    <Document
                        file={{ url: file }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={t('LOADINGPDF')}
                    >
                        <Page pageNumber={pageNumber || 1} width={size.width < 610 ? size.width : 610} className="page" />
                        <div className="controls">
                            <Button
                                variant="contained"
                                onClick={() => setPageNumber(prevState => (prevState > 1 ? (prevState - 1) : prevState))}
                            >
                                {t('PREVIOUSPAGE')}
                            </Button>
                            <p>{t('PAGE')} {pageNumber} {t('OF')} {numPagesPdf}</p>
                            <Button
                                id="right"
                                variant="contained"
                                onClick={() => setPageNumber(prevState => ((prevState < numPagesPdf) ? (prevState + 1) : prevState))}
                            >
                                {t('NEXTPAGE')}
                            </Button>
                        </div>
                    </Document>
                </div>
                <AcceptForm courseId={courseId} roomId={roomId} user={user} />
            </section>
        </TermContainer>
    );
};

export default Terms;
