import actions from '../../../utils/actions';

export const getPersonalRanking = (courseId) => ({
    type: actions.PERSONAL_RANKING_REQUEST,
    payload: courseId,
});

export const getPersonalRankingSuccess = (ranking) => ({
    type: actions.PERSONAL_RANKING_SUCCESS,
    payload: ranking,
});

export const getPersonalRankingFailure = () => ({ type: actions.PERSONAL_RANKING_FAILURE });

export const getGamificationCourseStatus = (courseId) => ({ type: actions.GAMIFICATION_COURSE_STATUS_REQUEST, payload: courseId });

export const getGamificationCourseStatusSuccess = (status) => ({ type: actions.GAMIFICATION_COURSE_STATUS_SUCCESS, payload: status });

export const getGamificationCourseStatusFailure = () => ({ type: actions.GAMIFICATION_COURSE_STATUS_FAILURE });

export const cleanGamificationCourseStatus = () => ({ type: actions.GAMIFICATION_COURSE_STATUS_CLEAN });
