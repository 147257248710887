import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

export default function StartTransmissionContainer({ children }) {
    const { t } = useTranslation();
    return (
        <Container>
            <div className="chat-header">
                <h4>{t('TRANSMISSION_TEST')}</h4>
            </div>
            {children}
            <div />
        </Container>
    );
}
