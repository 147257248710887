export const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 5120,
            settings: {
                slidesToShow: 15,
                slidesToScroll: 15,
                arrows: true,
            },
        },
        {
            breakpoint: 3840,
            settings: {
                slidesToShow: 10,
                slidesToScroll: 10,
                arrows: true,
            },
        },
        {
            breakpoint: 2560,
            settings: {
                slidesToShow: 8,
                slidesToScroll: 8,
                arrows: true,
            },
        },
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 7,
                arrows: true,
            },
        },
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6,
                arrows: true,
            },
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                arrows: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                arrows: true,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: true,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
            },
        },
    ],
};
