import CONSTANTS from '../constants';

export default {
    [CONSTANTS.ACTIVE]: 'Active',
    [CONSTANTS.ACCEPTTERMS]: 'I accept the terms of use',
    [CONSTANTS.ADDITIONAL_INFORMATION]: 'ADDITIONAL INFORMATION',
    [CONSTANTS.ALREADY_LOGED_IN]: 'You are already loged in other machine.',
    [CONSTANTS.APPLY]: 'Apply',
    [CONSTANTS.ATTACHMENTS]: 'Attachments',
    [CONSTANTS.BACKTO]: 'Back to',
    [CONSTANTS.BACK_INITIAL_PAGE]: 'BACK TO INITIAL PAGE',
    [CONSTANTS.BITRATE]: 'Bitrate',
    [CONSTANTS.BLOCK_USER_NOT_ALLOWED]: 'Blocking this user is not allowed.',
    [CONSTANTS.BUFFER_CLASSIFICATION]: 'Buffer Classification',
    [CONSTANTS.BUFFER_CLASSIFICATION_ABREVIATION]: 'Buf. Classification',
    [CONSTANTS.BROADCASTING_ROUTING_LEAVE]: 'Leaving the page will stop the broadcast. Continue?',
    [CONSTANTS.CANCEL]: 'Cancel',
    [CONSTANTS.CONCURRENT_ACCESS_VIDEO]: 'You are already watching on another device, Do you want to watch on current device?',
    [CONSTANTS.CHANNEL_ADDNEWVIDEO]: 'Add new video',
    [CONSTANTS.CHANNEL_ADDNEWLIVEVIDEO]: 'Live | Live',
    [CONSTANTS.CHANNEL_ADDNEWRECORDEDVIDEO]: 'Video upload',
    [CONSTANTS.CHANNEL_CHAT_ENABLED]: 'LIVE CHAT IS',
    [CONSTANTS.CHANNEL_CLEANVIDEO]: 'Clear video',
    [CONSTANTS.CHANNEL_COPIED]: 'Copied',
    [CONSTANTS.CHANNEL_CREATEVIDEO]: 'Create video',
    [CONSTANTS.CHANNEL_CREATEVIDEO2]: 'Create new video',
    [CONSTANTS.CHANNEL_EDITVIDEO]: 'Edit video',
    [CONSTANTS.CHANNEL_ENDLIVE]: 'End live!',
    [CONSTANTS.CHANNEL_ENDLIVEDESCRIPTION]: 'Do you really want to end the live?',
    [CONSTANTS.CHANNEL_ENDLIVEIRREVERSIBLE]: 'This operation cannot be undone',
    [CONSTANTS.CHANNEL_ENDDATE]: 'DATE AND END TIME',
    [CONSTANTS.CHANNEL_FAILREQUESTROOMVIDEOPRIORITY]: 'Failed to request access to video',
    [CONSTANTS.CHANNEL_FILES]: 'File',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Files must have the following formats: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_GOLIVE]: 'Go live!',
    [CONSTANTS.CHANNEL_MYVIDEOS]: 'My videos',
    [CONSTANTS.CHANNEL_NEWVIDEO]: 'New Video',
    [CONSTANTS.CHANNEL_NOVIDEO]: 'No rooms registered at the moment',
    [CONSTANTS.CHANNEL_PRIMARYURL]: 'PRIMARY URL',
    [CONSTANTS.CHANNEL_PASSWORD]: 'PASSWORD',
    [CONSTANTS.CHANNEL_ROOMNOTFOUND]: 'Room not found',
    [CONSTANTS.CHANNEL_SECONDARYURL]: 'SECONDARY URL',
    [CONSTANTS.CHANNEL_SELECTCOURSE]: 'SELECT THE CHANNEL TO WHICH THE VIDEO BELONGS',
    [CONSTANTS.CHANNEL_SELECTCOURSEPLACEHOLDER]: 'Select a course',
    [CONSTANTS.CHANNEL_SELECTTIMEZONE]: 'SELECT THE TIMEZONE',
    [CONSTANTS.CHANNEL_SENDVIDEO]: 'Upload video',
    [CONSTANTS.CHANNEL_STARTDATE]: 'DATE AND START TIME',
    [CONSTANTS.CHANNEL_THUMBNAIL]: 'Thumbnail',
    [CONSTANTS.CHANNEL_THUMBNAILSIZE]: 'Minimum size 266x110',
    [CONSTANTS.CHANNEL_UPLOADFILES]: 'UPLOAD FILES',
    [CONSTANTS.CHANNEL_UPLOADVIDEO]: 'VIDEO UPLOAD',
    [CONSTANTS.CHANNEL_VIDEOCOVER]: 'VIDEO COVER',
    [CONSTANTS.CHANNEL_VIDEODESCRIPTION]: 'VIDEO DESCRIPTION',
    [CONSTANTS.CHANNEL_VIDEOFORMAT]: 'The video must have the following formats: MP4, MKV',
    [CONSTANTS.CHANNEL_VIDEOTITLE]: 'VIDEO TITLE',
    [CONSTANTS.CHAT]: 'chat',
    [CONSTANTS.COMEBACK]: 'Return',
    [CONSTANTS.CONFIRM]: 'Confirm',
    [CONSTANTS.CONTROLS]: 'CONTROLS',
    [CONSTANTS.DISABLE_QUESTION]: 'DISABLE?',
    [CONSTANTS.DISABLED_CAPS]: 'DISABLED',
    [CONSTANTS.DISABLED_CAMERA]: 'Camera Disabled',
    [CONSTANTS.DO_KNOW_KOPA_APP]: "Do you know Kopa's App?",
    [CONSTANTS.DO_KNOW_KOPA_APP_TEXT]: 'You can access our app anytime and anywhere,just be connected to the internet with sufficient quality for broadcasting of content.',
    [CONSTANTS.DOWNLOAD_KOPA_APP]: 'DOWNLOAD THE APP NOW',
    [CONSTANTS.DONT_SHOW_AGAIN]: 'NÃO EXIBIR NOVAMENTE',
    [CONSTANTS.DONT_SHOW_AGAIN]: 'DO NOT SHOW AGAIN',
    [CONSTANTS.DOUBTS]: 'Doubts',
    [CONSTANTS.EDIT]: 'Edit',
    [CONSTANTS.ENABLE_QUESTION]: 'ENABLE?',
    [CONSTANTS.ENABLED_CAPS]: 'ENABLED',
    [CONSTANTS.ENDDATE]: 'Finish date',
    [CONSTANTS.ENGLISH]: 'English',
    [CONSTANTS.EMAIL]: 'EMAIL',
    [CONSTANTS.ENTER_ROOM]: 'Enter the room',
    [CONSTANTS.ERROR404]: 'Error 404',
    [CONSTANTS.ERROR_CHANGEPASSWORD]: 'Failed to change password, error status: ',
    [CONSTANTS.ERROR_CREDENTIALS]: 'Access credentials invalid, expired or nonexistent',
    [CONSTANTS.ERROR_PARAMETERS]: 'One or more input parameters are invalid',
    [CONSTANTS.ERROR_DEFAULT]: 'Unexpected server error',
    [CONSTANTS.ERROR_EMAILNOTFOUND]: 'EMAIL NOT FOUND',
    [CONSTANTS.ERROR_LOGIN]: 'WRONG EMAIL OR PASSWORD',
    [CONSTANTS.ERROR_LOGINFAIL]: 'Failed to login, error status:',
    [CONSTANTS.ERROR_NONETWORK]: 'No internet access',
    [CONSTANTS.ERROR_RECOVERPASSFAIL]: 'Fail to restore password',
    [CONSTANTS.FAIL_BLOCK_USER]: 'Failed to block user',
    [CONSTANTS.FAIL_CHANGELANGUAGE]: 'Failed to perform language change',
    [CONSTANTS.FAIL_CHANGEOFFERSTATUS]: 'Failed to change offer status',
    [CONSTANTS.FAIL_CHATNOTFOUND]: 'Chat Room not found',
    [CONSTANTS.FAIL_CONNECTWEBSOCKET]: 'Failed to connect to websocket',
    [CONSTANTS.FAIL_CREATEOFFER]: 'Failed to create offer',
    [CONSTANTS.FAIL_CREATETEAMS]: 'Failed to create teams',
    [CONSTANTS.FAIL_ENTERROOM]: 'Failed to enter the room',
    [CONSTANTS.FAIL_EXITROOM]: 'Failed to exit the room',
    [CONSTANTS.FAIL_GETCHAT]: 'Chat room not found',
    [CONSTANTS.FAIL_GETOFFER]: 'Offer request failed',
    [CONSTANTS.FAIL_GETTEAMSSTATUS]: 'Failed to request team status',
    [CONSTANTS.FAIL_GETTEAMS]: 'Failed to request teams',
    [CONSTANTS.FAIL_LOGOUT]: 'Failed to log out',
    [CONSTANTS.FAIL_PINMESSAGE]: 'Failed to pin message',
    [CONSTANTS.FAIL_SETTEAMSSTATUS]: 'Failed to change team status',
    [CONSTANTS.FAIL_UNPINMESSAGE]: 'Failed to unpin message',
    [CONSTANTS.FAILED_GET_USER_PROFILE]: 'FAILED TO GET USER PROFILE',
    [CONSTANTS.FILTER_FIRST]: 'Select the first filter',
    [CONSTANTS.FILTER_SECOND]: 'Select the second filter',
    [CONSTANTS.FILTER_SPECIFIC]: 'Date range',
    [CONSTANTS.FILTER_PRESETOPTIONS]: ['1 minute', '5 minutes', '30 minutes', '1 hour', '3 hours', '6 hours', '24 hours', '3 days', '7 days'],
    [CONSTANTS.FILTER_TIME]: 'Select time',
    [CONSTANTS.FLOW_PRIMARY]: 'Primary Flow',
    [CONSTANTS.FLOW_SECONDARY]: 'Secundary Flow',
    [CONSTANTS.FORGETPASSWORD]: 'FORGOT YOUR PASSWORD?',
    [CONSTANTS.FORGETPASSWORD_EMAIL]: 'Enter the email that is registered on the portal and we will send you a verification code.',
    [CONSTANTS.FROM_TIME]: 'From',
    [CONSTANTS.GENERAL]: 'General',
    [CONSTANTS.GEOLOCATION]: 'Geolocation',
    [CONSTANTS.GRAPH_AVG_VIEWBITRATE]: '',
    [CONSTANTS.GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH]: '',
    [CONSTANTS.GRAPH_AVG_VIEWDROPPEDFRAMESRATIO]: '',
    [CONSTANTS.GRAPH_AVG_VIEWLIVELATENCY]: '',
    [CONSTANTS.GRAPH_SUMVIEWTIME]: '',
    [CONSTANTS.GRAPH_VIEWERS]: '',
    [CONSTANTS.GRAPH_VIEWBUFFERTIMERATIO]: '',
    [CONSTANTS.GRAPH_VIEWERSBUFFERING]: '',
    [CONSTANTS.GRAPH_VIEWERSENGAGEMENT]: '',
    [CONSTANTS.GRAPH_VIEWERSDVR]: '',
    [CONSTANTS.HOME]: 'Home',
    [CONSTANTS.HOMEPAGE]: 'Homepage',
    [CONSTANTS.HOME_WELCOME]: "IT'S A PLEASURE TO HAVE YOU HERE WITH US",
    [CONSTANTS.HOME_NOLIVE]: 'Sorry, we currently have no live in progress.',
    [CONSTANTS.HOME_FEATURED_LIVES]: 'live at this moment',
    [CONSTANTS.HOURS]: 'Hours.',
    [CONSTANTS.INACTIVE]: 'Inactive',
    [CONSTANTS.INVALID_IMG_TYPE]: 'Invalid type. The file must be an image.',
    [CONSTANTS.INTERACTIVE_CHAT]: 'INTERACTIVE CHAT',
    [CONSTANTS.FINISHED]: 'Unavailable',
    [CONSTANTS.LANGUAGE]: 'LANGUAGE',
    [CONSTANTS.LAST]: 'Last',
    [CONSTANTS.LIVE]: 'Live',
    [CONSTANTS.LOADING]: 'Loading',
    [CONSTANTS.LOADINGCAPS]: 'LOADING',
    [CONSTANTS.LOADINGPDF]: 'Loading PDF',
    [CONSTANTS.LOGIN]: 'LOGIN',
    [CONSTANTS.LOGIN_EMAIL]: 'E-MAIL',
    [CONSTANTS.LOGIN_PASSWORD]: 'PASSWORD',
    [CONSTANTS.LOGIN_SIGNIN]: 'SIGN IN',
    [CONSTANTS.MESSAGE]: 'Message',
    [CONSTANTS.INVALID_VERIFICATION_CODE]: 'INVALID VERIFICATION CODE',
    [CONSTANTS.VERIFICATION_CODE_TITLE]: 'VERIFICATION CODE',
    [CONSTANTS.VERIFICATION_CODE_SENT]: 'We sent a verification code to the email',
    [CONSTANTS.MONITORS]: 'Monitors',
    [CONSTANTS.NAMECAPS]: 'NAME',
    [CONSTANTS.NEXT_STEP]: 'Next Step',
    [CONSTANTS.NO]: 'No',
    [CONSTANTS.NO_GAMIFICATION_RANKING]: 'The scores have not been computed yet. Wait just a little longer and during the event the ranking will be updated.',
    [CONSTANTS.NOTLIVE]: 'Live ended',
    [CONSTANTS.NEXTPAGE]: 'Next',
    [CONSTANTS.OF]: 'of',
    [CONSTANTS.OFFERS]: 'Offers',
    [CONSTANTS.OFFERS_ACTIVATEOFFERS]: 'Active offer',
    [CONSTANTS.OFFERS_BASEURL]: 'Insert the template url',
    [CONSTANTS.OFFERS_CREATEOFFER]: 'Create offers',
    [CONSTANTS.OFFERS_EDITOFFER]: 'Edit offer',
    [CONSTANTS.OFFERS_ENABLECONFIRM]: 'Do you want to activate the offer?',
    [CONSTANTS.OFFERS_MODEOFFERSINACTIVATE]: 'Offers are disabled',
    [CONSTANTS.OFFERS_NOOFFERS]: 'No offers registered',
    [CONSTANTS.OFFERS_SALESFORCEID]: 'Salesforce Id',
    [CONSTANTS.OFFERS_SELECTOR]: 'Select the type of template ID',
    [CONSTANTS.OFFERS_TEMPLATEURL]: 'Template url',
    [CONSTANTS.OFFERS_TYPEID]: 'Id type',
    [CONSTANTS.OFFLINE_ROOM]: 'Offline Room',
    [CONSTANTS.ON]: 'on',
    [CONSTANTS.VOUCHER_LOGIN]: 'Register with your voucher',
    [CONSTANTS.VOUCHER_PASSWORD_CREATION_TITLE]: 'PASSWORD CREATION',
    [CONSTANTS.VOUCHER_CODE_TITLE]: 'VOUCHER CODE',
    [CONSTANTS.VOUCHER_PASSWORD_LABEL]: 'PASSWORD',
    [CONSTANTS.VOUCHER_PASSWORD_CONFIRM_LABEL]: 'CONFIRM PASSWORD',
    [CONSTANTS.VOUCHER_PASSWORD_SAME]: 'Passwords must be the same',
    [CONSTANTS.VOUCHER_PASSWORD_MIN]: 'Password must be at least 6 characters',
    [CONSTANTS.VOUCHER_FINALIZE_REGISTRATION]: 'Finalize registration',
    [CONSTANTS.VOUCHER_CODE_SUBTITLE]: 'Insert your voucher code here',
    [CONSTANTS.VOUCHER_BUTTON_SUBMIT]: 'VALIDATE VOUCHER',
    [CONSTANTS.VOUCHER_REGISTER_TITLE]: 'SUCCESSFUL REGISTRATION',
    [CONSTANTS.VOUCHER_REGISTER_MESSAGE_SUCCESS]: 'Su registro fue exitoso, ahora lo redireccionaremos a la página de inicio',
    [CONSTANTS.VOUCHER_TERMS]: ['O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.',
        'O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.'],
    [CONSTANTS.VOUCHER_TERMS_ACEPPT]: 'I ACCEPT THE TERMS OF USE',
    [CONSTANTS.VOUCHER_TERMS_TITLE]: 'TERMS OF USE',
    [CONSTANTS.VOUCHER_TERMS_VALIDATION]: 'Accept the terms to proceed',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT1]: 'I ACCEPT THE ',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT2]: 'PRIVACY POLICY',
    [CONSTANTS.VOUCHER_NEXT_STEP_BUTTON]: 'Next step',
    [CONSTANTS.VOUCHER_USERDATA_TITLE]: 'PERSONAL DATA',
    [CONSTANTS.VOUCHER_USERDATA_FILL]: 'Fill in all the data',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME]: 'FULL NAME',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL]: 'E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_CONFIRM]: 'E-MAIL CONFIRM',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_DIFFERS]: 'Email addresses are not the same',
    [CONSTANTS.VOUCHER_USERDATA_DATE_OF_BIRTH]: 'DATE OF BIRTH',
    [CONSTANTS.VOUCHER_USERDATA_GENDER]: 'GENDER',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_MALE]: 'Male',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_FEM]: 'Feminine',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_OTHER]: 'Other',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_SELECT]: 'Select gender',
    [CONSTANTS.VOUCHER_USERDATA_INVALID_DATE]: 'Invalid date of birth',
    [CONSTANTS.VOUCHER_USERDATA_PHONE]: 'PHONE',
    [CONSTANTS.VOUCHER_USERDATA_PHONE_PATTERN]: 'Use only numbers in the phone field',
    [CONSTANTS.VOUCHER_USERDATA_TYPE_FULL_NAME]: 'User full name',
    [CONSTANTS.OPS]: 'Ops!',
    [CONSTANTS.PERMISSION_ENTERCHAT]: 'You are not allowed to enter this chat room',
    [CONSTANTS.PERMISSION_PINMESSAGE]: 'You are not allowed to pin a message in this chat room',
    [CONSTANTS.PERMISSION_UNPINMESSAGE]: 'You are not allowed to unpin messages in this chat room',

    [CONSTANTS.QUIZ_HEADER]: 'Select one of the options to answer',
    [CONSTANTS.PAGE]: 'Page',
    [CONSTANTS.PLEASECLOSE]: 'Please, close',
    [CONSTANTS.PORTUGUESE]: 'Portuguese',
    [CONSTANTS.PRESET]: 'Preset',
    [CONSTANTS.PREVIOUSPAGE]: 'Previous',
    [CONSTANTS.FINISHPAGE]: 'Finish',
    [CONSTANTS.SKIP]: 'Skip',
    [CONSTANTS.QUESTIONS]: 'Questions',
    [CONSTANTS.QUIZ_FAILED]: 'Failed to load quiz',
    [CONSTANTS.RECOVERPASSWORD_MINLENGTH]: 'The password must be at least 8 digits',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORD]: 'NEW PASSWORD',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORDREPEAT]: 'REPEAT NEW PASSWORD',
    [CONSTANTS.RECOVERPASSWORD_NOLINK]: 'The link is no longer available',
    [CONSTANTS.RECOVERPASSWORD_NOMATCH]: 'Passwords do not match',
    [CONSTANTS.RECOVERPASSWORD_REDEFINE]: 'REDEFINE PASSWORD',
    [CONSTANTS.RECOVERPASSWORD_SUCCESS]: 'Password successfully reset',
    [CONSTANTS.RECOVERPASSWORD_VERIFY]: 'CHECK YOUR EMAIL',
    [CONSTANTS.RECOVERPASSWORD_VERIFYDESC]: 'An email for you to change the password will be sent to your email box.',
    [CONSTANTS.ROOM_INTERACTIVE_INFORMATIONS]: 'In this room you can interact with the presenter when requested. Don t forget to check your audio and camera settings.',
    [CONSTANTS.TYPE_NEW_PASSWORD]: 'Enter the new password you want to use to login.',
    [CONSTANTS.STATUS]: 'Status',
    [CONSTANTS.STATUS_LIVE]: 'Live status',
    [CONSTANTS.SEARCH]: 'Search',
    [CONSTANTS.SEARCH_NOT_FOUND]: 'No entries to show',
    [CONSTANTS.SELECTQUESTION]: 'Select a question',
    [CONSTANTS.SEND]: 'SEND',
    [CONSTANTS.SEVERITY_BAD]: 'Bad',
    [CONSTANTS.SEVERITY_GOOD]: 'Good',
    [CONSTANTS.SEVERITY_WARN]: 'Warn',
    [CONSTANTS.SORRYPROBLEM]: 'Sorry, we had a problem.',
    [CONSTANTS.SPANISH]: 'Spanish',
    [CONSTANTS.STARTDATE]: 'Start date',
    [CONSTANTS.SUCCESS]: 'SUCCESS',
    [CONSTANTS.TEAM]: 'Team',
    [CONSTANTS.TEAMS]: 'Teams',
    [CONSTANTS.TEAMS_ACTIVATETEAMS]: 'Activate teams',
    [CONSTANTS.TEAMS_CREATETEAMS]: 'Create teams',
    [CONSTANTS.TEAMS_CREATETEAMSDESC]: "Create teams and make the participant's experience even more extraordinary",
    [CONSTANTS.TEAMS_GENERATETEAMS]: 'Generate teams',
    [CONSTANTS.TEAMS_MODETEAMS]: 'Team mode',
    [CONSTANTS.TEAMS_MODETEAMSINACTIVATE]: 'TEAM MODE OFF',
    [CONSTANTS.TEAMS_NOTEAMS]: 'No team registered at the moment',
    [CONSTANTS.TEAMS_QUANTITY]: 'Number of people on the team',
    [CONSTANTS.TOTIME]: 'To',
    [CONSTANTS.TRY_AGAIN]: 'Try again',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Unsupported file format',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Drop the image here',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Drop the files here',
    [CONSTANTS.UPLOAD_VIDEO_SUCCESS]: 'Video upload finished!',
    [CONSTANTS.UPLOAD_VIDEO_FAILURE]: 'Video upload failure!',
    [CONSTANTS.UPLOAD_VIDEO_PREPARING]: 'Preparing video.',
    [CONSTANTS.UPLOADING]: 'Uploading',
    [CONSTANTS.USERS_ACTIVE]: 'Active Users',
    [CONSTANTS.USERS_ACTIVECAPS]: 'ACTIVE USERS',
    [CONSTANTS.USERS_DISTRIBUTIONSHORT]: 'USERS DIST.',
    [CONSTANTS.USERS_ENGAGEMENT]: 'Users Engagement',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Unsupported file format',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Drop the image here',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Drop the files here',
    [CONSTANTS.VIDEO]: 'Video',
    [CONSTANTS.VIDEO_DESCRIPTION]: 'Video Description',
    [CONSTANTS.VOUCHER]: 'VOUCHER',
    [CONSTANTS.VOUCHER_EMAIL_VALIDATION]: 'E-MAIL VALIDATION',
    [CONSTANTS.VOUCHER_ENTER_FIELDS]: 'Fill in all values',
    [CONSTANTS.VOUCHER_ERROR_COURSE]: 'User already has the course',
    [CONSTANTS.VOUCHER_ERROR_VERIFY]: 'Failed to verify voucher',
    [CONSTANTS.VOUCHER_ERROR_USERCREATED]: 'User already registered',
    [CONSTANTS.VOUCHER_ERROR_INVALIDCODE]: 'Failed to check code',
    [CONSTANTS.VOUCHER_ERROR_EXPIREDCODE]: 'Expired code, resend code to email',
    [CONSTANTS.VOUCHER_ERROR_RESENDEMAIL]: 'Failed to resend email',
    [CONSTANTS.VOUCHER_ERROR_CONFIRMPASSWORD]: 'Failed to confirm password',
    [CONSTANTS.VOUCHER_ERROR_REGISTERDATA]: 'Failed to register data',
    [CONSTANTS.VOUCHER_ERROR_REGISTER]: 'Failed to register voucher',
    [CONSTANTS.ERROR_ROOM_ID]: 'You must provide the "roomId" ind order to send stats!',
    [CONSTANTS.VOUCHER_INVALID]: 'Invalid voucher',
    [CONSTANTS.VOUCHER_NEW_VOUCHER]: 'New Voucher',
    [CONSTANTS.VOUCHER_VALIDATE]: 'Your voucher with the code',
    [CONSTANTS.VOUCHER_VALIDATEPT2]: 'has been successfully validated',
    [CONSTANTS.VOUCHER_VALIDATE_CODE]: 'Validate voucher',
    [CONSTANTS.VOUCHER_VALIDATE_DENIED]: 'has not been successfully validated',
    [CONSTANTS.VOUCHER_VALIDATE_CODECAPS]: 'VOUCHER VALIDATION',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTION]: 'Do you really want to validate the voucher',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTIONPT2]: 'that releases the channel',
    [CONSTANTS.VOUCHER_YOUR_CODE]: 'YOUR VOUCHER CODE',
    [CONSTANTS.VOUCHER_REGISTERED]: 'Voucher successfully registered',
    [CONSTANTS.VOUCHER_RESEND_EMAIL]: 'Resend code',
    [CONSTANTS.VOUCHER_RESENT_EMAIL]: 'Code Resent',
    [CONSTANTS.VOUCHER_STEPS]: ['Terms of use', 'Personal data', 'Validation', 'Password creation'],
    [CONSTANTS.VOUCHER_VERIFICATION_CODE]: 'INSERT THE VERIFICATION CODE THAT WAS SUBMITTED TO THE EMAIL HERE',
    [CONSTANTS.VOUCHER_WARNING_VERIFY]: 'The voucher could not be verified',
    [CONSTANTS.WATCH_HERE]: 'WATCH HERE',
    [CONSTANTS.WARNINGS_MESSAGES]: 'Too many messages sent in a short time. Try again later.',
    [CONSTANTS.WARNINGS_MESSAGES_FAIL]: 'Failed to send message',
    [CONSTANTS.WARNINGS_MESSAGES_NOTALLOWED]: 'For your security it is not allowed to send links, phone numbers and e-mails',
    [CONSTANTS.WARNINGS_MESSAGES_NOTFOUND]: 'Chat room not found',
    [CONSTANTS.WARNINGS_MESSAGES_PERMISSIONS]: 'You are not allowed to send a message in this chat room',
    [CONSTANTS.YES]: 'Yes',
    [CONSTANTS.ORGANIZER]: 'Organizer',
    [CONSTANTS.MAX_PIN_MESSAGE_LIMIT]: 'Limit of 2 pinned messages at a time!',
    [CONSTANTS.SHOW_ALL]: 'SHOW ALL',
    [CONSTANTS.NEW_PASSWORD]: 'NEW PASSWORD',
    [CONSTANTS.REPEAT_NEW_PASSWORD]: 'REPEAT NEW PASSWORD',
    [CONSTANTS.SAVE_PASSWORD]: 'SAVE PASSWORD',
    [CONSTANTS.CREATE_NEW_PASSWORD]: 'CREATE NEW PASSWORD',
    [CONSTANTS.FIRST_ACESS_MESSAGE]: 'Welcome to Kopa! This is your first contact with our platform and to facilitate your next access create a new password for you.',
    [CONSTANTS.NETWORK_FAILED]: 'We are unable to access our server',
    [CONSTANTS.NETWORK_FAILED_MESSAGE]: 'Check your connection and try again',
    [CONSTANTS.FAIL_CHANGE_PASSWORD]: 'Failed to change password, try again',
    [CONSTANTS.SETTINGS]: 'Settings',
    [CONSTANTS.SETTINGS_AUDIO_VIDEO]: 'Audio and Video Settings',
    [CONSTANTS.LOGOUT]: 'Logout',
    [CONSTANTS.BLOCK]: 'Block',
    [CONSTANTS.USER]: 'User',
    [CONSTANTS.BLOCKING_MSG]: 'Do you want to block',
    [CONSTANTS.NO]: 'No',
    [CONSTANTS.USER_BLOCKED]: 'User successfully blocked!',
    [CONSTANTS.BLOCKED_USER_CHAT]: 'USER BLOCKED!',
    [CONSTANTS.WELCOME_TO_KOPA]: 'WELCOME TO KOPA',
    [CONSTANTS.WELCOME_TO_ROOM_INTERACTIVE]: 'Welcome to the interactive room',
    [CONSTANTS.INVALID_EMAIL]: 'INVALID E-MAIL.',
    [CONSTANTS.DID_RECEIVE_CODE]: 'DID NOT RECEIVE YOUR CODE?',
    [CONSTANTS.RESEND_CODE]: 'RESEND CODE',
    [CONSTANTS.VERIFY_CODE]: 'VERIFY CODE',
    [CONSTANTS.EXPIRED_VERIFICATION_CODE]: 'EXPIRED CODE',
    [CONSTANTS.CODE_SENT]: 'Sent',
    [CONSTANTS.TO_RESEND_CODE]: 'TO RESEND CODE',
    [CONSTANTS.MAX_ATTEMPTS_VERIFICATION_CODE]: 'MAX ATTEMPTS REACHED',
    [CONSTANTS.INTERVAL_VERIFICATION_CODE]: 'YOU MUST WAIT 1 MINUTE TO RESEND THE CODE',
    [CONSTANTS.ERROR_IMAGE_UPLOAD]: 'FAILED TO UPLOAD THE IMAGE',
    [CONSTANTS.SOCIAL_MEDIA]: 'SOCIAL MEDIAS',
    [CONSTANTS.PROFILE_UPDATED]: 'PROFILE UPDATED',
    [CONSTANTS.PROFILE_UPDATE_FAILED]: 'PROFILE UPDATED FAILED',
    [CONSTANTS.CROP_IMAGE]: 'CROP IMAGE',
    [CONSTANTS.VIEW_PROFILE]: 'VIEW PROFILE',
    [CONSTANTS.VIDEO_UNAVAILABLE]: 'VIDEO UNAVAILABLE',
    [CONSTANTS.LIVE_ACCESS_DENIED]: 'Sorry, you can not watch this video as a guest.',
    [CONSTANTS.SAVE]: 'SAVE',
    [CONSTANTS.MY_PROFILE]: 'MY PROFILE',
    [CONSTANTS.ACCESS_NOT_ALLOWED]: 'You do not have permission to access this content.',
    [CONSTANTS.HIDE]: 'Hide',

    [CONSTANTS.RANKING_POSITION]: 'Place',
    [CONSTANTS.NAME]: 'Name',
    [CONSTANTS.PLACE]: 'Place',
    [CONSTANTS.YOU]: 'You',
    [CONSTANTS.ARE_IN]: 'Are in',
    [CONSTANTS.WITH]: 'with',
    [CONSTANTS.VIEW_RANKING]: 'View ranking',
    [CONSTANTS.TOTAL_POINTS]: 'Total points',
    [CONSTANTS.FAILED_GET_RANKING]: 'failed to get rank',

    [CONSTANTS.WAITING_TRANSMISSION]: 'Waiting Transmission',
    [CONSTANTS.PREPARING]: 'Preparing...',
    [CONSTANTS.STARTING_LIVE]: 'Starting Live...',
    [CONSTANTS.START_TRANSMISSION]: 'Start transmission',
    [CONSTANTS.ALLOW_USER_MEDIA_ACCESS]: 'Allow access to camera and microphone to start a live.',
    [CONSTANTS.MICROFONES]: 'Microfones',
    [CONSTANTS.CAMERAS]: 'Cameras',
    [CONSTANTS.END_LIVE]: 'End live',
    [CONSTANTS.READY_TO_START_QUESTION]: 'Are you ready to start your transmission?',
    [CONSTANTS.CHECK_MEDIA_SETTINGS]: 'Do not forget to check your camera and audio settings.',
    [CONSTANTS.TRANSMISSION_TEST]: 'Transmission Test',
    [CONSTANTS.END_LIVE_QUESTION]: 'Are you sure you want to end the transmission?',
    [CONSTANTS.TRANSMISSION_STARTED]: 'Transmission Started!',
    [CONSTANTS.SAFARI_RECORDING_WARN]: 'Safari´s course presentation is not working. We suggest that you use another browser.',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: 'Welcome to Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: "It's a pleasure to have you here at Kopa. We invite you to take a quick tour of our platform. Come with us?",
    [CONSTANTS.ONBOARDING_LETS_START]: "Let's start our tour",
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Skip tour',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE_TITLE]: 'Home page',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE]: 'All channels you have access to are shown on the home page. Click on the box whenever you want to go back to the channel list.',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER_TITLE]: 'Organizer area',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER]: 'The organizer area is the exclusive space for you to manage your channels. By clicking here, you will be taken to a new tab, where you will have access to several features for managing your channels, such as: creating and editing videos and lives, generating reports, quizzes, etc.',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Logout',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Click here to exit Kopa.',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS_TITLE]: 'Channels',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS]: 'Here you will find all the videos available to you.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'User area',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Here you can view and edit your profile, adding photo and social media information. You can also select your preferred language.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'End tour through home page.',
    [CONSTANTS.ONBOARDING_HOME_MENU_TITLE]: 'User menu',
    [CONSTANTS.ONBOARDING_HOME_MENU]: 'Within the user menu you will have access to the Home button, exit Kopa and change the default Kopa language. If you have host permissions, access to manage your channel(s) is available to you.',

    [CONSTANTS.ONBOARDING_PRESENTATION_VIDEO]: 'presentation video',
    [CONSTANTS.ONBOARDING_VIDEO_AREA_TITLE]: 'Video area',
    [CONSTANTS.ONBOARDING_VIDEO_AREA]: "This is where you'll watch the best and most exclusive content",
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS_TITLE]: 'Additional Information',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS]: "This area contains additional information about the content you are currently watching. If it's not visible, don't worry, the content in this area is added according to the needs of those responsible for the channel.",
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS_TITLE]: 'Attachments',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS]: "The attachments section is where you can download attachments referring to the extra content of the video. If you don't see this section, don't worry, not all videos have downloadable attachments.",
    [CONSTANTS.ONBOARDING_VIDEO_RANKING_TITLE]: 'Ranking',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING]: "View your score and the top 10 score on the channel's gamification ranking. Don't worry if this session isn't visible, not all videos have user ratings.",
    [CONSTANTS.ONBOARDING_VIDEO_CHAT_TITLE]: 'Chat',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT]: 'Here you can interact with the participants who are watching the video in real time, in addition to answering your questions with the monitors.',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR_TITLE]: 'End tour',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR]: 'Our tour of Kopa has come to an end. Thank you for your preference and enjoy our platform. Team Kopa!',
    [CONSTANTS.FAIL_TO_LOAD_VIDEO_PROGRESS]: 'Failed to load video progress!',
    [CONSTANTS.UNSUPPORTED]: 'Unsupported',
    [CONSTANTS.ADVANCED]: 'Advanced',
    [CONSTANTS.NOISESUPPRESSION]: 'Noise suppression',
    [CONSTANTS.ECHOCANCELLATION]: 'Echo cancellation',
    [CONSTANTS.AUTOGAINCONTROL]: 'Auto gain control',
    [CONSTANTS.CONSTRAINT_ON]: 'On',
    [CONSTANTS.CONSTRAINT_OFF]: 'Off',
    [CONSTANTS.CAM_PERMISSION_MESSAGE]: 'Allow camera access to start a live!',
    [CONSTANTS.MIC_PERMISSION_MESSAGE]: 'Allow microphone access to start a live!',
    [CONSTANTS.UNAVAILABLE_MEDIA]: 'Unavailable media',
};
