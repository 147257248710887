export const changePasswordRequest = (email, voucherId = '') => ({
    type: 'CHANGE_PASSWORD_REQUEST',
    payload: { email, voucherId },
});

export const verifyRecoverCodeRequest = (email, code) => ({
    type: 'VERIFY_RECOVER_CODE_REQUEST',
    payload: { email, code },
});

export const verifyRecoverCodeSuccess = () => ({ type: 'VERIFY_RECOVER_CODE_SUCCESS' });

export const verifyRecoverCodeFailed = (error) => ({
    type: 'VERIFY_RECOVER_CODE_FAILED',
    payload: { error },
});

export const changePasswordSuccess = () => ({ type: 'CHANGE_PASSWORD_SUCCESS' });

export const changePasswordFailed = error => ({
    type: 'CHANGE_PASSWORD_FAILED',
    payload: { error },
});

export const newPasswordRequest = (password, token) => ({
    type: 'NEW_PASSWORD_REQUEST',
    payload: { password, token },
});

export const newPasswordSuccess = () => ({ type: 'NEW_PASSWORD_SUCCESS' });

export const newPasswordFailed = error => ({
    type: 'NEW_PASSWORD_FAILED',
    payload: { error },
});

export const clearErrors = () => ({ type: 'CLEAR_ERRORS' });
