import styled from 'styled-components';
import AttachDownloadImage from '../../../assets/icon-download.svg';

export const VideoAttach = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 10rem;
    overflow: auto;

    strong {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.04px;
    }
`;

export const VideoAttachItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin-top: 12px;
`;

export const VideoAttachItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #1c1b2d;
    padding: 14px 18px;
    margin-right: 11px;
    margin-bottom: 11px;
    height: 72px;
    width: 250px;
    color: #fff;
`;

export const AttachIcon = styled.img`
    height: 38px;
    margin-right: 17px;
`;

export const AttachDescription = styled.p`
    display: flex;
    justify-content: center;

    width: 129px;
    height: 42px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    border-right: 1px solid #3e3e3e;
    padding-right: 12px;
    margin-right: 12px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;

    > abbr {
        text-decoration: none;
    }
`;

export const AttachDownload = styled.a`
    width: 18px;
    height: 19.44px;
    cursor: pointer;
    border: 0;
    background: transparent url(${AttachDownloadImage}) no-repeat;
    background-size: 18px 19.44px;
`;
