import actions from '../../../utils/actions';

export function getOffersStatus(roomId) {
    return {
        type: actions.GET_OFFERS_STATUS,
        payload: roomId,
    };
}

export function setOffersStatus({ status, roomId }) {
    return {
        type: actions.SET_OFFERS_STATUS,
        payload: { status, roomId },
    };
}

export function getOffers(roomId) {
    return {
        type: actions.GET_OFFERS,
        payload: roomId,
    };
}

export function getOfferUrl(roomId) {
    return {
        type: actions.GET_OFFER_URL,
        payload: roomId,
    };
}

export function setOffers(offers) {
    return {
        type: actions.SET_OFFERS,
        payload: offers,
    };
}

export function putOffer({ roomId, templateUrl, useSalesforce }) {
    return {
        type: actions.CREATE_OFFER,
        payload: { roomId, templateUrl, useSalesforce },
    };
}

export function finishLoading({ roomId, templateUrl, useSalesforce }) {
    return {
        type: actions.FINISH_LOADING,
        payload: { roomId, templateUrl, useSalesforce },
    };
}
