import styled from 'styled-components';

export const SidebarWrapper = styled.div`
    padding: 25px 12px;
    height: 100vh;
    width: 100px;
    position: fixed;
    top: 0;
    transition: all 0.5s ease-in-out;
    z-index: 5;

    @media (max-width: 800px) {
        transform: translateX(-100%);
        visibility: hidden;
    }
`;

export const SidebarContainer = styled.div`
    background-color: #212032;
    width: 70px;
    height: 100%;
    border-radius: 18px;
    -webkit-box-shadow: 0px 0px 10px -4px #000000;
    box-shadow: 0px 0px 10px -4px #000000;
    z-index: 100;
    position: relative;

    ul {
        list-style: none;
    }

    .--menu-item {
        padding: 15px;
        cursor: pointer;
        filter: grayscale(1);
        &.emphasis {
            filter: brightness(4.5);
        }
        transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: 0.5;
        &:hover {
            transform: scale(1.1);
            filter: none;
            opacity: 1;
        }
        &:active {
            transform: scale(1);
        }
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        background-color: #1a1a2a;
        height: 4px;
        width: 30px;
        bottom: 0;
    }
`;

export const SidebarMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    height: calc(100% - 100px);
`;

export const SidebarMenuTop = styled.div``;

export const SidebarMenuBottom = styled.div``;

export const ExpansionHeader = styled.div`
    height: 57px;
    background-color: #232233;
    border-radius: 3px;
    padding: 10px;
    position: relative;

    small {
        font-size: 10px;
        color: #fff;
    }

    p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 155px;
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        color: #ff8f63;
        font-weight: bold;
    }

    .--content-toggler {
        background-color: transparent;
        border: 0;
        outline: none;
        position: absolute;
        right: 4px;
        top: 20px;
        padding: 3px;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &.--up {
            transform: rotate(180deg);
        }
    }

    &.--show-content {
        height: initial;

        p {
            white-space: normal;
            text-overflow: initial;
        }
    }
`;
