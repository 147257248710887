/* eslint-disable linebreak-style */
import styled from 'styled-components';

export const Wrapper = styled.div`
  height:100vh;
  background-color: #282b3a;

  svg {
    color: #00000038;
  }
`;

export const Content = styled.div`
  padding-left: 80px;
  padding-top: 119px;   
  height: 100%;  

  @media (max-width: 800px) {
    padding-left: 0;
    padding-top: 70px; 
  }
`;
