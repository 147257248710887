import React, { useState, useEffect, useRef, createRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import moment from 'moment';
import Picker from 'emoji-picker-react';
import { isSafari } from '../../utils/functions';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
    Container, MessageList, ContainScrollMessage, TabContainer, Tab, ReplyContainer,
    AskContainer, AnswerContainer, TimeContainer, CloseMessageSelected, TeamHeader,
    FixedMessage, InputMessage,
} from './styles';
import { CHAT_TABS } from '../../utils/constants';
import { sendMessage, replyMessage, unpinMessage, blockUser, updateLockedUserMessages } from '../../store/modules/rooms/actions';
import useUserType from '../../hooks/useUserType';
import { isEmpty, validURL, getUrlFromString, patternUrl } from '../../utils/functions';
import Message from './Message';
import closeIcon from '../../assets/close.svg';
import loadingIcon from '../../assets/loading-white.svg';
import Modal from '../Modal';

const maxMessageLines = 10;
const maxMessageLength = 200;
const lineSearchRegex = /\r\n|\r|\n/;

function messageSelector({ room }) {
    const { roomMessages, questionMessages, instructorRoomMessages, teamMessages } = room;
    return { roomMessages, questionMessages, instructorRoomMessages, teamMessages };
}

function roomSelector({ room }) {
    return {
        roomID: room.selectedRoom && room.selectedRoom.id,
        isEnabledTeamChat: room.isEnabledTeamChat,
        teamInformation: room.teamInformation,
        roomPinnedMessages: room.roomPinnedMessages,
    };
}

function countLines(str) {
    return str.split(lineSearchRegex).length;
}

function Reply({ question, setSelectedMessage, selected = false }) {
    const { userPermissions } = useUserType();

    return (
        <ReplyContainer selected={selected} onClick={() => ((userPermissions.instructor && !selected) ? setSelectedMessage(question) : null)}>
            {selected
                && (
                    <CloseMessageSelected>
                        <button type="button" onClick={() => setSelectedMessage(null)}>X</button>
                    </CloseMessageSelected>
                )}
            <AskContainer selected={selected}>
                <p><b>{question.messageSender}</b> {question.messageText}</p>
                {userPermissions.instructor
                    && (
                        <TimeContainer>
                            <p>{moment(question.created_at).format('HH:mm')}</p>
                        </TimeContainer>
                    )}
            </AskContainer>
            {
                question.replies.map(reply => (
                    <AnswerContainer selected={selected} key={reply.messageId}>
                        <p><b>{reply.messageSender}</b> {reply.messageText}</p>
                        {userPermissions.instructor
                            && (
                                <TimeContainer>
                                    <p>{moment(reply.created_at).format('HH:mm')}</p>
                                </TimeContainer>
                            )}
                    </AnswerContainer>
                ))
            }

        </ReplyContainer>
    );
}

function MessagesList({ type, setSelectedMessage, requestBlockUser }) {
    const { roomMessages, questionMessages, instructorRoomMessages, teamMessages } = useSelector(messageSelector, shallowEqual);
    const { roomID } = useSelector(roomSelector, shallowEqual);
    const [autoScroll, setAutoScroll] = useState(true);
    const messageListRef = useRef();
    let messages = [];

    switch (type) {
        case CHAT_TABS.GENERAL:
            messages = roomMessages.filter(message => message.roomId === roomID);
            break;
        case CHAT_TABS.QUESTIONS:
            messages = questionMessages.filter(message => message.roomId === roomID);
            break;
        case CHAT_TABS.INSTRUCTORS:
            messages = instructorRoomMessages.filter(message => message.roomId === roomID);
            break;

        case CHAT_TABS.TEAM:
            messages = teamMessages.filter(message => message.roomId === roomID);
            break;
        default:// Default General
            messages = roomMessages.filter(message => message.roomId === roomID);
            break;
    }

    const questions = questionMessages.filter(({ originalMessageId }) => originalMessageId === null);
    // eslint-disable-next-line no-restricted-syntax
    for (const question of questions) {
        const msgs = questionMessages.filter(({ originalMessageId }) => originalMessageId && (question.messageId === originalMessageId));
        question.replies = msgs;
        question.replies.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }

    function onScroll(event) {
        const scrollPosition = event.target.scrollTop;
        const bottomOfTheList = 1;
        const isAutoScrollEnabled = scrollPosition >= bottomOfTheList;
        setAutoScroll(isAutoScrollEnabled);
    }

    function scrollToBottom() {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }

    useEffect(() => {       
        if (autoScroll) {
            scrollToBottom();
        }
    }, [autoScroll, roomMessages]);

    return (
        <ContainScrollMessage ref={messageListRef} onScroll={onScroll}>
            <MessageList activeSafari={isSafari()}>
                {type === CHAT_TABS.QUESTIONS
                    ? questions.map(question => (
                        <Reply key={question.messageId} question={question} setSelectedMessage={setSelectedMessage} />
                    ))
                    : messages.map(m => (
                        <Message
                            key={m.messageId}
                            message={m}
                            colorIndex={m.colorIndex}
                            requestBlockUser={requestBlockUser}
                        />
                    ))}
            </MessageList>
        </ContainScrollMessage>
    );
}

function Toolbox({ roomID, currentTab, chatUserType, selectedMessage, setSelectedMessage }) {
    const { userPermissions } = useUserType();
    const [newMessage, setNewMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPiker] = useState(false);
    const [emoji, setEmoji] = useState('😀');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    function isValidMessage(message) {
        return !message.split('').every(char => char === ' ' || char === '\n');
    }

    function handleNewMessage() {
        const lines = countLines(newMessage);
        if (newMessage.length > 0 && lines <= maxMessageLines && isValidMessage(newMessage)) {
            const toSend = newMessage.trim().split(lineSearchRegex).filter(n => n).join('\n');
            if (selectedMessage && userPermissions.instructor && currentTab === 1) {
                dispatch(replyMessage(roomID, toSend, selectedMessage.messageId));
                setSelectedMessage(null);
            } else {
                dispatch(sendMessage(roomID, toSend, currentTab));
            }
            setNewMessage('');
        }
        setShowEmojiPiker(false);
    }

    function handleInputChange({ target: { value } }) {
        const lines = countLines(value);
        if (lines > maxMessageLines) {
            // @TODO notify user
            console.log('lines exceed max lines', lines);
        }
        if (value.length <= maxMessageLength && lines <= maxMessageLines) {
            setNewMessage(value);
        }
    }

    function textAreaAdjust() {
        const messageArea = document.getElementById('message-area');
        if (messageArea) {
            messageArea.style.height = '40px';
            messageArea.style.height = (messageArea.innerHTML !== '') && (newMessage !== '')
                ? `${messageArea.scrollHeight}px`
                : '40px';
        }
        // tratar seção de dúvidas - perguntas selecionadas.
        const selectedMessage = document.getElementById('reply-section');
        if (selectedMessage) {
            selectedMessage.style.bottom = `${(messageArea.scrollHeight) + selectedMessage.style.height}px`;
        }
    }

    useEffect(() => {
        textAreaAdjust();
        // eslint-disable-next-line
    }, [newMessage])

    function keyDownHandler(event) {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();

            if (isValidMessage(newMessage)) {
                handleNewMessage();
            }
        }
    }

    const textInput = useRef(null);
    useEffect(() => {
        textInput.current.focus();
    }, [selectedMessage]);

    function toggleEmojiPicker() {
        setShowEmojiPiker(!showEmojiPicker);
    }

    const handleEmojiClick = (_, emojiObject) => {
        const lines = countLines(newMessage);
        if (newMessage.length <= maxMessageLength && lines <= maxMessageLines) {
            setNewMessage(newMessage.concat(emojiObject.emoji));
        }
    };

    const disableMonitorChat = chatUserType.instructor && currentTab === 1 && selectedMessage === null;
    return (
        <>
            {showEmojiPicker && (
                <Picker
                    groupNames={{
                        smileys_people: 'Sorrisos e Pessoas',
                        animals_nature: 'Animais e Natureza',
                        food_drink: 'Comidas e Bebidas',
                        travel_places: 'Viagem e Lugares',
                        activities: 'Atividades',
                        objects: 'Objetos',
                        symbols: 'Símbolos',
                        flags: 'Bandeiras',
                        recently_used: 'Mais Usados',
                    }}
                    native
                    disableSearchBar
                    onEmojiClick={handleEmojiClick}
                />
            )}
            <InputMessage emojiExpanded={showEmojiPicker}>
                <textarea
                    id="message-area"
                    ref={textInput}
                    disabled={disableMonitorChat}
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder={disableMonitorChat ? t('SELECTQUESTION') : t('MESSAGE')}
                    onKeyDown={keyDownHandler}
                    maxLength={maxMessageLength}
                />
                <div
                    onMouseEnter={() => setEmoji('😁')}
                    onMouseLeave={() => setEmoji('😀')}
                    className="emoji-wrapper"
                    onKeyPress={toggleEmojiPicker}
                    role="button"
                    tabIndex="0"
                    onClick={toggleEmojiPicker}
                >
                    <span role="img" aria-label="emoji">{emoji}</span>
                </div>
                <div className="send-button-wrapper">
                    <button
                        onClick={handleNewMessage}
                        type="button"
                        disabled={
                            newMessage.length === 0
                            || !isValidMessage(newMessage)
                            || newMessage.length > 200
                            || disableMonitorChat
                        }
                    >
                        {t('SEND')}
                    </button>

                </div>

            </InputMessage>
        </>
    );
}

function Tabs({ isEnabledTeamChat, chatUserType, setCurrentTab }) {
    const [tabs, setTabs] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!(_.isEmpty(chatUserType))) {
            const { instructor, organizer } = chatUserType;
            const monitorTab = !(instructor || organizer);
            const teamTab = (organizer || instructor)
                ? true
                : !isEnabledTeamChat;
            const doubtTab = (organizer);

            const initialTabs = [
                { id: 0, name: t('GENERAL'), isActive: true, hidden: false },
                { id: 1, name: t('DOUBTS'), isActive: false, hidden: doubtTab },
                { id: 2, name: t('MONITORS'), isActive: false, hidden: monitorTab },
                { id: 3, name: t('TEAM'), isActive: false, hidden: teamTab },
            ];

            setTabs(initialTabs);
        }
    }, [chatUserType, isEnabledTeamChat, t]);

    function activeTab(index) {
        const newTabs = Object.assign([], tabs);
        // eslint-disable-next-line no-restricted-syntax
        for (const tab of newTabs) {
            tab.isActive = (index === tab.id);
        }

        setCurrentTab(index);
        setTabs(newTabs);
    }

    const filteredTabs = (tabs && tabs.filter(({ hidden }) => !hidden)) || [];
    return (
        <TabContainer>
            {
                filteredTabs && filteredTabs.map((tab) => (
                    <Tab
                        size={filteredTabs.length}
                        key={`${tab.id}`}
                        active={tab.isActive}
                        onClick={() => activeTab(tab.id)}
                    >
                        {tab.name}
                    </Tab>
                ))
            }
        </TabContainer>
    );
}

function Messages({ visionType, setSelectedMessage, selectedMessage, requestBlockUser }) {
    const { userPermissions } = useUserType();

    if (visionType === CHAT_TABS.QUESTIONS) { // Questions
        return (
            <>
                <MessagesList type={CHAT_TABS.QUESTIONS} setSelectedMessage={setSelectedMessage} selectedMessage={selectedMessage} requestBlockUser={requestBlockUser} />
                {(userPermissions.instructor && selectedMessage !== null)
                    && (
                        <section className="reply-section">
                            <Reply selected key={selectedMessage.messageId} question={selectedMessage} setSelectedMessage={setSelectedMessage} />
                        </section>
                    )}
            </>
        );
    } if (visionType === CHAT_TABS.INSTRUCTORS) {
        return (
            <MessagesList type={CHAT_TABS.INSTRUCTORS} requestBlockUser={requestBlockUser} />
        );
    } if (visionType === CHAT_TABS.TEAM) {
        return (
            <MessagesList type={CHAT_TABS.TEAM} requestBlockUser={requestBlockUser} />
        );
    }

    return (
        <MessagesList type={CHAT_TABS.GENERAL} requestBlockUser={requestBlockUser} />
    );
}

function FixedMessages({ messages, chatUserType, roomID }) {
    const dispatch = useDispatch();
    const [fixedMessageRef, setFixedMessageRef] = useState([]);
    const [loadingPin, setLoadingPin] = useState([]);
    const refLength = messages.length;

    function unpinHandler(e, message) {
        e.stopPropagation();
        setLoadingPin(prev => [...prev, message.messageId]);
        dispatch(unpinMessage(roomID, message.messageId));
    }

    useEffect(() => {
        setLoadingPin([]);
    }, [messages]);

    useEffect(() => {
        setFixedMessageRef(elRefs => (
            Array(refLength).fill().map((_, i) => elRefs[i] || createRef())
        ));
    }, [refLength]);

    useEffect(() => {
        fixedMessageRef.forEach(ref => {
            if (ref.current
                && ref.current.innerText
                && validURL(ref.current.innerText)) {
                const link = getUrlFromString(ref.current.innerText);
                ref.current.innerHTML = ref.current.innerText.replace(patternUrl, `<a target="_blank" href=${link}>${link}</a>`);
            }
        });
    }, [fixedMessageRef]);

    return (
        <FixedMessage>
            {messages && messages.length > 0 ? messages.map((message, index) => (
                <div key={index} className="container">
                    <div ref={fixedMessageRef[index]} className="messageWrapper">
                        {message.message}
                    </div>
                    {chatUserType.organizer ? (
                        <div className="pinWrapper">
                            {loadingPin.find(pinned => pinned === message.messageId)
                                ? <img src={loadingIcon} alt="Loading Icon" className="loading" />
                                : (
                                    <img
                                        onClick={(e) => unpinHandler(e, message)}
                                        className="pinIcon"
                                        alt="Pin Icon"
                                        src={closeIcon}
                                    />
                                )}
                        </div>
                    )
                        : ''}
                </div>
            )) : ''}
        </FixedMessage>
    );
}

export default function Chat({ quizComponent, tour = '', isOnboarding }) {
    const [currentTab, setCurrentTab] = useState(CHAT_TABS.GENERAL);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [currentBlockUser, setCurrentBlockUser] = useState({});
    const [confirmBlock, setConfirmBlock] = useState(false);
    const { roomID, isEnabledTeamChat, teamInformation, roomPinnedMessages } = useSelector(roomSelector, shallowEqual);
    const { userPermissions } = useUserType();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEnabledTeamChat) {
            setCurrentTab(CHAT_TABS.GENERAL);
        }
    }, [isEnabledTeamChat]);

    const requestBlockUser = (message, showModal) => {
        setCurrentBlockUser({ name: message.messageSender, userId: message.userId });
        setConfirmBlock(showModal);
    };

    function handleBlockUser(_) {
        if (currentBlockUser?.userId) {
            const roomId = history.location.pathname.split('/')[2];
            dispatch(blockUser(roomId, currentBlockUser.userId));

            dispatch(updateLockedUserMessages(currentBlockUser?.userId));

            setConfirmBlock(false);
        }
    }

    return (
        <Container data-tour={tour} isOnboarding={isOnboarding}>
            <Modal isOpen={confirmBlock} width={550} handleModal={() => setConfirmBlock(false)} title={t('BLOCK')} featuredTitle={t('USER')}>
                {confirmBlock && (
                    <div className="--confirm-modal">
                        <h5>{t('BLOCKING_MSG')} <span>{currentBlockUser?.name}</span> ?</h5>
                        <div className="--controls">
                            <button type="button" onClick={handleBlockUser}>{t('YES')}</button>
                            <button type="button" className="--cancel-button" onClick={() => setConfirmBlock(false)}>{t('NO')}</button>
                        </div>
                    </div>
                )}
            </Modal>
            {quizComponent && quizComponent}
            <div className="chat-header">
                <h4>{t('INTERACTIVE_CHAT')}</h4>
            </div>
            <Tabs
                isEnabledTeamChat={isEnabledTeamChat}
                chatUserType={userPermissions}
                setCurrentTab={(id) => { setCurrentTab(id); setSelectedMessage(null); }}
            />
            {roomPinnedMessages && roomPinnedMessages.length > 0
                ? (
                    <FixedMessages
                        messages={roomPinnedMessages}
                        chatUserType={userPermissions}
                        roomID={roomID}
                    />
                ) : ''}
            {(isEnabledTeamChat && currentTab === CHAT_TABS.TEAM && !isEmpty(teamInformation))
                && (
                    <TeamHeader>
                        <h4>{teamInformation.teamName}</h4>
                        <span>
                            {
                                teamInformation.members.map((name, index) => (name + (index === (teamInformation.members.length - 1) ? '' : ', ')))
                            }
                        </span>
                    </TeamHeader>
                )}
            <Messages
                visionType={currentTab}
                selectedMessage={selectedMessage}
                setSelectedMessage={(message) => setSelectedMessage(message)}
                requestBlockUser={requestBlockUser}
            />
            <Toolbox
                roomID={roomID}
                currentTab={currentTab}
                chatUserType={userPermissions}
                selectedMessage={selectedMessage}
                setSelectedMessage={(message) => setSelectedMessage(message)}
            />
        </Container>
    );
}
