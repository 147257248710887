import React from 'react';

import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '../pages/Login';
import Home from '../pages/Home';
import CourseSession from '../pages/CourseSession';
import ForcedLogout from '../pages/ForcedLogout';
import Terms from '../pages/Terms';
import Error404 from '../pages/404/index';
import RecoverPassword from '../pages/RecoverPassword';
import NewPassword from '../pages/RecoverPassword/newPassword';
import CheckEmail from '../pages/RecoverPassword/checkEmail';
import SuccessNewPassword from '../pages/RecoverPassword/success';
import FailedNewPassword from '../pages/RecoverPassword/failed';
import Voucher from '../pages/Voucher';
import VoucherLogin from '../pages/Voucher/Login';
import VoucherRegister from '../pages/Voucher/Register';
import ActiveVoucher from '../pages/ActiveVoucher';
import FirstAccess from '../pages/FirstAccess/firstAccess';
import NetworkFailed from '../pages/NetworkFailed';
import CodeVerification from '../pages/CodeVerification';
import NoPermission from '../pages/NoPermission';
import Presenter from '../pages/Presenter';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/codeVerification" component={CodeVerification} />
            <Route path="/recoverPassword/:voucherId?" component={RecoverPassword} />
            <Route path="/failedNewPassword" component={FailedNewPassword} />
            <Route path="/checkEmail/:voucherId?" component={CheckEmail} />
            <Route path="/newPassword/:t" component={NewPassword} />
            <Route path="/successNewPassword" component={SuccessNewPassword} />
            <Route path="/voucher/:id/register" component={VoucherRegister} />
            <Route path="/voucher/:id/login" component={VoucherLogin} />
            <Route path="/voucher/:id?" component={Voucher} />
            <Route isPrivate path="/networkFailed" component={NetworkFailed} />
            <Route isPrivate path="/producer/:roomId" component={Presenter} />
            <Route isPrivate path="/home" component={Home} />
            <Route path="/firstAccess/:username" component={FirstAccess} />
            <Route isPrivate path="/course/:roomId" component={CourseSession} />
            <Route isPrivate path="/activeVoucher" component={ActiveVoucher} />
            <Route isPrivate path="/forcedLogout" component={ForcedLogout} />
            <Route isPrivate path="/terms/:courseId/:roomId/:termUrl" component={Terms} />
            <Route isPrivate path="/noPermission" component={NoPermission} />
            <Route notFound component={Error404} />
        </Switch>
    );
}
