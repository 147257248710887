import { useSelector, shallowEqual } from 'react-redux';

function voucherSelector({ vouchers }) {
    return {
        voucher: vouchers.voucher,
        voucherVerification: vouchers.voucherVerification,
        voucherActivated: vouchers.voucherActivated,
    };
}

export default function useVouchers() {
    const {
        voucher,
        voucherVerification,
        voucherActivated,
    } = useSelector(voucherSelector, shallowEqual);
    return {
        voucher,
        voucherVerification,
        voucherActivated,
    };
}
