import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProfileSectionContainer, Divider } from './styles';
import UserSection from '../UserSection';
import SelectLanguage from '../SelectLanguage';
import { getUserProfile } from '../../store/modules/auth/actions';

function ProfileSection({ setConfirmBlock }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    return (
        <ProfileSectionContainer>
            <UserSection setConfirmBlock={setConfirmBlock} />
            <Divider />
            <SelectLanguage position="top right" />
        </ProfileSectionContainer>
    );
}

export default ProfileSection;
