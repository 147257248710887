import { combineReducers } from 'redux';

import login from './auth/reducer';
import room from './rooms/reducer';
import roomsProgress from './roomsProgress/reducer';
import offers from './offers/reducer';
import vouchers from './vouchers/reducer';
import recoverPassword from './recoverPassword/reducer';
import ranking from './ranking/reducer';

export default combineReducers({
    login,
    room,
    roomsProgress,
    offers,
    vouchers,
    recoverPassword,
    ranking,
});
