import React, { useEffect, useState } from 'react';
import { TooltipWrapper } from './styles';

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);
    const { children, content, direction } = props;

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 100);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => hideTip(), []);

    return (
        <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
            {children}
            {active && (
                <div className={`Tooltip-Tip ${direction || 'top'}`}>
                    {content}
                </div>
            )}
        </TooltipWrapper>
    );
};

export default Tooltip;
