import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { Container, Content, Preview } from './styles';
import avatarImg from '../../assets/avatar-profile.svg';

function FileList({ uploadedFile, url }) {
    const [imageURL, setImageURL] = useState(url);

    useEffect(() => {
        setImageURL(url);
    }, [url, uploadedFile]);

    return (
        <Container>
            <Content>
                <Preview src={imageURL || avatarImg} />
                {!uploadedFile.uploaded && !uploadedFile.error && (
                    <CircularProgressbar
                        styles={{
                            root: { width: 24 },
                            path: { stroke: '#7159c1' },
                        }}
                        strokeWidth={10}
                        value={uploadedFile.progress}
                    />
                )}
            </Content>
        </Container>
    );
}

export default FileList;
