import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';

import appDemo from '../../assets/appDemo.png';
import { AppAdvertisingModalContainer } from './styles';
import { checkMobileBrand } from '../../utils/functions';
import { dontShowMerchRequest } from '../../store/modules/auth/actions';

const AppAdvertisingModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dontShowMerch, setDontShowMerch] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleCheck = (e) => {
        setDontShowMerch(e.target.checked);
    };

    const handleDownloadApp = () => {
        checkMobileBrand();
        if (dontShowMerch) {
            dispatch(dontShowMerchRequest());
        }
        setIsModalOpen(false);
    };

    const closeAppAdvertising = () => {
        if (dontShowMerch) {
            dispatch(dontShowMerchRequest());
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (window.innerWidth < 768 && localStorage.getItem('@kopalive:appMerch')) {
            if (JSON.parse(localStorage.getItem('@kopalive:appMerch'))) {
                setIsModalOpen(true);
            }
        }
        localStorage.removeItem('@kopalive:appMerch');
    }, [dispatch]);

    if (isModalOpen) {
        return (
            <Modal isOpen={isModalOpen} handleModal={closeAppAdvertising}>
                <AppAdvertisingModalContainer>
                    <img src={appDemo} alt="app" />
                    <h1>{t('DO_KNOW_KOPA_APP').toUpperCase()}</h1>
                    <p>{t('DO_KNOW_KOPA_APP_TEXT')}</p>
                    <button onClick={handleDownloadApp} type="button">{t('DOWNLOAD_KOPA_APP')}</button>
                    <div className="merch-checkbox-area">
                        <Checkbox onChange={handleCheck} color="primary" />
                        <p>{t('DONT_SHOW_AGAIN')}</p>
                    </div>
                </AppAdvertisingModalContainer>
            </Modal>
        );
    }
    return null;
};

export default AppAdvertisingModal;
