import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
  display:flex;
  align-self:flex-end;
  margin-bottom:30px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #ffffff;

`;

export const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  padding: 2rem;
  height: 100%;
  margin-top: 3%;

  .logo {
    width: 280px;
    max-width: 100%;
    display: block;
  }

  @media(min-width: 500px) {
    width: 70%;
  }

  @media(min-width: 600px) {
    width: 50%;
  }

  @media(min-width: 990px) {
    width: 50%;
  }
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  span {
      height: 22px;
      margin-bottom: 8px;
      display: block;

      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #ffffff;
  } 

  input{
      background: #393737;
      border: 0;
      height: 40px;
      border-radius: 4px;
      padding: 0 15px;
      margin: 0 0 30px;
      color:#fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      color: #ffffff;

      &:focus,
      &:active {
          box-shadow: 0px 1px 0px 0px #1f1f1f;
      }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-webkit-autofill:valid
  {
    background: #393737 !important;
    color: #fff !important;
    -webkit-box-shadow: 0 0 0 1000px #393737 inset !important;
    -webkit-text-fill-color: #fff !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  button {
    height: auto;
    padding: 15px 0;
    border: 2px solid;
    border-image-source: linear-gradient(to left, #ff468b, #ffa54b 60%);
    border-image-slice: 1;

    &:hover {
      background: transparent;
      border: 2px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
      color: #FFF;
    } 
  }

  .register-button {
      height: auto;
      padding: 15px 0;
      margin: 20px 0;
      background: transparent;
      border-style: solid;
      border-width: 2px;
      border-image-source: linear-gradient(to left, #ffa54b, #ff468b 60%);
      border-image-slice: 1;

      &:hover {
          background: transparent;
          border: 2px solid #FFAE45;
          
          color: #FFAE45;
      }
  }

  a {
    position: relative;

    &:after {
      content: '';
      width: 1%;
      height: 2px;
      position: absolute;
      bottom: -2px;
      left: 0;

      background: linear-gradient(to left, #FF428D, #FFAE45);
      opacity: 0;
      transition: all .5s ease-in-out;

    }

    &:hover {
      &:after {
        width: 50%;
        opacity: 1;
        transition: all .5s ease-in-out;
      }
    }
  }

  @media(max-width: 500px) {
    button {
      font-size: 14px !important;
    }
  }

  @media(max-width: 500px) {
    button {
      font-size: 14px !important;
    }
  }

`;

export const ErrorMessage = styled.span`
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #fff;

`;

export const ErrorContaineir = styled.div`
  margin-top:18px;
  display: flex;
  align-self: center;

img{
  width:25px;
  height:25px;
  margin-right:10px;
}
`;

export const LanguageContainer = styled.div`
  display: flex;
  margin-top: 10px;

  h3 {
    color: white;
    margin-left: 0;
  }

  .arrow-down {
    margin-left: 10px;
    margin-right: 20px;
  }

  .language {
    display: flex;
    margin: 0 5px;
    align-items: center;
    
  }

  .dropdown-languages {
    position: relative;
  }

  .dropdown {
    overflow: hidden;
  }

  img {
    height: 10px;
    width: 10px;
    display: hidden;
  }
  
`;
