import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { UserSectionContainer, ImageFrame, ProfileId } from './styles';
import avatarImg from '../../assets/avatar.svg';

function UserSection({ setConfirmBlock }) {
    const { user } = useAuth();
    const { t } = useTranslation();

    return (
        <UserSectionContainer
            onClick={() => setConfirmBlock(true)}
        >
            <ImageFrame>
                <img
                    src={user?.thumbnail_url ? user?.thumbnail_url : avatarImg}
                    alt="Perfil"
                />
            </ImageFrame>
            <ProfileId>
                <p>{user?.name}</p>
                <strong
                    role="button"
                    tabIndex="0"
                    onClick={() => setConfirmBlock(true)}
                >{t('VIEW_PROFILE')}
                </strong>
            </ProfileId>
        </UserSectionContainer>
    );
}

export default UserSection;
