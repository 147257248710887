import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderContentMobile } from './styles';
import Breadcrumbs from '../Breadcrumbs';
// import Searchbar from '../Searchbar'; vai ser implementado
import SelectLanguage from '../SelectLanguage';
import UserSection from '../UserSection';
import MenuButton from '../MenuButton';
import logo from '../../assets/logo-kopa.svg';
import homeIcon from '../../assets/home-icon.svg';
import configIcon from '../../assets/config-icon.svg';
import signOutIcon from '../../assets/sign-out-icon.svg';
import { logout } from '../../store/modules/auth/actions';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import useAuth from '../../hooks/useAuth';
import ShowAppButton from './ShowAppButton';

function MenuMobile({ setConfirmBlock }) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { breadcrumbs } = useBreadcrumbs();
    const { user } = useAuth();

    function toggleMenuMobile() {
        setShowMobileMenu(!showMobileMenu);
    }

    function handleSingleMenuClick(path = null, blank = false) {
        if (path) {
            if (blank) {
                window.open(path, '_blank');
                return;
            }
            history.push(path);
        }
    }

    function handleLogout() {
        setShowMobileMenu(!showMobileMenu);
        dispatch(logout());
    }

    return (
        <HeaderContentMobile>
            <div className={`--top ${showMobileMenu ? 'open' : undefined}`}>
                <div className="logo-wrapper" role="button" tabIndex={0} onClick={() => window.location.replace('/')}>
                    <img src={logo} alt="Kopa" />
                </div>

                <div className="user-wrapper">
                    <UserSection setConfirmBlock={setConfirmBlock} />
                </div>

                <div className="logo-wrapper">
                    <Breadcrumbs items={breadcrumbs} />
                </div>
                <div>
                    <MenuButton
                        open={showMobileMenu}
                        onClick={toggleMenuMobile}
                    />
                </div>
            </div>
            {/* <div className="--bottom">
                <Searchbar mobile /> vai ser implementado
            </div> */}
            <div className={`menu-list ${showMobileMenu ? 'open' : undefined}`}>
                <ul>
                    <li onClick={() => handleSingleMenuClick('/')}>
                        <div className="--menu-item">
                            <img src={homeIcon} alt="Home" />
                            {t('HOMEPAGE')}
                        </div>
                    </li>
                    {
                        user?.organizer && (
                            <li onClick={() => handleSingleMenuClick('/organizer', true)}>
                                <div className="--menu-item">
                                    <img src={configIcon} alt={t('SETTINGS')} />
                                    {t('ONBOARDING_HOME_ORGANIZER_TITLE')}
                                </div>
                            </li>

                        )
                    }

                    <li onClick={handleLogout}>
                        <div className="--menu-item emphasis">
                            <img src={signOutIcon} alt="Sair" />
                            {t('LOGOUT')}
                        </div>
                    </li>
                </ul>

                <div className="footer">
                    <ShowAppButton />
                    <SelectLanguage position="bottom right" />
                </div>

            </div>
        </HeaderContentMobile>
    );
}

export default MenuMobile;
