import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import attention from '../../assets/attention.svg';
import KopaLogo from '../../assets/kopa-logo.svg';
import { changePassword } from '../../store/modules/auth/actions';
import { Container, Form, ErrorMessage, ErrorContainer } from './styles';

function selector({ login }) {
    return { error: login.error };
}

export default function FirstAccess() {
    const dispatch = useDispatch();
    const { error } = useSelector(selector, shallowEqual);
    const [newPassword, setNewPassword] = useState('');
    const [tokenForChange, setTokenForChange] = useState('');
    const [message, setMessage] = useState(false);
    const history = useHistory();
    const [messageError, setMessageError] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const { t } = useTranslation();
    const { username } = useParams();

    useEffect(() => {
        function checkIsFirstAccess() {
            if (history.location.state.firstAccess === 'firstAccess') {
                setTokenForChange(history.location.state.token || '');
                return;
            }
            history.push('/');
        }
        checkIsFirstAccess();
    }, [history]);

    function handleChangePassword() {
        if (newPassword.length < 8) {
            setMessageError('A senha deve conter mais de 8 caracteres');
            setMessage(true);
            return;
        }
        if (newPassword === repeatNewPassword) {
            dispatch(changePassword(newPassword, tokenForChange, username));
        } else {
            setMessageError('As senhas não conferem, tente novamente');
            setMessage(true);
        }
    }

    function keyHandler(evt) {
        if (evt.keyCode === 13) {
            handleChangePassword();
        }
    }

    function keyHandlerPassword(evt) {
        if (evt.keyCode === 13) {
            handleChangePassword();
        } else if (evt.keyCode === 9 && document.getElementById('recover')) {
            document.getElementById('recover').focus();
        }
    }

    return (
        <Container>
            <img
                className="logo"
                src={KopaLogo}
                alt="KopaLogo"
            />
            <h2 className="textHeader">{t('CREATE_NEW_PASSWORD')}</h2>
            <span className="textMessage">{t('FIRST_ACESS_MESSAGE')}</span>
            <Form>
                <div>
                    <span>{t('NEW_PASSWORD')}</span>
                </div>
                <input type="password" autoComplete="off" value={newPassword} onChange={e => setNewPassword(e.target.value)} onKeyUp={keyHandler} />
                <div>
                    <span>{t('REPEAT_NEW_PASSWORD')}</span>
                </div>
                <input type="password" autoComplete="off" value={repeatNewPassword} onChange={e => setRepeatNewPassword(e.target.value)} onKeyDown={keyHandlerPassword} />
                <button
                    id="submitButton"
                    type="submit"
                    onClick={handleChangePassword}
                >{t('SAVE_PASSWORD')}
                </button>
            </Form>
            {message && (
                <div className="errorMessage">
                    <img src={attention} alt="att" />
                    <h3>{messageError}</h3>
                </div>
            )}
            {error && (
                <ErrorContainer>
                    <img src={attention} alt="att" />
                    <ErrorMessage>{error}</ErrorMessage>
                </ErrorContainer>
            )}
        </Container>

    );
}
