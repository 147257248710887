import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSize from '../../hooks/useSize';
import useAuth from '../../hooks/useAuth';

import { changeOnBoardingStatus, setOnBoarding } from '../../store/modules/auth/actions';

import { Onboarding } from './styles';

export default function OnboardingHome() {
    const [isTourOpen, setTourOpen] = useState(false);
    const { width } = useSize();
    const { user, onBoarding } = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeOnBoardingStatus());
    }, [dispatch]);

    useEffect(() => {
        if (onBoarding?.data) {
            if (!(onBoarding.data.onBoardingHome) && !(onBoarding.skipped)) {
                setTourOpen(true);
            }
        }
    }, [dispatch, onBoarding]);

    // eslint-disable-next-line consistent-return
    useLayoutEffect(() => {
        if (isTourOpen) {
            const originalStyle = window.getComputedStyle(document.body).overflow;
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = originalStyle;
            };
        }
    }, [isTourOpen]);

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        setTourOpen(false);
    }

    function welcome() {
        return (
            {
                level: 'all',
                selector: '',
                content: ({ goTo }) => (
                    <>
                        <h1>{t('ONBOARDING_HOME_WELCOME_TITLE')}</h1>
                        <p>{t('ONBOARDING_HOME_WELCOME')}</p>
                        <div className="buttons">
                            <button
                                type="button"
                                className="btn continue-btn"
                                onClick={() => goTo(1)}
                            >
                                {t('ONBOARDING_LETS_START')}
                            </button>
                            <button
                                type="button"
                                className="btn ignore-btn"
                                onClick={() => handleCloseOnboarding('onBoardingHome', true)}
                            >
                                {t('ONBOARDING_SKIP_TOUR')}
                            </button>
                        </div>
                    </>
                ),
                position: 'center',
            }
        );
    }

    function home() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__home"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_HOME_HOMEPAGE_TITLE')}</h2>
                        <p>{t('ONBOARDING_HOME_HOMEPAGE')}</p>
                    </>
                ),
            }
        );
    }

    function organizer() {
        return (
            {
                level: 'organizer',
                selector: '[data-tour="tour__organizer"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_HOME_ORGANIZER_TITLE')}</h2>
                        <p>{t('ONBOARDING_HOME_ORGANIZER')}</p>
                    </>
                ),
            }
        );
    }

    function logout() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__logout"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_HOME_LOGOUT_TITLE')}</h2>
                        <p>{t('ONBOARDING_HOME_LOGOUT')}</p>
                    </>
                ),
            }
        );
    }

    function channels() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__room"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_HOME_CHANNELS_TITLE')}</h2>
                        <p>{t('ONBOARDING_HOME_CHANNELS')}</p>
                    </>
                ),
            }
        );
    }

    function userArea() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__user"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_HOME_USER_AREA_TITLE')}</h2>
                        <p>{t('ONBOARDING_HOME_USER_AREA')}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingHome')}
                        >
                            {t('ONBOARDING_HOME_COMPLETE')}
                        </button>
                    </>
                ),
            }
        );
    }

    function dropDownMenu() {
        return (
            {
                level: 'all',
                selector: '[data-tour="tour__burger__button"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_HOME_MENU_TITLE')}</h2>
                        <p>{t('ONBOARDING_HOME_MENU')}</p>
                        <button
                            type="button"
                            className="btn complete-btn"
                            onClick={() => handleCloseOnboarding('onBoardingHome')}
                        >
                            {t('ONBOARDING_HOME_COMPLETE')}
                        </button>
                    </>
                ),
                position: 'bottom',
            }
        );
    }

    const mobileSteps = [
        welcome(),
        channels(),
        dropDownMenu(),
    ];

    const desktopSteps = [
        welcome(),
        home(),
        organizer(),
        logout(),
        channels(),
        userArea(),
    ];

    function defineSteps(width, user) {
        const filteredSteps = width > 800 ? desktopSteps : mobileSteps;

        if (!(user.admin || user.organizer)) {
            const userSteps = filteredSteps.filter(step => step.level === 'all');
            return userSteps;
        }

        return filteredSteps;
    }

    const steps = defineSteps(width, user);

    return (
        <Onboarding
            steps={(steps)}
            isOpen={isTourOpen}
            className="onboardingHome"
            maskClassName="onboardingOverlay"
            accentColor="#ff438d"
            disableInteraction
            closeWithMask={false}
            rounded={4}
            onRequestClose={() => handleCloseOnboarding('onBoardingHome', true)}
            closeButtonAriaLabel= {t('ONBOARDING_COMPLETE')}
        />
    );
}
