import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #212032;
  border-radius: 8px;
  overflow: hidden;
  position: relative;  
  
  @media(min-width: 800px) {
    width: 30%;
    height: 100%;
  }

  @media(max-width: 800px) and (min-width: 650px) {
    width: 95%;
    margin: 0 auto;
    overflow: unset;
  }

  @media(max-width: 650px) and (orientation: landscape) {
    height: 100%;
  }

  header {
    width:100%;
    height: 50px;
    padding: 0.5rem;
    display:flex; 
    justify-content:center; 
    align-items:center;
    border-bottom: 2px solid #403e3e;
  }
  
  .chat-header {   
    width: 100%;
    height: 62px;        
    border-radius: 8px;
    background-color: #1c1b2d;
    display:flex; 
    justify-content:center; 
    align-items:center;
    padding: 0 5px;

    h4 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.04px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
    }

    @media (max-width: 650px) {
        display: none;
    }

    @media screen and (min-width: 48.75rem) and (max-width: 64rem ) {
        & h4 {
            font-size: 1rem;
        }
    }
  }  
   
`;
