import styled from 'styled-components';

export const TermsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

    h1 {
        @media(max-width: 500px) {
            font-size: 18px;
        }
    }
    .checkbox {
        display: flex;
        font-weight: 800;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 0 1rem;

        p {
            cursor: pointer;
            line-height: 18px;
        }
        &:last-of-type {
            margin-bottom: 20px;
        }
        p span {
            color: #ff9f4f;
        }
    }
    

    .terms {
        height: 340px;
        margin: 2% 0 1%;
        width: 60%;
        padding: 1rem;
        background-color: #323232;
        overflow-y: auto;
        
        @media(max-width: 500px) {
            margin: 8% 0 8%;  
        }

        p {
            margin-bottom: 2%;
            text-align: left;
        }

        .MuiTypography-body1 {
            font-family: '';
            font-weight: 600;
        }


        ::-webkit-scrollbar {
            width: 5px;
        }
        /* Track */
        ::-webkit-scrollbar-track {
            background: #2d2c2c; 
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #000; 
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #000; 
        }

    }
    .border {
        background-image: linear-gradient(to left, #ffae45, #ff428d 60%);
        width: 5%;
        height: 3px;
    }

    .MuiCheckbox-root { 
        color: rgba(0, 0, 0, 0.2);
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
        color: white;
    }

    .MuiCheckbox-colorPrimary {
    }


    button {
        width: 200px;
        height: auto;
        padding: 15px;
        border: 2px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
        margin-bottom: 50px;

        &:hover {
            background: transparent;
            border: 2px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
            color: #FFF;
        }
    }

    @media(max-width: 500px) {
      .border {
        width: 35% !important;
      }
      
      .terms {
        width: 90% !important;
        height: 250px !important;
      }
      
    }
`;