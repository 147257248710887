const {
    REACT_APP_BASE_API,
    REACT_APP_BASE_WS,
    REACT_APP_GAMES_API,
    REACT_APP_RANKING_BASE_TIME_MS,
    REACT_APP_RANKING_RANDOM_TIME_MS,
    REACT_APP_WEBRTC_LIVESTREAM,
} = process.env;

export const urls = {
    BASE_API: REACT_APP_BASE_API,
    BASE_WS: REACT_APP_BASE_WS,
    REACT_GAMES_API: REACT_APP_GAMES_API,
    WEBRTC_LIVESTREAM: REACT_APP_WEBRTC_LIVESTREAM,
};

export const CHAT_TABS = {
    GENERAL: 0,
    QUESTIONS: 1,
    INSTRUCTORS: 2,
    TEAM: 3,
};

export const TIMERS = {
    CHECK_ROOM_LIVE: 5000,
    REFRESH_REPORTS: 10000,
    REFRESH_RANKING: 10000,
    REFRESH_HOME: 20000,
    SECOND: 1000,
    TIMEOUT: 60000,
    WATERMARK_CHECK: 5000,
    WATERMARK_TIMER_ON: 5000,
    WATERMARK_TIMER_OFF: 60000,
    RANKING_BASE_TIME: Number(REACT_APP_RANKING_BASE_TIME_MS) || 35000,
    RANKING_RANDOM_TIME: Number(REACT_APP_RANKING_RANDOM_TIME_MS) || 20000,
};

export const VIDEO_TYPES = Object.freeze({
    LIVE: 'LIVE',
    RECORDED: 'RECORDED',
});

export const MAX_TEAM_PEOPLE = 50;

export const UPLOAD_VIDEO_STATUS = Object.freeze({
    PREPARING: 'PREPARING',
    UPLOADING: 'UPLOADING',
    READY: 'READY',
    CONVERTING: 'CONVERTING',
    UPLOAD_ERROR: 'UPLOAD_ERROR',
});

export const user_permissions = {
    ADMIN: 'admin',
    ORGANIZER: 'organizer',
    MONITOR: 'monitor',
    USER: 'user',
};

export const appStoreLinks = {
    googlePlay: 'https://play.google.com/store/apps/details?id=live.kopa.app&hl=pt_BR&gl=US',
    appleStore: 'https://apps.apple.com/br/app/kopa-live/id1517895069?l=en',
};
