import { all, takeLatest, put } from 'redux-saga/effects';
import { changePasswordSuccess, changePasswordFailed, verifyRecoverCodeSuccess, verifyRecoverCodeFailed } from './actions';
import Notify from '../../../utils/notification';
import history from '../../../services/history';
import api from '../../../services/api';
import { translate } from '../../../utils/functions';

function* changePassword({ payload }) {
    const { email, voucherId } = payload;
    try {
        yield api.recoverPassword({ email });
        yield put(changePasswordSuccess());
        Notify.success(translate('CODE_SENT'));
        history.push({
            pathname: voucherId ? `/checkEmail/${voucherId}` : '/codeVerification',
            state: email,
        });
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
            case 404:
                message = translate('ERROR_EMAILNOTFOUND');
                break;
            case 403:
                message = translate('INTERVAL_VERIFICATION_CODE');
                break;
            default:
                message = translate('ERROR_RECOVERPASSFAIL');
            }
        }
        console.log('login error', error, message);
        yield put(changePasswordFailed(message));
        // history.push('/checkEmail');
    }
}

function* newPassword(action) {
    const { password, token } = action.payload;
    try {
        yield api.changePassword(password, token);
        yield put(changePasswordSuccess());
        history.push('/successNewPassword');
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
            case 401:
                message = translate('ERROR_CREDENTIALS');
                history.push('/failedNewPassword');
                break;
            default:
                message = translate('ERROR_RECOVERPASSFAIL');
            }
        }
        console.log('login error', error, message);
        yield put(changePasswordFailed(message));
    }
}

function* verifyCodeRecover({ payload }) {
    const { email, code } = payload;
    try {
        const data = yield api.verifyRecoverCode(email, code);
        yield put(verifyRecoverCodeSuccess());
        history.push({ pathname: `/newPassword/${data.token}` });
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
            case 400:
                message = translate('INVALID_VERIFICATION_CODE');
                break;
            case 401:
                message = translate('EXPIRED_VERIFICATION_CODE');
                break;
            case 403:
                message = translate('MAX_RETRIES_VERIFICATION_CODE');
                break;
            default:
                message = translate('ERROR_RECOVERPASSFAIL');
            }
        }
        console.log('code recover error', error, message);
        yield put(verifyRecoverCodeFailed(message));
    }
}

export default all([
    takeLatest('VERIFY_RECOVER_CODE_REQUEST', verifyCodeRecover),
    takeLatest('CHANGE_PASSWORD_REQUEST', changePassword),
    takeLatest('NEW_PASSWORD_REQUEST', newPassword),

]);
