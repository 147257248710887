import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
import {
    Container,
    ContainerConfirm,
    ContainerSuccess,
} from './styles';
import CorrectIcon from '../../assets/correct2.svg';
import CautionIcon from '../../assets/caution.svg';
import { verifyVoucher, associateRegisteredVoucher } from '../../store/modules/vouchers/actions';
import useVouchers from '../../hooks/useVoucher';

const ActiveVoucher = () => {
    const [openModal, setOpenModal] = useState(true);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalFailed, setOpenModalFailed] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [voucher, setVoucher] = useState('');
    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);
    const [channelName, setChannelName] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const { voucherVerification, voucherActivated } = useVouchers();
    const { t } = useTranslation();

    const handleModal = () => {
        setOpenModal(false);
        setOpenModalConfirm(false);
        setOpenModalSuccess(false);
        setOpenModalFailed(false);
    };

    const handleVoucher = () => {
        dispatch(verifyVoucher(voucher, false));
    };

    const typeCode = (position, keyEvent) => {
        const { key } = keyEvent;

        if (key !== 'Backspace') {
            switch (position) {
            case 1:
                if (input1.current.value.length === 4) {
                    input2.current.focus();
                }
                break;
            case 2:
                if (input2.current.value.length === 4) {
                    input3.current.focus();
                }
                break;
            case 3:
                if (input3.current.value.length === 4) {
                    input4.current.focus();
                }
                break;
            case 4:
                if (key === 'Enter' && input3.current.value.length === 4) {
                    handleVoucher();
                }
                break;
            default:
                input1.current.focus();
                break;
            }
        }
    };

    const handlePaste = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const data = event.clipboardData.getData('Text');
        setVoucher(data);
        for (let index = 0; index < Math.floor(data.length / 4); index += 1) {
            switch (index + 1) {
            case 1:
                input1.current.value = data.substring(0, 4);
                input1.current.focus();
                break;
            case 2:
                input2.current.value = data.substring(4, 8);
                input2.current.focus();
                break;
            case 3:
                input3.current.value = data.substring(8, 12);
                input3.current.focus();
                break;
            case 4:
                input4.current.value = data.substring(12, 16);
                input4.current.focus();
                break;
            default:
                break;
            }
        }
    };

    const handleBackspace = (position, keyEvent) => {
        const { key } = keyEvent;
        if (key === 'Backspace') {
            switch (position) {
            case 2:
                if (input2.current.value === '') {
                    input1.current.focus();
                }
                break;
            case 3:
                if (input3.current.value === '') {
                    input2.current.focus();
                }
                break;
            case 4:
                if (input4.current.value === '') {
                    input3.current.focus();
                }
                break;
            default:
                break;
            }
        }
    };

    const handleInputChange = () => {
        const code = input1.current.value.toUpperCase() + input2.current.value.toUpperCase()
        + input3.current.value.toUpperCase() + input4.current.value.toUpperCase();
        setVoucher(code);
    };


    const handleConfirm = () => {
        dispatch(associateRegisteredVoucher(voucher));
    };

    const handleSuccess = () => {
        setOpenModal(true);
        setOpenModalSuccess(false);
    };

    const handleFailed = () => {
        setOpenModal(true);
        setOpenModalFailed(false);
    };

    useEffect(() => {
        if (!openModal && !openModalConfirm
            && !openModalSuccess && !openModalFailed) {
            history.push('/home');
        }
    }, [openModal, openModalConfirm,
        openModalSuccess, openModalFailed, history]);

    useEffect(() => {
        if (voucherVerification && voucherVerification.ableToUse && openModal) {
            setChannelName(voucherVerification.courseName);
            setOpenModalConfirm(true);
            setOpenModal(false);
        }
        // eslint-disable-next-line
    }, [voucherVerification, t])

    useEffect(() => {
        if (openModalConfirm && voucherActivated) {
            setOpenModalSuccess(true);
            setOpenModalConfirm(false);
        } else if (openModalConfirm && voucherActivated !== null) {
            setOpenModalFailed(true);
            setOpenModalConfirm(false);
        }
    // eslint-disable-next-line
    }, [voucherActivated])

    return (
        <>
            {/* Modal Confirm */}
            <Modal isOpen={openModal} width="700" handleModal={handleModal}>
                <Container>
                    <h1>{t('VOUCHER')}</h1>
                    <div>
                        <h4>{t('VOUCHER_YOUR_CODE')}</h4>
                        <div className="voucher-code">
                            <input
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus
                                ref={input1}
                                onKeyDown={(key) => handleBackspace(1, key)}
                                onKeyUp={(key) => typeCode(1, key)}
                                onPaste={handlePaste}
                                type="text"
                                maxLength="4"
                                onChange={handleInputChange}
                            />
                            <input
                                ref={input2}
                                onKeyDown={(key) => handleBackspace(2, key)}
                                onKeyUp={(key) => typeCode(2, key)}
                                type="text"
                                maxLength="4"
                                onChange={handleInputChange}
                            />
                            <input
                                ref={input3}
                                onKeyDown={(key) => handleBackspace(3, key)}
                                onKeyUp={(key) => typeCode(3, key)}
                                type="text"
                                maxLength="4"
                                onChange={handleInputChange}
                            />
                            <input
                                ref={input4}
                                onKeyDown={(key) => handleBackspace(4, key)}
                                onKeyUp={(key) => typeCode(4, key)}
                                type="text"
                                maxLength="4"
                                onChange={handleInputChange}
                            />
                        </div>
                        <button
                            type="button"
                            disabled={!(voucher && voucher.length === 16)}
                            onClick={handleVoucher}
                        >
                            {t('VOUCHER', { context: 'VALIDATE_CODE' })}
                        </button>
                    </div>
                </Container>
            </Modal>
            {/* Modal Confirm */}
            <Modal isOpen={openModalConfirm} handleModal={handleModal}>
                <ContainerConfirm>
                    <h1>{t('VOUCHER', { context: 'VALIDATE_CODECAPS' })}</h1>
                    <p> {t('VOUCHER', { context: 'VALIDATE_QUESTION' })}
                        <span>123{voucher}</span>
                        {t('VOUCHER', { context: 'VALIDATE_QUESTIONPT2' })}
                        <span> kkkkkk{channelName}</span> ?
                    </p>
                    <div className="buttons">
                        <button type="button" className="confirm" onClick={handleConfirm}>{t('YES')}</button>
                        <button type="button" className="cancel" onClick={handleModal}>{t('NO')}</button>
                    </div>
                </ContainerConfirm>
            </Modal>
            {/* Modal Success */}
            <Modal isOpen={openModalSuccess} handleModal={handleModal}>
                <ContainerSuccess>
                    <h1>{t('VOUCHER')}</h1>
                    <div>
                        <img alt="Correct Icon" src={CorrectIcon} />
                        <p>{t('VOUCHER', { context: 'VALIDATE' })} {voucher}
                            {t('VOUCHER', { context: 'VALIDATEPT2' })}
                        </p>
                        <button type="button" onClick={handleSuccess}>{t('VOUCHER', { context: 'NEW_VOUCHER' })}</button>
                    </div>
                </ContainerSuccess>
            </Modal>
            {/* Modal failed */}
            <Modal isOpen={openModalFailed} handleModal={handleModal}>
                <ContainerSuccess>
                    <h1>{t('VOUCHER')}</h1>
                    <div>
                        <img alt="Caution Icon" src={CautionIcon} />
                        <p>{t('VOUCHER', { context: 'VALIDATE' })} {voucher}
                            {t('VOUCHER', { context: 'VALIDATE_DENIED' })}
                        </p>
                        <button type="button" onClick={handleFailed}>{t('TRY_AGAIN')}</button>
                    </div>
                </ContainerSuccess>
            </Modal>
        </>
    );
};

export default ActiveVoucher;
