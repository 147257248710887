import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import useAuth from '../../../hooks/useAuth';
import OnboardingHome from '../../../components/Onboardings/onboardingHome';

import { Wrapper, Content } from './styles';

export default function DefaultLayout({ children }) {
    const { user } = useAuth();

    return (
        <Wrapper>
            {(user)
                && <OnboardingHome />}
            <Header />
            <Sidebar />
            <Content>
                {children}
            </Content>
        </Wrapper>
    );
}

DefaultLayout.propTypes = { children: PropTypes.element.isRequired };
