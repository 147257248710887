import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import langs from '../../../assets/i18n/constants';

import {
    QuestionContainer,
    QuestionContent,
    QuestionTitle,
    QuestionList,
    QuestionItem,
    QuestionContainerChatMode,
    QuestionTitleWrapper,
    QuestionContainerHeader,
    QuestionContentChatMode,
    QuestionListChatMode,
    QuestionItemChatMode,
} from './styles';

export default function SimpleQuiz({ values, handleSelect, chatMode = false }) {
    const { release, id, question, answers } = values;
    const [selected, setSelected] = useState('');
    const { t } = useTranslation();

    function handleAnswer(answer) {
        setSelected(answer);
        handleSelect({ id, answer });
    }

    if (chatMode) {
        return (
            release && (
                <QuestionContainerChatMode>
                    <QuestionContainerHeader>
                        <h5>{t(langs.QUIZ_HEADER)}</h5>
                    </QuestionContainerHeader>
                    <QuestionContentChatMode>
                        <QuestionTitleWrapper>
                            <small>{t(langs.QUESTIONS)}</small>
                            <QuestionTitle>{question}</QuestionTitle>
                        </QuestionTitleWrapper>
                        <QuestionListChatMode>
                            {
                                answers?.map((answer, index) => (
                                    <QuestionItemChatMode
                                        className={selected === answer ? 'selected' : ''}
                                        onClick={() => handleAnswer(answer)}
                                        key={index}
                                    >
                                        {answer}
                                    </QuestionItemChatMode>

                                ))
                            }
                        </QuestionListChatMode>
                    </QuestionContentChatMode>
                </QuestionContainerChatMode>

            )
        );
    }

    return (
        release && (
            <QuestionContainer>
                <QuestionContainerHeader>
                    <h5>{t(langs.QUIZ_HEADER)}</h5>
                </QuestionContainerHeader>
                <QuestionContent>
                    <QuestionTitleWrapper>
                        <small>{t(langs.QUESTIONS)}</small>
                    </QuestionTitleWrapper>
                    <QuestionTitle>{question}</QuestionTitle>
                    <QuestionList>
                        {
                            answers?.map((answer, index) => (
                                <QuestionItem
                                    className={selected === answer ? 'selected' : ''}
                                    onClick={() => handleAnswer(answer)}
                                    key={index}
                                >
                                    {answer}
                                </QuestionItem>

                            ))
                        }
                    </QuestionList>
                </QuestionContent>
            </QuestionContainer>

        )
    );
}
