import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    color: white;
    overflow: auto;
`;

export const Logo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem 0; 
    height: 7%;

    @media(max-width: 400px) {
        width: 100%;
        height: 5%;
        margin: 3% auto 0;
        img {
            width: inherit;
        }
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 2% auto 2%;
    padding: 0 3%;
    justify-content: space-around;

    img {
        margin: 0 5px;
    }

    img {
        fill: green !important;
    }

    svg text {
        fill: green !important;
    }

    @media(min-width: 1200px) {
        width: 80%;
        padding: 0 10%;
    }

    @media(max-width: 500px) {
      flex-flow: row wrap;
      margin: 10% auto 10%;
    }
`;

export const FinishContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 70%;

    .finish {
        display: flex;
        align-items: center;

        h1 {
            font-size: 42px;
        }
    }

    path {
        fill: #fff;
    }
`;

export const Divider = styled.div`
    content: '';
    width: 25%;
    height: 0;
    margin-top: 18px;
    border: ${props => props.stepIndex < props.index ? 'solid 1px #323232' : 'solid 1px #ff4e86' };

    @media (max-width: 500px) {
      width: 0.1px;
      height: 15px;
      margin-top: 0px;
    }
`;

export const Step = styled.span`
    background-color: ${props => props.stepIndex < props.index ? '#323232' : '#ff4e86'};
    padding: 10px;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 800;
    opacity: ${props => props.stepIndex < props.index ? '50%' : '100%'};
`;