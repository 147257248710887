import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import { verifyRecoverCodeRequest, changePasswordRequest, clearErrors } from '../../store/modules/recoverPassword/actions';

import KopaLogo from '../../assets/kopa-logo.svg';
import attention from '../../assets/alert.svg';
import CodeInput from './CodeInput';
import {
    Container,
    ErrorContaineir,
    Form,
    InputArea,
    InstructionsArea,
    ResendArea,
} from './styles';

function selector({ recoverPassword }) {
    return { error: recoverPassword.error };
}

function getEndTime() {
    return Date.now() + 59 * 1000; // 59 seconds
}

export default function CodeVerification() {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [email, setEmail] = useState('');
    const [messageFailCode, setMessageFailCode] = useState(false);
    const [isTimerFinished, setIsTimerFinished] = useState(false);
    const [endTime, setEndTime] = useState(getEndTime());

    const dispatch = useDispatch();
    const { error } = useSelector(selector, shallowEqual);
    const loading = useSelector((state) => state.recoverPassword.loading);
    const { t } = useTranslation();
    const history = useHistory();
    const inputRefA = useRef();
    const inputRefB = useRef();
    const inputRefC = useRef();
    const inputRefD = useRef();
    const inputRefE = useRef();
    const inputRefF = useRef();

    const INPUTREFS = {
        0: inputRefA,
        1: inputRefB,
        2: inputRefC,
        3: inputRefD,
        4: inputRefE,
        5: inputRefF,
    };

    function formatTimer(currentTime) {
        return currentTime < 10 ? `00:0${currentTime}` : `00:${currentTime}`;
    }

    function resetTimer() {
        setEndTime(getEndTime());
        setIsTimerFinished(false);
    }

    function handleCodeVerification() {
        dispatch(verifyRecoverCodeRequest(email, otp.join('')));
    }

    async function handleNewPassword() {
        if (email !== '') {
            dispatch(changePasswordRequest(email));
            resetTimer();
        } else {
            history.push('/recoverPassword');
        }
    }

    function handleRef(index) {
        return INPUTREFS[index];
    }

    function checkEmptyFields() {
        const otpValues = otp.join('');
        if (otpValues.length === 6) {
            return false;
        }
        return true;
    }

    function handleChange(elem, index) {
        if (index < 5 && INPUTREFS[index]?.current?.value !== '') {
            INPUTREFS[index + 1].current.select();
        }
        setOtp([...otp.map((digit, idx) => ((idx === index) ? elem.target.value : digit))]);
    }

    function keyHandler(evt, index) {
        INPUTREFS[index].current.value = '';

        if (index === 0 && evt.keyCode === 8) {
            setTimeout(() => { INPUTREFS[0].current.value = ''; }, 100);
        }

        if (evt.keyCode === 8 && !loading && index > 0) {
            INPUTREFS[index].current.value = '';
            setTimeout(() => INPUTREFS[index - 1].current.focus(), 100);
            setOtp([...otp.map((digit, idx) => ((idx === index) ? INPUTREFS[index].current.value : digit))]);
            return;
        }
        if (checkEmptyFields()) {
            return;
        }
        if (evt.keyCode === 13 && !loading) {
            handleCodeVerification();
        }
    }

    useEffect(() => {
        dispatch(clearErrors());
    }, [dispatch]);

    useEffect(() => {
        function checkEmailInfo() {
            if (history.location.state) {
                setEmail(history.location.state);
                return;
            }
            history.push('/recoverPassword');
        }

        return checkEmailInfo();
    }, [history]);

    useEffect(() => {
        setMessageFailCode(error);
    }, [error, messageFailCode]);

    return (
        <Container>
            <img className="logo" src={KopaLogo} alt="KopaLogo" />
            <Form>
                <InstructionsArea>
                    <h1>{t('VERIFICATION_CODE_TITLE')}</h1>
                    <p>{t('VERIFICATION_CODE_SENT')}</p>
                    <strong>{email}</strong>
                </InstructionsArea>
                <div className="codeInputLabel">
                    <span>{t('VERIFICATION_CODE_TITLE')}</span>
                </div>
                <InputArea>
                    {
                        otp.map((digit, index) => (
                            <CodeInput
                                id={`inputRef${index}`}
                                ref={handleRef(index)}
                                key={index}
                                name={`otp${index}`}
                                type="text"
                                value={digit}
                                autoComplete={window.chrome ? 'no' : 'off'}
                                onChange={(e) => handleChange(e, index)}
                                alertMessage={messageFailCode && messageFailCode}
                                onKeyDown={e => keyHandler(e, index)}
                            />
                        ))
                    }
                </InputArea>
                {
                    error === t('MAX_RETRIES_VERIFICATION_CODE') || error === t('EXPIRED_VERIFICATION_CODE')
                        ? history.push('/recoverPassword') : error && (
                            <ErrorContaineir>
                                <img src={attention} alt="ico" />
                                <span>{error}</span>
                            </ErrorContaineir>
                        )
                }
                {
                    isTimerFinished ? (
                        <ResendArea>
                            <p>
                                {t('DID_RECEIVE_CODE')}
                                {' '}
                                <strong
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleNewPassword()}
                                >
                                    {t('RESEND_CODE')}
                                </strong>
                            </p>
                        </ResendArea>
                    ) : (
                        <ResendArea>
                            <Countdown
                                date={endTime}
                                onComplete={() => setIsTimerFinished(true)}
                                renderer={props => <p>{formatTimer(props.seconds)} {t('TO_RESEND_CODE')}</p>}
                            />
                        </ResendArea>
                    )
                }
                <button
                    id={
                        checkEmptyFields()
                            ? 'submitButtonDisabled'
                            : 'submitButton'
                    }
                    disabled={
                        loading || checkEmptyFields()
                    }
                    type="submit"
                    onClick={() => handleCodeVerification()}
                >
                    {loading ? `${t('LOADINGCAPS')}...` : t('VERIFY_CODE')}
                </button>
            </Form>
        </Container>
    );
}
