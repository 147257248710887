import styled from 'styled-components';

export const ExtraUserInfoContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
`;

export const NoVideoImage = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 180px;
    }

    @media screen and (max-width: 768px) {
        img {
            width: 100px;
        }
    }
`;

export const TextArea = styled.div`
    width: 100%;
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    h1 {
        font-size: 1.8rem;
        text-align: center;
    }

    p {
        font-size: 1.2rem;
        text-align: center;
    }


    @media screen and (max-width: 768px) {
        p {
            font-size: 1rem;
        }
    }

`;
