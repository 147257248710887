import actions from '../../../utils/actions';

const DEFAULT_STATE = {
    rooms: '',
    selectedRoom: null,
    selectedEditRoom: null,
    statusLiveRoomEditSelected: null,
    roomMessages: [],
    roomPinnedMessages: [],
    isChatEnabled: true,
    questionMessages: [],
    instructorRoomMessages: [],
    teamMessages: [],
    loading: false,
    chatTabs: null,
    chatUserType: {},
    isEnabledTeamChat: false,
    roomEngagement: null,
    roomEventLog: null,
    roomGraph: null,
    roomGeolocalization: null,
    saleEnabled: false,
    videoToUpload: null,
    progress: 0,
    quiz: null,
    technology: null,
    videoUrl: null,
    socketPath: null,
    interactive: false,
    liveStatus: null,
};
export default function roomReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
        case 'ROOM_REQUEST':
            return { ...state, loading: true };
        case 'USER_ROOMS_REQUEST':
            return { ...state, loading: true };
        case 'ROOMS_SUCCESS':
            return { ...state, rooms: payload, loading: false };
        case 'ROOM_SUCCESS':
            return {
                ...state,
                selectedRoom: payload,
                loading: false,
                isChatEnabled: payload.chatEnabled,
                saleEnabled: payload.saleEnabled,
                roomPinnedMessages: payload.pinnedMessages,
                technology: payload.technology,
                videoUrl: payload.videoUrl,
                socketPath: payload.socketPath,
                interactive: payload.interactive,
                liveStatus: payload.liveStatus,
            };
        case actions.ROOM_EDIT_SELECT_SUCCESS:
            return { ...state, selectedEditRoom: payload, loading: false };
        case actions.ROOM_FAIL:
            return { ...state, loading: false };
        case actions.ROOM_EDIT_SELECT:
            return { ...state, loading: true };
        case actions.ROOM_VIDEO_PRIORITY_REQUEST:
            return { ...state, loading: true };
        case actions.ROOM_VIDEO_PRIORITY_REQUEST_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                selectedRoom: {
                    ...state.selectedRoom,
                    ...payload,
                },
            };
        case actions.ROOM_VIDEO_PRIORITY_REQUEST_FAIL:
            return { ...state, loading: false };
        case actions.ROOM_VIDEO_PRIORITY_REQUEST_REMOVE_URL:
            return {
                ...state,
                selectedRoom: {
                    ...state.selectedRoom,
                    videoUrl: null,
                    canWatch: false,
                    video_availability: 'playing_elsewhere',
                },
            };
        case 'ROOM_CLEAR_SELECTED':
            return {
                ...state,
                selectedRoom: null,
                selectedEditRoom: null,
                statusLiveRoomEditSelected: null,
                roomMessages: [],
                roomPinnedMessages: [],
                questionMessages: [],
                instructorRoomMessages: [],
                teamMessages: [],
                chatUserType: DEFAULT_STATE.chatUserType,
                roomEngagement: null,
                roomEventLog: null,
                roomGraph: null,
                roomGeolocalization: null,
                saleEnabled: false,
            };
        case actions.ROOM_CLEAR_EDIT_SELECTED:
            return {
                ...state,
                selectedEditRoom: null,
            };
        case actions.ROOM_SET_STATUS_ROOM:
            return {
                ...state,
                statusLiveRoomEditSelected: payload,
            };
        case 'ROOM_SET_MESSAGES':
            return {
                ...state,
                roomMessages: payload.roomMessages,
                questionMessages: payload.questionMessages,
                instructorRoomMessages: payload.instructorRoomMessages,
                teamMessages: payload.teamMessages,
            };
        case 'ROOM_SET_CHAT_TABS':
            return { ...state, chatTabs: payload };
        case actions.ROOM_CREATE:
            return { ...state, loading: true };
        case actions.ROOM_SET_CHAT_USER_TYPE:
            return { ...state, chatUserType: payload.chatUserType };

        case 'ROOM_ADD_MESSAGE':
            return { ...state, roomMessages: payload };

        case actions.ROOM_CHAT_SET_ENABLE_TEAM:
            return {
                ...state,
                isEnabledTeamChat: payload.isEnabledTeamChat,
                teamMessages: !payload.isEnabledTeamChat ? [] : state.teamMessages,
            };

        case actions.ROOM_CHAT_SET_CHAT_ENABLE_ROOM:
            return {
                ...state,
                isChatEnabled: payload.isChatEnabled,
                roomMessages: !payload.isChatEnabled ? [] : state.roomMessages,
                questionMessages: !payload.isChatEnabled ? [] : state.questionMessages,
                instructorRoomMessages: !payload.isChatEnabled ? [] : state.instructorRoomMessages,
                teamMessages: !payload.isChatEnabled ? [] : state.teamMessages,
            };
        case actions.UPDATED_BLOCKED_USER_MESSAGES: {
            const { userId } = payload;
            const currentMessages = [...state.roomMessages];

            const updatedMessages = currentMessages.map((message) => {
                if (message.userId === userId) {
                    return { ...message, blocked: true };
                }
                return message;
            });
            return { ...state, roomMessages: updatedMessages };
        }

        case actions.ROOM_CHAT_SET_TEAM_INFORMATION:
            return {
                ...state,
                teamInformation: payload.teamInformation,
                teamMessages: (payload.teamInformation.teamId !== state.teamInformation.teamId) ? [] : state.teamMessages,
            };
        case actions.ROOM_SET_ENGAGEMENT:
            return {
                ...state,
                roomEngagement: payload,
            };

        case actions.SET_QUIZ:
            return { ...state, quiz: payload };
        case actions.ROOM_SET_EVENTLOG:
            return { ...state, roomEventLog: payload };
        case actions.ROOM_SET_GRAPH:
            return { ...state, roomGraph: payload };
        case actions.ROOM_SET_GEOLOCALIZATION:
            return { ...state, roomGeolocalization: payload };
        case actions.ROOM_SHOW_QRCODE:
            return { ...state, saleEnabled: payload };
        case actions.ROOM_SET_PIN_MESSAGE:
            return { ...state, roomPinnedMessages: payload };
        case actions.ROOM_SET_CHAT_ENABLED:
            return { ...state, isChatEnabled: payload.chatEnabled };
        case actions.ROOM_SET_TO_LIVE:
            return {
                ...state,
                selectedRoom: {
                    ...state.selectedRoom,
                    live: true,
                },
            };
        case actions.ROOM_SET_VIDEO_UPLOAD:
            return { ...state, videoToUpload: payload };
        case actions.ROOM_UPLOAD_VIDEO_UPDATE_PROGRESS:
            return { ...state, progress: payload };
        case actions.ROOM_UPLOAD_IN_BACKGROUND:
            return { ...state, isUploadingInBackground: payload };
        case 'CLEAR_ALL':
        case 'LOGOUT':
            return DEFAULT_STATE;
        default:
            return state;
    }
}
