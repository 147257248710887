import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #444;
  position: relative;

  div.upload-info {
    width: 28px;
    height: 28px;

    position: absolute;
    right: 8px;

    button {
      background-color: #fcefe2;
      border-radius: 50%;
      padding: 7px 8px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #fdf9f5;
      }
    }
  }
`;

export const Preview = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;
