import React from 'react';
import { Container } from './styles';
import noVideoIcon from '../../../assets/no-video.svg';

export default function NoVideo({ title = '', subtitle = '' }) {
    return (
        <Container>
            <img src={noVideoIcon} alt="No video available" />
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
        </Container>
    );
}
