import CONSTANTS from '../constants';

export default {
    [CONSTANTS.ACTIVE]: 'Activo',
    [CONSTANTS.ACCEPTTERMS]: 'Acepto los términos de uso',
    [CONSTANTS.ADDITIONAL_INFORMATION]: 'INFORMACIONES ADICIONALES',
    [CONSTANTS.ALREADY_LOGED_IN]: 'Ya estás conectado a otra máquina.',
    [CONSTANTS.APPLY]: 'Aplicar',
    [CONSTANTS.ATTACHMENTS]: 'Archivos adjuntos',
    [CONSTANTS.BACKTO]: 'Regresar a',
    [CONSTANTS.BACK_INITIAL_PAGE]: 'VOLVER A LA PÁGINA INICIAL',
    [CONSTANTS.BITRATE]: 'Bitrate',
    [CONSTANTS.BLOCK_USER_NOT_ALLOWED]: 'No se permite bloquear a este usuario.',
    [CONSTANTS.BUFFER_CLASSIFICATION]: 'Clasificación de búfer',
    [CONSTANTS.BUFFER_CLASSIFICATION_ABREVIATION]: 'Clas. de búfer',
    [CONSTANTS.BROADCASTING_ROUTING_LEAVE]: 'Salir de la página detendrá la transmisión. ¿Continuar?',
    [CONSTANTS.CANCEL]: 'Cancelar',
    [CONSTANTS.CONCURRENT_ACCESS_VIDEO]: 'Ya estás mirando en otro dispositivo, ¿quieres mirar en ese dispositivo?',
    [CONSTANTS.CHANNEL_ADDNEWVIDEO]: 'Agregar nuevo video',
    [CONSTANTS.CHANNEL_ADDNEWLIVEVIDEO]: 'En vivo | En vivo',
    [CONSTANTS.CHANNEL_ADDNEWRECORDEDVIDEO]: 'Carga de video',
    [CONSTANTS.CHANNEL_CHAT_ENABLED]: 'EL CHAT DE TRANSMISIÓN ES',
    [CONSTANTS.CHANNEL_CLEANVIDEO]: 'Borrar video',
    [CONSTANTS.CHANNEL_COPIED]: 'Copiado',
    [CONSTANTS.CHANNEL_CREATEVIDEO]: 'Crear video',
    [CONSTANTS.CHANNEL_CREATEVIDEO2]: 'Crear nuevo video',
    [CONSTANTS.CHANNEL_EDITVIDEO]: 'Editar video',
    [CONSTANTS.CHANNEL_ENDDATE]: 'FECHA Y HORA FINAL',
    [CONSTANTS.CHANNEL_ENDLIVE]: 'End live!',
    [CONSTANTS.CHANNEL_ENDLIVEDESCRIPTION]: '¿De verdad quieres terminar la transmisión?',
    [CONSTANTS.CHANNEL_ENDLIVEIRREVERSIBLE]: 'Esta operación no se puede deshacer',
    [CONSTANTS.CHANNEL_FAILREQUESTROOMVIDEOPRIORITY]: 'No se pudo solicitar acceso al video',
    [CONSTANTS.CHANNEL_FILES]: 'Archivo',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Los archivos deben tener los siguientes formatos: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_GOLIVE]: 'Go live!',
    [CONSTANTS.CHANNEL_MYVIDEOS]: 'Mis vídeos',
    [CONSTANTS.CHANNEL_NEWVIDEO]: 'Nuevo video',
    [CONSTANTS.CHANNEL_NOVIDEO]: 'No hay habitaciones registradas actualmente',
    [CONSTANTS.CHANNEL_PRIMARYURL]: 'URL PRINCIPAL',
    [CONSTANTS.CHANNEL_PASSWORD]: 'CONTRASEÑA',
    [CONSTANTS.CHANNEL_ROOMNOTFOUND]: 'Habitación no encontrada',
    [CONSTANTS.CHANNEL_SECONDARYURL]: 'URL SECUNDARIA',
    [CONSTANTS.CHANNEL_SELECTCOURSE]: 'SELECCIONE EL CANAL AL ​​QUE PERTENECE EL VIDEO',
    [CONSTANTS.CHANNEL_SELECTCOURSEPLACEHOLDER]: 'Seleccione un curso',
    [CONSTANTS.CHANNEL_SELECTTIMEZONE]: 'SELECCIONE LA ZONA HORARIA',
    [CONSTANTS.CHANNEL_SENDVIDEO]: 'Subir video',
    [CONSTANTS.CHANNEL_STARTDATE]: 'FECHA Y HORA DE INICIO',
    [CONSTANTS.CHANNEL_THUMBNAIL]: 'Miniatura',
    [CONSTANTS.CHANNEL_THUMBNAILSIZE]: 'Tamaño mínimo 266x110',
    [CONSTANTS.CHANNEL_UPLOADFILES]: 'SUBIR ARCHIVO',
    [CONSTANTS.CHANNEL_UPLOADVIDEO]: 'CARGA DE VIDEO',
    [CONSTANTS.CHANNEL_VIDEOCOVER]: 'PORTADA DE VIDEO',
    [CONSTANTS.CHANNEL_VIDEODESCRIPTION]: 'DESCRIPCIÓN DEL VIDEO',
    [CONSTANTS.CHANNEL_VIDEOFORMAT]: 'El video debe tener los siguientes formatos: MP4, MKV',
    [CONSTANTS.CHANNEL_VIDEOTITLE]: 'TÍTULO DO VÍDEO',
    [CONSTANTS.CHAT]: 'chat',
    [CONSTANTS.COMEBACK]: 'Vuelve',
    [CONSTANTS.CONFIRM]: 'Confirmar',
    [CONSTANTS.CONTROLS]: 'CONTROLES',
    [CONSTANTS.DISABLE_QUESTION]: 'DESABILITAR?',
    [CONSTANTS.DISABLE_QUESTION]: '¿DESHABILITAR?',
    [CONSTANTS.DISABLED_CAMERA]: 'Cámara Desactivada',
    [CONSTANTS.DO_KNOW_KOPA_APP]: 'Conoces la App de Kopa?',
    [CONSTANTS.DO_KNOW_KOPA_APP_TEXT]: 'Puede acceder a nuestra aplicación en cualquier momento y en cualquier lugar, solo estar conectado a internet con calidad suficiente para transmitir de contenido',
    [CONSTANTS.DOWNLOAD_KOPA_APP]: 'DESCARGA LA APP AHORA',
    [CONSTANTS.DONT_SHOW_AGAIN]: 'NO VOLVER A MOSTRAR',
    [CONSTANTS.DOUBTS]: 'Dudas',
    [CONSTANTS.EDIT]: 'Para editar',
    [CONSTANTS.ENABLE_QUESTION]: '¿HABILITAR?',
    [CONSTANTS.ENABLED_CAPS]: 'HABILITADO',
    [CONSTANTS.ENDDATE]: 'Finalización',
    [CONSTANTS.ENGLISH]: 'Inglés',
    [CONSTANTS.EMAIL]: 'CORREO ELECTRÓNICO',
    [CONSTANTS.ENTER_ROOM]: 'Entra en la sala',
    [CONSTANTS.ERROR404]: 'Error 404',
    [CONSTANTS.ERROR_CHANGEPASSWORD]: 'No se pudo cambiar la contraseña, estado de error:',
    [CONSTANTS.ERROR_CREDENTIALS]: 'Credenciales de acceso inválidas, caducadas o inexistentes',
    [CONSTANTS.ERROR_PARAMETERS]: 'Uno o más parámetros de entrada no son válidos.',
    [CONSTANTS.ERROR_DEFAULT]: 'Error inesperado del servidor',
    [CONSTANTS.ERROR_EMAILNOTFOUND]: 'EL CORREO ELETRÓNICO NO ENCONTRADO',
    [CONSTANTS.ERROR_LOGIN]: 'CORREO ELECTRÓNICO O CONTRASEÑA INCORRECTOS',
    [CONSTANTS.ERROR_LOGINFAIL]: 'Error de inicio de sesión, estado de error:',
    [CONSTANTS.ERROR_NONETWORK]: 'Sin acceso a Internet',
    [CONSTANTS.ERROR_RECOVERPASSFAIL]: 'No se pudo restaurar la contraseña',
    [CONSTANTS.FAIL_BLOCK_USER]: 'No se pudo bloquear al usuario',
    [CONSTANTS.FAIL_CHANGELANGUAGE]: 'No se pudo realizar el cambio de idioma',
    [CONSTANTS.FAIL_CHANGEOFFERSTATUS]: 'No se pudo cambiar el estado de la oferta',
    [CONSTANTS.FAIL_CHATNOTFOUND]: 'No se encontró la sala de chat',
    [CONSTANTS.FAIL_CONNECTWEBSOCKET]: 'No se pudo conectar a websocket',
    [CONSTANTS.FAIL_CREATEOFFER]: 'No se pudo crear la oferta',
    [CONSTANTS.FAIL_CREATETEAMS]: 'No se pudieron crear equipos',
    [CONSTANTS.FAIL_ENTERROOM]: 'No se pudo entrar a la habitación',
    [CONSTANTS.FAIL_EXITROOM]: 'No pudo salir de la habitación',
    [CONSTANTS.FAIL_GETCHAT]: 'No se encontró la sala de chat',
    [CONSTANTS.FAIL_GETOFFER]: 'La solicitud de oferta falló',
    [CONSTANTS.FAIL_GETTEAMSSTATUS]: 'No se pudo solicitar el estado del equipo',
    [CONSTANTS.FAIL_GETTEAMS]: 'No se pudieron solicitar equipos',
    [CONSTANTS.FAIL_LOGOUT]: 'No se pudo cerrar la sesión',
    [CONSTANTS.FAIL_SETTEAMSSTATUS]: 'No se pudo cambiar el estado del equipo',
    [CONSTANTS.FAILED_GET_USER_PROFILE]: 'FALLO EN OBTENER PERFIL DE USUARIO',
    [CONSTANTS.FILTER_FIRST]: 'Seleccione el primer filtro',
    [CONSTANTS.FILTER_SECOND]: 'Seleccione el segundo filtro',
    [CONSTANTS.FILTER_SPECIFIC]: 'Período de tiempo',
    [CONSTANTS.FILTER_PRESETOPTIONS]: ['1 minuto', '5 minutos', '30 minutos', '1 hora', '3 horas', '6 horas', '24 horas', '3 dias', '7 dias'],
    [CONSTANTS.FILTER_TIME]: 'Selecione o tempo',
    [CONSTANTS.FLOW_PRIMARY]: 'Flujo primario',
    [CONSTANTS.FLOW_SECONDARY]: 'Flujo secundario',
    [CONSTANTS.FORGETPASSWORD]: '¿OLVIDASTE TU CONTRASEÑA?',
    [CONSTANTS.FORGETPASSWORD_EMAIL]: 'Ingrese el correo electrónico que está registrado en el portal y le enviaremos un código de verificación.',
    [CONSTANTS.FROM_TIME]: 'En',
    [CONSTANTS.GENERAL]: 'General',
    [CONSTANTS.GEOLOCATION]: 'Geolocalización',
    [CONSTANTS.GRAPH_AVG_VIEWBITRATE]: 'Tasa de bits promedio',
    [CONSTANTS.GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH]: 'Ancho de banda descendente',
    [CONSTANTS.GRAPH_AVG_VIEWDROPPEDFRAMESRATIO]: 'Cuadros perdidos promedio',
    [CONSTANTS.GRAPH_AVG_VIEWLIVELATENCY]: 'Latencia media',
    [CONSTANTS.GRAPH_SUMVIEWTIME]: 'Suma del tiempo de visualización',
    [CONSTANTS.GRAPH_VIEWERS]: 'Usuarios',
    [CONSTANTS.GRAPH_VIEWBUFFERTIMERATIO]: 'Tiempo medio de búfer',
    [CONSTANTS.GRAPH_VIEWERSBUFFERING]: 'Buffering de usuários',
    [CONSTANTS.GRAPH_VIEWERSENGAGEMENT]: 'Participación del usuario',
    [CONSTANTS.GRAPH_VIEWERSDVR]: 'Usuarios DVR',
    [CONSTANTS.HOME]: 'Inicio',
    [CONSTANTS.HOMEPAGE]: 'Página de inicio',
    [CONSTANTS.HOME_WELCOME]: 'ES UN PLACER TENERTE AQUÍ CON NOSOTROS',
    [CONSTANTS.HOME_NOLIVE]: 'Lo sentimos, actualmente no tenemos ningún live en progreso.',
    [CONSTANTS.HOME_FEATURED_LIVES]: 'En vivo en este momento',
    [CONSTANTS.HOURS]: 'Horas',
    [CONSTANTS.INACTIVE]: 'Inactivo',
    [CONSTANTS.INVALID_IMG_TYPE]: 'Tipo no válido. El archivo debe ser una imagen.',
    [CONSTANTS.INTERACTIVE_CHAT]: 'CHAT INTERACTIVO',
    [CONSTANTS.FINISHED]: 'Indisponible',
    [CONSTANTS.LANGUAGE]: 'IDIOMA',
    [CONSTANTS.LAST]: 'Ultimo',
    [CONSTANTS.LIVE]: 'En vivo',
    [CONSTANTS.LOADING]: 'Cargando',
    [CONSTANTS.LOADINGCAPS]: 'CARGANDO',
    [CONSTANTS.LOADINGPDF]: 'Cargando PDF',
    [CONSTANTS.LOGIN]: 'LOGIN',
    [CONSTANTS.LOGIN_EMAIL]: 'CORREO ELECTRÓNICO',
    [CONSTANTS.LOGIN_PASSWORD]: 'CONTRASEÑA',
    [CONSTANTS.LOGIN_SIGNIN]: 'INICIAR SESIÓN',
    [CONSTANTS.MESSAGE]: 'Mensaje',
    [CONSTANTS.INVALID_VERIFICATION_CODE]: 'CÓDIGO DE VERIFICACIÓN INVALIDO',
    [CONSTANTS.VERIFICATION_CODE_TITLE]: 'CÓDIGO DE VERIFICACIÓN',
    [CONSTANTS.VERIFICATION_CODE_SENT]: 'Enviamos un código de verificación al correo electrónico',
    [CONSTANTS.MONITORS]: 'Monitores',
    [CONSTANTS.NAMECAPS]: 'NOMBRE',
    [CONSTANTS.NEXT_STEP]: 'Próxima Etapa',
    [CONSTANTS.NO]: 'No',
    [CONSTANTS.NO_GAMIFICATION_RANKING]: 'Las puntuaciones aún no han sido computadas. Espera un poco más y durante el evento se actualizará la clasificación.',
    [CONSTANTS.NOTLIVE]: 'Transmisión cerrado',
    [CONSTANTS.NEXTPAGE]: 'Siguiente',
    [CONSTANTS.OF]: 'en',
    [CONSTANTS.OFFERS]: 'Ofertas',
    [CONSTANTS.OFFERS_ACTIVATEOFFERS]: 'Activar oferta',
    [CONSTANTS.OFFERS_BASEURL]: 'Insertar la plantilla de URL',
    [CONSTANTS.OFFERS_CREATEOFFER]: 'Crear oferta',
    [CONSTANTS.OFFERS_EDITOFFER]: 'Editar oferta',
    [CONSTANTS.OFFERS_ENABLECONFIRM]: '¿Quieres activar una oferta?',
    [CONSTANTS.OFFERS_MODEOFFERSINACTIVATE]: 'Las ofertas están deshabilitadas',
    [CONSTANTS.OFFERS_NOOFFERS]: 'No hay ofertas registradas',
    [CONSTANTS.OFFERS_SALESFORCEID]: 'Salesforce Id',
    [CONSTANTS.OFFERS_SELECTOR]: 'Seleccione el tipo de ID de plantilla',
    [CONSTANTS.OFFERS_TEMPLATEURL]: 'Template url',
    [CONSTANTS.OFFERS_TYPEID]: 'Tipo de identificación',
    [CONSTANTS.OFFLINE_ROOM]: 'Sala sin conexión',
    [CONSTANTS.ON]: 'on',
    [CONSTANTS.OPS]: 'Ops!',
    [CONSTANTS.PERMISSION_ENTERCHAT]: 'No se le permite ingresar a esta sala de chat',
    [CONSTANTS.PERMISSION_PINMESSAGE]: 'No se le permite fijar un mensaje en esta sala de chat',
    [CONSTANTS.PERMISSION_UNPINMESSAGE]: 'No puedes desanclar mensajes en esta sala de chat',
    [CONSTANTS.SETTINGS_AUDIO_VIDEO]: 'Configuración de audio y video',
    [CONSTANTS.VOUCHER_LOGIN]: 'Regístrese con su bono',
    [CONSTANTS.VOUCHER_PASSWORD_CREATION_TITLE]: 'CREACIÓN DE CONTRASEÑA',
    [CONSTANTS.VOUCHER_CODE_TITLE]: 'CÓDIGO DE CUPÓN',
    [CONSTANTS.VOUCHER_PASSWORD_LABEL]: 'CONTRASEÑA',
    [CONSTANTS.VOUCHER_PASSWORD_CONFIRM_LABEL]: 'CONFIRMAR CONTRASEÑA',
    [CONSTANTS.VOUCHER_PASSWORD_SAME]: 'Las contraseñas deben ser las mismas',
    [CONSTANTS.VOUCHER_PASSWORD_MIN]: 'La contraseña debe tener al menos 6 caracteres',
    [CONSTANTS.VOUCHER_FINALIZE_REGISTRATION]: 'Finalizar registro',
    [CONSTANTS.VOUCHER_CODE_SUBTITLE]: 'Inserte su código de cupón aquí',
    [CONSTANTS.VOUCHER_BUTTON_SUBMIT]: 'VALIDAR VOUCHER',
    [CONSTANTS.VOUCHER_REGISTER_TITLE]: 'REGISTRO EXITOSO',
    [CONSTANTS.VOUCHER_TERMS]: ['O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.',
        'O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.'],
    [CONSTANTS.VOUCHER_TERMS_ACEPPT]: 'ACEPTO LOS TÉRMINOS DE USO',
    [CONSTANTS.VOUCHER_TERMS_TITLE]: 'TÉRMINOS DE USO',
    [CONSTANTS.VOUCHER_TERMS_VALIDATION]: 'Acepte los términos para continuar',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT1]: 'ACEPTO LA ',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT2]: 'POLÍTICA DE PRIVACIDAD',
    [CONSTANTS.VOUCHER_NEXT_STEP_BUTTON]: 'Proximo paso',
    [CONSTANTS.VOUCHER_USERDATA_TITLE]: 'INFORMACIÓN PERSONAL',
    [CONSTANTS.VOUCHER_USERDATA_FILL]: 'Completa todos los datos',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME]: 'NOMBRE COMPLETO',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL]: 'E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_CONFIRM]: 'CONFIRMAR E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_DIFFERS]: 'Las direcciones de correo electrónico no son las mismas',
    [CONSTANTS.VOUCHER_USERDATA_DATE_OF_BIRTH]: 'FECHA DE NACIMIENTO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER]: 'GÉNERO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_MALE]: 'Masculino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_FEM]: 'Femenino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_OTHER]: 'Otro',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_SELECT]: 'Seleccione el sexo',
    [CONSTANTS.VOUCHER_USERDATA_INVALID_DATE]: 'Fecha de nacimiento no válida',
    [CONSTANTS.VOUCHER_USERDATA_PHONE]: 'TELÉFONO',
    [CONSTANTS.VOUCHER_USERDATA_PHONE_PATTERN]: 'Use solo números en el campo del teléfono',
    [CONSTANTS.VOUCHER_USERDATA_TYPE_FULL_NAME]: 'Nombre completo del usuario',

    [CONSTANTS.QUIZ_HEADER]: 'Seleccione una de las opciones para responder',
    [CONSTANTS.PAGE]: 'Página',
    [CONSTANTS.PLEASECLOSE]: 'Por favor cierra',
    [CONSTANTS.PORTUGUESE]: 'Portugués',
    [CONSTANTS.PRESET]: 'Preestablecido',
    [CONSTANTS.PREVIOUSPAGE]: 'Anterior',
    [CONSTANTS.FINISHPAGE]: 'Finalizar',
    [CONSTANTS.SKIP]: 'Pasar',
    [CONSTANTS.QUESTIONS]: 'Preguntas',
    [CONSTANTS.QUIZ_FAILED]: 'No se pudo cargar la prueba',
    [CONSTANTS.RECOVERPASSWORD_MINLENGTH]: 'La contraseña debe tener al menos 8 dígitos',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORD]: 'NUEVA CONTRASEÑA',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORDREPEAT]: 'REPITA LA NUEVA CONTRASEÑA',
    [CONSTANTS.RECOVERPASSWORD_NOLINK]: 'O link não está mais disponível',
    [CONSTANTS.RECOVERPASSWORD_NOMATCH]: 'Las contraseñas no coinciden',
    [CONSTANTS.RECOVERPASSWORD_REDEFINE]: 'REDEFINIR CONTRASEÑA',
    [CONSTANTS.RECOVERPASSWORD_SUCCESS]: 'Contraseña restablecida correctamente',
    [CONSTANTS.RECOVERPASSWORD_VERIFY]: 'CONSULTAR SU CORREO ELECTRÓNICO',
    [CONSTANTS.RECOVERPASSWORD_VERIFYDESC]: 'Se enviará un correo electrónico a su casilla de correo electrónico para que cambie su contraseña.',
    [CONSTANTS.ROOM_INTERACTIVE_INFORMATIONS]: 'En esta sala puede interactuar con el presentador cuando lo solicite. No olvide verificar la configuración de audio y de la cámara.',
    [CONSTANTS.TYPE_NEW_PASSWORD]: 'Ingrese la nueva contraseña que desea usar para iniciar sesión en nuestra plataforma aquí.',
    [CONSTANTS.SEARCH]: 'Buscar',
    [CONSTANTS.SEARCH_NOT_FOUND]: 'No hay entradas para mostrar',
    [CONSTANTS.SELECTQUESTION]: 'Selecciona una pregunta',
    [CONSTANTS.SEND]: 'ENVIAR',
    [CONSTANTS.SEVERITY_BAD]: 'Malo',
    [CONSTANTS.SEVERITY_GOOD]: 'Bueno',
    [CONSTANTS.SEVERITY_WARN]: 'Advertencia',
    [CONSTANTS.SORRYPROBLEM]: 'Lo sentimos, tuvimos un problema.',
    [CONSTANTS.SPANISH]: 'Español',
    [CONSTANTS.STARTDATE]: 'Inicio',
    [CONSTANTS.STATUS]: 'Status',
    [CONSTANTS.STATUS_LIVE]: 'Transmisión status',
    [CONSTANTS.SUCCESS]: 'ÉXITO',
    [CONSTANTS.TEAM]: 'Equipo',
    [CONSTANTS.TEAMS]: 'Equipos',
    [CONSTANTS.TEAMS_ACTIVATETEAMS]: 'Habilitar equipos',
    [CONSTANTS.TEAMS_CREATETEAMS]: 'Crear equipos',
    [CONSTANTS.TEAMS_CREATETEAMSDESC]: 'Crea equipos y haz que la experiencia de los participantes sea aún más extraordinaria',
    [CONSTANTS.TEAMS_GENERATETEAMS]: 'Generar equipos',
    [CONSTANTS.TEAMS_MODETEAMS]: 'Modo equipo',
    [CONSTANTS.TEAMS_MODETEAMSINACTIVATE]: 'MODO EQUIPOS APAGADO',
    [CONSTANTS.TEAMS_NOTEAMS]: 'No hay equipos registrados en este momento',
    [CONSTANTS.TEAMS_QUANTITY]: 'Número de personas del equipo',
    [CONSTANTS.TRY_AGAIN]: 'Intentar nuevamente',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Formato de archivo no soportado',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Suelta la imagen aquí',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Suelta el (los) archivo (s) aquí',
    [CONSTANTS.UPLOAD_VIDEO_SUCCESS]: 'Carga de video finalizada!',
    [CONSTANTS.UPLOAD_VIDEO_FAILURE]: 'Error de carga de video!',
    [CONSTANTS.UPLOAD_VIDEO_PREPARING]: 'Preparando video.',
    [CONSTANTS.UPLOADING]: 'Subiendo',
    [CONSTANTS.USERS_ACTIVE]: 'Usuarios activos',
    [CONSTANTS.USERS_ACTIVECAPS]: 'USUARIOS ACTIVOS',
    [CONSTANTS.USERS_DISTRIBUTIONSHORT]: 'DIS. DE USUARIOS',
    [CONSTANTS.USERS_ENGAGEMENT]: 'Usuarios comprometidos',
    [CONSTANTS.VIDEO]: 'Vídeo',
    [CONSTANTS.VIDEO_DESCRIPTION]: 'Descripción del video',
    [CONSTANTS.VOUCHER]: 'VOUCHER',
    [CONSTANTS.VOUCHER_ENTER_FIELDS]: 'Complete todos los valores',
    [CONSTANTS.VOUCHER_EMAIL_VALIDATION]: 'VALIDACIÓN DE CORREO ELECTRÓNICO',
    [CONSTANTS.VOUCHER_ERROR_COURSE]: 'El usuario ya tiene el curso',
    [CONSTANTS.VOUCHER_ERROR_VERIFY]: 'No se pudo verificar el cupón',
    [CONSTANTS.VOUCHER_ERROR_USERCREATED]: 'Usuario ya registrado',
    [CONSTANTS.VOUCHER_ERROR_INVALIDCODE]: 'No se pudo verificar el código',
    [CONSTANTS.VOUCHER_ERROR_EXPIREDCODE]: 'Código caducado, reenviar el código al correo electrónico',
    [CONSTANTS.VOUCHER_ERROR_RESENDEMAIL]: 'No se pudo reenviar el correo electrónico',
    [CONSTANTS.VOUCHER_ERROR_CONFIRMPASSWORD]: 'No se pudo confirmar la contraseña',
    [CONSTANTS.VOUCHER_ERROR_REGISTERDATA]: 'No se pudieron registrar los datos',
    [CONSTANTS.VOUCHER_ERROR_REGISTER]: 'No se pudo registrar el vale',
    [CONSTANTS.ERROR_ROOM_ID]: '¡Debes proporcionar la "id de sala" para poder enviar estadísticas!',
    [CONSTANTS.VOUCHER_INVALID]: 'Código invalido',
    [CONSTANTS.VOUCHER_NEW_VOUCHER]: 'Cupón nuevo',
    [CONSTANTS.VOUCHER_VALIDATE]: 'Tu bono con el código',
    [CONSTANTS.VOUCHER_VALIDATEPT2]: 'ha sido validado con éxito',
    [CONSTANTS.VOUCHER_VALIDATE_CODE]: 'Validar bono',
    [CONSTANTS.VOUCHER_VALIDATE_DENIED]: 'no ha sido validado con éxito',
    [CONSTANTS.VOUCHER_VALIDATE_CODECAPS]: 'VALIDACIÓN DE VALES',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTION]: '¿De verdad quieres validar el bono',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTIONPT2]: 'que libera el canal',
    [CONSTANTS.VOUCHER_REGISTERED]: 'Cupón registrado correctamente',
    [CONSTANTS.VOUCHER_VERIFICATION_CODE]: 'INSPIRE AQUI OU CÓDIGO DE VERIFICAÇÃO QUE FOI ROTEU PARA OU E-MAIL',
    [CONSTANTS.VOUCHER_YOUR_CODE]: 'SU CÓDIGO DE VOUCHER',
    [CONSTANTS.VOUCHER_RESEND_EMAIL]: 'Reenviar código',
    [CONSTANTS.VOUCHER_RESENT_EMAIL]: 'Código reenviado',
    [CONSTANTS.VOUCHER_STEPS]: ['Condiciones de uso', 'Datos personales', 'Validación', 'Creación de contraseña'],
    [CONSTANTS.VOUCHER_WARNING_VERIFY]: 'No se pudo verificar el cupón',
    [CONSTANTS.WATCH_HERE]: 'VER EN ESTE DISPOSITIVO',
    [CONSTANTS.WARNINGS_MESSAGES]: 'Muchos mensajes expresados ​​en poco tiempo. Vuelve a intentarlo más tarde.',
    [CONSTANTS.WARNINGS_MESSAGES_FAIL]: 'No se pudo enviar el mensaje',
    [CONSTANTS.WARNINGS_MESSAGES_NOTALLOWED]: 'Por su seguridad, no está permitido enviar enlaces, números de teléfono y correos electrónicos.',
    [CONSTANTS.WARNINGS_MESSAGES_NOTFOUND]: 'No se encontró la sala de chat',
    [CONSTANTS.WARNINGS_MESSAGES_PERMISSIONS]: 'No se le permite enviar un mensaje en esta sala de chat',
    [CONSTANTS.YES]: 'Si',
    [CONSTANTS.ORGANIZER]: 'Organizador',
    [CONSTANTS.MAX_PIN_MESSAGE_LIMIT]: 'Límite de 2 mensajes anclados a la vez!',
    [CONSTANTS.SHOW_ALL]: 'MOSTRAR TODO',
    [CONSTANTS.NEW_PASSWORD]: 'NUEVA CONTRASEÑA',
    [CONSTANTS.REPEAT_NEW_PASSWORD]: 'REPITA LA NUEVA CONTRASEÑA',
    [CONSTANTS.SAVE_PASSWORD]: 'GUARDAR CONTRASEÑA',
    [CONSTANTS.CREATE_NEW_PASSWORD]: 'CREAR NUEVA CONTRASEÑA',
    [CONSTANTS.FIRST_ACESS_MESSAGE]: '¡Bienvenido a Kopa! Este es tu primer contacto con nuestra plataforma y para facilitar su próximo acceso cree una nueva contraseña para usted.',
    [CONSTANTS.NETWORK_FAILED]: 'No se puede acceder a nuestro servidor',
    [CONSTANTS.NETWORK_FAILED_MESSAGE]: 'Comprueba tu conexión y vuelve a intentarlo',
    [CONSTANTS.FAIL_CHANGE_PASSWORD]: 'no se pudo cambiar la contraseña, inténtelo de nuevo',
    [CONSTANTS.BLOCK]: 'Bloquear',
    [CONSTANTS.USER]: 'Usuario',
    [CONSTANTS.BLOCKING_MSG]: 'Quieres bloquear',
    [CONSTANTS.NO]: 'No',
    [CONSTANTS.USER_BLOCKED]: 'Usuario bloqueado con éxito!',
    [CONSTANTS.BLOCKED_USER_CHAT]: 'USUARIO BLOQUEADO!',
    [CONSTANTS.WELCOME_TO_KOPA]: 'BIENVENIDO A KOPA',
    [CONSTANTS.WELCOME_TO_ROOM_INTERACTIVE]: 'Bienvenidos a la sala interactiva',
    [CONSTANTS.INVALID_EMAIL]: 'E-MAIL INVÁLIDO.',
    [CONSTANTS.DID_RECEIVE_CODE]: '¿NO RECIBISTE TU CÓDIGO?',
    [CONSTANTS.RESEND_CODE]: 'REENVIAR CODIGO',
    [CONSTANTS.VERIFY_CODE]: 'CONSULTAR EL CÓDIGO',
    [CONSTANTS.EXPIRED_VERIFICATION_CODE]: 'CÓDIGO CADUCADO',
    [CONSTANTS.CODE_SENT]: 'Enviado',
    [CONSTANTS.TO_RESEND_CODE]: 'PARA REENVIAR EL CÓDIGO',
    [CONSTANTS.MAX_ATTEMPTS_VERIFICATION_CODE]: 'MÁXIMOS INTENTOS ALCANZADOS',
    [CONSTANTS.INTERVAL_VERIFICATION_CODE]: 'DEBES ESPERAR 1 MINUTO PARA VOLVER A ENVIAR EL CÓDIGO',
    [CONSTANTS.ERROR_IMAGE_UPLOAD]: 'FALLO AL CARGAR LA IMAGEN',
    [CONSTANTS.SOCIAL_MEDIA]: 'REDES SOCIALES',
    [CONSTANTS.PROFILE_UPDATED]: 'PERFIL ACTUALIZADO',
    [CONSTANTS.PROFILE_UPDATE_FAILED]: 'NO SE PUEDO ACTUALIZAR EL PERFIL',
    [CONSTANTS.CROP_IMAGE]: 'DELIMITAR IMAGEN',
    [CONSTANTS.VIEW_PROFILE]: 'VER PERFIL',
    [CONSTANTS.VIDEO_UNAVAILABLE]: 'VIDEO NO DISPONIBLE',
    [CONSTANTS.LIVE_ACCESS_DENIED]: 'Lo siento, no puedes ver este video como un invitado.',
    [CONSTANTS.SAVE]: 'GUARDAR',
    [CONSTANTS.MY_PROFILE]: 'MI PERFIL',
    [CONSTANTS.ACCESS_NOT_ALLOWED]: 'No tienes permiso para acceder a este contenido.',
    [CONSTANTS.HIDE]: 'Esconder',

    [CONSTANTS.RANKING_POSITION]: 'Lugar',
    [CONSTANTS.NAME]: 'Nombre',
    [CONSTANTS.PLACE]: 'Lugar',
    [CONSTANTS.YOU]: 'usted',
    [CONSTANTS.ARE_IN]: 'estás en el',
    [CONSTANTS.WITH]: 'con',
    [CONSTANTS.VIEW_RANKING]: 'Ver ranking',
    [CONSTANTS.TOTAL_POINTS]: 'Total de puntos',
    [CONSTANTS.FAILED_GET_RANKING]: 'No se pudo obtener la clasificación',

    [CONSTANTS.WAITING_TRANSMISSION]: 'Esperando transmisión',
    [CONSTANTS.PREPARING]: 'Preparando...',
    [CONSTANTS.STARTING_LIVE]: 'Comenzando en vivo...',
    [CONSTANTS.START_TRANSMISSION]: 'Iniciar transmisión',
    [CONSTANTS.ALLOW_USER_MEDIA_ACCESS]: 'Permita el acceso a la cámara y al micrófono para iniciar una transmisión en vivo.',
    [CONSTANTS.MICROFONES]: 'Micrófonos',
    [CONSTANTS.CAMERAS]: 'Cámaras',
    [CONSTANTS.END_LIVE]: 'Terminar en vivo',
    [CONSTANTS.READY_TO_START_QUESTION]: '¿Estás listo para comenzar tu transmisión?',
    [CONSTANTS.CHECK_MEDIA_SETTINGS]: 'No olvide comprobar la configuración de su cámara y audio.',
    [CONSTANTS.TRANSMISSION_TEST]: 'Prueba de transmisión',
    [CONSTANTS.END_LIVE_QUESTION]: '¿Está seguro de que desea finalizar la transmisión?',
    [CONSTANTS.TRANSMISSION_STARTED]: '¡Transmisión iniciada!',
    [CONSTANTS.SAFARI_RECORDING_WARN]: 'La presentación del curso de Safari no funciona. Le sugerimos que utilice otro navegador.',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: '¡Bienvenido a Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: 'Es un placer tenerte aquí en Kopa. Lo invitamos a realizar un recorrido rápido por nuestra plataforma. ¿Ven con nosotros?',
    [CONSTANTS.ONBOARDING_LETS_START]: 'Empecemos nuestro recorrido',
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Omitir recorrido',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE]: 'Todos los canales a los que tiene acceso se muestran en la página de inicio. Haga clic en el cuadro siempre que desee volver a la lista de canales.',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER_TITLE]: 'Área del organizador',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER]: 'El área de organizadores es el espacio exclusivo para que gestiones tus canales. Al hacer clic aquí, se lo llevará a una nueva pestaña, donde tendrá acceso a varias funciones para administrar sus canales, como: crear y editar videos y vidas, generar informes, cuestionarios, etc.',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Salir',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Haga clic aquí para salir de Kopa.',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS_TITLE]: 'Canales',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS]: 'Aquí encontrará todos los videos disponibles para usted.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'Área de usuario',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Aquí puede ver y editar su perfil, agregando fotos e información de redes sociales. También puede seleccionar su idioma preferido.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'Finaliza el recorrido a través de la página de inicio.',
    [CONSTANTS.ONBOARDING_HOME_MENU_TITLE]: 'Menú del usuario',
    [CONSTANTS.ONBOARDING_HOME_MENU]: 'Dentro del menú de usuario, tendrá acceso al botón Inicio, salga de Kopa y cambie el idioma predeterminado de Kopa. Si tiene permisos de anfitrión, el acceso para administrar sus canales está disponible para usted.',

    [CONSTANTS.ONBOARDING_PRESENTATION_VIDEO]: 'Video de presentación',
    [CONSTANTS.ONBOARDING_VIDEO_AREA_TITLE]: 'Área de video',
    [CONSTANTS.ONBOARDING_VIDEO_AREA]: 'Aquí es donde verás el mejor y más exclusivo contenido.',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS_TITLE]: 'Información adicional',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS]: 'Esta área contiene información adicional sobre el contenido que está viendo actualmente. Si no es visible no te preocupes, el contenido de esta área se agrega según las necesidades de los responsables del canal.',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS_TITLE]: 'Archivos adjuntos',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS]: 'La sección de archivos adjuntos es donde puede descargar archivos adjuntos que se refieren al contenido adicional del video. Si no ve esta sección, no se preocupe, no todos los videos tienen archivos adjuntos descargables.',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING_TITLE]: 'Clasificación',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING]: 'Vea su puntaje y el puntaje de los 10 mejores en el ranking de gamificación del canal. No se preocupe si no está visible, no todos los videos tienen calificaciones de usuarios.',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT_TITLE]: 'Chat',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT]: 'Aquí puedes interactuar con los participantes que están viendo el video en tiempo real, además de responder tus preguntas con los monitores.',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR_TITLE]: 'Finalizar tutorial',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR]: 'Nuestro recorrido por Kopa ha llegado a su fin. Gracias por su preferencia y disfrute de nuestra plataforma. ¡Equipo Kopa!',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: '¡Bienvenido a Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: 'Es un placer tenerte aquí en Kopa. Lo invitamos a realizar un recorrido rápido por nuestra plataforma. ¿Ven con nosotros?',
    [CONSTANTS.ONBOARDING_LETS_START]: 'Empecemos nuestro recorrido',
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Omitir recorrido',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE_TITLE]: 'Página de inicio',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE]: 'Todos los canales a los que tiene acceso se muestran en la página de inicio. Haga clic en el cuadro siempre que desee volver a la lista de canales.',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER_TITLE]: 'Área del organizador',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER]: 'El área de organizadores es el espacio exclusivo para que gestiones tus canales. Al hacer clic aquí, se lo llevará a una nueva pestaña, donde tendrá acceso a varias funciones para administrar sus canales, como: crear y editar videos y vidas, generar informes, cuestionarios, etc.',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Salir',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Haga clic aquí para salir de Kopa.',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS_TITLE]: 'Canales',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS]: 'Aquí encontrará todos los videos disponibles para usted.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'Área de usuario',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Aquí puede ver y editar su perfil, agregando fotos e información de redes sociales. También puede seleccionar su idioma preferido.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'Finaliza el recorrido a través de la página de inicio.',

    [CONSTANTS.ONBOARDING_PRESENTATION_VIDEO]: 'Video de presentación',
    [CONSTANTS.ONBOARDING_VIDEO_AREA_TITLE]: 'Área de video',
    [CONSTANTS.ONBOARDING_VIDEO_AREA]: 'Aquí es donde verás el mejor y más exclusivo contenido.',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS_TITLE]: 'Información adicional',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS]: 'Esta área contiene información adicional sobre el contenido que está viendo actualmente. Si no es visible no te preocupes, el contenido de esta área se agrega según las necesidades de los responsables del canal.',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS_TITLE]: 'Archivos adjuntos',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS]: 'La sección de archivos adjuntos es donde puede descargar archivos adjuntos que se refieren al contenido adicional del video. Si no ve esta sección, no se preocupe, no todos los videos tienen archivos adjuntos descargables.',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING_TITLE]: 'Clasificación',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING]: 'Vea su puntaje y el puntaje de los 10 mejores en el ranking de gamificación del canal. No se preocupe si no está visible, no todos los videos tienen calificaciones de usuarios.',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT_TITLE]: 'Chat',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT]: 'Aquí puedes interactuar con los participantes que están viendo el video en tiempo real, además de responder tus preguntas con los monitores.',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR_TITLE]: 'Finalizar tutorial',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR]: 'Nuestro recorrido por Kopa ha llegado a su fin. Gracias por su preferencia y disfrute de nuestra plataforma. ¡Equipo Kopa!',
    [CONSTANTS.FAIL_TO_LOAD_VIDEO_PROGRESS]: 'No se pudo cargar el progreso del video!',
    [CONSTANTS.UNSUPPORTED]: 'No soportado',
    [CONSTANTS.ADVANCED]: 'Avanzado',
    [CONSTANTS.NOISESUPPRESSION]: 'Supresión de ruido',
    [CONSTANTS.ECHOCANCELLATION]: 'Cancelación de eco',
    [CONSTANTS.AUTOGAINCONTROL]: 'Control aut. de ganancia',
    [CONSTANTS.CONSTRAINT_ON]: 'Activ.',
    [CONSTANTS.CONSTRAINT_OFF]: 'Inact.',
    [CONSTANTS.CAM_PERMISSION_MESSAGE]: '¡Permita el acceso a la cámara para iniciar una transmisión en vivo!',
    [CONSTANTS.MIC_PERMISSION_MESSAGE]: '¡Permita el acceso al micrófono para iniciar una transmisión en vivo!',
    [CONSTANTS.UNAVAILABLE_MEDIA]: 'Medios no disponibles',
};
