import { useEffect } from 'react';
import { setToken } from '../../store/modules/auth/actions';
import { store } from '../../store/index';

const RehydrateConnection = () => {
    useEffect(() => {
        store.dispatch(setToken());
    }, []);

    return null;
};

export default RehydrateConnection;
