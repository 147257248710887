import React from 'react';
import { useTranslation } from 'react-i18next';
import concurrentUserImg from '../../../assets/concurrent.svg';
import { ConcurrentUserInfoContainer, ConcurrentUserImg, TextArea, WatchHereButton } from './styles';

export default function ConcurrentUser({ quizComponent = null, getPriorityVideoUrl }) {
    const { t } = useTranslation();
    return (
        <ConcurrentUserInfoContainer>
            {
                quizComponent || (
                    <>
                        <ConcurrentUserImg>
                            <img src={concurrentUserImg} alt="Concurrent User" />
                        </ConcurrentUserImg>
                        <TextArea>
                            <h1>{t('CONCURRENT_ACCESS_VIDEO')}</h1>
                        </TextArea>
                        <WatchHereButton onClick={getPriorityVideoUrl}>
                            <p>{ t('WATCH_HERE') }</p>
                        </WatchHereButton>
                    </>

                )
            }
        </ConcurrentUserInfoContainer>
    );
}
