import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
  display:flex;
  align-self:flex-end;
  margin-bottom:30px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #ffffff;

`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2rem;
  position: relative;

  .logo {
    width: 280px;
    max-width: 100%;
    display: block;
  }
`;

export const Form = styled.div`
  max-width: 37.60rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  span {
      height: 22px;
      margin-bottom: 8px;
      display: block;

      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #ffffff;
  } 

  input {
      background: #393737;
      border: 0;
      height: 40px;
      border-radius: 4px;
      padding: 0 15px;
      margin: 0 0 30px;
      color:#fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      color: #ffffff;      
    }

  #submitButton {
      opacity: 1;
  }

  #submitButtonDisabled {
      opacity: .5;
      cursor: default;

      &:hover{
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
      }
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: transparent !important;
  }

  @media(max-width: 425px){
      width: 100%;
  } 

`;

export const InputArea = styled.div`
    position: relative;
    width: 100%;

    .loginInputLabel {
        margin-top: 2.3rem;
    }

    .emailMessageError {
        position: absolute;
        top: 4rem;
        display: flex;
        width: 100%;;
        img {
            margin: auto;
            margin-right: 0;
        }
        p {
            margin-left: 0;
            color: #ff6363;
            margin-left: 0.5rem;
            font-weight: bold;
            font-size: .8rem
      }
    }

    @media(max-width: 375px){
        .emailMessageError{
            p {
                font-size: .8rem;
            }
            img {
                width: 12px;
            }
        }
    }

`;

export const ErrorMessage = styled.span`
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #fff;
`;

export const ErrorContaineir = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c64646;
  margin: 18px 0;
  display: flex;
  align-self: center;
  border-radius: 8px;
    span {
        margin: 0;
        text-align: center;
        display: flex;
        align-items: center;
    }
    img{
    width:25px;
    height:25px;
    margin-right:10px;
    }

    @media(max-width: 1440px){
        span {   
            margin: 0;
            font-size: 12px;
        }
    }
`;

export const LanguageContainer = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 1rem;

  h3 {
    color: white;
    margin-left: 0;
  }

  .arrow-down {
    margin-left: 10px;
    margin-right: 20px;
  }

  .language {
    display: flex;
    margin: 0 5px;
    align-items: center;

    h3 {
        color: #fff;
    }
    
  }

  .dropdown-languages {
    position: relative;
  }

  .langIco {
      width: 20px;
      height: 20px;
      margin-right: .5rem;
  }

  img {
    height: 10px;
    width: 10px;
  }
  
`;
