import React from 'react';
import { useTranslation } from 'react-i18next';

import phoneApp from '../../../assets/phone.svg';
import { checkMobileBrand } from '../../../utils/functions';
import { ShowAppButtonContainer, ShowAppButtonContent } from './styles';

const ShowAppButton = () => {
    const { t } = useTranslation();

    return (
        <ShowAppButtonContainer>
            <ShowAppButtonContent>
                <div className="showapp-box">
                    <img src={phoneApp} alt="phoneApp" />
                </div>
                <div>
                    <h1>
                        {t('DO_KNOW_KOPA_APP')}
                    </h1>
                    <button onClick={checkMobileBrand} type="button">{t('DOWNLOAD_KOPA_APP')}</button>
                </div>

            </ShowAppButtonContent>

        </ShowAppButtonContainer>
    );
};

export default ShowAppButton;
