import styled from 'styled-components';

const messageBackgroundColors = ['#27253f'];

export const MessageContainer = styled.div`
    width: 100%;
    min-height: min-content;
    padding: 6px 10px;
    background: ${(props) => messageBackgroundColors[
        props.colorIndex % messageBackgroundColors.length
    ]};
    border-radius: 13.5px;
    margin-top: 4px;
    display: flex;
    flex-flow: row wrap;
    @media (max-width: 650px) {
        &:first-child {
            margin-bottom: 30px;
        }
    }
    .--confirm-modal {
        padding-top: 18px;
        display: flex;
        padding: 15px;
        flex-direction: column;
        padding-top: 18px;
        align-items: center;

        h5 {
            color: #fff;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;

            span {
                color: #fe8d62;
            }
        }

        button {
            border: none;
            color: #fff;
            background: linear-gradient(90deg, #ffac47 0%, #ff438d 100%);
            width: 300px;
            min-height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;
            font-weight: bold;
            text-transform: uppercase;
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &.--cancel-button {
                color: #ff5b7d;
                background: transparent;
                border: solid 1px #ff5a7d;
            }

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }
    }
`;

export const Options = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative;

    img {
        cursor: pointer;
        width: 20px;
        position: absolute;
        right: -5px;
    }

    .loading {
        animation: rotate 1s linear infinite;
        border: none;
        cursor: none;

        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        }
    }
`;

export const TextContainer = styled.div`
    width: 90%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const AuthorIdentificationSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    span.--monitor {
        color: #ff985f;
        font-size: 0.5rem;
        font-weight: bold;
    }
`;

export const MessageName = styled.span`
    display: block;
    color: ${({ isMonitor }) => (isMonitor ? '#ff5a7e' : '#ff985f')};
    font-weight: 800;
    justify-self: flex-start;
    margin-right: 5px;
`;

export const MessageText = styled.span`
    color: white;
    white-space: pre-wrap;
    word-break: break-word;
`;

export const BlockUserButton = styled.button`
    border: none;
    outline: none;
    background: none;
    font-size: 9px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    padding: 3px;
    display: flex;
    align-items: center;
    max-width: 140px;

    &.block-icon {
        margin-right: 4px;
    }

    &:hover {
        color: #e85477;
    }
`;

export const BlockedUserText = styled.small`
    color: #e85477;
`;
