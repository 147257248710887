import CONSTANTS from '../constants';

export default {
    [CONSTANTS.ACTIVE]: 'Ativo',
    [CONSTANTS.ACCEPTTERMS]: 'Eu aceito os termos de uso',
    [CONSTANTS.ADDITIONAL_INFORMATION]: 'INFORMAÇÕES ADICIONAIS',
    [CONSTANTS.ALREADY_LOGED_IN]: 'Você já está logado em outra máquina.',
    [CONSTANTS.APPLY]: 'Aplicar',
    [CONSTANTS.ATTACHMENTS]: 'Anexos',
    [CONSTANTS.BACKTO]: 'Voltar para',
    [CONSTANTS.BACK_INITIAL_PAGE]: 'VOLTAR PARA PÁGINA INICIAL',
    [CONSTANTS.BITRATE]: 'Taxa de transmissão de bits',
    [CONSTANTS.BLOCK_USER_NOT_ALLOWED]: 'Bloquear este usuário não é permitido.',
    [CONSTANTS.BUFFER_CLASSIFICATION]: 'Classificação do buffer',
    [CONSTANTS.BUFFER_CLASSIFICATION_ABREVIATION]: 'CLAS. DO BUFFER',
    [CONSTANTS.BROADCASTING_ROUTING_LEAVE]: 'Sair da página interromperá a transmissão. Deseja continuar?',
    [CONSTANTS.CANCEL]: 'Cancelar',
    [CONSTANTS.CHANNEL_ADDNEWVIDEO]: 'Adicionar novo vídeo',
    [CONSTANTS.CHANNEL_ADDNEWLIVEVIDEO]: 'Ao vivo | Live',
    [CONSTANTS.CHANNEL_ADDNEWRECORDEDVIDEO]: 'Upload de vídeo',
    [CONSTANTS.CHANNEL_CLEANVIDEO]: 'Limpar vídeo',
    [CONSTANTS.CHANNEL_COPIED]: 'Copiado',
    [CONSTANTS.CHANNEL_CREATEVIDEO]: 'Criar vídeo',
    [CONSTANTS.CHANNEL_CREATEVIDEO2]: 'Criar novo vídeo',
    [CONSTANTS.CHANNEL_CHAT_ENABLED]: 'O CHAT DA LIVE ESTÁ',
    [CONSTANTS.CHANNEL_EDITVIDEO]: 'Editar vídeo',
    [CONSTANTS.CHANNEL_ENDDATE]: 'DATA E HORA DE TÉRMINO',
    [CONSTANTS.CHANNEL_ENDLIVE]: 'End live!',
    [CONSTANTS.CHANNEL_ENDLIVEDESCRIPTION]: 'Deseja realmente encerrar a live?',
    [CONSTANTS.CHANNEL_ENDLIVEIRREVERSIBLE]: 'Esta operação não poderá ser desfeita',
    [CONSTANTS.CHANNEL_FAILREQUESTROOM]: 'Falha ao solicitar sala',
    [CONSTANTS.CHANNEL_FAILREQUESTROOMVIDEOPRIORITY]: 'Falha ao solicitar acesso ao vídeo',
    [CONSTANTS.CHANNEL_FAILREQUESTROOMS]: 'Falha ao solicitar salas',
    [CONSTANTS.CHANNEL_FILES]: 'Arquivo',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Os arquivos devem possuir os seguintes formatos: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_FILES]: 'Arquivo',
    [CONSTANTS.CHANNEL_FILEFORMAT]: 'Os arquivos devem possuir os seguintes formatos: PPT, PPTX, DOC, DOCX, PDF, JPG, PNG',
    [CONSTANTS.CHANNEL_GOLIVE]: 'Go live!',
    [CONSTANTS.CHANNEL_MYVIDEOS]: 'Meus vídeos',
    [CONSTANTS.CHANNEL_NEWVIDEO]: 'Novo Vídeo',
    [CONSTANTS.CHANNEL_NOVIDEO]: 'Nenhuma sala cadastrada no momento',
    [CONSTANTS.CHANNEL_PRIMARYURL]: 'URL PRIMÁRIA',
    [CONSTANTS.CHANNEL_PASSWORD]: 'SENHA',
    [CONSTANTS.CHANNEL_ROOMNOTFOUND]: 'Sala não encontrada',
    [CONSTANTS.CHANNEL_SECONDARYURL]: 'URL SECUNDÁRIA',
    [CONSTANTS.CHANNEL_SELECTCOURSE]: 'SELECIONE O CANAL AO QUAL O VÍDEO PERTENCE',
    [CONSTANTS.CHANNEL_SELECTTIMEZONE]: 'SELECIONE O FUSO HORÁRIO',
    [CONSTANTS.CHANNEL_SELECTCOURSEPLACEHOLDER]: 'Selecione um curso',
    [CONSTANTS.CHANNEL_SENDVIDEO]: 'Enviar vídeo',
    [CONSTANTS.CHANNEL_STARTDATE]: 'DATA E HORA DE INíCIO',
    [CONSTANTS.CHANNEL_THUMBNAIL]: 'Thumbnail',
    [CONSTANTS.CHANNEL_THUMBNAILSIZE]: 'Tamanho mínimo 266x110',
    [CONSTANTS.CHANNEL_UPLOADFILES]: 'UPLOAD DE ARQUIVOS',
    [CONSTANTS.CHANNEL_UPLOADVIDEO]: 'UPLOAD DE VÍDEO',
    [CONSTANTS.CHANNEL_VIDEOCOVER]: 'CAPA DO VÍDEO',
    [CONSTANTS.CHANNEL_VIDEODESCRIPTION]: 'DESCRIÇÃO DO VÍDEO',
    [CONSTANTS.CHANNEL_VIDEOFORMAT]: 'O vídeo deve ter os seguintes formatos: MP4, MKV',
    [CONSTANTS.CHANNEL_VIDEOTITLE]: 'TÍTULO DO VÍDEO',
    [CONSTANTS.CHAT]: 'chat',
    [CONSTANTS.CONCURRENT_ACCESS_VIDEO]: 'VOCÊ JÁ ESTÁ ASSISTINDO EM OUTRO DISPOSITIVO, \n DESEJA ASSISTIR NESSE DISPOSITIVO ?',
    [CONSTANTS.CONFIRM]: 'Confirmar',
    [CONSTANTS.CONTROLS]: 'CONTROLES',
    [CONSTANTS.COMEBACK]: 'Voltar',
    [CONSTANTS.DISABLE_QUESTION]: 'DESABILITAR?',
    [CONSTANTS.DISABLED_CAPS]: 'DESABILITADO',
    [CONSTANTS.DISABLED_CAMERA]: 'Câmera Desativada',
    [CONSTANTS.DO_KNOW_KOPA_APP]: 'Já conhece o aplicativo do Kopa?',
    [CONSTANTS.DO_KNOW_KOPA_APP_TEXT]: 'Você pode acessar nosso aplicativo a qualquer hora e em qualquer lugar, basta estar conectado à internet com qualidade suficiente para a difusão de conteúdos.',
    [CONSTANTS.DOWNLOAD_KOPA_APP]: 'BAIXAR O APLICATIVO AGORA',
    [CONSTANTS.DONT_SHOW_AGAIN]: 'NÃO EXIBIR NOVAMENTE',
    [CONSTANTS.DOUBTS]: 'Dúvidas',
    [CONSTANTS.EDIT]: 'Editar',
    [CONSTANTS.ENABLE_QUESTION]: 'HABILITAR?',
    [CONSTANTS.ENABLED_CAPS]: 'HABILITADO',
    [CONSTANTS.ENDDATE]: 'Data de término',
    [CONSTANTS.ENGLISH]: 'Inglês',
    [CONSTANTS.EMAIL]: 'E-MAIL',
    [CONSTANTS.ENTER_ROOM]: 'Entrar na sala',
    [CONSTANTS.ERROR404]: 'Erro 404',
    [CONSTANTS.ERROR_CHANGEPASSWORD]: 'Falha ao alterar senha, status de erro: ',
    [CONSTANTS.ERROR_CREDENTIALS]: 'Credenciais de acesso inválida, expirada ou inexistente',
    [CONSTANTS.ERROR_PARAMETERS]: 'Um ou mais parâmetros de entrada estão inválidos.',
    [CONSTANTS.ERROR_DEFAULT]: 'Erro inesperado do servidor',
    [CONSTANTS.ERROR_EMAILNOTFOUND]: 'E-MAIL NÃO ENCONTRADO',
    [CONSTANTS.ERROR_LOGIN]: 'E-MAIL OU SENHA INCORRETOS',
    [CONSTANTS.ERROR_LOGINFAIL]: 'Falha ao realizar login, status de erro:',
    [CONSTANTS.ERROR_NONETWORK]: 'Sem acesso a internet',
    [CONSTANTS.ERROR_RECOVERPASSFAIL]: 'Falha ao restaurar senha',
    [CONSTANTS.ERROR_ROOM_ID]: 'Você deve fornecer o "id da sala" para enviar estatísticas!',
    [CONSTANTS.FAIL_BLOCK_USER]: 'Falha ao bloquear usuário',
    [CONSTANTS.FAIL_CHANGELANGUAGE]: 'Falha ao realizar mudança de linguagem',
    [CONSTANTS.FAIL_CHANGEOFFERSTATUS]: 'Falha ao alterar status das ofertas',
    [CONSTANTS.FAIL_CHATNOTFOUND]: 'Sala de Chat não encontrado',
    [CONSTANTS.FAIL_CONNECTWEBSOCKET]: 'Falha ao conectar com websocket',
    [CONSTANTS.FAIL_CREATEOFFER]: 'Falha ao criar oferta',
    [CONSTANTS.FAIL_CREATETEAMS]: 'Falha ao criar equipes',
    [CONSTANTS.FAIL_ENTERROOM]: 'Falha ao entrar na sala',
    [CONSTANTS.FAIL_EXITROOM]: 'Falha ao sair da sala',
    [CONSTANTS.FAIL_GETCHAT]: 'Sala de chat não encontrada',
    [CONSTANTS.FAIL_GETOFFER]: 'Falha ao solicitar oferta',
    [CONSTANTS.FAIL_GETTEAMSSTATUS]: 'Falha ao solicitar status das equipes',
    [CONSTANTS.FAIL_GETTEAMS]: 'Falha ao solicitar equipes',
    [CONSTANTS.FAIL_LOGOUT]: 'Falha ao realizar logout',
    [CONSTANTS.FAIL_PINMESSAGE]: 'Falha ao fixar mensagem',
    [CONSTANTS.FAIL_SETTEAMSSTATUS]: 'Falha ao alterar status das equipes',
    [CONSTANTS.FAIL_UNPINMESSAGE]: 'Falha ao desafixar mensagem',
    [CONSTANTS.FAILED_GET_USER_PROFILE]: 'FALHA AO BUSCAR PERFIL DE USUÁRIO',
    [CONSTANTS.FILTER_FIRST]: 'Selecione o primeiro filtro',
    [CONSTANTS.FILTER_SECOND]: 'Selecione o segundo filtro',
    [CONSTANTS.FILTER_SPECIFIC]: 'Período de tempo',
    [CONSTANTS.FILTER_PRESETOPTIONS]: ['1 minuto', '5 minutos', '30 minutos', '1 hora', '3 horas', '6 horas', '24 horas', '3 dias', '7 dias'],
    [CONSTANTS.FILTER_TIME]: 'Selecione o tempo',
    [CONSTANTS.FLOW_PRIMARY]: 'Fluxo Primário',
    [CONSTANTS.FLOW_SECONDARY]: 'Fluxo Secundário',
    [CONSTANTS.FORGETPASSWORD]: 'ESQUECEU SUA SENHA?',
    [CONSTANTS.FORGETPASSWORD_EMAIL]: 'Digite o e-mail que está cadastrado no portal e iremos enviar um código de verificação para o seu e-mail.',
    [CONSTANTS.FROM_TIME]: 'De',
    [CONSTANTS.GENERAL]: 'Geral',
    [CONSTANTS.GEOLOCATION]: 'Geolocalização',
    [CONSTANTS.GRAPH_AVG_VIEWBITRATE]: 'Média de taxa de bits',
    [CONSTANTS.GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH]: 'Largura de banda de downstream',
    [CONSTANTS.GRAPH_AVG_VIEWDROPPEDFRAMESRATIO]: 'Média de frames perdido',
    [CONSTANTS.GRAPH_AVG_VIEWLIVELATENCY]: 'Latência média',
    [CONSTANTS.GRAPH_SUMVIEWTIME]: 'Somatório de tempo de visualização',
    [CONSTANTS.GRAPH_VIEWERS]: 'Usuários',
    [CONSTANTS.GRAPH_VIEWBUFFERTIMERATIO]: 'Tempo médio de buffer',
    [CONSTANTS.GRAPH_VIEWERSBUFFERING]: 'Buffering de usuários',
    [CONSTANTS.GRAPH_VIEWERSENGAGEMENT]: 'Engajamento de usuários',
    [CONSTANTS.GRAPH_VIEWERSDVR]: 'Usuários DVR',
    [CONSTANTS.HOME]: 'Início',
    [CONSTANTS.HOMEPAGE]: 'Página Inicial',
    [CONSTANTS.HOME_WELCOME]: 'É UM PRAZER TER VOCÊ AQUI CONOSCO',
    [CONSTANTS.HOME_NOLIVE]: 'Desculpe, no momento não temos nenhuma live em andamento.',
    [CONSTANTS.HOME_FEATURED_LIVES]: 'Lives do momento',
    [CONSTANTS.HOURS]: 'Horas',
    [CONSTANTS.INACTIVE]: 'Inativo',
    [CONSTANTS.INVALID_IMG_TYPE]: 'Tipo inválido. O arquivo deve ser uma imagem.',
    [CONSTANTS.INTERACTIVE_CHAT]: 'CHAT INTERATIVO',
    [CONSTANTS.FINISHED]: 'Encerrado',
    [CONSTANTS.LANGUAGE]: 'IDIOMA',
    [CONSTANTS.LAST]: 'Ultimo(s)',
    [CONSTANTS.LIVE]: 'Ao vivo',
    [CONSTANTS.LOADING]: 'Carregando',
    [CONSTANTS.LOADINGCAPS]: 'CARREGANDO',
    [CONSTANTS.LOADINGPDF]: 'Carregando PDF',
    [CONSTANTS.LOGIN]: 'LOGIN',
    [CONSTANTS.LOGIN_EMAIL]: 'E-MAIL',
    [CONSTANTS.LOGIN_PASSWORD]: 'SENHA',
    [CONSTANTS.LOGIN_SIGNIN]: 'ENTRAR',
    [CONSTANTS.MESSAGE]: 'Mensagem',
    [CONSTANTS.INVALID_VERIFICATION_CODE]: 'CÓDIGO DE VERIFICAÇÃO INVÁLIDO',
    [CONSTANTS.VERIFICATION_CODE_TITLE]: 'CÓDIGO DE VERIFICAÇÃO',
    [CONSTANTS.VERIFICATION_CODE_SENT]: 'Encaminhamos um código de verificação para o e-mail',
    [CONSTANTS.MONITORS]: 'Monitores',
    [CONSTANTS.NAMECAPS]: 'NOME',
    [CONSTANTS.NO]: 'Não',
    [CONSTANTS.NO_GAMIFICATION_RANKING]: 'Nenhuma pontuação ainda foi computada. Espere só mais um pouco e durante o evento o ranking será atualizado.',
    [CONSTANTS.NOTLIVE]: 'Live encerrada',
    [CONSTANTS.NEXT_STEP]: 'Próximo passo',
    [CONSTANTS.NEXTPAGE]: 'Próxima',
    [CONSTANTS.OF]: 'de',
    [CONSTANTS.OFFERS]: 'Ofertas',
    [CONSTANTS.OFFERS_ACTIVATEOFFERS]: 'Ativar oferta',
    [CONSTANTS.OFFERS_BASEURL]: 'Insira a url template',
    [CONSTANTS.OFFERS_CREATEOFFER]: 'Criar oferta',
    [CONSTANTS.OFFERS_EDITOFFER]: 'Editar oferta',
    [CONSTANTS.OFFERS_ENABLECONFIRM]: 'Deseja ativar a oferta?',
    [CONSTANTS.OFFERS_MODEOFFERSINACTIVATE]: 'As ofertas estão desativadas',
    [CONSTANTS.OFFERS_NOOFFERS]: 'Nenhuma oferta cadastrada',
    [CONSTANTS.OFFERS_SALESFORCEID]: 'Salesforce Id',
    [CONSTANTS.OFFERS_SELECTOR]: 'Selecione o tipo de ID do template',
    [CONSTANTS.OFFERS_TEMPLATEURL]: 'Template url',
    [CONSTANTS.OFFERS_TYPEID]: 'Tipo de Id',
    [CONSTANTS.OFFLINE_ROOM]: 'Sala offline',
    [CONSTANTS.OPS]: 'Ops!',
    [CONSTANTS.ON]: 'on',
    [CONSTANTS.PERMISSION_ENTERCHAT]: 'Você não tem permissão para entrar nessa sala de chat',
    [CONSTANTS.PERMISSION_PINMESSAGE]: 'Você não tem permissão para fixar mensagem nessa sala de chat',
    [CONSTANTS.PERMISSION_UNPINMESSAGE]: 'Você não tem permissão para desafixar mensagem nessa sala de chat',
    [CONSTANTS.VOUCHER_LOGIN]: 'Cadastre-se com seu voucher',
    [CONSTANTS.VOUCHER_PASSWORD_CREATION_TITLE]: 'CRIAÇÃO DE SENHA',
    [CONSTANTS.VOUCHER_PASSWORD_LABEL]: 'SENHA',
    [CONSTANTS.VOUCHER_PASSWORD_CONFIRM_LABEL]: 'CONFIRMAR SENHA',
    [CONSTANTS.VOUCHER_PASSWORD_SAME]: 'As senhas precisam ser iguais',
    [CONSTANTS.VOUCHER_PASSWORD_MIN]: 'A senha deve ter no mínimo 6 caracteres',
    [CONSTANTS.VOUCHER_FINALIZE_REGISTRATION]: 'Finalizar cadastro',
    [CONSTANTS.VOUCHER_CODE_TITLE]: 'CÓDIGO DO VOUCHER',
    [CONSTANTS.VOUCHER_CODE_SUBTITLE]: 'Insira aqui o código do seu voucher',
    [CONSTANTS.VOUCHER_BUTTON_SUBMIT]: 'VALIDAR VOUCHER',
    [CONSTANTS.VOUCHER_REGISTER_TITLE]: 'CADASTRO REALIZADO COM SUCESSO',
    [CONSTANTS.VOUCHER_REGISTER_MESSAGE_SUCCESS]: 'Seu cadastro foi realizado com sucesso, agora vamos te redirecionar para a home',
    [CONSTANTS.VOUCHER_TERMS]: ['O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.',
        'O Usuário está ciente de que fornece informação de forma consciente e voluntária por meio de [FORMULÁRIO/ETC], ou por meio dos sites operados pela LBCA.'],
    [CONSTANTS.VOUCHER_TERMS_ACEPPT]: 'ACEITO OS TERMOS DE USO',
    [CONSTANTS.VOUCHER_TERMS_VALIDATION]: 'Aceite os termos para prosseguir',
    [CONSTANTS.VOUCHER_TERMS_TITLE]: 'TERMOS DE USO',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT1]: 'ACEITO A ',
    [CONSTANTS.VOUCHER_ACEPPT_TERMS_PRIVACY_PT2]: 'POLÍTICA DE PRIVACIDADE',
    [CONSTANTS.VOUCHER_NEXT_STEP_BUTTON]: 'Próximo passo',

    [CONSTANTS.VOUCHER_USERDATA_TITLE]: 'DADOS PESSOAIS',
    [CONSTANTS.VOUCHER_USERDATA_FILL]: 'Preencha todos os dados',
    [CONSTANTS.VOUCHER_USERDATA_FULL_NAME]: 'NOME COMPLETO',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL]: 'E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_CONFIRM]: 'CONFIRMAR E-MAIL',
    [CONSTANTS.VOUCHER_USERDATA_EMAIL_DIFFERS]: 'Endereços de email não são o mesmo',
    [CONSTANTS.VOUCHER_USERDATA_DATE_OF_BIRTH]: 'DATA DE NASCIMENTO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER]: 'SEXO',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_SELECT]: 'Selecione o gênero',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_MALE]: 'Masculino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_FEM]: 'Feminino',
    [CONSTANTS.VOUCHER_USERDATA_GENDER_OTHER]: 'Outro',
    [CONSTANTS.VOUCHER_USERDATA_INVALID_DATE]: 'Data de nascimento inválida',
    [CONSTANTS.VOUCHER_USERDATA_PHONE]: 'TELEFONE',
    [CONSTANTS.VOUCHER_USERDATA_PHONE_PATTERN]: 'Utilize apenas números no campo de telefone',
    [CONSTANTS.VOUCHER_USERDATA_TYPE_FULL_NAME]: 'Nome completo do usuário',

    [CONSTANTS.QUIZ_HEADER]: 'Selecione uma das opções para responder',
    [CONSTANTS.PAGE]: 'Página',
    [CONSTANTS.PLEASECLOSE]: 'Por favor, feche',
    [CONSTANTS.PORTUGUESE]: 'Português',
    [CONSTANTS.PRESET]: 'Predefinido',
    [CONSTANTS.PREVIOUSPAGE]: 'Anterior',
    [CONSTANTS.FINISHPAGE]: 'Finalizar',
    [CONSTANTS.SKIP]: 'Pular',
    [CONSTANTS.QUESTIONS]: 'Perguntas',
    [CONSTANTS.QUIZ_FAILED]: 'Falha ao carregar quiz',
    [CONSTANTS.RECOVERPASSWORD_MINLENGTH]: 'A senha deve ter no mínimo 8 dígitos',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORD]: 'NOVA SENHA',
    [CONSTANTS.RECOVERPASSWORD_NEWPASSWORDREPEAT]: 'REPETIR NOVA SENHA',
    [CONSTANTS.RECOVERPASSWORD_NOLINK]: 'O link não está mais disponível',
    [CONSTANTS.RECOVERPASSWORD_NOMATCH]: 'As senhas não conferem',
    [CONSTANTS.RECOVERPASSWORD_REDEFINE]: 'CRIAR NOVA SENHA',
    [CONSTANTS.RECOVERPASSWORD_SUCCESS]: 'Senha redefinida com sucesso',
    [CONSTANTS.RECOVERPASSWORD_VERIFY]: 'VERIFIQUE SEU E-MAIL',
    [CONSTANTS.RECOVERPASSWORD_VERIFYDESC]: 'Um e-mail para que você realize a troca de senha, será enviada para a sua caixa de e-mail.',
    [CONSTANTS.ROOM_INTERACTIVE_INFORMATIONS]: 'Nessa sala você poderá interagir com o apresentador quando solicitado. Não se esqueça de verificar as suas configurações de áudio e câmera.',
    [CONSTANTS.TYPE_NEW_PASSWORD]: 'Digite aqui a nova senha que você deseja utilizar para logar na nossa plataforma.',
    [CONSTANTS.SEARCH]: 'Pesquisar',
    [CONSTANTS.SEARCH_NOT_FOUND]: 'Nenhuma entrada para mostrar',
    [CONSTANTS.SELECTQUESTION]: 'Selecione uma pergunta',
    [CONSTANTS.SEND]: 'ENVIAR',
    [CONSTANTS.SEVERITY_BAD]: 'Ruim',
    [CONSTANTS.SEVERITY_GOOD]: 'Bom',
    [CONSTANTS.SEVERITY_WARN]: 'Aviso',
    [CONSTANTS.SORRYPROBLEM]: 'Desculpa, tivemos um problema.',
    [CONSTANTS.SPANISH]: 'Espanhol',
    [CONSTANTS.STARTDATE]: 'Data de inicio',
    [CONSTANTS.STATUS]: 'Status',
    [CONSTANTS.STATUS_LIVE]: 'Status da live',
    [CONSTANTS.SUCCESS]: 'SUCESSO',
    [CONSTANTS.TEAM]: 'Equipe',
    [CONSTANTS.TEAMS]: 'Equipes',
    [CONSTANTS.TEAMS_ACTIVATETEAMS]: 'Ativar equipes',
    [CONSTANTS.TEAMS_CREATETEAMS]: 'Criar equipes',
    [CONSTANTS.TEAMS_CREATETEAMSDESC]: 'Crie equipes e deixe a experiência dos participantes ainda mais extraordinária',
    [CONSTANTS.TEAMS_GENERATETEAMS]: 'Gerar equipes',
    [CONSTANTS.TEAMS_MODETEAMS]: 'Modo equipe',
    [CONSTANTS.TEAMS_MODETEAMSINACTIVATE]: 'MODO EQUIPES DESATIVADO',
    [CONSTANTS.TEAMS_NOTEAMS]: 'Nenhuma equipe cadastrada no momento',
    [CONSTANTS.TEAMS_QUANTITY]: 'Quantidade de pessoas na equipe',
    [CONSTANTS.TOTIME]: 'Às',
    [CONSTANTS.TRY_AGAIN]: 'Tentar novamente',
    [CONSTANTS.TOTIME]: 'Às',
    [CONSTANTS.UPLOAD_ERROR_MESSAGE]: 'Formato de arquivo não suportado',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE]: 'Solte a imagem aqui',
    [CONSTANTS.UPLOAD_SUCCESS_MESSAGE_MULTIPLE]: 'Solte o(s) arquivo(s) aqui',
    [CONSTANTS.UPLOAD_VIDEO_SUCCESS]: 'Upload de vídeo concluído!',
    [CONSTANTS.UPLOAD_VIDEO_FAILURE]: 'Upload de vídeo falhou!',
    [CONSTANTS.UPLOAD_VIDEO_PREPARING]: 'Preparando video.',
    [CONSTANTS.UPLOADING]: 'Enviando',
    [CONSTANTS.USERS_ACTIVE]: 'Usuários ativos',
    [CONSTANTS.USERS_ACTIVECAPS]: 'USUÁRIOS ATIVOS',
    [CONSTANTS.USERS_DISTRIBUTIONSHORT]: 'DIS. DE USUÁRIOS',
    [CONSTANTS.USERS_ENGAGEMENT]: 'Usuários engajados',
    [CONSTANTS.VIDEO]: 'Vídeo',
    [CONSTANTS.VIDEO_DESCRIPTION]: 'Descrição do Vídeo',
    [CONSTANTS.VOUCHER]: 'VOUCHER',
    [CONSTANTS.VOUCHER_EMAIL_VALIDATION]: 'VALIDAÇÃO DO E-MAIL',
    [CONSTANTS.VOUCHER_ENTER_FIELDS]: 'Preencha todos os valores',
    [CONSTANTS.VOUCHER_ERROR_COURSE]: 'Usuário já possui o curso',
    [CONSTANTS.VOUCHER_ERROR_VERIFY]: 'Falha ao verificar voucher',
    [CONSTANTS.VOUCHER_ERROR_USERCREATED]: 'Usuário já cadastrado',
    [CONSTANTS.VOUCHER_ERROR_INVALIDCODE]: 'Falha ao verificar código',
    [CONSTANTS.VOUCHER_ERROR_EXPIREDCODE]: 'Código expirado, reenviar código para email',
    [CONSTANTS.VOUCHER_ERROR_RESENDEMAIL]: 'Falha ao reenviar email',
    [CONSTANTS.VOUCHER_ERROR_CONFIRMPASSWORD]: 'Falha ao confirmar senha',
    [CONSTANTS.VOUCHER_ERROR_REGISTERDATA]: 'Falha ao cadastrar dados',
    [CONSTANTS.VOUCHER_ERROR_REGISTER]: 'Falha ao registrar voucher',
    [CONSTANTS.VOUCHER_NEW_VOUCHER]: 'Novo Voucher',
    [CONSTANTS.VOUCHER_INVALID]: 'Voucher inválido',
    [CONSTANTS.VOUCHER_VALIDATE]: 'Seu voucher com o código',
    [CONSTANTS.VOUCHER_VALIDATEPT2]: 'foi validado com sucesso',
    [CONSTANTS.VOUCHER_VALIDATE_CODE]: 'Validar voucher',
    [CONSTANTS.VOUCHER_VALIDATE_DENIED]: 'não foi validado com sucesso',
    [CONSTANTS.VOUCHER_VALIDATE_CODECAPS]: 'VALIDAÇÃO DO VOUCHER',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTION]: 'Você realmente deseja validar o voucher',
    [CONSTANTS.VOUCHER_VALIDATE_QUESTIONPT2]: 'que libera o canal',
    [CONSTANTS.VOUCHER_REGISTERED]: 'Voucher cadastrado com sucesso',
    [CONSTANTS.VOUCHER_RESEND_EMAIL]: 'Reenviar código',
    [CONSTANTS.VOUCHER_RESENT_EMAIL]: 'Código reenviado',
    [CONSTANTS.VOUCHER_STEPS]: ['Termos de uso', 'Dados Pessoais', 'Validação', 'Criação de senha'],
    [CONSTANTS.VOUCHER_VERIFICATION_CODE]: 'INSIRA AQUI O CÓDIGO DE VERIFICAÇÃO QUE FOI ENCAMINHADO PARA O E-MAIL',
    [CONSTANTS.VOUCHER_WARNING_VERIFY]: 'O cupom não pôde ser verificado',
    [CONSTANTS.VOUCHER_YOUR_CODE]: 'CÓDIGO DO SEU VOUCHER',
    [CONSTANTS.WATCH_HERE]: 'ASSISTIR NESSE DISPOSITIVO',
    [CONSTANTS.WARNINGS_MESSAGES]: 'Muitas mensagens enviadas em um curto intervalo de tempo. Tentar novamente depois.',
    [CONSTANTS.WARNINGS_MESSAGES_FAIL]: 'Falha ao enviar mensagem',
    [CONSTANTS.WARNINGS_MESSAGES_NOTALLOWED]: 'Para a sua segurança não é permitido enviar links, números telefônicos e e-mails',
    [CONSTANTS.WARNINGS_MESSAGES_NOTFOUND]: 'Sala de chat não encontrada',
    [CONSTANTS.WARNINGS_MESSAGES_PERMISSIONS]: 'Você não tem permissão para enviar mensagem nessa sala de chat',
    [CONSTANTS.YES]: 'Sim',
    [CONSTANTS.ORGANIZER]: 'Organizador',
    [CONSTANTS.MAX_PIN_MESSAGE_LIMIT]: 'Limite de 2 mensagens fixadas por vez!',
    [CONSTANTS.SHOW_ALL]: 'VER TODOS',
    [CONSTANTS.NEW_PASSWORD]: 'NOVA SENHA',
    [CONSTANTS.REPEAT_NEW_PASSWORD]: 'REPETIR NOVA SENHA',
    [CONSTANTS.SAVE_PASSWORD]: 'SALVAR SENHA',
    [CONSTANTS.CREATE_NEW_PASSWORD]: 'CRIAR NOVA SENHA',
    [CONSTANTS.FIRST_ACESS_MESSAGE]: 'Bem-vindo ao Kopa! Esse é o seu primeiro contato com a nossa plataforma e para facilitar os seus próximos acessos crie uma nova senha para você. ',
    [CONSTANTS.NETWORK_FAILED]: 'Não foi possível acessar nosso servidor',
    [CONSTANTS.NETWORK_FAILED_MESSAGE]: ' Verifique a sua conexão e tente novamente',
    [CONSTANTS.FAIL_CHANGE_PASSWORD]: 'Falha em mudar senha, tente novamente',
    [CONSTANTS.SETTINGS]: 'Configurações',
    [CONSTANTS.SETTINGS_AUDIO_VIDEO]: 'Configurações de áudio e vídeo',
    [CONSTANTS.LOGOUT]: 'Sair',
    [CONSTANTS.BLOCK]: 'Bloquear',
    [CONSTANTS.USER]: 'Participante',
    [CONSTANTS.BLOCKING_MSG]: 'Deseja bloquear o usuário',
    [CONSTANTS.NO]: 'Não',
    [CONSTANTS.USER_BLOCKED]: 'Usuário foi bloqueado com sucesso!',
    [CONSTANTS.BLOCKED_USER_CHAT]: 'USUARIO BLOQUEADO!',
    [CONSTANTS.WELCOME_TO_KOPA]: 'BEM-VINDO AO KOPA',
    [CONSTANTS.WELCOME_TO_ROOM_INTERACTIVE]: 'Bem-vindo(a) sala interativa',
    [CONSTANTS.INVALID_EMAIL]: 'E-MAIL INVÁLIDO.',
    [CONSTANTS.DID_RECEIVE_CODE]: 'NÃO RECEBEU SEU CÓDIGO?',
    [CONSTANTS.RESEND_CODE]: 'REENVIAR CÓDIGO',
    [CONSTANTS.VERIFY_CODE]: 'VERIFICAR CÓDIGO',
    [CONSTANTS.EXPIRED_VERIFICATION_CODE]: 'CÓDIGO EXPIRADO',
    [CONSTANTS.CODE_SENT]: 'Enviado',
    [CONSTANTS.TO_RESEND_CODE]: 'PARA REENVIAR O CÓDIGO',
    [CONSTANTS.MAX_ATTEMPTS_VERIFICATION_CODE]: 'NÚMERO MÁXIMO DE TENTATIVAS ALCANÇADOS',
    [CONSTANTS.INTERVAL_VERIFICATION_CODE]: 'AGUARDE 1 MINUTO PARA REENVIAR O CÓDIGO.',
    [CONSTANTS.ERROR_IMAGE_UPLOAD]: 'FALHA AO FAZER UPLOAD DA IMAGEM',
    [CONSTANTS.SOCIAL_MEDIA]: 'REDES SOCIAIS',
    [CONSTANTS.PROFILE_UPDATED]: 'PERFIL ATUALIZADO',
    [CONSTANTS.PROFILE_UPDATE_FAILED]: 'FALHA NA ATUALIZAÇÃO DO PERFIL',
    [CONSTANTS.CROP_IMAGE]: 'CORTAR IMAGEM',
    [CONSTANTS.VIEW_PROFILE]: 'VISUALIZAR PERFIL',
    [CONSTANTS.VIDEO_UNAVAILABLE]: 'VÍDEO NÃO DISPONÍVEL',
    [CONSTANTS.LIVE_ACCESS_DENIED]: 'Desculpa, mas você não pode assistir a este vídeo como um convidado.',
    [CONSTANTS.SAVE]: 'SALVAR',
    [CONSTANTS.MY_PROFILE]: 'MEU PERFIL',
    [CONSTANTS.ACCESS_NOT_ALLOWED]: 'Você não possui permissão pra acessar esse conteúdo.',
    [CONSTANTS.HIDE]: 'Ocultar',

    [CONSTANTS.RANKING_POSITION]: 'Lugar',
    [CONSTANTS.NAME]: 'Nome',
    [CONSTANTS.PLACE]: 'Lugar',
    [CONSTANTS.YOU]: 'Você',
    [CONSTANTS.ARE_IN]: 'Está em',
    [CONSTANTS.WITH]: 'com',
    [CONSTANTS.VIEW_RANKING]: 'Visualizar Ranking',
    [CONSTANTS.TOTAL_POINTS]: 'Total de Pontos',
    [CONSTANTS.FAILED_GET_RANKING]: 'Falha ao obter ranking!',

    [CONSTANTS.WAITING_TRANSMISSION]: 'Aguardando Transmissão',
    [CONSTANTS.PREPARING]: 'Preparando...',
    [CONSTANTS.STARTING_LIVE]: 'Iniciando Live...',
    [CONSTANTS.START_TRANSMISSION]: 'Iniciar Transmissão',
    [CONSTANTS.ALLOW_USER_MEDIA_ACCESS]: 'Permita o acesso a câmera e o microfone para iniciar uma live.',
    [CONSTANTS.MICROFONES]: 'Microfones',
    [CONSTANTS.CAMERAS]: 'Câmeras',
    [CONSTANTS.END_LIVE]: 'Encerrar live',
    [CONSTANTS.READY_TO_START_QUESTION]: 'Pronto para iniciar sua transmissão?',
    [CONSTANTS.CHECK_MEDIA_SETTINGS]: 'Não esqueça de verificar as configurações de câmera e áudio.',
    [CONSTANTS.TRANSMISSION_TEST]: 'Teste de Transmissão',
    [CONSTANTS.END_LIVE_QUESTION]: 'Tem certeza de deseja encerrar a transmissão?',
    [CONSTANTS.TRANSMISSION_STARTED]: 'A live começou!',
    [CONSTANTS.SAFARI_RECORDING_WARN]: 'A apresentação de cursos pelo SAFARI não está funcionando. Sugerimos que utilize outro navegador.',

    [CONSTANTS.ONBOARDING_HOME_WELCOME_TITLE]: 'Bem-vindo(a) ao Kopa!',
    [CONSTANTS.ONBOARDING_HOME_WELCOME]: 'É um prazer ter você aqui no Kopa. Convidamos você a realizar um tour rápido pela nossa plataforma. Vem com a gente?',
    [CONSTANTS.ONBOARDING_LETS_START]: 'Começar o tour',
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Pular tour',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE_TITLE]: 'Página inicial',
    [CONSTANTS.ONBOARDING_LETS_START]: 'Começar nosso tour',
    [CONSTANTS.ONBOARDING_SKIP_TOUR]: 'Pular tour',
    [CONSTANTS.ONBOARDING_HOME_HOMEPAGE]: 'Na página inicial são mostrados todos os canais que você tem acesso. Clique na casinha sempre que quiser voltar para a lista de canais.',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER_TITLE]: 'Área do organizador',
    [CONSTANTS.ONBOARDING_HOME_ORGANIZER]: 'A área do organizador é o espaço exclusivo para você gerenciar os seus canais. Ao clicar aqui, você será direcionado para uma nova aba, onde terá acesso a diversas funcionalidades para gerenciamento dos seus canais, como: criar e editar vídeos e lives, gerar relatórios, quizzes, etc.',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT_TITLE]: 'Sair',
    [CONSTANTS.ONBOARDING_HOME_LOGOUT]: 'Clique aqui para sair do Kopa.',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS_TITLE]: 'Canais',
    [CONSTANTS.ONBOARDING_HOME_CHANNELS]: 'Aqui você encontra todos os vídeos disponíveis para você.',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA_TITLE]: 'Área do usuário',
    [CONSTANTS.ONBOARDING_HOME_USER_AREA]: 'Aqui você poderá visualizar e editar o seu perfil, adicionando foto e informações das redes sociais. Também pode selecionar o idioma de sua preferência.',
    [CONSTANTS.ONBOARDING_HOME_COMPLETE]: 'Finalizar tour da página inicial',
    [CONSTANTS.ONBOARDING_HOME_MENU_TITLE]: 'Menu do usuário',
    [CONSTANTS.ONBOARDING_HOME_MENU]: 'Dentro do menu do usuário você terá acesso ao botão da Página Inicial, sair do Kopa e mudar o idioma padrão do Kopa. Caso você tenha permissões de organizador, está disponível para você o acesso ao gerenciamento do(s) seu(s) canal(is).',

    [CONSTANTS.ONBOARDING_PRESENTATION_VIDEO]: 'Vídeo de apresentação',
    [CONSTANTS.ONBOARDING_COMPLETE]: 'Concluir tutorial',
    [CONSTANTS.ONBOARDING_VIDEO_AREA_TITLE]: 'Área do vídeo',
    [CONSTANTS.ONBOARDING_VIDEO_AREA]: 'É aqui onde você irá assistir os melhores e mais exclusivos conteúdos.',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS_TITLE]: 'Informações adicionais',
    [CONSTANTS.ONBOARDING_VIDEO_INFORMATIONS]: 'Essa área contém as informações adicionais para o conteúdo que você está assistindo no momento. Caso não esteja visível, não se preocupe, significa que não há nada para ser exibido aqui no momento.',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS_TITLE]: 'Anexos',
    [CONSTANTS.ONBOARDING_VIDEO_ATTACHMENTS]: 'A seção de anexos é onde você poderá baixar os anexos referentes aos conteúdos extras do vídeo. Caso você não visualize essa seção, não se preocupe, nem todos os vídeos possuem anexos para download.',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING_TITLE]: 'Classificação',
    [CONSTANTS.ONBOARDING_VIDEO_RANKING]: 'Visualize a sua pontuação e a pontuação dos 10 primeiros colocados no ranking de gamificação do canal. Não se preocupe caso não esteja visível, nem todos os vídeos possuem classificação de usuários.',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT_TITLE]: 'Chat',
    [CONSTANTS.ONBOARDING_VIDEO_CHAT]: 'Aqui você poderá interagir com os participantes que estão assistindo o vídeo em tempo real, além de tirar suas dúvidas com os monitores.',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR_TITLE]: 'Finalizar tour',
    [CONSTANTS.ONBOARDING_VIDEO_END_TOUR]: 'Nosso tour pelo Kopa chegou ao fim. Obrigado pela preferência e aproveite bastante a nossa plataforma. Abraços, Equipe Kopa!',
    [CONSTANTS.FAIL_TO_LOAD_VIDEO_PROGRESS]: 'Falha ao carregar progressos de vídeos!',
    [CONSTANTS.UNSUPPORTED]: 'Não suportado',
    [CONSTANTS.ADVANCED]: 'Avançado',
    [CONSTANTS.NOISESUPPRESSION]: 'Supressão de ruído',
    [CONSTANTS.ECHOCANCELLATION]: 'Cancelamento de echo',
    [CONSTANTS.AUTOGAINCONTROL]: 'Controle aut. de ganho',
    [CONSTANTS.CONSTRAINT_ON]: 'Lig.',
    [CONSTANTS.CONSTRAINT_OFF]: 'Desl.',
    [CONSTANTS.CAM_PERMISSION_MESSAGE]: 'Permita o acesso a câmera para iniciar uma live.',
    [CONSTANTS.MIC_PERMISSION_MESSAGE]: 'Permita o acesso ao microfone para iniciar uma live.',
    [CONSTANTS.UNAVAILABLE_MEDIA]: 'Mídia indisponível',
};
