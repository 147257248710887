export default {
    ACTIVE: 'ACTIVE',
    ACCEPTTERMS: 'ACCEPTTERMS',
    ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
    ALREADY_LOGED_IN: 'ALREADY_LOGED_IN',
    APPLY: 'APPLY',
    ATTACHMENTS: 'ATTACHMENTS',
    BACKTO: 'BACKTO',
    BACK_INITIAL_PAGE: 'BACK_INITIAL_PAGE',
    BITRATE: 'BITRATE',
    BLOCK_USER_NOT_ALLOWED: 'BLOCK_USER_NOT_ALLOWED',
    BUFFER_CLASSIFICATION: 'BUFFER_CLASSIFICATION',
    BUFFER_CLASSIFICATION_ABREVIATION: 'BUFFER_CLASSIFICATION_ABREVIATION',
    BROADCASTING_ROUTING_LEAVE: 'BROADCASTING_ROUTING_LEAVE',
    CANCEL: 'CANCEL',
    CHANNEL_ADDNEWVIDEO: 'CHANNEL_ADDNEWVIDEO',
    CHANNEL_ADDNEWLIVEVIDEO: 'CHANNEL_ADDNEWLIVEVIDEO',
    CHANNEL_ADDNEWRECORDEDVIDEO: 'CHANNEL_ADDNEWRECORDEDVIDEO',
    CHANNEL_CLEANVIDEO: 'CHANNEL_CLEANVIDEO',
    CHANNEL_COPIED: 'CHANNEL_COPIED',
    CHANNEL_CREATEVIDEO: 'CHANNEL_CREATEVIDEO',
    CHANNEL_CREATEVIDEO2: 'CHANNEL_CREATEVIDEO2',
    CHANNEL_CHAT_ENABLED: 'CHANNEL_CHAT_ENABLED',
    CHANNEL_EDITVIDEO: 'CHANNEL_EDITVIDEO',
    CHANNEL_ENDDATE: 'CHANNEL_ENDDATE',
    CHANNEL_ENDLIVE: 'CHANNEL_ENDLIVE',
    CHANNEL_ENDLIVEIRREVERSIBLE: 'CHANNEL_ENDLIVEIRREVERSIBLE',
    CHANNEL_ENDLIVEDESCRIPTION: 'CHANNEL_ENDLIVEDESCRIPTION',
    CHANNEL_FAILREQUESTROOM: 'CHANNEL_FAILREQUEST',
    CHANNEL_FAILREQUESTROOMVIDEOPRIORITY: 'CHANNEL_FAILREQUESTROOMVIDEOPRIORITY',

    CHANNEL_FAILREQUESTROOMS: 'CHANNEL_FAILREQUESTROOMS',
    CHANNEL_FILES: 'CHANNEL_FILES',
    CHANNEL_FILEFORMAT: 'CHANNEL_FILEFORMAT',
    CHANNEL_GOLIVE: 'CHANNEL_GOLIVE',
    CHANNEL_MYVIDEOS: 'CHANNEL_MYVIDEOS',
    CHANNEL_NEWVIDEO: 'CHANNEL_NEWVIDEO',
    CHANNEL_NOVIDEO: 'CHANNEL_NOVIDEO',
    CHANNEL_PRIMARYURL: 'CHANNEL_PRIMARYURL',
    CHANNEL_PASSWORD: 'CHANNEL_PASSWORD',
    CHANNEL_ROOMNOTFOUND: 'CHANNEL_ROOMNOTFOUND',
    CHANNEL_SECONDARYURL: 'CHANNEL_SECONDARYURL',
    CHANNEL_SENDVIDEO: 'CHANNEL_SENDVIDEO',
    CHANNEL_SELECTCOURSE: 'CHANNEL_SELECTCOURSE',
    CHANNEL_SELECTCOURSEPLACEHOLDER: 'CHANNEL_SELECTCOURSE2',
    CHANNEL_SELECTTIMEZONE: 'CHANNEL_SELECTTIMEZONE',
    CHANNEL_STARTDATE: 'CHANNEL_STARTDATE',
    CHANNEL_THUMBNAIL: 'CHANNEL_THUMBNAIL',
    CHANNEL_THUMBNAILSIZE: 'CHANNEL_THUMBNAILSIZE',
    CHANNEL_UPLOADFILES: 'CHANNEL_UPLOADFILES',
    CHANNEL_UPLOADVIDEO: 'CHANNEL_UPLOADVIDEO',
    CHANNEL_VIDEOCOVER: 'CHANNEL_VIDEOCOVER',
    CHANNEL_VIDEODESCRIPTION: 'CHANNEL_VIDEODESCRIPTION',
    CHANNEL_VIDEOFORMAT: 'CHANNEL_VIDEOFORMAT',
    CHANNEL_VIDEOTITLE: 'CHANNEL_VIDEOTITLE',
    CHAT: 'CHAT',
    CONCURRENT_ACCESS_VIDEO: 'CONCURRENT_ACCESS_VIDEO',
    CONFIRM: 'CONFIRM',
    CONTROLS: 'CONTROLS',
    COMEBACK: 'COMEBACK',
    DISABLE_QUESTION: 'DISABLE_QUESTION',
    DISABLED_CAPS: 'DISABLED_CAPS',
    DISABLED_CAMERA: 'DISABLED_CAMERA',
    DO_KNOW_KOPA_APP: 'DO_KNOW_KOPA_APP',
    DO_KNOW_KOPA_APP_TEXT: 'DO_KNOW_KOPA_APP_TEXT',
    DOWNLOAD_KOPA_APP: 'DOWNLOAD_KOPA_APP',
    DONT_SHOW_AGAIN: 'DONT_SHOW_AGAIN',
    DOUBTS: 'DOUBTS',
    EDIT: 'EDIT',
    ENABLE_QUESTION: 'ENABLE_QUESTION',
    ENABLED_CAPS: 'ENABLED_CAPS',
    ENGLISH: 'ENGLISH',
    ENDDATE: 'ENDDATE',
    EMAIL: 'EMAIL',
    ENTER_ROOM: 'ENTER_ROOM',
    ERROR404: 'ERROR404',
    ERROR_CHANGEPASSWORD: 'ERROR_CHANGEPASSWORD',
    ERROR_CREDENTIALS: 'ERROR_CREDENTIALS',
    ERROR_PARAMETERS: 'ERROR_PARAMETERS',
    ERROR_DEFAULT: 'ERROR_DEFAULT',
    ERROR_EMAILNOTFOUND: 'ERROR_EMAILNOTFOUND',
    ERROR_LOGIN: 'ERROR_LOGIN',
    ERROR_LOGINFAIL: 'ERROR_LOGINFAIL',
    ERROR_NONETWORK: 'ERROR_NONETWORK',
    ERROR_RECOVERPASSFAIL: 'ERROR_RECOVERPASSFAIL',
    ERROR_ROOM_ID: 'ERROR_ROOM_ID',
    FAIL_BLOCK_USER: 'FAIL_BLOCK_USER',
    FAIL_CHANGELANGUAGE: 'FAIL_CHANGELANGUAGE',
    FAIL_CHANGEOFFERSTATUS: 'FAIL_CHANGEOFFERSTATUS',
    FAIL_CHATNOTFOUND: 'FAIL_CHATNOTFOUND',
    FAIL_CONNECTWEBSOCKET: 'FAIL_CONNECTWEBSOCKET',
    FAIL_CREATEOFFER: 'FAIL_CREATEOFFER',
    FAIL_CREATETEAMS: 'FAIL_CREATETEAMS',
    FAIL_ENTERROOM: 'FAIL_ENTERROOM',
    FAIL_EXITROOM: 'FAIL_EXITROOM',
    FAIL_GETCHAT: 'FAIL_GETCHAT',
    FAIL_GETOFFER: 'FAIL_GETOFFER',
    FAIL_GETTEAMSSTATUS: 'FAIL_GETTEAMSSTATUS',
    FAIL_GETTEAMS: 'FAIL_GETTEAMS',
    FAIL_LOGOUT: 'FAIL_LOGOUT',
    FAIL_PINMESSAGE: 'FAIL_PINMESSAGE',
    FAIL_UNPINMESSAGE: 'FAIL_UNPINMESSAGE',
    FAIL_SETTEAMSSTATUS: 'FAIL_SETTEAMSSTATUS',
    FAILED_GET_USER_PROFILE: 'FAILED_GET_USER_PROFILE',
    FILTER_FIRST: 'FILTER_FIRST',
    FILTER_SECOND: 'FILTER_SECOND',
    FILTER_SPECIFIC: 'FILTER_SPECIFIC',
    FILTER_PRESETOPTIONS: 'FILTER_PRESETOPTIONS',
    FILTER_TIME: 'FILTER_TIME',
    FLOW_PRIMARY: 'FLOW_PRIMARY',
    FLOW_SECONDARY: 'FLOW_SECONDARY',
    FORGETPASSWORD: 'FORGETPASSWORD',
    FORGETPASSWORD_EMAIL: 'FORGETPASSWORD_EMAIL',
    FROM_TIME: 'FROM_TIME',
    GENERAL: 'GENERAL',
    GEOLOCATION: 'GEOLOCATION',
    GRAPH_AVG_VIEWBITRATE: 'GRAPH_AVGVIEWBITRATE',
    GRAPH_AVG_VIEWDOWNSTREAMBANDWIDTH: 'GRAPH_AVGVIEWDOWNSTREAMBANDWIDTH',
    GRAPH_AVG_VIEWDROPPEDFRAMESRATIO: 'GRAPH_AVGVIEWDROPPEDFRAMESRATIO',
    GRAPH_AVG_VIEWLIVELATENCY: 'GRAPH_AVGVIEWLIVELATENCY',
    GRAPH_SUMVIEWTIME: 'GRAPH_SUMVIEWTIME',
    GRAPH_VIEWERS: 'GRAPH_VIEWERS',
    GRAPH_VIEWERSBUFFERING: 'GRAPH_VIEWERSBUFFERING',
    GRAPH_VIEWERSENGAGEMENT: 'GRAPH_VIEWERSENGAGEMENT',
    GRAPH_VIEWERSDVR: 'GRAPH_VIEWERSDVR',
    GRAPH_VIEWBUFFERTIMERATIO: 'GRAPH_VIEWBUFFERTIMERATIO',
    HOME: 'HOME',
    HOMEPAGE: 'HOMEPAGE',
    HOME_WELCOME: 'HOME_WELCOME',
    HOME_NOLIVE: 'HOME_NOLIVE',
    HOME_FEATURED_LIVES: 'HOME_FEATURED_LIVES',
    HOURS: 'HOURS',
    INACTIVE: 'INACTIVE',
    INVALID_IMG_TYPE: 'INVALID_IMG_TYPE',
    INTERACTIVE_CHAT: 'INTERACTIVE_CHAT',
    FINISHED: 'FINISHED',
    LANGUAGE: 'LANGUAGE',
    LAST: 'LAST',
    LIVE: 'LIVE',
    LOADING: 'LOADING',
    LOADINGCAPS: 'LOADINGCAPS',
    LOADINGPDF: 'LOADINGPDF',
    LOGIN: 'LOGIN',
    LOGIN_EMAIL: 'LOGIN_EMAIL',
    LOGIN_PASSWORD: 'LOGIN_PASSWORD',
    LOGIN_SIGNIN: 'LOGIN_SIGNIN',
    MESSAGE: 'MESSAGE',
    MONITORS: 'MONITORS',
    NAMECAPS: 'NAMECAPS',
    NEXT_STEP: 'NEXT_STEP',
    NO: 'NO',
    NO_GAMIFICATION_RANKING: 'NO_GAMIFICATION_RANKING',
    NOTLIVE: 'NOTLIVE',
    NEXTPAGE: 'NEXTPAGE',
    OF: 'OF',
    OFFERS: 'OFFERS',
    OFFERS_ACTIVATEOFFERS: 'OFFERS_ACTIVATEOFFERS',
    OFFERS_BASEURL: 'OFFERS_BASEURL',
    OFFERS_CREATEOFFER: 'OFFERS_CREATEOFFER',
    OFFERS_EDITOFFER: 'OFFERS_EDITOFFER',
    OFFERS_ENABLECONFIRM: 'OFFERS_ENABLECONFIRM',
    OFFERS_MODEOFFERSINACTIVATE: 'OFFERS_MODEOFFERSINACTIVATE',
    OFFERS_NOOFFERS: 'OFFERS_NOOFFERS',
    OFFERS_SALESFORCEID: 'OFFERS_SALESFORCEID',
    OFFERS_SELECTOR: 'OFFERS_SELECTOR',
    OFFERS_TEMPLATEURL: 'OFFERS_TEMPLATEURL',
    OFFERS_TYPEID: 'OFFERS_TYPEID',
    OFFLINE_ROOM: 'OFFLINE_ROOM',
    ON: 'on',
    OPS: 'OPS',
    PERMISSION_ENTERCHAT: 'PERMISSION_ENTERCHAT',
    PERMISSION_PINMESSAGE: 'PERMISSION_PINMESSAGE',
    PERMISSION_UNPINMESSAGE: 'PERMISSION_UNPINMESSAGE',
    QUIZ_HEADER: 'QUIZ_HEADER',
    PAGE: 'PAGE',
    PLEASECLOSE: 'PLEASECLOSE',
    PORTUGUESE: 'PORTUGUESE',
    PRESET: 'PRESET',
    PREVIOUSPAGE: 'PREVIOUSPAGE',
    FINISHPAGE: 'FINISHPAGE',
    SKIP: 'SKIP',
    QUIZ_FAILED: 'QUIZ_FAILED',
    QUESTIONS: 'QUESTIONS',
    RECOVERPASSWORD_MINLENGTH: 'RECOVERPASSWORD_MINLENGTH',
    RECOVERPASSWORD_NEWPASSWORD: 'RECOVERPASSWORD_NEWPASSWORD',
    RECOVERPASSWORD_NEWPASSWORDREPEAT: 'RECOVERPASSWORD_NEWPASSWORDREPEAT',
    RECOVERPASSWORD_NOLINK: 'RECOVERPASSWORD_NOLINK',
    RECOVERPASSWORD_NOMATCH: 'RECOVERPASSWORD_NOMATCH',
    RECOVERPASSWORD_REDEFINE: 'RECOVERPASSWORD_REDEFINE',
    RECOVERPASSWORD_SUCCESS: 'RECOVERPASSWORD_SUCCESS',
    RECOVERPASSWORD_VERIFY: 'RECOVERPASSWORD_VERIFY',
    RECOVERPASSWORD_VERIFYDESC: 'RECOVERPASSWORD_VERIFYDESC',
    ROOM_INTERACTIVE_INFORMATIONS: 'ROOM_INTERACTIVE_INFORMATIONS',
    TYPE_NEW_PASSWORD: 'TYPE_NEW_PASSWORD',
    SEARCH: 'SEARCH',
    SEARCH_NOT_FOUND: 'SEARCH_NOT_FOUND',
    SELECTQUESTION: 'SELECTQUESTION',
    SEND: 'SEND',
    SEVERITY_BAD: 'SEVERITY_BAD',
    SEVERITY_GOOD: 'SEVERITY_GOOD',
    SEVERITY_WARN: 'SEVERITY_WARN',
    SORRYPROBLEM: 'SORRYPROBLEM',
    SPANISH: 'SPANISH',
    STARTDATE: 'STARTDATE',
    STATUS: 'STATUS',
    STATUS_LIVE: 'STATUS_LIVE',
    SUCCESS: 'SUCCESS',
    TEAM: 'TEAM',
    TEAMS: 'TEAMS',
    TEAMS_ACTIVATETEAMS: 'TEAMS_ACTIVATETEAMS',
    TEAMS_CREATETEAMS: 'TEAMS_CREATETEAMS',
    TEAMS_CREATETEAMSDESC: 'TEAMS_CREATETEAMSDESC',
    TEAMS_GENERATETEAMS: 'TEAMS_GENERATETEAMS',
    TEAMS_MODETEAMS: 'TEAMS_MODETEAMS',
    TEAMS_MODETEAMSINACTIVATE: 'TEAMS_MODETEAMSINACTIVATE',
    TEAMS_NOTEAMS: 'TEAMS_NOTEAMS',
    TEAMS_QUANTITY: 'TEAMS_QUANTITY',
    TOTIME: 'TOTIME',
    TRY_AGAIN: 'TRY_AGAIN',
    UPLOAD_ERROR_MESSAGE: 'UPLOAD_ERROR_MESSAGE',
    UPLOAD_SUCCESS_MESSAGE: 'UPLOAD_SUCCESS_MESSAGE',
    UPLOAD_SUCCESS_MESSAGE_MULTIPLE: 'UPLOAD_SUCCESS_MESSAGE_MULTIPLE',
    UPLOAD_VIDEO_SUCCESS: 'UPLOAD_VIDEO_SUCCESS',
    UPLOAD_VIDEO_FAILURE: 'UPLOAD_VIDEO_FAILURE',
    UPLOAD_VIDEO_PREPARING: 'UPLOAD_VIDEO_PREPARING',
    UPLOADING: 'UPLOADING',
    USERS_ACTIVE: 'USERS_ACTIVE',
    USERS_ACTIVECAPS: 'USERS_ACTIVECAPS',
    USERS_DISTRIBUTIONSHORT: 'USERS_DISTRIBUTIONSHORT',
    USERS_ENGAGEMENT: 'USERS_ENGAGEMENT',
    VIDEO: 'VIDEO',
    VIDEO_DESCRIPTION: 'VIDEO_DESCRIPTION',
    WELCOME_TO_KOPA: 'WELCOME_TO_KOPA',
    WELCOME_TO_ROOM_INTERACTIVE: 'WELCOME_TO_ROOM_INTERACTIVE',
    DID_RECEIVE_CODE: 'DID_RECEIVE_CODE',
    RESEND_CODE: 'RESEND_CODE',
    VERIFY_CODE: 'VERIFY_CODE',
    EXPIRED_VERIFICATION_CODE: 'EXPIRED_VERIFICATION_CODE',
    CODE_SENT: 'CODE_SENT',
    TO_RESEND_CODE: 'TO_RESEND_CODE',
    MAX_ATTEMPTS_VERIFICATION_CODE: 'MAX_ATTEMPTS_VERIFICATION_CODE',
    INTERVAL_VERIFICATION_CODE: 'INTERVAL_VERIFICATION_CODE',
    ERROR_IMAGE_UPLOAD: 'ERROR_IMAGE_UPLOAD',
    SOCIAL_MEDIA: 'SOCIAL_MEDIA',
    ACCESS_NOT_ALLOWED: 'ACCESS_NOT_ALLOWED',
    HIDE: 'HIDE',
    FAIL_TO_LOAD_VIDEO_PROGRESS: 'FAIL_TO_LOAD_VIDEO_PROGRESS',

    VOUCHER_LOGIN: 'VOUCHER_LOGIN',
    VOUCHER_PASSWORD_CREATION_TITLE: 'VOUCHER_PASSWORD_CREATION_TITLE',
    VOUCHER_PASSWORD_LABEL: 'VOUCHER_PASSWORD_LABEL',
    VOUCHER_PASSWORD_CONFIRM_LABEL: 'VOUCHER_PASSWORD_CONFIRM_LABEL',
    VOUCHER_PASSWORD_SAME: 'VOUCHER_PASSWORD_SAME',
    VOUCHER_PASSWORD_MIN: 'VOUCHER_PASSWORD_MIN',
    VOUCHER_FINALIZE_REGISTRATION: 'VOUCHER_FINALIZE_REGISTRATION',

    VOUCHER_CODE_TITLE: 'VOUCHER_CODE_TITLE',
    VOUCHER_CODE_SUBTITLE: 'VOUCHER_CODE_SUBTITLE',
    VOUCHER_BUTTON_SUBMIT: 'VOUCHER_BUTTON_SUBMIT',
    VOUCHER_REGISTER_TITLE: 'VOUCHER_REGISTER_TITLE',
    VOUCHER_REGISTER_MESSAGE_SUCCESS: 'VOUCHER_REGISTER_MESSAGE_SUCCESS',

    VOUCHER_TERMS: 'VOUCHER_TERMS',
    VOUCHER_TERMS_ACEPPT: 'VOUCHER_TERMS_ACEPPT',
    VOUCHER_TERMS_TITLE: 'VOUCHER_TERMS_TITLE',
    VOUCHER_TERMS_VALIDATION: 'VOUCHER_TERMS_VALIDATION',
    VOUCHER_ACEPPT_TERMS_PRIVACY_PT1: 'VOUCHER_ACEPPT_TERMS_PRIVACY_PT1',
    VOUCHER_ACEPPT_TERMS_PRIVACY_PT2: 'VOUCHER_ACEPPT_TERMS_PRIVACY_PT2',
    VOUCHER_NEXT_STEP_BUTTON: 'VOUCHER_NEXT_STEP_BUTTON',

    VOUCHER_USERDATA_TITLE: 'VOUCHER_USERDATA_TITLE',
    VOUCHER_USERDATA_FILL: 'VOUCHER_USERDATA_FILL',
    VOUCHER_USERDATA_FULL_NAME: 'VOUCHER_USERDATA_FULL_NAME',
    VOUCHER_USERDATA_EMAIL: 'VOUCHER_USERDATA_EMAIL',
    VOUCHER_USERDATA_EMAIL_CONFIRM: 'VOUCHER_USERDATA_EMAIL_CONFIRM',
    VOUCHER_USERDATA_EMAIL_DIFFERS: 'VOUCHER_USERDATA_EMAIL_DIFFERS',
    VOUCHER_USERDATA_DATE_OF_BIRTH: 'VOUCHER_USERDATA_DATE_OF_BIRTH',
    VOUCHER_USERDATA_GENDER: 'VOUCHER_USERDATA_GENDER',
    VOUCHER_USERDATA_GENDER_MALE: 'VOUCHER_USERDATA_GENDER_MALE',
    VOUCHER_USERDATA_GENDER_FEM: 'VOUCHER_USERDATA_GENDER_FEM',
    VOUCHER_USERDATA_GENDER_SELECT: 'VOUCHER_USERDATA_GENDER_SELECT',
    VOUCHER_USERDATA_GENDER_OTHER: 'VOUCHER_USERDATA_GENDER_OTHER',
    VOUCHER_USERDATA_INVALID_DATE: 'VOUCHER_USERDATA_INVALID_DATE',
    VOUCHER_USERDATA_PHONE: 'VOUCHER_USERDATA_PHONE',
    VOUCHER_USERDATA_PHONE_PATTERN: 'VOUCHER_USERDATA_PHONE_PATTERN',
    VOUCHER_USERDATA_TYPE_FULL_NAME: 'VOUCHER_USERDATA_TYPE_FULL_NAME',

    VOUCHER: 'VOUCHER',
    VOUCHER_EMAIL_VALIDATION: 'VOUCHER_EMAIL_VALIDATION',
    VOUCHER_ENTER_FIELDS: 'VOUCHER_ENTER_FIELDS',
    VOUCHER_ERROR_COURSE: 'VOUCHER_ERROR_COURSE',
    VOUCHER_ERROR_VERIFY: 'VOUCHER_ERROR_VERIFY',
    VOUCHER_ERROR_USERCREATED: 'VOUCHER_ERROR_USERCREATED',
    VOUCHER_ERROR_INVALIDCODE: 'VOUCHER_ERROR_INVALIDCODE',
    VOUCHER_ERROR_EXPIREDCODE: 'VOUCHER_ERROR_EXPIREDCODE',
    VOUCHER_ERROR_RESENDEMAIL: 'VOUCHER_ERROR_RESENDEMAIL',
    VOUCHER_ERROR_CONFIRMPASSWORD: 'VOUCHER_ERROR_CONFIRMPASSWORD',
    VOUCHER_ERROR_REGISTERDATA: 'VOUCHER_ERROR_REGISTERDATA',
    VOUCHER_ERROR_REGISTER: 'VOUCHER_ERROR_REGISTER',
    VOUCHER_NEW_VOUCHER: 'VOUCHER_NEW_VOUCHER',
    VOUCHER_INVALID: 'VOUCHER_INVALID',
    VOUCHER_VALIDATE: 'VOUCHER_VALIDATE',
    VOUCHER_VALIDATEPT2: 'VOUCHER_VALIDATEPT2',
    VOUCHER_VALIDATE_CODE: 'VOUCHER_VALIDATE_CODE',
    VOUCHER_VALIDATE_DENIED: 'VOUCHER_VALIDATE_DENIED',
    VOUCHER_VALIDATE_CODECAPS: 'VOUCHER_VALIDATE_CODECAPS',
    VOUCHER_VALIDATE_QUESTION: 'VOUCHER_VALIDATE_QUESTION',
    VOUCHER_VALIDATE_QUESTIONPT2: 'VOUCHER_VALIDATE_QUESTIONPT2',
    VOUCHER_REGISTERED: 'VOUCHER_REGISTERED',
    VOUCHER_RESEND_EMAIL: 'VOUCHER_RESEND_EMAIL',
    VOUCHER_RESENT_EMAIL: 'VOUCHER_RESENT_EMAIL',
    VOUCHER_STEPS: 'VOUCHER_STEPS',
    VOUCHER_VERIFICATION_CODE: 'VOUCHER_VERIFICATION_CODE',
    VOUCHER_WARNING_VERIFY: 'VOUCHER_WARNING_VERIFY',
    VOUCHER_YOUR_CODE: 'VOUCHER_YOUR_CODE',

    WATCH_HERE: 'WATCH_HERE',
    WARNINGS_MESSAGES: 'WARNINGS_MESSAGES',
    WARNINGS_MESSAGES_FAIL: 'WARNINGS_MESSAGES_FAIL',
    WARNINGS_MESSAGES_NOTALLOWED: 'WARNINGS_MESSAGES_NOTALLOWED',
    WARNINGS_MESSAGES_NOTFOUND: 'WARNINGS_MESSAGES_NOTFOUND',
    WARNINGS_MESSAGES_PERMISSIONS: 'WARNINGS_MESSAGES_PERMISSIONS',
    INVALID_EMAIL: 'INVALID_EMAIL',
    YES: 'YES',
    SHOW_ALL: 'SHOW_ALL',
    ORGANIZER: 'ORGANIZER',
    MAX_PIN_MESSAGE_LIMIT: 'MAX_PIN_MESSAGE_LIMIT',
    NEW_PASSWORD: 'NEW_PASSWORD',
    REPEAT_NEW_PASSWORD: 'REPEAT_NEW_PASSWORD',
    SAVE_PASSWORD: 'SAVE_PASSWORD',
    CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
    FIRST_ACESS_MESSAGE: 'FIRST_ACESS_MESSAGE',
    NETWORK_FAILED: 'NETWORK_FAILED',
    NETWORK_FAILED_MESSAGE: 'NETWORK_FAILED_MESSAGE',
    FAIL_CHANGE_PASSWORD: 'FAIL_CHANGE_PASSWORD',
    SETTINGS: 'SETTINGS',
    SETTINGS_AUDIO_VIDEO: 'SETTINGS_AUDIO_VIDEO',
    LOGOUT: 'LOGOUT',
    INVALID_VERIFICATION_CODE: 'INVALID_VERIFICATION_CODE',
    VERIFICATION_CODE_TITLE: 'VERIFICATION_CODE_TITLE',
    VERIFICATION_CODE_SENT: 'VERIFICATION_CODE_SENT',
    MY_PROFILE: 'MY_PROFILE',
    PROFILE_UPDATED: 'PROFILE_UPDATED',
    PROFILE_UPDATE_FAILED: 'PROFILE_UPDATE_FAILED',
    CROP_IMAGE: 'CROP_IMAGE',
    VIEW_PROFILE: 'VIEW_PROFILE',
    VIDEO_UNAVAILABLE: 'VIDEO_UNAVAILABLE',
    LIVE_ACCESS_DENIED: 'LIVE_ACCESS_DENIED',
    SAVE: 'SAVE',
    UNAVAILABLE_MEDIA: 'UNAVAILABLE_MEDIA',

    // BLOCK LIST
    BLOCK: 'BLOCK',
    USER: 'USER',
    BLOCKING_MSG: 'BLOCKING_MSG',
    USER_BLOCKED: 'USER_BLOCKED',
    BLOCKED_USER_CHAT: 'BLOCKED_USER_CHAT',
    // Ranking
    RANKING_POSITION: 'RANKING_POSITION',
    NAME: 'NAME',
    PLACE: 'PLACE',
    YOU: 'YOU',
    ARE_IN: 'ARE_IN',
    WITH: 'WITH',
    VIEW_RANKING: 'VIEW_RANKING',
    TOTAL_POINTS: 'TOTAL_POINTS',
    FAILED_GET_RANKING: 'FAILED_GET_RANKING',

    // WebRTC
    WAITING_TRANSMISSION: 'WAITING_TRANSMISSION',
    PREPARING: 'PREPARING',
    STARTING_LIVE: 'STARTING_LIVE',
    START_TRANSMISSION: 'START_TRANSMISSION',
    ALLOW_USER_MEDIA_ACCESS: 'ALLOW_USER_MEDIA_ACCESS',
    MICROFONES: 'MICROFONES',
    CAMERAS: 'CAMERAS',
    END_LIVE: 'END_LIVE',
    READY_TO_START_QUESTION: 'READY_TO_START_QUESTION',
    CHECK_MEDIA_SETTINGS: 'CHECK_MEDIA_SETTINGS',
    TRANSMISSION_TEST: 'TRANSMISSION_TEST',
    END_LIVE_QUESTION: 'END_LIVE_QUESTION',
    TRANSMISSION_STARTED: 'TRANSMISSION_STARTED',
    SAFARI_RECORDING_WARN: 'SAFARI_RECORDING_WARN',
    UNSUPPORTED: 'UNSUPPORTED',
    ADVANCED: 'ADVANCED',
    NOISESUPPRESSION: 'NOISESUPPRESSION',
    ECHOCANCELLATION: 'ECHOCANCELLATION',
    AUTOGAINCONTROL: 'AUTOGAINCONTROL',
    CONSTRAINT_ON: 'CONSTRAINT_ON',
    CONSTRAINT_OFF: 'CONSTRAINT_OFF',
    CAM_PERMISSION_MESSAGE: 'CAM_PERMISSION_MESSAGE',
    MIC_PERMISSION_MESSAGE: 'MIC_PERMISSION_MESSAGE',

    // Onboarding buttons
    ONBOARDING_HOME_COMPLETE: 'ONBOARDING_HOME_COMPLETE',
    ONBOARDING_COMPLETE: 'ONBOARDING_COMPLETE',
    ONBOARDING_LETS_START: 'ONBOARDING_LETS_START',
    ONBOARDING_SKIP_TOUR: 'ONBOARDING_SKIP_TOUR',

    // Onbaording home

    ONBOARDING_HOME_WELCOME_TITLE: 'ONBOARDING_HOME_WELCOME_TITLE',
    ONBOARDING_HOME_WELCOME: 'ONBOARDING_HOME_WELCOME',
    ONBOARDING_HOME_HOMEPAGE_TITLE: 'ONBOARDING_HOME_HOMEPAGE_TITLE',
    ONBOARDING_HOME_HOMEPAGE: 'ONBOARDING_HOME_HOMEPAGE',
    ONBOARDING_HOME_ORGANIZER_TITLE: 'ONBOARDING_HOME_ORGANIZER_TITLE',
    ONBOARDING_HOME_ORGANIZER: 'ONBOARDING_HOME_ORGANIZER',
    ONBOARDING_HOME_LOGOUT_TITLE: 'ONBOARDING_HOME_LOGOUT_TITLE',
    ONBOARDING_HOME_LOGOUT: 'ONBOARDING_HOME_LOGOUT',
    ONBOARDING_HOME_CHANNELS_TITLE: 'ONBOARDING_HOME_CHANNELS_TITLE',
    ONBOARDING_HOME_CHANNELS: 'ONBOARDING_HOME_CHANNELS',
    ONBOARDING_HOME_USER_AREA_TITLE: 'ONBOARDING_HOME_USER_AREA_TITLE',
    ONBOARDING_HOME_USER_AREA: 'ONBOARDING_HOME_USER_AREA',
    ONBOARDING_HOME_MENU_TITLE: 'ONBOARDING_HOME_MENU_TITLE',
    ONBOARDING_HOME_MENU: 'ONBOARDING_HOME_MENU',

    // Onboarding videos
    ONBOARDING_PRESENTATION_VIDEO: 'ONBOARDING_PRESENTATION_VIDEO',
    ONBOARDING_VIDEO_AREA_TITLE: 'ONBOARDING_VIDEO_AREA_TITLE',
    ONBOARDING_VIDEO_AREA: 'ONBOARDING_VIDEO_AREA',
    ONBOARDING_VIDEO_INFORMATIONS_TITLE: 'ONBOARDING_VIDEO_INFORMATIONS_TITLE',
    ONBOARDING_VIDEO_INFORMATIONS: 'ONBOARDING_VIDEO_INFORMATIONS',
    ONBOARDING_VIDEO_ATTACHMENTS_TITLE: 'ONBOARDING_VIDEO_ATTACHMENTS_TITLE',
    ONBOARDING_VIDEO_ATTACHMENTS: 'ONBOARDING_VIDEO_ATTACHMENTS',
    ONBOARDING_VIDEO_RANKING_TITLE: 'ONBOARDING_VIDEO_RANKING_TITLE',
    ONBOARDING_VIDEO_RANKING: 'ONBOARDING_VIDEO_RANKING',
    ONBOARDING_VIDEO_CHAT_TITLE: 'ONBOARDING_VIDEO_CHAT_TITLE',
    ONBOARDING_VIDEO_CHAT: 'ONBOARDING_VIDEO_CHAT',
    ONBOARDING_VIDEO_END_TOUR_TITLE: 'ONBOARDING_VIDEO_END_TOUR_TITLE',
    ONBOARDING_VIDEO_END_TOUR: 'ONBOARDING_VIDEO_END_TOUR',
};
