import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import KopaLogo from '../../assets/kopa-logo.svg';
import { changePasswordRequest, clearErrors } from '../../store/modules/recoverPassword/actions';
import { Container, Form, ErrorMessage, ErrorContaineir, InstructionsArea } from './styles';
import attention from '../../assets/alert.svg';
import AuthInput from '../../components/AuthInput';

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const error = useSelector(state => state.recoverPassword.error);
    const loading = useSelector(state => state.recoverPassword.loading);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [messageFailEmail, setMessageFailEmail] = useState(false);

    const { t } = useTranslation();
    const { voucherId } = useParams();

    useEffect(() => {
        dispatch(clearErrors());
    }, [dispatch]);

    function validateEmail(email) {
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
        if (emailRegex.test(email)) {
            setIsEmailValid(true);
            return true;
        }
        if (email.length > 0) {
            setIsEmailValid(false);
        }
        return false;
    }

    function handleNewPassword() {
        if (email !== '') {
            dispatch(changePasswordRequest(email, voucherId));
        }
    }

    function keyHandler(evt) {
        if (evt.keyCode === 13 && !loading && isEmailValid) {
            handleNewPassword();
        }
    }

    function handleChange(e) {
        setEmail(e);
        setIsEmailValid(validateEmail(e));
        if (validateEmail(e)) {
            setMessageFailEmail(false);
        }
    }

    function checkInvalidEmail() {
        if (!isEmailValid) {
            setMessageFailEmail(true);
        } else {
            setMessageFailEmail(false);
        }
    }

    return (
        <Container>
            <img
                src={KopaLogo}
                alt="KopaLogo"
                className="logo"
            />
            <InstructionsArea>
                <h3>{t('FORGETPASSWORD')}</h3>
                <p>{t('FORGETPASSWORD_EMAIL')}</p>
            </InstructionsArea>
            <Form>
                <div>
                    <span>{t('EMAIL')}</span>
                </div>
                <AuthInput
                    type="email"
                    autoComplete={window.chrome ? 'no' : 'off'}
                    value={email}
                    onChange={e => handleChange(e.target.value)}
                    onKeyUp={keyHandler}
                    onBlur={() => checkInvalidEmail()}
                    alertMessage={messageFailEmail && messageFailEmail}
                    email
                />

                {messageFailEmail && (
                <div className="emailMessageError">
                    <p>{t('INVALID_EMAIL')}</p>
                </div>
                )}

                <button
                    id={
                        !isEmailValid
                            ? 'submitButtonDisabled'
                            : 'submitButton'
                    }
                    disabled={loading || !isEmailValid}
                    type="submit"
                    onClick={handleNewPassword}
                >{loading ? `${t('LOADING')}...` : t('SEND')}
                </button>
            </Form>
            {error && (
            <ErrorContaineir>
                <img src={attention} alt="att" />
                <ErrorMessage>{error}</ErrorMessage>
            </ErrorContaineir>
            )}
        </Container>

    );
}
