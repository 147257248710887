import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { replaceUntil } from '../../../utils/functions';
import { resendEmail, verifyEmail } from '../../../store/modules/vouchers/actions';
import Notify from '../../../utils/notification';
import { ValidationContainer } from './styles';
import { useTranslation } from 'react-i18next';


const VoucherValidation = ({emailResend, voucherId}) => {
  const input1 = useRef(null)
  const input2 = useRef(null)
  const input3 = useRef(null)
  const input4 = useRef(null)
  const input5 = useRef(null)
  const input6 = useRef(null)
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBackspace = (position, keyEvent) => {
    const { key } = keyEvent;
    if (key === 'Backspace') {
      switch (position) {
        case 2:
          if (input2.current.value === '') {
            input1.current.focus();
          }
          break;
        case 3:
          if (input3.current.value === '') {
            input2.current.focus();
          }
          break;                
        case 4:
          if (input4.current.value === '') {
            input3.current.focus();
          }
          break;
        case 5:
          if (input5.current.value === '') {
            input4.current.focus();
          }
          break;
        case 6:
          if (input6.current.value === '') {
            input5.current.focus();
          }
          break;
        default:
          break;
      }
    } 
  }

  const typeCode = (position, keyEvent) => {
      const { key } = keyEvent;

      if (key !== 'Backspace') {
          switch (position) {
              case 1:
                  input2.current.focus()
                  break;
              case 2:
                  input3.current.focus()
                  break;
              case 3:
                  input4.current.focus()
                  break;                
              case 4:
                  input5.current.focus()
                  break;
              case 5:
                  input6.current.focus()
                  break;
              case 6:
                  if (key === 'Enter') {
                    handleVoucherValidation()
                  };
                  break;
              default:
                  input1.current.focus()
                  break;
          }    
      }
  }

  const handleVoucherValidation = () => {
    if (input1.current.value && input2.current.value &&
        input3.current.value && input4.current.value &&
        input5.current.value && input6.current.value) {
      const code =    input1.current.value.toUpperCase() + input2.current.value.toUpperCase()
                    + input3.current.value.toUpperCase() + input4.current.value.toUpperCase()
                    + input5.current.value.toUpperCase() + input6.current.value.toUpperCase()
      
      dispatch(verifyEmail({voucherId, code}))
    } else {
      Notify.warn(t('VOUCHER', {context: 'ENTER_FIELDS'}))
    }
      
  }

  const handleResend = () => {
      input1.current.value = '';
      input2.current.value = '';
      input3.current.value = '';
      input4.current.value = '';
      input5.current.value = '';
      input6.current.value = '';
      input1.current.focus();
      Notify.success(t('VOUCHER', {context: 'RESENT_EMAIL'}));
      dispatch(resendEmail(encodeURIComponent(emailResend)));
  }

  const handlePaste = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const data = event.clipboardData.getData('Text');
    for (let index = 0; index < Math.floor(data.length); index++) {
      switch (index + 1) {
        case 1:
          input1.current.value = data.substring(0, 1);
          input1.current.focus();
          break;
        case 2:
          input2.current.value = data.substring(1, 2);
          input2.current.focus();
          break;
        case 3:
          input3.current.value = data.substring(2, 3);
          input3.current.focus();
          break;
        case 4:
          input4.current.value = data.substring(3, 4);
          input4.current.focus();
          break;
        case 5:
          input5.current.value = data.substring(4, 5);
          input5.current.focus();
          break;
        case 6:
          input6.current.value = data.substring(5, 6);
          input6.current.focus();
          break;
        default:
          break;
      }
    }
  }

  return (
      <ValidationContainer>
          <h1>{t('VOUCHER', {context: 'EMAIL_VALIDATION'})}</h1>
          <div className="border" />
          <div className='validation'>
              <p>{t('VOUCHER', {context: 'VERIFICATION_CODE'})}
                <span>{replaceUntil(8, '*', emailResend)}</span>
              </p>

              <div className="code">
                  <input  autoFocus ref={input1}  onKeyDown={(key) => handleBackspace(1, key)} 
                          onKeyUp={(key) => typeCode(1, key)}
                          onPaste={handlePaste} 
                          type="text" maxLength="1">
                  </input>
                  <input ref={input2} 
                          onKeyDown={(key) => handleBackspace(2, key)} 
                          onKeyUp={(key) => typeCode(2, key)} 
                          type="text" 
                          maxLength="1">
                  </input>
                  <input ref={input3} 
                          onKeyDown={(key) => handleBackspace(3, key)} 
                          onKeyUp={(key) => typeCode(3, key)} 
                          type="text" 
                          maxLength="1">
                          </input>
                  <input ref={input4} 
                          onKeyDown={(key) => handleBackspace(4, key)} 
                          onKeyUp={(key) => typeCode(4, key)} 
                          type="text" 
                          maxLength="1">
                          </input>
                  <input ref={input5} 
                          onKeyDown={(key) => handleBackspace(5, key)} 
                          onKeyUp={(key) => typeCode(5, key)} 
                          type="text" 
                          maxLength="1">
                          </input>
                  <input ref={input6} 
                          onKeyDown={(key) => handleBackspace(6, key)} 
                          onKeyUp={(key) => typeCode(6, key)} 
                          type="text" 
                          maxLength="1">
                          </input>
              </div>

              <div className="buttons">
                  <button className="resend" onClick={handleResend}>{t('VOUCHER', {context: 'RESEND_EMAIL'})}</button>
                  <button onClick={handleVoucherValidation}>{t('NEXT_STEP')}</button>
              </div>
          </div>  
      </ValidationContainer>
  )
}

export default VoucherValidation;