import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

function userSelector({ room }) {
    return { userPermissions: room.chatUserType };
}

export default function useUserType() {
    const { userPermissions } = useSelector(userSelector, shallowEqual);
    const [userTypes, setUserTypes] = useState([]);

    useEffect(() => {
        if (userPermissions !== null) {
            const types = [];
            if (userPermissions.instructor) {
                types.push('Monitor');
            }
            setUserTypes(types);
        }
    }, [userPermissions]);

    return { userPermissions, userTypes };
}
