import actions from '../../../utils/actions';

const DEFAULT_STATE = {
    voucher: null,
    voucherVerification: {},
    voucherActivated: null,
};

export default function roomReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
    case actions.VOUCHER_SET_VERIFICATION:
        return { ...state, voucherVerification: payload };
    case actions.VOUCHER_SET_ACTIVATED:
        return { ...state, voucherActivated: payload };
    case actions.VOUCHER_CLEAR_ASSOCIATION:
    default:
        return state;
    }
}
