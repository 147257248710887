import * as mediasoup from 'mediasoup-client';

/**
 * @typedef { import('mediasoup-client').types.Producer } Producer
 */

/**
 * @typedef { import('mediasoup-client').types.Transport } Transport
 */

class MediaSoupProducerService {
    /**
     * @type {{ audio:MediaStream, video:MediaStream }}
     */
    _stream = { audio: null, video: null };

    /**
     * @type { import('socket.io-client').Socket }
     */
    _socket;

    /**
     * @type { import('mediasoup-client').Device }
     */
    _mediaDevice;

    /**
      *  @type { Producer }
      */
    _audioProducer;

    /**
      *  @type { Producer }
      */
    _videoProducer;

    /**
     *  @type { Transport }
     */
    _transport = null;

    /**
      *  @type { Producer[] }
      */
    producers = []

    constructor() {
        this.state = null;
        this.kinds = {};
        this.produce = this.produce.bind(this);
        this.connectionStateChange = this.connectionStateChange.bind(this);
        this.createProducerTransport = this.createProducerTransport.bind(this);
        this.setAudioStream = this.setAudioStream.bind(this);
        this.setVideoStream = this.setVideoStream.bind(this);
        this.stopStreaming = this.stopStreaming.bind(this);
        this.connect = this.connect.bind(this);
        this.loadDevice = this.loadDevice.bind(this);
        this.pauseAudio = this.pauseAudio.bind(this);
        this.pauseVideo = this.pauseVideo.bind(this);

        this.handleConnectListener = null;
        this.handleDisconnectListener = null;
    }

    onConnectListener() {
        if (this.handleConnectListener) {
            this.handleConnectListener();
        }
    }

    onDisconnectListener() {
        if (this._transport) {
            this._transport.close();
        }

        if (this._videoProducer) {
            this._videoProducer.close();
        }

        if (this._audioProducer) {
            this._audioProducer.close();
        }
        if (this.handleDisconnectListener) {
            this.handleDisconnectListener();
        }
    }

    setKinds(kinds) {
        this.kinds = kinds;
    }

    setSocket(socket) {
        this._socket = socket;
    }

    setAudioStream(stream) {
        this._stream.audio = stream;
    }

    setVideoStream(stream) {
        this._stream.video = stream;
    }

    pauseAudio() {
        if (this._audioProducer.paused) {
            this._audioProducer.resume();
            if (this._socket.connected) this._socket.request('mediaProducerResume', { kinds: ['audio'] });
        } else {
            this._audioProducer.pause();
            if (this._socket.connected) this._socket.request('mediaProducerPause', { kinds: ['audio'] });
        }
    }

    pauseVideo() {
        if (this._videoProducer.paused) {
            this._videoProducer.resume();
            if (this._socket.connected) this._socket.request('mediaProducerResume', { kinds: ['video'] });
        } else {
            this._videoProducer.pause();
            if (this._socket.connected) this._socket.request('mediaProducerPause', { kinds: ['video'] });
        }
    }

    async stopStreaming(keepSocketConnection = false) {
        if (this._transport) {
            this._transport.close();

            if (this._socket.connected) {
                await this._socket.request('producerTransportClosed');
                if (!keepSocketConnection) {
                    this._socket.disconnect();
                }
            }
        }
    }

    async loadDevice(routerRtpCapabilities) {
        try {
            this._mediaDevice = new mediasoup.Device();
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
            }
        }
        await this._mediaDevice.load({ routerRtpCapabilities });
    }

    async produce({ kind, rtpParameters }, callback, errorCallBack) {
        try {
            const { id } = await this._socket.request('produce', {
                transportId: this._transport.id,
                kind,
                rtpParameters,
            });
            callback({ id });
        } catch (err) {
            console.log('error', err);
            errorCallBack(err);
        }
    }

    async connectionStateChange(state) {
        this.state = state;
        switch (state) {
            case 'connected':
                this.onConnectListener();
                break;
            case 'disconnected':
                this.onDisconnectListener();
                break;
            default:
                break;
        }
    }

    async createProducerTransport({ dtlsParameters }, callback, errorCallBack) {
        try {
            const res = await this._socket.request(
                'connectProducerTransport',
                { dtlsParameters },
            );
            callback(res);
        } catch (err) {
            errorCallBack(err);
        }
    }

    async produceAudio() {
        if (this._mediaDevice.canProduce('audio') && this.kinds.audio && this._stream.audio) {
            if (!this._audioProducer || this._audioProducer.closed) {
                const audioTrack = this._stream.audio.getAudioTracks()[0];
                const audioProducer = await this._transport.produce({ track: audioTrack, stopTracks: false });
                this._audioProducer = audioProducer;
                this.producers.push(audioProducer);
            }
        }
    }

    async produceVideo() {
        if (this._mediaDevice.canProduce('video') && this.kinds.video && this._stream.video) {
            if (!this._videoProducer || this._videoProducer.closed) {
                const videoTrack = this._stream.video.getVideoTracks()[0];
                const videoProducer = await this._transport.produce({
                    track: videoTrack,
                    stopTracks: false,
                    encodings: [
                        { maxBitrate: 3000000, scaleResolutionDownBy: 1 },
                        { maxBitrate: 1000000, scaleResolutionDownBy: 2 },
                        { maxBitrate: 600000, scaleResolutionDownBy: 4 },
                    ],
                });
                this._videoProducer = videoProducer;
                this.producers.push(videoProducer);
            }
        }
    }

    async connect() {
        if (!this._stream.video) {
            console.error('Video stream not provided!');
        }

        const serverRtpParameters = await this._socket?.request(
            'getRouterRtpCapabilities',
        );
        await this.loadDevice(serverRtpParameters);

        if (this._mediaDevice.loaded) {
            const data = await this._socket.request('createProducerTransport', {
                forceTcp: false,
                rtpCapabilities: this._mediaDevice.rtpCapabilities,
            });

            this._transport = this._mediaDevice.createSendTransport(data);
            this._transport.on('connect', this.createProducerTransport);
            this._transport.on('produce', this.produce);
            this._transport.on('connectionstatechange', this.connectionStateChange);
            if (this._mediaDevice.loaded) {
                try {
                    await this.produceVideo();
                    await this.produceAudio();
                } catch (err) {
                    console.error('stream error', err);
                }
            }
        }
    }
}

export default new MediaSoupProducerService();
