import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    height: 100%;       

    .video-details {
        button {
            &:disabled {
               filter: brightness(0.5); 
            }
        }
    }

    @media (min-width: 650px) {
        flex-flow: row nowrap;
        padding: 1rem;
         &.is-safari {
           padding: 0.6rem 1.3rem;
        }
    }

    @media (max-width: 600px) and (orientation: landscape) {
        flex-flow: column nowrap;
    }

    @media(max-width: 799px) and (orientation: portrait) {
        flex-flow: column nowrap;
        .end-transmission {
            position: relative;
            right: initial;
            margin-right: 15px;
        }        
    }

    @media (max-width: 990px) and (orientation: landscape) {
        flex-flow: row nowrap;
    }

    @media screen and (min-width: 48.75rem) and (max-width: 64rem ) {
        & h2 {
            font-size: 1rem;
        }
    }

    
`;

export const VideoPreview = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 40%;
    position: relative;

    @media (min-width: 650px) {
        height: 100%;
        margin-bottom: 10px;
        padding: 0 20px 0 20px;
    }

    @media (max-width: 650px) {
        height: 100%;
    }

    @media (max-width: 650px) and (orientation: landscape) {
        height: 100%;
    }

    .mute-controls {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-25%, 50%);        

        img {
            height: 60px;
        }
    }

    .video-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 62px;
        padding: 10px;
        margin-bottom: 15px;
        background-color: #1c1b2d;
        min-height: 65px;

        span {
            height: 28px;
            border-radius: 3px;
            background-color: #ff5b7d;
            position: relative;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 10px 5px 25px;
            min-width: 104px;

            &:before {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: #fff;
                left: 10px;
                border-radius: 50%;
            }
        }

        h5 {
            font-size: clamp(12px, 0.4em + 1vw, 1.5em);
            font-weight: 800;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.48px;
            color: #ffffff;
        }

        h6 {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.02px;
            color: #ffffff;
        }

        @media (max-width: 450px){
            h5 {
                display: none;
            }
        }
        @media (max-width: 400px){
            span {
                font-size: 10px;
                min-width: 80px;
            }
        
        }
    }

    .video-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        border-radius: 8px;
        background-color: #212032;
        overflow: hidden;
    }

    .video-details {
        /* max-height: 20rem; */
        /* height: 50px;         */
        width: 100%;
    }

    .video-player {
        position: relative;
        overflow: hidden;
        video {
            width: 100%;
            height: 100%;
        }
        
        height: ${(props) => (props.collapsed
        ? 'calc(98% - 65px)'
        : props.hasAttachments
            ? '68%'
            : '100%')};
    }
    @media (max-width: 650px) {
        .video-player {
            height: ${(props) => (props.collapsed
        ? 'calc(98% - 45px)'
        : props.hasAttachments
            ? '68%'
            : '100%')};
        }
    }

    @media (max-width: 600px) {
       video {
           /* max-height: 200px; */
       }
    }
`;

export const DescriptionContainer = styled.div`
    width: 100%;
    max-height: 13rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
`;

export const Chat = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 300px;
    width: 25vw;
`;

export const StartStopTransmission = styled.button`
    border: none;
    color: #FFF;
    background: linear-gradient(90deg,#e4830e 0%, #FF438D 100%);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 8px;  
    font-weight: bold;
    text-transform: uppercase; 
    transition: all 0.1s ease-in-out;
    padding: 0 15px;
    width: 100%;
    text-transform: uppercase;
    
    &:disabled {     
        color: #ff5b7d;             
        background: #212032;
        border: solid 1px #ff5a7d;
    }

    &:not(:disabled):hover {
        transform: scale(1.02);
    }
`;

export const AccessCameraDenied = styled.div`
    background-color: #151420;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;

    h2 {
        margin: 10px;
        text-transform: uppercase;
    }

    h3 {
        text-align: center;
    }
    
`;

export const StartTransmissionHeading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #fff;
    padding: 0 14px;
    overflow: auto;
    min-height: 300px;

    h2 {
        margin-top: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 30px;
    } 

    @media (max-height: 700px) {

        img {
            display: none;
        }

        h2 {
            display: none;
        }

        p {
            margin-bottom: 10px;
        }

    }

    @media (max-width: 620px){
        justify-content: flex-start;

        img {
            width: 2rem;
        }

        h2 {
            font-size: .9rem;           
        } 

        p {
            font-size: .8rem;           
        }
    }
`;

export const RecordingWarning = styled.div`    
    background: #e76370;
    margin: 0 22px 0 42px;
    border-radius: 8px;
    display: flex;
    height: 58px;
    align-items: center;
    overflow: hidden;

    @media(max-width: 799px) and (orientation: portrait) {
        margin: 0 42px;
    }

    @media (max-width: 650px) {
        margin: 0;
    }
`;
export const WarnIconContainer = styled.div`
    background: #d45e63;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;
export const WarnText = styled.h2`
    color: #fff;
    margin-left: 0.5rem;
    font-size: clamp(12px, 0.4em + 1vw, 1.5em);
`;

export const NoConnectionWarning = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;

    .error-network {
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        justify-content: center;
        margin: 0;
    }


    span {
        font-size: 3rem;
        color: #d45e63;
    }
`;
