import { useSelector, shallowEqual } from 'react-redux';

function roomsSelector({ room }) {
    return {
        rooms: room.rooms,
        selectedRoom: room.selectedRoom,
        selectedEditRoom: room.selectedEditRoom,
        statusLiveRoomEditSelected: room.statusLiveRoomEditSelected,
        pinnedMessages: room.roomPinnedMessages,
        loading: room.loading,
        roomEngagement: room.roomEngagement,
        roomEventLog: room.roomEventLog,
        roomGraph: room.roomGraph,
        roomGeolocalization: room.roomGeolocalization,
        isChatEnabled: room.isChatEnabled,
        videoToUpload: room.videoToUpload,
        progress: room.progress,
        isUploadingInBackground: room.isUploadingInBackground,
        quiz: room.quiz,
        technology: room.technology,
        videoUrl: room.videoUrl,
        socketPath: room.socketPath,
        interactive: room.interactive,
        liveStatus: room.liveStatus,
    };
}

export default function useRooms() {
    const {
        rooms,
        selectedRoom,
        selectedEditRoom,
        statusLiveRoomEditSelected,
        pinnedMessages,
        loading,
        roomEngagement,
        roomEventLog,
        roomGraph,
        roomGeolocalization,
        isChatEnabled,
        videoToUpload,
        progress,
        isUploadingInBackground,
        quiz,
        technology,
        videoUrl,
        socketPath,
        interactive,
        liveStatus,
    } = useSelector(roomsSelector, shallowEqual);
    return {
        rooms,
        selectedRoom,
        selectedEditRoom,
        statusLiveRoomEditSelected,
        pinnedMessages,
        loading,
        roomEngagement,
        roomEventLog,
        roomGraph,
        roomGeolocalization,
        isChatEnabled,
        videoToUpload,
        progress,
        isUploadingInBackground,
        quiz,
        technology,
        videoUrl,
        socketPath,
        interactive,
        liveStatus,
    };
}
