import React from 'react';

import { useTranslation } from 'react-i18next';
import caution from '../../assets/alert.svg';
import { Container, MessageCointainer, LinkButton } from './styles';

export default function NoPermission() {
    const { t } = useTranslation();

    return (
        <Container>
            <MessageCointainer>
                <img
                    className="correct-image"
                    src={caution}
                    alt="KopaLogo"
                />
                <h1 className="alert-title">{t('OPS')}</h1>
                <h3 className="alert-subtitle">{t('ACCESS_NOT_ALLOWED')}</h3>
                <LinkButton to="/">
                    <button
                        type="submit"
                    > {t('COMEBACK')}
                    </button>
                </LinkButton>
            </MessageCointainer>

        </Container>

    );
}
