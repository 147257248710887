/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Container,
    VideoPreview,
    Device,
} from './styles';

import mediaSoupService from '../../../services/MediaSoupProducerService';
import producerMicIcon from '../../../assets/producer-mic-icon.svg';
import producerMicMuteIcon from '../../../assets/producer-mic-mute-icon.svg';
import producerCamIcon from '../../../assets/producer-cam-icon.svg';
import producerCamMuteIcon from '../../../assets/producer-cam-mute-icon.svg';
import NoVideo from '../NoVideo';
import CONSTANTS from '../../../assets/i18n/constants';

export default function UserMediaSettings() {
    const videoWrapper = useRef();
    const videoElement = useRef();
    const audioElement = useRef();

    const { t } = useTranslation();

    const [isCamMute, setIsCamMute] = useState(false);
    const [isMicMute, setIsMicMute] = useState(false);

    function loadUserMedia() {
        videoElement.current.srcObject = mediaSoupService._stream.video;
        audioElement.current.srcObject = mediaSoupService._stream.audio;
    }

    useEffect(() => {
        loadUserMedia();
    }, []);

    async function handleMuteCamera() {
        const track = mediaSoupService._stream.video?.getVideoTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            setIsCamMute(!track.enabled);

            if (!mediaSoupService._videoProducer) {
                console.log('No video producer found!');
                return;
            }
            mediaSoupService.pauseVideo();
        }
    }

    async function handleMuteMicrofone() {
        const track = mediaSoupService._stream.audio?.getAudioTracks()[0];

        if (track) {
            track.enabled = !track.enabled;
            setIsMicMute(!track.enabled);

            if (!mediaSoupService._audioProducer) {
                console.log('No audio producer found!');
                return;
            }

            mediaSoupService.pauseAudio();
        }
    }

    useEffect(() => {
        setIsCamMute(mediaSoupService._videoProducer?.paused || false);
        setIsMicMute(mediaSoupService._audioProducer?.paused || false);
    }, []);

    return (
        <Container>
            <VideoPreview>
                <section className="video-container">
                    <div className="video-player" ref={videoWrapper}>
                        <video ref={videoElement} autoPlay playsInline />
                        <audio ref={audioElement} autoPlay muted />
                        {
                            isCamMute && <NoVideo title={t(CONSTANTS.OPS)} subtitle={t(CONSTANTS.DISABLED_CAMERA)} />
                        }
                        {
                            !mediaSoupService._stream.video?.active && <NoVideo title={t(CONSTANTS.OPS)} subtitle={t(CONSTANTS.UNAVAILABLE_MEDIA)} />
                        }
                    </div>
                    <div className="video-details">
                        <div className="device-wrapper">
                            <Device onClick={handleMuteMicrofone}>
                                {isMicMute ? (
                                    <img src={producerMicMuteIcon} alt="Microfone" />
                                ) : (
                                    <img src={producerMicIcon} alt="Microfone" />
                                )}
                            </Device>
                        </div>
                        <div className="device-wrapper">
                            <Device className={isCamMute ? 'mute-device' : ''} onClick={handleMuteCamera}>
                                {isCamMute ? (
                                    <img src={producerCamMuteIcon} alt="Camera" />
                                ) : (
                                    <img src={producerCamIcon} alt="Camera" />
                                )}
                            </Device>
                        </div>
                    </div>
                </section>

            </VideoPreview>
        </Container>
    );
}
