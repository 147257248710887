import React, {useRef, useState} from 'react';
import { DataContainer } from './styles'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { registerVoucherUser } from '../../../store/modules/vouchers/actions';
import Notify from '../../../utils/notification';
import MaskedInput from 'react-text-mask'
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const VoucherData = () => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const confirmEmailRef = useRef(null);
  const genreRef = useRef(null);
  const phoneRef = useRef(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [birthDate, setBirthDate] = useState('');

  const handleVoucherData = (e) => {
      e.preventDefault();
      if ((birthDate !== '') && !(moment(birthDate, 'DD/MM/YYYY', true).isValid())) {
        Notify.warn(t('VOUCHER', {context: 'USERDATA_INVALID_DATE'}))
      } else
      if (emailRef.current.value !== confirmEmailRef.current.value) {
        Notify.warn(t('VOUCHER', {context: 'USERDATA_EMAIL_DIFFERS'}));
      } else
      if (nameRef.current.value &&
          emailRef.current.value &&
          confirmEmailRef.current.value &&
          genreRef.current.value !== t('VOUCHER', {context: 'USERDATA_GENDER_SELECT'}) &&
          genreRef.current.value &&
          phoneRef.current.state && phoneRef.current.state.formattedNumber.length > 8) {
            const data = {
              "username": emailRef.current.value,
              "name": nameRef.current.value,
              "fullName": nameRef.current.value,
              "phone": parseInt(phoneRef.current.state.formattedNumber),
              "gender": genreRef.current.value,
              "dateOfBirth": moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              "voucherId": id,
            }
            dispatch(registerVoucherUser(data));
      }
      else {
        Notify.warn(t('VOUCHER', {context: 'USERDATA_FILL'}))
      }
  }

  const handleChange = (e) => {
    e.preventDefault();
  }

  return (
      <DataContainer>
          <h1>{t('VOUCHER', {context: 'USERDATA_TITLE'})}</h1>
          <div className="border" />
          <div className='data'>
              <form onSubmit={handleVoucherData}>
                  <div className="name">
                      <label>{t('VOUCHER', {context: 'USERDATA_FULL_NAME'})}</label>
                      <input autoFocus ref={nameRef} type="text" placeholder={t('VOUCHER', {context: 'USERDATA_TYPE_FULL_NAME'})}></input>
                  </div>
                  <div className="email">
                      <div>
                          <label>{t('VOUCHER', {context: 'USERDATA_EMAIL'})}</label>
                          <input  
                                  ref={emailRef}
                                  type="email"
                                  placeholder="usuario@gmail.com"
                                  onCut={handleChange} 
                                  onCopy={handleChange}
                                  onPaste={handleChange} 
                                  ></input>
                      </div>
                      <div>
                          <label>{t('VOUCHER', {context: 'USERDATA_EMAIL_CONFIRM'})}</label>
                          <input  
                                  ref={confirmEmailRef}
                                  type="email" 
                                  onCut={handleChange} 
                                  onCopy={handleChange}
                                  onPaste={handleChange} 
                                  placeholder="usuario@gmail.com"></input>
                      </div>
                  </div>
                  <div className="info">
                      <div className="birth">
                          <label>{t('VOUCHER', {context: 'USERDATA_DATE_OF_BIRTH'})}</label>
                          <MaskedInput 
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={birthDate}
                            onChange={e => setBirthDate(e.target.value)}
                            showMask
                            
                          />
                      </div>
                      <div className="genre middle-col-3">
                          <label>{t('VOUCHER', {context: 'USERDATA_GENDER'})}</label>
                          <select ref={genreRef}>
                              <option>{t('VOUCHER', {context: 'USERDATA_GENDER_SELECT'})}</option>
                              <option>{t('VOUCHER', {context: 'USERDATA_GENDER_MALE'})}</option>
                              <option>{t('VOUCHER', {context: 'USERDATA_GENDER_FEM'})}</option>
                              <option>{t('VOUCHER', {context: 'USERDATA_GENDER_OTHER'})}</option>
                          </select>
                      </div>
                      <div className="phone">
                          <label>{t('VOUCHER', {context: 'USERDATA_PHONE'})}</label>
                          <PhoneInput
                            autoFormat={false}
                            ref={phoneRef}
                            country='br'
                            enableSearch={true}
                            searchPlaceholder={t('SEARCH')}
                            searchNotFound={t('SEARCH', {context: 'NOT_FOUND'})}
                          />
                      </div>
                  </div>
                  <button className="submit" type="submit">{t('VOUCHER', {context: 'NEXT_STEP_BUTTON'})}</button>
              </form>
          </div> 
      </DataContainer>
  )
}

export default VoucherData