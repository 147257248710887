import styled from 'styled-components';

export const ProfileModalContainer = styled.div`
    width: 100%;
    max-height: 85vh;
    height: 75vh;
    padding: 2rem 4rem;
    display: ${props => `${props.cropping ? 'none' : 'flex'}`};
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    align-self: center;

    button {
        width: 100%;
        padding: 15px 0;
        min-height: 3rem;
        border: 0;
        border-radius: 8px;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
    }


    @media screen and (max-width: 425px){
        padding: 2rem 1rem;
    }
`;

export const ImageProfileContainer = styled.div`
    width: 258px;
    min-height: 12rem;
    display: flex;
    position: relative;
`;

export const ImageBackground = styled.div`
    width: 77%;
    height: 95%;
    position: absolute;
    right: .6rem;
    background: linear-gradient(40deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
    border-radius: 8px;

`;

export const ImageProfile = styled.div`
    width: 77%;
    height: 95%;
    position: absolute;
    right: 1rem;
    top: .4rem;
    border-radius: 8px;
    background-color: #141429;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }

    .upload-profile-thumbnail {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
    }


`;

export const ButtonsArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
`;

export const ToolButton = styled.div`
    height: 30px;
    width: 30px;
    border-radius:  15px;
    background-color: #FF8F63;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(255,66,141,1);
    }

    img {
        width: 60%;
    }
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.2rem 0;


    p {
        margin: 0 .3rem;
        color: #ff6363;
    }
`;

export const ProfileDataInfoAreaContainer = styled.div`
    width: 100%;
    padding: .4rem;
    max-width: 890px;
    background-color: #141429;
    min-height: 6.5rem;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
`;

export const ProfileDataInfoArea = styled.div`
    text-align: center;
`;

export const WelcomeText = styled.div`
    color: #FF8F63;
    font-weight: bold;

`;

export const ProfileName = styled.div`
    font-size: 1.3rem;
    color: #fff;
    font-weight: bold;

`;

export const ProfileInfo = styled.div`
    font-size: .8rem;
    color: #fff;
`;

export const SocialMediaArea = styled.div`
    width: 100%;
    min-height: 15rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`;

export const SocialMediaTextHead = styled.div`
    font-size: 1.3rem;
    color: #fff;
    font-weight: bold;
    margin: 1rem 0;

`;

export const CropContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;

    img {
        max-height: 50vh;
    }

    .crop-component {
        width: 90%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        canvas {
            max-height: 50vh;
            height: 100% !important;
            width: auto !important;
        }
    }

    button {
        margin-top: 2rem;
        width: 100%;
        padding: 15px 0;
        border: 0;
        border-radius: 8px;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
    }

`;
