import React from 'react';
import {
    Container,
    Content,
    CloseButton,
    ContentTitle,
    Title,
    ContentBody,
} from './styles';

import closeIcon from '../../assets/close-icon.svg';

export default function Modal({
    isOpen, handleModal, children, width, title = '', featuredTitle = '', customClasses = '', bodyWidth = null,
}) {
    return (
        isOpen && (
            <Container>
                <Content width={width}>
                    <ContentTitle>
                        <Title>
                            <p>{`${title}`}</p>
                            <p className="featured">
                                {`${featuredTitle}`}
                            </p>
                        </Title>
                        <CloseButton className="--pulse" type="button" onClick={handleModal}>
                            <img src={closeIcon} alt="Fechar modal" />
                        </CloseButton>
                    </ContentTitle>
                    <ContentBody className={customClasses} bodyWidth={bodyWidth}>
                        {children}
                    </ContentBody>
                </Content>
            </Container>
        )
    );
}
