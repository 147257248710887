import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
  display:flex;
  align-self:flex-end;
  margin-bottom:30px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.18px;
  color: #ffffff;

`;

export const Container = styled.div`
  margin: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2rem;

  .errorMessage {
    display: flex;
    justify-content: space-between;
    h3 {
      height: 33px;
      margin: 1em;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.27px;
      text-align: center;
      color: #ffffff;
    }
  }
    
  
  .textHeader {
    height: 33px;
    margin: 1em;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    text-align: center;
    color: #ffffff;
  }

  .textMessage {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: 0.21px;
    text-align: center;
  }

  .logo {
    width: 280px;
    max-width: 100%;
    display: block;
  }

  @media(min-width: 500px) {
    width: 70%;
  }

  @media(min-width: 600px) {
    width: 50%;
  }

  @media(min-width: 990px) {
    width: 50%;
  }
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  span {
      height: 22px;
      margin-bottom: 8px;
      display: block;

      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #ffffff;
  } 

  input{
      background: #24243e;
      border: 0;
      height: 40px;
      border-radius: 4px;
      padding: 0 15px;
      margin: 0 0 30px;
      color:#fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      color: #ffffff;
  }
  button {
      border: none;
      color: #FFF;
      background: linear-gradient(90deg,#FF438D 0%,#FFAC47 100%);
      width: 100%;
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-weight: 600;
      border-radius: 4px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: transparent;
  }

`;

export const ErrorMessage = styled.span`
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #fff;

`;

export const ErrorContainer = styled.div`
  margin-top:18px;
  display: flex;
  align-self: center;

img{
  width:25px;
  height:25px;
  margin-right:10px;
}
`;

export const LanguageContainer = styled.div`
  display: flex;
  margin-top: 10px;

  h3 {
    color: white;
    margin-left: 0;
  }

  .arrow-down {
    margin-left: 10px;
    margin-right: 20px;
  }

  .language {
    display: flex;
    margin: 0 5px;
    align-items: center;
    
  }

  .dropdown-languages {
    position: relative;
  }

  img {
    height: 10px;
    width: 10px;
  }
  
`;
