import { all } from 'redux-saga/effects';

import login from './auth/sagas';
import room from './rooms/saga';
import roomsProgress from './roomsProgress/saga';
import recoverPassword from './recoverPassword/sagas';
import offers from './offers/saga';
import voucher from './vouchers/saga';
import ranking from './ranking/saga';

export default function* rootSaga() {
    return yield all([
        login,
        room,
        roomsProgress,
        recoverPassword,
        offers,
        voucher,
        ranking,
    ]);
}
