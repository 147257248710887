export default {
    // Rooms
    ACCEPT_TERMS_OF_USAGE: 'ACCEPT_TERMS_OF_USAGE',
    ROOM_SET_CHAT_USER_TYPE: 'ROOM_SET_CHAT_USER_TYPE',
    ROOM_REPLY_MESSAGE: 'ROOM_REPLY_MESSAGE',
    ROOM_EDIT_SELECT: 'ROOM_EDIT_SELECT',
    ROOM_FAIL: 'ROOM_FAIL',
    ROOM_EDIT_SELECT_SUCCESS: 'ROOM_EDIT_SELECT_SUCCESS',
    ROOM_CHAT_SET_ENABLE_TEAM: 'ROOM_CHAT_SET_ENABLE_TEAM',
    ROOM_CHAT_SET_TEAM_INFORMATION: 'ROOM_CHAT_SET_TEAM_INFORMATION',
    ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM: 'ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM',
    ROOM_CHAT_SET_CHAT_ENABLE_ROOM: 'ROOM_CHAT_SET_CHAT_ENABLE_ROOM',
    ROOM_CREATE: 'ROOM_CREATE',
    ROOM_EDIT: 'ROOM_EDIT',

    ROOM_VIDEO_PRIORITY_REQUEST: 'ROOM_VIDEO_PRIORITY_REQUEST',
    ROOM_VIDEO_PRIORITY_REQUEST_SUCCESS: 'ROOM_VIDEO_PRIORITY_REQUEST_SUCCESS',
    ROOM_VIDEO_PRIORITY_REQUEST_FAIL: 'ROOM_VIDEO_PRIORITY_REQUEST_FAIL',
    ROOM_VIDEO_PRIORITY_REQUEST_REMOVE_URL: 'ROOM_VIDEO_PRIORITY_REQUEST_REMOVE_URL',

    ROOM_CLEAR_EDIT_SELECTED: 'ROOM_CLEAR_EDIT_SELECTED',
    ROOM_GOLIVE: 'ROOM_GOLIVE',
    ROOM_ENDLIVE: 'ROOM_ENDLIVE',
    ROOM_IS_LIVE: 'ROOM_IS_LIVE',
    ROOM_ENGAGEMENT: 'ROOM_ENGAGEMENT',
    ROOM_SET_ENGAGEMENT: 'ROOM_SET_ENGAGEMENT',
    ROOM_EVENTLOG: 'ROOM_EVENTLOG',
    ROOM_SET_EVENTLOG: 'ROOM_SET_EVENTLOG',
    ROOM_GRAPH: 'ROOM_GRAPH',
    ROOM_SET_GRAPH: 'ROOM_SET_GRAPH',
    ROOM_GEOLOCALIZATION: 'ROOM_GEOLOCALIZATION',
    ROOM_SET_GEOLOCALIZATION: 'ROOM_SET_GEOLOCALIZATION',
    ROOM_SET_STATUS_ROOM: 'ROOM_SET_STATUS_ROOM',
    ROOM_SET_TO_LIVE: 'ROOM_SET_TO_LIVE',
    ROOM_PIN_MESSAGE: 'ROOM_PIN_MESSAGE',
    ROOM_SET_PIN_MESSAGE: 'ROOM_SET_PIN_MESSAGE',
    ROOM_UNPIN_MESSAGE: 'ROOM_UNPIN_MESSAGE',
    ROOM_SET_CHAT_ENABLED: 'ROOM_SET_CHAT_ENABLED',
    CHANGE_TEAM_CHAT: 'CHANGE_TEAM_CHAT',
    ROOM_SHOW_QRCODE: 'ROOM_SHOW_QRCODE',
    SET_TERM_URL: 'SET_TERM_URL',
    UPDATED_BLOCKED_USER_MESSAGES: 'UPDATED_BLOCKED_USER_MESSAGES',
    ROOM_GET_PROGRESS: 'ROOM_GET_PROGRESS',
    ROOM_SET_PROGRESS: 'ROOM_SET_PROGRESS',
    // Teams
    GET_TEAMS_STATUS: 'GET_TEAMS_STATUS',
    SET_TEAMS_STATUS: 'SET_TEAMS_STATUS',
    ASYNC_SET_TEAMS_STATUS: 'ASYNC_SET_TEAMS_STATUS',
    GET_TEAMS: 'GET_TEAMS',
    SET_TEAMS: 'SET_TEAMS',
    ASYNC_CREATE_TEAMS: 'ASYNC_CREATE_TEAMS',
    // Offers
    GET_OFFERS_STATUS: 'GET_OFFERS_STATUS',
    SET_OFFERS_STATUS: 'SET_OFFERS_STATUS',
    SET_OFFERS_STATUS_SOCKET: 'SET_OFFERS_STATUS_SOCKET',
    GET_OFFERS: 'GET_OFFERS',
    GET_OFFER_URL: 'GET_OFFER_URL',
    SET_OFFERS: 'SET_OFFERS',
    SET_OFFER_URL: 'SET_OFFER_URL',
    CREATE_OFFER: 'CREATE_OFFER',
    FINISH_OFFER_REQUEST: 'FINISH_OFFER_REQUEST',
    // Vouchers
    VOUCHER_VERIFY: 'VOUCHER_VERIFY',
    VOUCHER_SET_VERIFICATION: 'VOUCHER_SET_VERIFICATION',
    VOUCHER_REGISTER_USER: 'VOUCHER_REGISTER_USER',
    VOUCHER_VERIFY_EMAIL: 'VOUCHER_VERIFY_EMAIL',
    VOUCHER_RESEND_VERIFY: 'VOUCHER_RESEND_VERIFY',
    VOUCHER_CONFIRM_PASSWORD: 'VOUCHER_CONFIRM_PASSWORD',
    VOUCHER_ASSOCIATE: 'VOUCHER_ASSOCIATE',
    VOUCHER_ASSOCIATE_REGISTERED: 'VOUCHER_ASSOCIATE_REGISTERED',
    VOUCHER_CLEAR_ASSOCIATION: 'VOUCHER_CLEAR_ASSOCIATION',
    VOUCHER_SET_ACTIVATED: 'VOUCHER_SET_ACTIVATED',

    // Quizz
    SET_QUIZ: 'SET_QUIZ',
    GET_QUIZ: 'GET_QUIZ',
    ANSWER_QUIZ: 'ANSWER_QUIZ',
    FINISH_QUIZ: 'FINISH_QUIZ',

    CHAT_BLOCK_USER: 'CHAT_BLOCK_USER',

    // Ranking
    PERSONAL_RANKING_REQUEST: 'PERSONAL_RANKING_REQUEST',
    PERSONAL_RANKING_SUCCESS: 'PERSONAL_RANKING_SUCCESS',
    PERSONAL_RANKING_FAILURE: 'PERSONAL_RANKING_FAILURE',
    GAMIFICATION_COURSE_STATUS_REQUEST: 'GAMIFICATION_COURSE_STATUS_REQUEST',
    GAMIFICATION_COURSE_STATUS_SUCCESS: 'GAMIFICATION_COURSE_STATUS_SUCCESS',
    GAMIFICATION_COURSE_STATUS_FAILURE: 'GAMIFICATION_COURSE_STATUS_FAILURE',
    GAMIFICATION_COURSE_STATUS_CLEAN: 'GAMIFICATION_COURSE_STATUS_CLEAN',
};
