import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { Switch } from '@mantine/core';
import producerMicIcon from '../../../assets/producer-mic-icon.svg';
import producerMicMuteIcon from '../../../assets/producer-mic-mute-icon.svg';
import producerCamIcon from '../../../assets/producer-cam-icon.svg';
import producerCamMuteIcon from '../../../assets/producer-cam-mute-icon.svg';
import producerDownIcon from '../../../assets/arrow-down-icon.svg';
import mediaSoupService from '../../../services/MediaSoupProducerService';
import languageConstants from '../../../assets/i18n/constants';
import {
    DevicesContainer, DeviceList, Device, DeviceType, EndTransmissionButton, DeviceTabTypeContainer,
} from './styles';

function advancedAudioConstraintsBuilder(t) {
    return (
        [
            {
                description: t(languageConstants.NOISESUPPRESSION),
                key: 'noiseSuppression',
                enabled: false,
            },
            {
                description: t(languageConstants.ECHOCANCELLATION),
                key: 'echoCancellation',
                enabled: false,
            },
            {
                description: t(languageConstants.AUTOGAINCONTROL),
                key: 'autoGainControl',
                enabled: false,
            },
        ]
    );
}

function devicesAudioTabsBuilder(t) {
    return [
        { label: t(languageConstants.MICROFONES), key: 'microfones' },
        { label: t(languageConstants.ADVANCED), key: 'advanced' },
    ];
}

function devicesVideoTabsBuilder(t) {
    return [
        { label: t(languageConstants.CAMERAS), key: 'cameras' },
    ];
}

function DeviceTabType({ tabItems = [], activeTab, setActiveTab = () => { } }) {
    return (
        <DeviceTabTypeContainer>
            {tabItems.map(tab => (
                <DeviceType
                    key={tab.key}
                    className={`${activeTab === tab.key ? '--active-tab' : ''} ${tabItems.length < 2 ? '--single' : ''}`}
                    onClick={() => setActiveTab(tab.key)}
                >
                    {tab.label}
                </DeviceType>
            ))}
        </DeviceTabTypeContainer>
    );
}

export default function MediaControls({
    isLive = false,
    selectedCam,
    selectedMic,
    getUserMediaAudio,
    getUserMediaVideo,
    audioStream,
    videoStream,
    audioInputs,
    videoInputs,
    microfonePermissionDenied,
    cameraPermissionDenied,
    stopTransmissionQuestion,
    startingLive,
    changeAudionConstraints,
    audioConstraintsState,
    supportedConstraints,
}) {
    const { t } = useTranslation();

    const [isCamMute, setIsCamMute] = useState(false);
    const [isMicMute, setIsMicMute] = useState(false);

    const [showMicDevices, setShowMicDevices] = useState(false);
    const [showCamDevices, setShowCamDevices] = useState(false);
    const [activeTab, setActiveTab] = useState('microfones');

    const devicesAudioTabs = devicesAudioTabsBuilder(t);
    const devicesVideoTabs = devicesVideoTabsBuilder(t);
    const advancedAudioConstraints = advancedAudioConstraintsBuilder(t);

    async function handleChangeCamera(deviceId) {
        selectedCam.current = deviceId;
        getUserMediaVideo();
    }

    async function handleChangeMicrofone(deviceId) {
        selectedMic.current = deviceId;
        getUserMediaAudio();
    }

    function handleShowMicDevices() {
        setActiveTab('microfones');
        setShowMicDevices(!showMicDevices);
        if (showCamDevices) {
            setShowCamDevices(false);
        }
    }

    function handleShowCamDevices() {
        setShowCamDevices(!showCamDevices);
        if (showMicDevices) {
            setShowMicDevices(false);
        }
    }

    async function handleMuteCamera() {
        const track = videoStream.current?.getVideoTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            setIsCamMute(!track.enabled);

            if (!mediaSoupService._videoProducer) {
                console.log('No video producer found!');
                return;
            }

            mediaSoupService.pauseVideo();
        }
    }

    async function handleMuteMicrofone() {
        const track = audioStream.current?.getAudioTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            setIsMicMute(!track.enabled);

            if (!mediaSoupService._audioProducer) {
                console.log('No audio producer found!');
                return;
            }

            mediaSoupService.pauseAudio();
        }
    }

    function handleClickOutsideList() {
        setActiveTab('microfones');
        setShowMicDevices(false);
    }

    return (
        <DevicesContainer className={isLive ? 'is-live' : ''}>
            <div>
                <OutsideClickHandler onOutsideClick={handleClickOutsideList}>
                    <div className="device-wrapper">
                        {showMicDevices && activeTab === 'microfones' && (
                            <DeviceList className="device-list">
                                <DeviceTabType
                                    tabItems={devicesAudioTabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                                <ul>
                                    {
                                        audioInputs?.map((device, index) => (
                                            <li key={index}>
                                                <button
                                                    disabled={isLive}
                                                    type="button"
                                                    className={selectedMic.current === device.deviceId ? 'active' : ''}
                                                    onClick={() => handleChangeMicrofone(device.deviceId)}
                                                >
                                                    {device?.label}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </DeviceList>
                        )}
                        {advancedAudioConstraints && activeTab === 'advanced' && (
                            <DeviceList className="device-list">
                                <DeviceTabType
                                    tabItems={devicesAudioTabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                />
                                <ul>
                                    {
                                        advancedAudioConstraints?.map((option) => (
                                            <li
                                                key={option.key}
                                            >
                                                <p>
                                                    {option?.description}
                                                </p>
                                                {
                                                    supportedConstraints[option.key]
                                                        ? (
                                                            <Switch
                                                                key={option.key}
                                                                size="md"
                                                                onChange={event => changeAudionConstraints(option.key, event.currentTarget.checked)}
                                                                checked={audioConstraintsState[option.key].exact || false}
                                                                onLabel={t(languageConstants.CONSTRAINT_ON)}
                                                                offLabel={t(languageConstants.CONSTRAINT_OFF)}
                                                            />
                                                        )
                                                        : (
                                                            <span>{t(languageConstants.UNSUPPORTED)}</span>
                                                        )
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </DeviceList>
                        )}

                        <button
                            type="button"
                            disabled={microfonePermissionDenied}
                            className={showMicDevices ? 'list-devices up' : 'list-devices'}
                            onClick={handleShowMicDevices}
                        >

                            <img className="show-device-arrow" src={producerDownIcon} alt="show device" />
                        </button>

                        <Device onClick={handleMuteMicrofone} disabled={isLive}>
                            {microfonePermissionDenied || isMicMute ? (
                                <img src={producerMicMuteIcon} alt="Microfone" />
                            ) : (
                                <img src={producerMicIcon} alt="Microfone" />
                            )}
                        </Device>
                    </div>
                </OutsideClickHandler>

                <OutsideClickHandler onOutsideClick={() => setShowCamDevices(false)}>
                    <div className="device-wrapper">
                        {showCamDevices && (
                            <DeviceList className="device-list">
                                <DeviceTabType tabItems={devicesVideoTabs} activeTab={devicesVideoTabs[0].key} />
                                <ul>
                                    {
                                        videoInputs?.map((device, index) => (
                                            <li key={index}>
                                                <button
                                                    disabled={isLive}
                                                    type="button"
                                                    className={selectedCam.current === device.deviceId ? 'active' : ''}
                                                    onClick={() => handleChangeCamera(device.deviceId)}
                                                >
                                                    {device?.label}
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </DeviceList>
                        )}

                        <button
                            type="button"
                            disabled={cameraPermissionDenied}
                            className={showCamDevices ? 'list-devices up' : 'list-devices'}
                            onClick={handleShowCamDevices}
                        >

                            <img className="show-device-arrow" src={producerDownIcon} alt="show device" />
                        </button>

                        <Device className={isCamMute ? 'mute-device' : ''} onClick={handleMuteCamera} disabled={isLive}>
                            {cameraPermissionDenied || isCamMute ? (
                                <img src={producerCamMuteIcon} alt="Camera" />
                            ) : (
                                <img src={producerCamIcon} alt="Camera" />
                            )}
                        </Device>
                    </div>
                </OutsideClickHandler>
            </div>
            {
                isLive && !startingLive && (
                    <EndTransmissionButton className="end-transmission" onClick={stopTransmissionQuestion}>
                        {t(languageConstants.END_LIVE)}
                    </EndTransmissionButton>
                )
            }
        </DevicesContainer>
    );
}
