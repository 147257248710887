import React from 'react';
import useAuth from '../../hooks/useAuth';
import Course from './course';
import MockCourse from './mockCourse';

const SwitchCourse = () => {
    const { onBoarding } = useAuth();

    if (onBoarding?.data && (!(onBoarding?.data?.onBoardingRoom) && !onBoarding?.skipped)) {
        return <MockCourse />;
    }
    return <Course />;
};

export default SwitchCourse;
