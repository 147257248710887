import styled from 'styled-components';

export const Container = styled.div`    
    position: absolute;
    top: calc(50% - ${({ height }) => height + 3}px);
    width: 100%;
    z-index: 1;

    &.hidden {
        visibility: hidden;
    }

    .controls {
        position: absolute;
        left: 0;
        top: calc(50%);
        right: 0;
        z-index: 50;
        width: 100%;
        height: min-content;
        display: flex ;
        justify-content: space-evenly;
        align-items: center;

        button {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
        }

        img {
            width: 100px;            
            filter: invert();
            min-width: 50px;
        }

        @media (max-width: 800px) {
            img {
                width: calc(0.4em + 1vw);         
            }
        }
    }

`;
