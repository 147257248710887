import styled from 'styled-components';

export const ShowAppButtonContainer = styled.div`
    margin: 1rem 0px;
    padding: .5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (orientation: landscape) {
        display: none;
    }
        
`;

export const ShowAppButtonContent = styled.div`
    display: flex;
    justify-content: center;
    background-color: #191829;
    padding: 2rem;
    border-radius: 10px;
    align-items: center;
    width: 90%;
    position: relative;
    text-align: center;

    h1 {
        font-size: 1rem;
        margin: 1rem 0;

    }
    
    .showapp-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        border-radius: 5px;
        background: linear-gradient(40deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        position: absolute;
        top: -20px;
        
        img {
            width: 50%;
            height: auto;
        }
    }

    button {
        width: 100%;
        padding: 15px 0;
        border: 0;
        border-radius: 8px;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;

        @media (max-width: 375px){
            font-size: 1rem;
        }
    }
    
    `;
