import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    color: white;
`;

export const Logo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem; 
    height: 8%;
`;

export const VoucherContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0; 
    text-align: center;
    height: 80%;
        
    button {
        
        font-size: 14px;
        padding: 8px;
    }
`;

export const Header = styled.div`
    width: 100%;
    margin: 5% 0 2%;

    h1 {
        font-size: 42px;
    }
    h3 {
        font-size: 20px;
        font-weight: 600;
    }

    @media(max-width: 500px) {
        margin-bottom: 30px;

        h1 {
            font-size: 32px;
        }
        h3 {
            font-size: 15px;
        }
    }
`;

export const InputContainer = styled.div`
    width: 40%;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;

    p {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
    }

    input {
        margin: 2% 5% 10% 0;
        width: 100%;
        background: #393737;
        border: 0;
        height: 40px;
        border-radius: 4px;
        padding: 0 15px;
        color:#fff;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;
        color: #ffffff;
        text-align: left;
        text-transform: uppercase;

        &:focus,
        &:active {
            box-shadow: 0px 1px 0px 0px #1f1f1f;
        }
    }

    .code {
      display: flex;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill:valid
    {
        background: #393737 !important;
        color: #fff !important;
        -webkit-box-shadow: 0 0 0 1000px #393737 inset !important;
        -webkit-text-fill-color: #fff !important;
    }

    button {
        width: 50%;
        margin: 0 auto;

        &:hover {
            background: transparent;
            border: 2px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
            color: #FFF;
        }
    }

    button[disabled] {
        background: gray;

        cursor: unset;

        &:hover {
            border: none;
        }
    }

    @media(max-width: 1080px) {
      width: 500px;

        button {
            width: 80%;
        }
    }
    
    @media(max-width: 500px) {
        width: 90%;
        
        input {
            font-size: 10px
        }

        button {
            width: 80%;
        } 
    }
`;