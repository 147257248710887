import React from 'react';

import faceIco from '../../../assets/face-white.svg';
import twitterIco from '../../../assets/twitter-white.svg';
import instagramIco from '../../../assets/instagram-white.svg';
import { Container, ImgContainer, SocialInput } from './styles';

const SocialMediaInput = ({
    autoComplete,
    value,
    onChange,
    placeholder,
    socialMedia,
}) => {
    const socialMediasIco = {
        facebook: faceIco,
        twitter: twitterIco,
        instagram: instagramIco,
    };

    return (
        <Container>
            <ImgContainer>
                <img src={socialMediasIco[socialMedia]} alt="ico" />
            </ImgContainer>
            <SocialInput
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={onChange}
                autoComplete={autoComplete}
            />
        </Container>
    );
};

export default SocialMediaInput;
