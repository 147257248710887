import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';
import api from '../services/api';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    notFound,
    ...rest
}) {
    const loggedIn = api.isLogged();
    const history = useHistory();
    const isProducerPage = history.location.pathname.split('/')[1];

    // const loggedIn = true;
    if (!loggedIn && isPrivate) {
        if (isProducerPage === 'producer') {
            const producerRedirectPath = history.location.pathname + history.location.search;
            sessionStorage.setItem('@kopaRedirect', producerRedirectPath);
        }
        return <Redirect to="/" />;
    }

    if (loggedIn && !isPrivate && !notFound) {
        return <Redirect to="/home" />;
    }

    const Layout = loggedIn ? DefaultLayout : AuthLayout;

    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouteWrapper.defaultProps = { isPrivate: false };
