import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-weight: 800;
    padding: 1rem;

    .voucher-code {
        display: flex;
        flex-direction: row;
        width: 100%;
        
        input {
            text-align: left;
            font-size:18px;
            font-weight:700;
        }
    }

    h1 {
        text-align: center;
    }

    div {
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        width: 85%;
        justify-content: center;
        align-items: center;

        h4 {
            align-self: baseline;
            margin: 0 15px;
        }

        input {
            margin: 5px 15px 20px;
            height: 40px;
            width: 100%;
            text-align: left;
            padding-left: 10px;
            text-transform: uppercase;
        }

        button {
            width: 200px;
            height: auto;
            margin: 0 auto;
            padding: 10px;
        
            background: linear-gradient(to left, #FF428D, #FFAE45);
            border: 2px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
            color: #fff;

            font-weight: bold;
            font-size: 16px;
    
            &:hover {
                background: transparent;
                border: 2px solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
                color: #FF4E86;
            }
        }
        
        button[disabled] {
            color: #FFF;

            border: none;

            background: gray;

            cursor: unset;
        }
    }
`;

export const ContainerConfirm = styled.div`
    display: 'flex';
    flex-direction: 'row';
    justify-content: center;
    text-align: center;
    width: 100%;
    font-weight: 800;

    h1 {
        font-size: 24px;
    }

    p {
        color: #8c8c8c;
        padding: 15px 30px;
        text-align: center;
    }

    span {
      color: #ff8c79;
      padding: 0 5px;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
    }

    .confirm {
      border: none;
      color: #FFF;
      width: 40%;
      height: 30px;
      font-weight: 700;
      background-image: linear-gradient(to left, #ffae45, #ff428d 80%);
    }

    .cancel {
      width: 40%;
      height: 30px;
      color: #ff4989;
      font-weight: 700;
      background-color: transparent !important;
      border-image-slice: 1;
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to left, #ff4b88, #ffa44b 0%);
    }
`;

export const ContainerSuccess = styled.div`
    display: 'flex';
    flex-direction: 'row';
    justify-content: center;
    width: 100%;
    font-weight: 800;

    h1 {
        text-align: center;
    }

    div {
        margin: 20px;
        color: #8c8c8c;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h4 {
            align-self: baseline;
        }

        p {
          margin: 10px 0;
          text-align: center;
        }

        button {
            border: none;
            color: #FFF;
            width: 50%;
            height: 30px;
            background-image: linear-gradient(to left, #ffae45, #ff428d 80%);
        }
    }

    img {
      width: 80px;
      margin: 10px;
    }
`;
