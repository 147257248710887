import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AuthInput from '../../components/AuthInput';
import KopaLogo from '../../assets/kopa-logo.svg';
import { newPasswordRequest } from '../../store/modules/recoverPassword/actions';
import { Container, Form, ErrorMessage, ErrorContaineir, NewPassInstructionsArea } from './styles';
import attention from '../../assets/alert.svg';

export default function NewPassword({ match: { params } }) {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const loading = useSelector(state => state.recoverPassword.loading);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const token = params.t;
    const { t } = useTranslation();

    function handleNewPassword() {
        if (newPassword.length < 8) {
            setError(t('RECOVERPASSWORD', { context: 'MINLENGTH' }));
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setError(t('RECOVERPASSWORD', { context: 'NOMATCH' }));
            return;
        }
        dispatch(newPasswordRequest(newPassword, token));
    }

    function keyHandler(evt) {
        if (evt.keyCode === 13 && !loading) {
            handleNewPassword();
        }
    }

    return (
        <Container>
            <img
                className="logo"
                src={KopaLogo}
                alt="KopaLogo"
            />
            <h1>{t('RECOVERPASSWORD', { context: 'REDEFINE' })}</h1>
            <Form>
                <NewPassInstructionsArea>
                    <p>{t('TYPE_NEW_PASSWORD')}</p>
                </NewPassInstructionsArea>
                <div>
                    <span>{t('RECOVERPASSWORD', { context: 'NEWPASSWORD' })}</span>
                </div>
                <AuthInput
                    passwordIcon
                    type="password"
                    autoComplete={window.chrome ? 'no' : 'off'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyUp={keyHandler}
                />
                <div>
                    <span>{t('RECOVERPASSWORD', { context: 'NEWPASSWORDREPEAT' })}</span>
                </div>
                <AuthInput
                    passwordIcon
                    type="password"
                    autoComplete={window.chrome ? 'no' : 'off'}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    onKeyUp={keyHandler}
                />
                <button
                    disabled={loading}
                    type="submit"
                    onClick={handleNewPassword}
                >{loading ? `${t('LOADING')}...` : t('SEND')}
                </button>
            </Form>
            {error && (
            <ErrorContaineir>
                <img src={attention} alt="att" />
                <ErrorMessage>{error}</ErrorMessage>
            </ErrorContaineir>
            )}
        </Container>

    );
}
