import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import axios from 'axios';
import AvatarEditor from 'react-avatar-editor';

import { MdError } from 'react-icons/md';
import api from '../../../services/api';
import useAuth from '../../../hooks/useAuth';
import FileList from '../../FileList';
import Modal from '../../Modal';
import photo from '../../../assets/photo-camera.svg';
import deleteWhite from '../../../assets/delete-white.svg';

import {
    ButtonsArea,
    CropContainer,
    ErrorContainer,
    ImageBackground,
    ImageProfile,
    ImageProfileContainer,
    ProfileDataInfoArea,
    ProfileDataInfoAreaContainer,
    ProfileInfo,
    ProfileModalContainer,
    ProfileName,
    SocialMediaArea,
    SocialMediaTextHead,
    ToolButton,
    WelcomeText,
} from './styles';
import SocialMediaInput from '../SocialMediaInput';
import { phoneMask } from '../../../utils/functions';
import { editProfileRequest } from '../../../store/modules/auth/actions';
import Notify from '../../../utils/notification';

const ProfileModal = ({ confirmBlock, setConfirmBlock }) => {
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [facebookAccount, setFacebookAccount] = useState('');
    const [instagramAccount, setInstagramAccount] = useState('');
    const [twitterAccount, setTwitterAccount] = useState('');
    const [thumbnailURL, setThumbnailURL] = useState('');

    // UPLOADING IMAGE
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [isUploading, setIsUploading] = useState([]);
    const [uploadedMultipleFiles, setUploadedMultipleFiles] = useState([]);

    // CROP
    const [upImg, setUpImg] = useState(null);
    const inputRef = useRef(null);
    const setEditorRef = useRef(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { user, loading } = useAuth();

    const handleCloseModal = () => {
        setUpImg(null);
        setConfirmBlock(false);
    };

    useEffect(() => {
        setUserName(user?.name?.split(' ')[0]);
        setFacebookAccount(user?.social_medias?.facebook);
        setTwitterAccount(user?.social_medias?.twitter);
        setInstagramAccount(user?.social_medias?.instagram);
        setUserPhone(user?.phone);
        setUserEmail(user?.userId);
        setThumbnailURL(user?.thumbnail_url || '');
    }, [user, confirmBlock]);

    const updateFiles = useCallback(uploadingFile => {
        setUploadedMultipleFiles(oldFiles => (oldFiles.map(file => (file.id === uploadingFile.id ? { ...file, ...uploadingFile } : file))));
        // eslint-disable-next-line
    }, [uploadedMultipleFiles]);

    function updateFileProgress(data, progress, isMultipleUpload = false) {
        const uploadingFile = data;
        uploadingFile.progress = progress.progress;
        if (isMultipleUpload) {
            updateFiles(uploadingFile);
        } else {
            setUploadedFiles(uploadingFile);
        }
    }

    function updateFileUrl(data, url, isMultipleUpload = false) {
        const uploadingFile = data;
        uploadingFile.url = url;
        uploadingFile.uploaded = true;
        if (isMultipleUpload) {
            updateFiles(uploadingFile);
        } else {
            setThumbnailURL(url);
            setUploadedFiles(uploadingFile);
        }
    }

    const processUpload = async (uploadedFile, isMultipleUpload = false) => {
        const data = new FormData();
        const baseUrl = `/config/thumbnail/upload/${uploadedFile.name}`;
        setIsUploading([...isUploading, uploadedFile.name]);

        try {
            const response = await api._api.post(baseUrl);
            const { fields } = response.data.uploadObject;
            Object.keys(fields).forEach(key => {
                data.append(key, fields[key]);
            });
            data.append('file', uploadedFile.file);

            await axios.post(`${response.data.uploadObject.url}`, data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: e => {
                        const progress = Number(Math.round((e.loaded * 100) / e.total));
                        updateFileProgress(uploadedFile, { progress }, isMultipleUpload);
                    },
                });

            setIsUploading(prev => prev.filter(item => item !== uploadedFile.name));
            const urlFile = isMultipleUpload ? response.data.attachmentUrl : response.data.imageUrl;
            updateFileUrl(uploadedFile, urlFile, isMultipleUpload);
        } catch (err) {
            const error = uploadedFile;
            error.error = true;
            setIsUploading(prev => prev.filter(item => item !== uploadedFile.name));
            if (isMultipleUpload) {
                setUploadedMultipleFiles([error]);
            } else {
                setThumbnailURL('');
                setUploadedFiles([error]);
            }
        }
    };

    const handleUpload = ([file]) => {
        setThumbnailURL('');

        const image = new Image();
        image.addEventListener('load', () => {
            if (image.width > 300 || image.height > 300) {
                Notify.warn('Tamanho máximo da imagem: 300x300');
            } else {
                const newUploadedFiles = {
                    file,
                    id: uniqueId(),
                    name: file.name,
                    readableSize: filesize(file.size),
                    preview: URL.createObjectURL(file),
                    progress: 0,
                    uploaded: false,
                    error: false,
                    url: null,
                };
                setUploadedFiles(newUploadedFiles);
                processUpload(newUploadedFiles);
            }
        });
        image.src = URL.createObjectURL(file);
    };

    useEffect(() => URL.revokeObjectURL(uploadedFiles.preview)
        // eslint-disable-next-line
        , [])

    const handleUpdateProfile = () => {
        dispatch(editProfileRequest({
            social_medias: {
                instagram: instagramAccount,
                facebook: facebookAccount,
                twitter: twitterAccount,
            },
            thumbnail_url: thumbnailURL,
        }));
    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setUpImg(null);
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (reader.result && !reader.result.match(/image/)) {
                    Notify.error(t('INVALID_IMG_TYPE'));
                    return;
                }
                setUpImg(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleEditCrop = () => {
        inputRef.current.click();
    };

    const onCroppedEnd = () => {
        let canvasScaled;

        if (setEditorRef.current) {
            canvasScaled = setEditorRef.current.getImageScaledToCanvas();
        } else {
            return;
        }

        setUpImg(null);
        canvasScaled.toBlob((file) => {
            handleUpload([new File([file], `${uniqueId()}.png`, file)]);
        }, 'image/png');
    };

    return (
        <>
            <Modal
                isOpen={confirmBlock}
                width={600}
                handleModal={handleCloseModal}
                title={t('MY_PROFILE')}
                bodyWidth={100}
            >
                <ProfileModalContainer cropping={upImg}>
                    <ImageProfileContainer>
                        <ButtonsArea>
                            <ToolButton onClick={() => handleEditCrop()}>
                                <img src={photo} alt="edit" />
                            </ToolButton>
                            <ToolButton onClick={() => setThumbnailURL('')}>
                                <img src={deleteWhite} alt="delete" />
                            </ToolButton>
                        </ButtonsArea>
                        <ImageBackground />
                        <ImageProfile>
                            <FileList uploadedFile={uploadedFiles} url={thumbnailURL} />
                            <div className="upload-profile-thumbnail">
                                <input
                                    ref={inputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={onSelectFile}
                                    id="image-crop"
                                />
                            </div>
                        </ImageProfile>
                    </ImageProfileContainer>

                    {uploadedFiles?.error && (
                        <ErrorContainer>
                            <MdError size={24} color="#e57878" />
                            <p>
                                {t('ERROR_IMAGE_UPLOAD')}
                            </p>
                        </ErrorContainer>
                    )}

                    <ProfileDataInfoAreaContainer>
                        <ProfileDataInfoArea>
                            <WelcomeText>
                                {t('WELCOME_TO_KOPA')}
                            </WelcomeText>
                            <ProfileName>
                                {userName?.toUpperCase()}
                            </ProfileName>
                            <ProfileInfo>
                                {userEmail} {userPhone && `| ${phoneMask(userPhone)}`}
                            </ProfileInfo>

                        </ProfileDataInfoArea>
                    </ProfileDataInfoAreaContainer>

                    <SocialMediaArea>
                        <SocialMediaTextHead>
                            {t('SOCIAL_MEDIA')}
                        </SocialMediaTextHead>
                        <SocialMediaInput
                            value={facebookAccount}
                            onChange={e => setFacebookAccount(e.target.value)}
                            socialMedia="facebook"
                            placeholder="Facebook"
                        />
                        <SocialMediaInput
                            value={instagramAccount}
                            onChange={e => setInstagramAccount(e.target.value)}
                            socialMedia="instagram"
                            placeholder="Instagram"
                        />
                        <SocialMediaInput
                            value={twitterAccount}
                            onChange={e => setTwitterAccount(e.target.value)}
                            socialMedia="twitter"
                            placeholder="Twitter"
                        />
                    </SocialMediaArea>

                    <button
                        disabled={isUploading?.length > 0}
                        type="button"
                        onClick={() => handleUpdateProfile()}
                    >
                        {loading
                            ? `${t('LOADINGCAPS')}...`
                            : t('SAVE')}
                    </button>

                </ProfileModalContainer>
                {upImg && (

                    <CropContainer>
                        <div className="crop-component">
                            <AvatarEditor
                                image={upImg}
                                width={250}
                                height={250}
                                scale={1}
                                rotate={0}
                                disableHiDPIScaling
                                ref={setEditorRef}
                            />
                            <button
                                type="submit"
                                onClick={() => onCroppedEnd()}
                                disabled={!upImg}
                            >
                                {t('CROP_IMAGE')}
                            </button>

                        </div>

                    </CropContainer>
                )}
            </Modal>
        </>
    );
};

export default React.memo(ProfileModal);
