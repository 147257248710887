/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';

import {
    Container,
    Description,
    RoomList,
    CenterContainer,
    MessageContainer,
    ContainerVideo,
} from './styles';
import CameraIcon from '../../assets/camera.svg';
import { getRooms } from '../../store/modules/rooms/actions';
import { getWatchedVideosProgress as getVideosProgress } from '../../store/modules/roomsProgress/actions';
import { TIMERS } from '../../utils/constants';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import RoomSlider from '../../components/Slider';
import VideoCardSkeleton from '../../components/VideoCardSkeleton';
import AppAdvertisingModal from '../../components/AppAdvertisingModal';
import useRoomsProgress from '../../hooks/useRoomsProgress';

function selector({ room }) {
    return { rooms: room.rooms };
}

const Home = () => {
    const [isVideoInLive, setIsVideoInLive] = useState(false);
    const [liveVideos, setLiveVideos] = useState([]);

    const loading = useSelector(state => state.room.loading);
    const dispatch = useDispatch();
    const { rooms } = useSelector(selector, shallowEqual);
    const { t } = useTranslation();
    const { setItems } = useBreadcrumbs();
    const { videos: videosProgress } = useRoomsProgress();

    let sortedVideos;

    function roomsSort(rooms) {
        rooms.sort((roomA, roomB) => {
            if (roomA.live === roomB.live) {
                return (roomA.name < roomB.name) ? -1 : (roomA.name > roomB.name) ? 1 : 0;
            }
            return (roomA.live > roomB.live) ? -1 : 1;
        });
        return rooms;
    }

    const getLiveRooms = useCallback(() => {
        if (rooms === '') return;
        const lives = [];
        const liveRooms = rooms?.filter(room => room.rooms.length > 0);
        liveRooms.map(item => item.rooms.map(video => video.live && lives.push(video)));
        if (lives?.length > 0) {
            setIsVideoInLive(true);
        } else {
            setIsVideoInLive(false);
        }
        setLiveVideos(lives);
    }, [rooms]);

    useEffect(() => {
        dispatch(getRooms());
    }, [dispatch]);

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(getRooms());
        }, TIMERS.REFRESH_HOME);

        return () => clearTimeout(timer);
    }, [dispatch]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: '/home', label: t('WELCOME_TO_KOPA') },
            { to: '/home', label: t('HOMEPAGE') },
        ];
        setItems(breadcrumbsItems);
    }, [setItems, t]);

    useEffect(() => {
        getLiveRooms();
    }, [rooms, getLiveRooms]);

    useEffect(() => {
        if (rooms.length) {
            dispatch(getVideosProgress(rooms));
        }
    }, [dispatch, rooms]);

    return (
        <>
            {rooms === '' ? <VideoCardSkeleton /> : (Array.isArray(rooms) && rooms.length > 0)
                ? (
                    <Container>
                        {loading && <Loading />}

                        <ContainerVideo>
                            {isVideoInLive && (
                                <RoomList>
                                    <div className="channel-header">
                                        <h1 className="title-category">{t('HOME', { context: 'FEATURED_LIVES' })}</h1>
                                    </div>
                                    <RoomSlider videos={liveVideos} />
                                </RoomList>
                            )}

                            {rooms.map((mocRoom) => {
                                sortedVideos = roomsSort(mocRoom.rooms);
                                return (
                                    mocRoom.rooms.length > 0
                                    && (
                                        <RoomList key={mocRoom.id} data-tour="tour__room">
                                            <div className="channel-header">
                                                <h1 className="title-category">{mocRoom.name}</h1>
                                            </div>
                                            <RoomSlider videos={sortedVideos} progress={videosProgress} />
                                        </RoomList>
                                    )
                                );
                            })}
                        </ContainerVideo>
                    </Container>
                )
                : (
                    <CenterContainer>
                        <img src={CameraIcon} alt="camera" />
                        <MessageContainer>
                            <h1>{t('HOME', { context: 'WELCOME' })}</h1>
                            <Description>{t('HOME', { context: 'NOLIVE' })}</Description>
                        </MessageContainer>
                    </CenterContainer>
                )}

            <AppAdvertisingModal />
        </>
    );
};

export default Home;
