import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Image, Ops, Message, ExitButton } from './styles';

import CautionIcon from '../../assets/caution.svg';
import { clearAll } from '../../store/modules/auth/actions';
import { forcedLogoutExit } from '../../components/Header';

const ForcedLogout = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(clearAll());
    }, [dispatch]);

    return (
        <Container>
            <Image src={CautionIcon} alt="caution" />
            <Ops>{t('ops')}</Ops>
            <Message>{t('ALREADY_LOGED_IN')}</Message>
            <ExitButton onClick={forcedLogoutExit}>{t('PLEASECLOSE')}</ExitButton>
        </Container>
    );
};

export default ForcedLogout;
