import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2rem;
  position: relative;

  @media screen and (max-width: 375px){
      .logo {
        width: 280px;
        max-width: 100%;
        display: block;
      }
  }

`;

export const InstructionsArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    
    h1 {
      color: #ffffff;
      font-weight: bold;
      text-align: center;
    }

    p {
      color: #ffffff;
      margin-top: 2rem;
      font-size: 1.3rem;
      text-align: center;
    }
    
    strong {
      color: #ff9d5a;
      word-break: break-all;
      font-size: 1.3rem;
      text-align: center;
    }
`;

export const Form = styled.div`
  max-width: 37.60rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
      height: 22px;
      margin-bottom: 8px;
      display: block;

      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #ffffff;
  } 

  input {
      background: #393737;
      border: 0;
      height: 40px;
      border-radius: 4px;
      padding: 0 15px;
      margin: 0 0 30px;
      color:#fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      color: #ffffff;      
    }

    
    .codeInputLabel {
        margin: 1rem 0;
        margin-bottom: .3rem;
    }
    #submitButton {
        opacity: 1;
    }

  #submitButtonDisabled {
      opacity: .5;
      cursor: default;

      &:hover{
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
      }
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: transparent !important;
  }

  @media(max-width: 425px){
      width: 100%;
  } 

`;

export const ResendArea = styled.div`
    width: 100%;
    margin: 2rem 0;

    p {
        color: #fff;
        font-size: 1.3rem;
        text-align: center;
    }

    strong {
        color: #ff9d5a;
        cursor: pointer;

    }
`;

export const InputArea = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const ErrorContaineir = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 18px 0;
  display: flex;
  align-self: center;
    span {
        margin: 0;
        text-align: center;
        display: flex;
        align-items: center;
        color: #ff6363;

    }
    img{
    width:25px;
    height:25px;
    margin-right:10px;
    }

    @media(max-width: 1440px){
        span {   
            margin: 0;
            font-size: 12px;
        }
    }
`;
