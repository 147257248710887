import actions from '../../../utils/actions';

export function getRooms() {
    return { type: 'ROOMS_REQUEST' };
}

export function getUserRooms() {
    return { type: 'USER_ROOMS_REQUEST' };
}

export function roomEditClose() {
    return { type: actions.ROOM_FAIL };
}

export function fetchRoom(roomID, getPriority = false) {
    return {
        type: 'ROOM_REQUEST',
        payload: {
            roomID,
            getPriority,
        },
    };
}

export function fetchEditRoom(roomID) {
    return {
        type: actions.ROOM_EDIT_SELECT,
        payload: roomID,
    };
}

export function setRooms(rooms) {
    return {
        type: 'ROOMS_SUCCESS',
        payload: rooms,
    };
}

export function setRoom(room) {
    return {
        type: 'ROOM_SUCCESS',
        payload: room,
    };
}

export function setEditRoom(room) {
    return {
        type: actions.ROOM_EDIT_SELECT_SUCCESS,
        payload: room,
    };
}

export function createRoom(room) {
    return {
        type: 'ROOM_CREATE',
        payload: room,
    };
}

export function editRoom(room, id) {
    return {
        type: actions.ROOM_EDIT,
        payload: { room, id },
    };
}

export function getRoomPriorityVideoUrl(roomID) {
    return {
        type: actions.ROOM_VIDEO_PRIORITY_REQUEST,
        payload: roomID,
    };
}

export function setRoomPriorityVideoUrlSuccess(video_url, socketPath, technology) {
    return {
        type: actions.ROOM_VIDEO_PRIORITY_REQUEST_SUCCESS,
        payload: { videoUrl: video_url, socketPath, technology, video_availability: 'any' },
    };
}

export function setRoomPriorityVideoUrlFail() {
    return { type: actions.ROOM_VIDEO_PRIORITY_REQUEST_FAIL };
}

export function removeRoomPriorityVideoUrl() {
    return { type: actions.ROOM_VIDEO_PRIORITY_REQUEST_REMOVE_URL };
}

export function goLiveRoom(roomID) {
    return {
        type: actions.ROOM_GOLIVE,
        payload: roomID,
    };
}

export function endLiveRoom(roomID) {
    return {
        type: actions.ROOM_ENDLIVE,
        payload: roomID,
    };
}

export function getRoomEngagement(roomID) {
    return {
        type: actions.ROOM_ENGAGEMENT,
        payload: roomID,
    };
}

export function getRoomEventLog(roomID) {
    return {
        type: actions.ROOM_EVENTLOG,
        payload: roomID,
    };
}

export function getRoomGraph(roomID, startDate = null, endDate = null) {
    return {
        type: actions.ROOM_GRAPH,
        payload: { roomID, startDate, endDate },
    };
}

export function getRoomGeolocalization(roomID, startDate = null, endDate = null) {
    return {
        type: actions.ROOM_GEOLOCALIZATION,
        payload: { roomID, startDate, endDate },
    };
}

export function setLiveStatus(roomID) {
    return {
        type: actions.ROOM_SET_STATUS_ROOM,
        payload: roomID,
    };
}

export function roomIsLive(roomID) {
    return {
        type: actions.ROOM_IS_LIVE,
        payload: roomID,
    };
}

export function clearSelected() {
    return { type: 'ROOM_CLEAR_SELECTED' };
}

export function clearEditSelected() {
    return { type: actions.ROOM_CLEAR_EDIT_SELECTED };
}

export function exitRoom() {
    return { type: 'ROOM_EXIT' };
}

export function sendMessage(roomID, message, type) {
    return {
        type: 'ROOM_SEND_MESSAGE',
        payload: {
            roomID,
            message,
            type,
        },
    };
}

export function replyMessage(roomID, text, originalMessageId) {
    return {
        type: actions.ROOM_REPLY_MESSAGE,
        payload: {
            roomID,
            text,
            originalMessageId,
        },
    };
}

export function enterChat(roomID) {
    return {
        type: 'ROOM_ENTER_CHAT',
        payload: roomID,
    };
}

export function exitChat(roomID) {
    return {
        type: 'ROOM_EXIT_CHAT',
        payload: roomID,
    };
}

export function setMessages(roomMessages, questionMessages,
    instructorRoomMessages, teamMessages) {
    return {
        type: 'ROOM_SET_MESSAGES',
        payload: {
            roomMessages,
            questionMessages,
            instructorRoomMessages,
            teamMessages,
        },
    };
}

export function pinMessage(roomID, messageID) {
    return {
        type: actions.ROOM_PIN_MESSAGE,
        payload: {
            roomID,
            messageID,
        },
    };
}

export function unpinMessage(roomID, messageID) {
    return {
        type: actions.ROOM_UNPIN_MESSAGE,
        payload: {
            roomID,
            messageID,
        },
    };
}

export function changeChatEnabled(roomID, chatEnabled) {
    return {
        type: actions.ROOM_SET_CHAT_ENABLED,
        payload: {
            roomID,
            chatEnabled,
        },
    };
}

export function setChatTabs(chatTabs) {
    return {
        type: 'ROOM_SET_CHAT_TABS',
        payload: chatTabs,
    };
}

export const changeTeamChat = (room) => ({
    type: actions.CHANGE_TEAM_CHAT,
    payload: { ...room },
});

export const setEnableTeamChat = (isEnabledTeamChat) => ({
    type: actions.ROOM_CHAT_SET_ENABLE_TEAM,
    payload: { ...isEnabledTeamChat },
});

export const setTeamInformation = (teamInformation) => ({
    type: actions.ROOM_CHAT_SET_TEAM_INFORMATION,
    payload: { teamInformation },
});

export const setChatEnableRoom = (room) => ({
    type: actions.ROOM_CHAT_ASYNC_SET_CHAT_ENABLE_ROOM,
    payload: { room },
});

export const setOfferEnableRoom = (saleEnabled) => ({
    type: actions.ROOM_SHOW_QRCODE,
    payload: saleEnabled,
});

export const acceptTermOfUsage = (courseId, userId, roomId) => ({
    type: actions.ACCEPT_TERMS_OF_USAGE,
    payload: { courseId, userId, roomId },
});

export const setQuiz = (payload) => ({
    type: actions.SET_QUIZ,
    payload,
});

export const getQuizInfo = (quizId) => ({
    type: actions.GET_QUIZ,
    payload: quizId,
});

export const answerQuiz = (questionId, answer) => ({
    type: actions.ANSWER_QUIZ,
    payload: { questionId, answer },
});

export const finishQuiz = () => ({ type: actions.FINISH_QUIZ });

export const blockUser = (roomId, userId) => ({
    type: actions.CHAT_BLOCK_USER,
    payload: { roomId, userId },
});

export const updateLockedUserMessages = (userId) => ({
    type: actions.UPDATED_BLOCKED_USER_MESSAGES,
    payload: { userId },
});

export const changeRoomLiveStatus = () => ({ type: actions.ROOM_SET_TO_LIVE });
