import React from 'react';
import { Container } from './styles';

export default function QuizContainer({ quizComponent }) {
    return (
        <Container>
            { quizComponent && quizComponent}
        </Container>
    );
}
