import styled from 'styled-components';
import playIcon from '../../assets/play.svg';

export const SliderButton = styled.button`
    border: none;
    outline: none;
    border-radius: 5px;
    background: none;
    width: 250px;
    overflow: hidden;
    position: relative;
    opacity: 0.6;  
    transition: all .3s ease-in-out;  

    img {
        width: 100%;        
        object-fit: contain;
        object-position: top;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;  
    }

    &::before {            
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(${playIcon});
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(-15px);
        opacity: 0;
        transition: all 0.3s ease-in-out;  
    }

    &:hover {
        box-shadow: 7px 6px 39px -18px #000000;
        opacity: 1;  

        &::before {
            opacity: 1;
        }
    }
`;

export const VideoCard = styled.div`
    background-color: #212032;    
    .progress-wrap {
        background-color: #11101d;
        width: 100%;
        height: 4px;
        border-radius: 1px;
    }
`;

export const Footer = styled.div`
    position: relative;
    z-index: 8 !important;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;  
    align-items: flex-start;
    background-color: #212032;
    color: #fff;
    text-transform: uppercase;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: center;

    h5 {
        font-size: clamp(14px, 0.3em + 1vw, 1.2em);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    p {
        font-size: 12px;
        font-weight: bold;
    }
`;

export const LiveTag = styled.span`
    position: absolute;
    right: 10px;
    top: 10px;    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #ff5b7d;
    border-radius: 3px;

    font-size: 14px;
    font-weight: 600;
    color: white;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.29px;
    text-transform: uppercase;

    &::before {
        content: '';
        height: 10px;
        width: 10px;
        background-color: #fff;
        border-radius: 50%;
        margin-right: 5px;
    }
    
`;

export const VideoProgress = styled.div`
    height: 4px;
    width: ${props => props.progress || 0}%;
    background-image: linear-gradient(to right, #ff6478 0%, #ff965f 100%);
    z-index: 1;  
    border-radius: 1px; 
    float : left ;
    transition: all ease-in-out 1s;
`;
