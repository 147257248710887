import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import KopaLogo from '../../assets/kopa-logo.svg';
import { Container, MessageCointaine, LinkButton } from './styles';

export default function CheckEmail() {
    const { t } = useTranslation();
    const { voucherId } = useParams();

    return (
        <Container>
            <img
                className="logo"
                src={KopaLogo}
                alt="KopaLogo"
            />
            <MessageCointaine>
                <h1>{t('RECOVERPASSWORD', { context: 'VERIFY' })}</h1>
                <h3>{t('RECOVERPASSWORD', { context: 'VERIFYDESC' })}</h3>
                <LinkButton to={voucherId ? `/voucher/${voucherId}/login` : '/'}>
                    <button
                        type="submit"
                    > {t('COMEBACK')}
                    </button>
                </LinkButton>
            </MessageCointaine>
        </Container>

    );
}
