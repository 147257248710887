import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;   

    .MuiCircularProgress-colorPrimary {
        color: #ff617a;
    }

`;
