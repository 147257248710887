import React from 'react';
import { useTranslation } from 'react-i18next';
import langs from '../../../../assets/i18n/constants';
import { NoRankingContainer, NoRankingText, NoRankingTitle } from './styles';
import ranking from '../../../../assets/game-ranking.svg';

const NoRanking = () => {
    const { t } = useTranslation();
    return (
        <NoRankingContainer>
            <img src={ranking} alt="ranking" />
            <NoRankingTitle>OPS</NoRankingTitle>
            <NoRankingText>{t(langs.NO_GAMIFICATION_RANKING)}</NoRankingText>
        </NoRankingContainer>
    );
};

export default NoRanking;
