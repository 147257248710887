import { all, put, takeLatest } from 'redux-saga/effects';
import actions from '../../../utils/actions';
import {
    getPersonalRankingSuccess, getPersonalRankingFailure,
    getGamificationCourseStatusSuccess, getGamificationCourseStatusFailure,
} from './actions';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import { translate } from '../../../utils/functions';

function* getRankingRequest({ payload: courseId }) {
    try {
        const ranking = yield api.getPersonalRanking(courseId);
        yield put(getPersonalRankingSuccess(ranking));
    } catch (error) {
        if (error.response) {
            defaultErrorHandler(error, { default: error.response.data.error_description || 'Falha ao obter ranking!' });
            yield put(getPersonalRankingFailure());
        } else {
            defaultErrorHandler(error, { default: translate('FAILED_GET_RANKING') });
        }
    }
}

function* getGamificationCourseStatus({ payload: courseId }) {
    try {
        const { hasGamification } = yield api.getGamificationCourseStatus(courseId);
        yield put(getGamificationCourseStatusSuccess(hasGamification));
    } catch (error) {
        if (error.response) {
            defaultErrorHandler(error, { default: error.response.data.error_description || 'Falha ao obter status da gamificação do canal!' });
            yield put(getGamificationCourseStatusFailure());
        } else {
            defaultErrorHandler(error, 'Falha ao obter status da gamificação do canal!');
        }
    }
}

export default all([
    takeLatest(actions.PERSONAL_RANKING_REQUEST, getRankingRequest),
    takeLatest(actions.GAMIFICATION_COURSE_STATUS_REQUEST, getGamificationCourseStatus),
]);
