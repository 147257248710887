import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display:flex; 
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  background-color: #212032;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  
  @media(min-width: 800px) {
    width: 30%;
    height: 100%;
  }

  @media(max-width: 800px) and (min-width: 650px) {
    width: 95%;
    margin: 0 auto;
  }

  @media(max-width: 650px) and (orientation: landscape) {
    height: 100%;
  }  
`;
