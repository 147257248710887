import React from 'react';
import { connect } from 'react-redux';

import ChatSocket from '../../services/ChatSocket';
import {
    setMessages,
    changeTeamChat,
    setTeamInformation,
    setChatEnableRoom,
    setOfferEnableRoom,
    fetchRoom,
    setQuiz,
    removeRoomPriorityVideoUrl,
    changeRoomLiveStatus,
    clearSelected,
} from '../../store/modules/rooms/actions';
import history from '../../services/history';
import actions from '../../utils/actions';

// configs
const MESSAGE_LIMIT_PER_GENERAL_ROOM = 100;
// const MESSAGE_LIMIT_PER_QUESTIONS_ROOM = 100; Não possui limite de msgs
const MESSAGE_LIMIT_PER_INSTRUCTORS_ROOM = 100;
const MESSAGE_LIMIT_PER_TEAM_ROOM = 100;

const userColorIndex = { count: 0 };

const setUserPermissions = (userPermissions) => ({ type: actions.ROOM_SET_CHAT_USER_TYPE, payload: { chatUserType: userPermissions } });
class ChatEvents extends React.Component {
    constructor(props) {
        super(props);
        this.message = ChatSocket.addMessageListener(this.messageHandler);
        this.disconnect = ChatSocket.addDisconnectListener(this.disconnectHandler);
        this.close = ChatSocket.addCloseListener(this.closeConnectionHandler);
        const { setUserPermissions } = this.props;
        ChatSocket.setUserPermissionsHandler = setUserPermissions;
    }

    commandHandler = (msg) => {
        switch (msg.action) {
            case 'reload':
                window.location.reload(true);
                break;

            default:
                break;
        }
    }

    messageHandler = (message) => {
        const {
            changeTeamChat,
            setTeamInformation,
            setChatEnableRoom,
            setOfferEnableRoom,
            fetchRoom,
            clearSelected,
            setQuiz,
            changeRoomLiveStatus,
        } = this.props;

        if (Object.prototype.hasOwnProperty.call(message, 'eventType')) {
            switch (message.eventType) {
                case 'teamEnable':
                    changeTeamChat(message.payload);
                    break;
                case 'teamChange':
                    setTeamInformation(message.payload);
                    break;
                case 'chatEnable':
                    setChatEnableRoom(message.payload);
                    break;
                case 'offerEnable':
                    setOfferEnableRoom(message.payload.enabled);
                    break;
                case 'pin_message':
                case 'unpin_message':
                case 'att_offer':
                    fetchRoom(message.payload.roomId);
                    break;

                case 'release_question':
                    setQuiz(message.payload);
                    break;

                case 'release_question_with_groups':
                    setQuiz(message.payload);
                    break;

                case 'update_released_question':
                    setQuiz(message.payload);
                    break;

                case 'update_released_question_with_groups':
                    setQuiz(message.payload);
                    break;
                case 'room_live_status':
                    changeRoomLiveStatus();
                    break;

                case 'reload_player':
                    clearSelected();
                    fetchRoom(message.payload.roomId);
                    break;

                default:
                    break;
            }
            return;
        }
        const {
            roomMessages,
            questionMessages,
            instructorRoomMessages,
            teamMessages,
            selectedRoom,
            setMessages,
            removeRoomPriorityVideoUrl,
        } = this.props;

        let initialRoomMessages = Object.assign([], roomMessages);
        let initialQuestionMessages = Object.assign([], questionMessages);
        let initialInstructorMessages = Object.assign([], instructorRoomMessages);
        let initialTeamMessages = Object.assign([], teamMessages);

        if (message.type === 'error') {
            this.errorMessageHandler(message);
            return;
        }

        if (message.type === 'command') {
            this.commandHandler(message);
            return;
        }

        if (message.type === 'closeVideo') {
            removeRoomPriorityVideoUrl();
        }

        if (message.type === 'unauthorized_by_limit') {
            console.log('UNAUTHORIZED_BY_LIMIT');
        }

        if (!selectedRoom) {
            return;
        }

        const { messageSender } = message;

        if (userColorIndex[messageSender] === undefined) {
            userColorIndex[messageSender] = userColorIndex.count;
            userColorIndex.count += 1;
        }

        if (ChatSocket.reconnection && initialRoomMessages.find(m => m.messageId === message.messageId)) {
            return;
        }

        const toInsert = { ...message, colorIndex: userColorIndex[messageSender] };

        switch (message.type) {
            case 'roomMessage':
                initialRoomMessages = [toInsert, ...initialRoomMessages];
                initialRoomMessages.sort((a, b) => (a.created_at > b.created_at));
                if (initialRoomMessages.length > MESSAGE_LIMIT_PER_GENERAL_ROOM) {
                    initialRoomMessages.pop();
                }
                break;

            case 'questionMessage':
                initialQuestionMessages = [toInsert, ...initialQuestionMessages];
                break;

            case 'instructorRoomMessage':
                initialInstructorMessages = [toInsert, ...initialInstructorMessages];
                if (initialInstructorMessages.length > MESSAGE_LIMIT_PER_INSTRUCTORS_ROOM) {
                    initialInstructorMessages.pop();
                }
                break;

            case 'teamMessage':
                initialTeamMessages = [toInsert, ...initialTeamMessages];
                if (initialTeamMessages.length > MESSAGE_LIMIT_PER_TEAM_ROOM) {
                    initialTeamMessages.pop();
                }
                break;

            default:
                break;
        }

        setMessages(
            initialRoomMessages, initialQuestionMessages,
            initialInstructorMessages, initialTeamMessages,
        );
    }

    errorMessageHandler = (message) => {
        switch (message.error_code) {
            case 'unauthorized':
                history.push('/forcedLogout');
                break;
            default:
                console.log('Not implemented for error code', message.error_code);
        }
    }

    disconnectHandler = () => {
        console.log('desconectado do socket, tentativa de reconexão será feita');
    }

    closeConnectionHandler = (fromLogout = false) => {
        console.log('Desistindo de conexão com socket');
        if (fromLogout) return;
        history.push(history.location.pathname === '/forcedLogout' ? '/forcedLogout' : '/networkFailed');
    }

    render() {
        return null;
    }
}

const mapStateToProps = ({ room }) => ({
    selectedRoom: room.selectedRoom || {},
    roomMessages: room.roomMessages || [],
    questionMessages: room.questionMessages || [],
    instructorRoomMessages: room.instructorRoomMessages || [],
    teamMessages: room.teamMessages || [],
});

export default connect(mapStateToProps,
    {
        setChatEnableRoom,
        setMessages,
        changeTeamChat,
        setTeamInformation,
        setOfferEnableRoom,
        fetchRoom,
        setQuiz,
        removeRoomPriorityVideoUrl,
        setUserPermissions,
        changeRoomLiveStatus,
        clearSelected,
    })(ChatEvents);
