const INITIAL_STATE = {
    error: false,
    loading: false,
    loggedIn: false,
    token: localStorage.getItem('KopaLive') || '',
    user: null,
    tokenForChange: '',
    onBoarding: {},
};

export default function login(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return { ...state, loading: true, error: null };
        case 'CHANGE_PASSWORD_TOKEN':
            return { ...state, tokenForChange: action.payload, loading: false };
        case 'LOGIN_SUCCESS':
            return {
                loading: false,
                error: false,
                loggedIn: true,
                token: action.payload.token,
                user: null,
            };
        case 'LOGIN_FAILED':
            return { ...state, loading: false, error: action.payload.error };
        case 'SET_USER':
            return { ...state, loading: false, user: action.payload.user };
        case 'EDIT_PROFILE_REQUEST':
            return { ...state, loading: true, error: false };
        case 'GET_USER_PROFILE':
            return { ...state, loading: true, error: false };
        case 'SET_ONBOARDING_STATUS':
            return { ...state, onBoarding: action.payload };
        case 'EDIT_PROFILE_SUCCESS':
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    social_medias: {
                        instagram: action.payload.social_medias.instagram,
                        facebook: action.payload.social_medias.facebook,
                        twitter: action.payload.social_medias.twitter,
                    },
                    thumbnail_url: action.payload.thumbnail_url,
                },
                error: false,
            };
        case 'GET_USER_ERROR':
        case 'EDIT_PROFILE_FAIL':
            return { ...state, loading: false, error: action.payload };
        case 'CLEAR_ALL':
        case 'LOGOUT':
            return { ...INITIAL_STATE };
        default:
            return state;
    }
}
