/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';


const Error404 = () => {
    const loggedIn = useSelector(state => state.login.loggedIn);
    const { t } = useTranslation();
    return (
        <Container>
            <section className="error-content">
                <h1> {t('ERROR404')} </h1>
                <p> {t('SORRYPROBLEM')}</p>
                <Link to={loggedIn ? '/home' : '/'}>
                    <button type="button"> {t('HOMEPAGE')} </button>
                </Link>
            </section>
        </Container>
    );
};

export default Error404;
