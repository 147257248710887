import { useSelector, shallowEqual } from 'react-redux';

function rankingSelector({ ranking }) {
    return {
        loading: ranking.loading,
        firstTen: ranking.firstTen,
        user: ranking.user,
        hasGamification: ranking.hasGamification,
        loadingGamificationStatus: ranking.loadingGamificationStatus,
    };
}

export default function useRanking() {
    const { loading, firstTen, user, hasGamification, loadingGamificationStatus } = useSelector(rankingSelector, shallowEqual);
    return { loading, firstTen, user, hasGamification, loadingGamificationStatus };
}
