import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2rem;

  h1{
    margin-top: 25px;
    width: 285px;
    height: 33px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    text-align: center;
    color: #ffffff;
  }

  button {
    padding: 15px 0;
    border: 0;
    border-radius: 8px;
    color:#fff;
    background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.21px;

    &:hover {
        background: linear-gradient(90deg,  rgba(255,66,141,0.8) 0%, rgba(255,174,70,0.8) 100%);
    }
  }

  @media screen and (max-width: 375px){
      .logo {
        width: 280px;
        max-width: 100%;
        display: block;
      }
  }
`;

export const MessageCointainer = styled.div`
    max-width: 37.60rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2rem;

    h3 {
        color: #fff;
        text-align: center;
        margin: 2rem;
    }

    button{
        width: 100%;
        margin-top: 25px;
    }

    img{
        width:150px;
        height:150px;
    }

    .alert-title {
        font-size: 48px;
        height: 100%;
    }

    .alert-subtitle {
        font-size: 30px;


    }
    
    @media screen and (max-width: 375px){
        .alert-subtitle {
          font-size: 20px;
          margin-right: 0;
          margin-left: 0;
        }
    }
  }

`;

export const LinkButton = styled(Link)`
  width:100%
`;
