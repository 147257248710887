import { useSelector, shallowEqual } from 'react-redux';

function authSelector({ login }) {
    return { user: login.user, loading: login.loading, onBoarding: login.onBoarding };
}

export default function useAuth() {
    const { user, loading, onBoarding } = useSelector(authSelector, shallowEqual);
    return { user, loading, onBoarding };
}
