import { all, takeLatest, put } from 'redux-saga/effects';
import {
    loginSuccess,
    loginFailed,
    clearAll,
    setUser,
    changePasswordToken,
    loginRequest,
    editProfileSuccess,
    editProfileFail,
    getUserError,
    setOnBoardingStatus,
} from './actions';
import { associateRegisteredVoucher } from '../vouchers/actions';
import history from '../../../services/history';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import ChatSocket from '../../../services/ChatSocket';
import { translate, parseJwt } from '../../../utils/functions';
import Notify from '../../../utils/notification';

function* login(action) {
    const { username, password, voucherId } = action.payload;

    try {
        const res = yield api.login(username, password);
        const accessToken = api.getAccessToken();
        localStorage.setItem('KopaLive', JSON.stringify({ accessToken }));
        const accessTokenDecoded = parseJwt(accessToken);
        if (accessTokenDecoded.force_pw_change === true) {
            yield put(changePasswordToken(accessTokenDecoded.token));
            history.push({
                pathname: `/firstAccess/${username}`,
                state: { firstAccess: 'firstAccess', token: accessTokenDecoded.token },
            });
            return;
        }
        const { session_token: sessionToken } = yield api.createSession();

        yield ChatSocket.connect(accessToken, sessionToken);
        const { data } = yield api.getUser();
        yield put(loginSuccess(api._token.accessToken));
        const appMerchValue = res.appMerch ?? null;
        localStorage.setItem('@kopalive:appMerch', appMerchValue);
        yield put(setUser(data));
        if (voucherId) {
            yield put(associateRegisteredVoucher(voucherId, 0));
        } else {
            const redirectToProducerPage = sessionStorage.getItem('@kopaRedirect');
            if (redirectToProducerPage) {
                history.push(redirectToProducerPage);
            } else {
                history.push('/home');
            }
        }
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    message = translate('ERROR_LOGIN');
                    break;
                default:
                    message = translate('ERROR_LOGINFAIL') + error.response.status;
            }
        }
        console.log('login error', error, message);
        yield put(loginFailed(message));
    }
}

function* logout() {
    try {
        localStorage.removeItem('@selectedChannel');
        localStorage.removeItem('KopaLive');
        yield ChatSocket.disconnect(true);
        yield api.logout();
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('FAIL_LOGOUT') });
        console.log('logout error', error, message);
    } finally {
        history.push('/');
    }
}

function* setToken() {
    const token = JSON.parse(localStorage.getItem('KopaLive'));
    if (token) {
        if (parseJwt(token.accessToken)?.force_pw_change === true) {
            return;
        }
        api.setToken(token);
        try {
            const { session_token: session } = yield api.createSession();
            yield ChatSocket.connect(token.accessToken, session);
        } catch (error) {
            console.log('Falha ao conectar com websocket');
            history.push('/networkFailed');
        }
    } else {
        yield put(clearAll());
    }
}

function* changeLanguage({ payload }) {
    try {
        yield api.changeUserLanguage(payload);
        const { data } = yield api.getUser();
        yield put(setUser(data));
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('FAIL_CHANGELANGUAGE') });
        console.log('change language error', error, message);
    }
}

function* changePassword({ payload }) {
    try {
        yield api.changePassword(payload.newPassword, payload.acessToken);
        yield put(loginRequest(payload.username, payload.newPassword));
    } catch (error) {
        defaultErrorHandler({ default: translate('FAIL_CHANGE_PASSWORD') });
    }
}

function* getUserProfile() {
    try {
        const { data } = yield api.getUser();
        yield put(setUser(data));
    } catch (error) {
        yield put(getUserError(error));
        defaultErrorHandler(error, { default: translate('FAILED_GET_USER_PROFILE') });
    }
}

function* editProfileRequest({ payload }) {
    try {
        const res = yield api.editProfile(payload);
        yield put(editProfileSuccess(payload));
        if (res.status !== 200) {
            throw res;
        }
        const { data } = yield api.getUser();
        yield put(setUser(data));
        Notify.success(translate('PROFILE_UPDATED'));
    } catch (error) {
        let message = translate('ERROR_NONETWORK');
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    message = translate('ERROR_PARAMETERS');
                    break;
                case 401:
                    message = translate('ERROR_CREDENTIALS');
                    break;
                default:
                    message = translate('ERROR_NONETWORK');
            }
        }
        console.log('update profile error', error, message);
        yield put(editProfileFail(message));
        Notify.error(translate('PROFILE_UPDATE_FAILED'));
    }
}

function* setOnBoarding({ payload }) {
    const { key, skipped, global_skip } = payload;

    try {
        yield api.setOnBoarding(key, skipped, global_skip);
        const onBoarding = yield api.getUserOnBoarding();
        yield put(setOnBoardingStatus(onBoarding));
    } catch (error) {
        console.log('error -> ', error);
    }
}

function* changeOnBoardingStatus() {
    try {
        const onBoarding = yield api.getUserOnBoarding();
        yield put(setOnBoardingStatus(onBoarding));
    } catch (error) {
        console.log('error -> ', error);
    }
}

function* appMerchRequest() {
    try {
        yield api.appMerchRequest(false);
    } catch (error) {
        defaultErrorHandler(error);
    }
}

export default all([
    takeLatest('SET_TOKEN', setToken),
    takeLatest('LOGIN_REQUEST', login),
    takeLatest('CHANGE_PASSWORD', changePassword),
    takeLatest('LOGOUT', logout),
    takeLatest('CHANGE_USER_LANGUAGE', changeLanguage),
    takeLatest('GET_USER_PROFILE', getUserProfile),
    takeLatest('EDIT_PROFILE_REQUEST', editProfileRequest),
    takeLatest('SET_ONBOARDING', setOnBoarding),
    takeLatest('CHANGE_ONBOARDING_STATUS', changeOnBoardingStatus),
    takeLatest('DONT_SHOW_MERCH_REQUEST', appMerchRequest),
]);
