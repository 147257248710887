import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SliderButton, VideoCard, Footer, LiveTag, VideoProgress } from './styles';
import { settings } from './breakpoints';
import langs from '../../assets/i18n/constants';

const formatValue = (value) => (value ? Number(value).toFixed(2) : 0);
const normalize = (value, maxValue) => ((value - 0) * 100) / (maxValue - 0);
const numberToHHMMSS = (value) => {
    if ((!value && value !== 0) || (Number.isNaN(value))) return null;

    const sec_num = parseInt(value, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;
    return `${hours}:${minutes}:${seconds}`;
};

function renderProgress(rawVideos = [], rawProgress) {
    const result = rawVideos;
    result.forEach(video => {
        const matchedVideoProgress = rawProgress.find(item => item.videoId === video.tokyo_video_id);
        if (matchedVideoProgress) {
            video.duration = matchedVideoProgress.duration;
            video.position = matchedVideoProgress.position;
        }
    });
    return result;
}

export default function RoomSlider({ videos, progress = [] }) {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Slider {...settings}>
            {
                renderProgress(videos, progress).map(video => (
                    <SliderButton key={video.id} onClick={() => history.push(`/course/${video.id}`)}>
                        <VideoCard>
                            {
                                video.live && <LiveTag>{t(langs.LIVE)}</LiveTag>
                            }
                            <img src={video.image} alt={video.name} />
                            <div className="progress-wrap">
                                <VideoProgress progress={normalize(formatValue(video.position), formatValue(video.duration))} />
                            </div>
                            <Footer>
                                <h5>{video.name}</h5>
                                <p>{numberToHHMMSS(video?.duration)}</p>
                            </Footer>
                        </VideoCard>
                    </SliderButton>
                ))
            }
        </Slider>
    );
}
