import React, { useCallback, useEffect } from 'react';
import { Container } from './styles';
import backwardIcon from '../../assets/10sec_backward.svg';
import forwardIcon from '../../assets/10sec_forward.svg';
import playOverlayIcon from '../../assets/play_overlay.svg';
import pauseOverlayIcon from '../../assets/pause_overlay.svg';

let nativeControlBarHeight = 34;

export default function OverlayVideoControls({
    handlePlay, handlePause, handleBackward, handleForward, isPlaying, show, maintainTimeout,
}) {
    const handleClick = useCallback((handle) => () => {
        if (maintainTimeout) {
            maintainTimeout();
        }
        handle();
    }, [maintainTimeout]);

    useEffect(() => {
        const nativeControlBarContainer = document.querySelector('#player_ifp').contentWindow.document.querySelector('.controlBarContainer');
        if (nativeControlBarContainer) {
            nativeControlBarHeight = nativeControlBarContainer.clientHeight;
        }
    }, []);

    useEffect(() => {
        const videoHolder = document.querySelector('#player_ifp').contentWindow.document.querySelector('.videoHolder');
        if (videoHolder) {
            if (show) {
                videoHolder.style.webkitFilter = 'brightness(0.5)';
            } else {
                videoHolder.style.webkitFilter = '';
            }
        }
        const mwEmbedPlayer = document.querySelector('#player_ifp').contentWindow.document.querySelector('.mwEmbedPlayer');
        if(mwEmbedPlayer){
            if(show){
                mwEmbedPlayer.style.cursor = 'default';
            }
        }
    }, [show]);

    return (
        <Container height={nativeControlBarHeight}>
            <div className={`controls ${show ? 'show' : ''} `} id="overlay-container">
                {
                    show && (
                        <>
                            <button onClick={handleClick(handleBackward)} type="button">
                                <img src={backwardIcon} alt="backward-icon" />
                            </button>
                            <button onClick={isPlaying ? handleClick(handlePause) : handleClick(handlePlay)} type="button">
                                <img src={isPlaying ? pauseOverlayIcon : playOverlayIcon} alt="play-icon" />
                            </button>
                            <button onClick={handleClick(handleForward)} type="button">
                                <img src={forwardIcon} alt="forward-icon" />
                            </button>
                        </>
                    )
                }
            </div>
        </Container>
    );
}
