import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import langs from '../../assets/i18n/constants';
import Modal from '../Modal';
import { CustomRoutingPromptContainer, Content, ContentText, ButtonArea } from './styles';

const CustomRoutingPrompt = ({ when, navigateCallback, contentText }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigateCallback(lastLocation.pathname);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmedNavigation, lastLocation]);

    const showModal = (location) => {
        setModalVisible(true);
        setLastLocation(location);
    };

    const closeModal = (callback) => {
        setModalVisible(false);

        if (typeof callback === 'function') callback();
    };

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation) {
            showModal(nextLocation);
            return false;
        }

        return true;
    };

    const handleConfirmNavigationClick = () => {
        closeModal(() => {
            if (lastLocation) {
                setConfirmedNavigation(true);
            }
        });
    };

    return (
        <CustomRoutingPromptContainer>
            <Prompt
                when={when}
                message={handleBlockedNavigation}
            />
            <Modal
                isOpen={modalVisible}
                handleModal={closeModal}
            >
                <Content>
                    <ContentText>{contentText}</ContentText>
                    <ButtonArea>
                        <button onClick={closeModal} type="button">{t(langs.CANCEL)?.toUpperCase()}</button>
                        <button onClick={handleConfirmNavigationClick} type="button">{t(langs.CONFIRM)?.toUpperCase()}</button>
                    </ButtonArea>
                </Content>
            </Modal>
        </CustomRoutingPromptContainer>
    );
};

export default CustomRoutingPrompt;
