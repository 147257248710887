import styled from 'styled-components';

export const AppAdvertisingModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 2rem;
    text-align: center;
    height: 80vh;
    overflow-y: auto;

    div {
        padding: 4rem 0;
    }
    
    img {
        width: 90%;
        height: auto;
        margin: 2rem 0;
    }
    
    h1 {
        color: #fff;
        margin: .7rem 0;
    }

    p {
        color: #fff;
        margin: 1rem 0;
    }

    button {
        width: 100%;
        padding: 15px 0;
        border: 0;
        border-radius: 8px;
        color:#fff;
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.21px;

        @media (max-width: 375px){
            font-size: 1rem;
        }
    }

    .merch-checkbox-area {
        display: flex;
        padding: 0;
        margin: 0;
    }

    @media (max-height: 670px){

        img {
            width: 60%;
            height: auto;
            margin: .5rem 0;
        }
    
        h1 {
            font-size: .95rem
            margin: .5rem 0
        }

        p {
            font-size: .95rem
        }

        button {
            font-size: .8rem

        }

    }

`;
