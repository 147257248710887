import styled from 'styled-components'

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .datepick {
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
    }

    h1 {
      @media(max-width: 500px) {
          font-size: 18px;
      }
  }

    .data {
        width: 75%;
        padding: 1rem;

        form {
            width: 100%;
            font-weight: 800;
            display: flex;
            justify-content: space-around;
            

            input {
                background: #393737;
                border: 0;
                border-radius: 0;
                margin: 5px 0;
                color: white;
                font-weight: 800;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active,
            input:-webkit-autofill:valid
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus,
            select:-webkit-autofill:active,
            select:-webkit-autofill:valid
            
            {
              background: #393737 !important;
              color: #fff !important;
              -webkit-box-shadow: 0 0 0 1000px #393737 inset !important;
              -webkit-text-fill-color: #fff !important;
              transition: background-color 5000s ease-in-out 0s;
            }

            select {
              padding-left: 15px;
              margin-top: 5px;
              background: #393737;
              border: 0;
              font-weight: 800;
              height: 60%;
              color: white;
              -webkit-appearance:none;

              option {
                font-weight: 800;
              }
            }

            .name{
              display: flex;
              flex-direction: column;
              text-align: left;
              
              @media(min-width: 500px) {
                margin-bottom: 20px;
              }
            }

            .email {
                display: flex;

                @media(min-width: 500px) {
                  margin-bottom: 20px;
                }

                div {
                    width: 48%;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    &:last-of-type {
                        margin-left: 4%;
                    }
                }
            }

            .info {
                display: flex;
                margin: 5px 0;
                
                .middle-col-3 {
                    margin: 0 3%;
                }

                > div {
                    width: 35%;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                }

                > button {
                  color: green;
                }

                .react-datepicker-wrapper input {
                  width: inherit;
                }
                
                .react-datepicker__tab-loop  {
                  button {
                    height: 15px !important;
                    background: transparent !important;
                    border: inherit !important;
                  }
                }
            }
        }

        .submit {
          width: 200px;
          height: auto;
          margin: 40px auto 50px;
          padding: 15px;
          border: 2px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
  
          &:hover {
              background: transparent;
              border: 2px solid;
              border-image-slice: 1;
              border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
              color: #FFF;
          }
      }

      .react-tel-input {
        width: 100% !important;
        height: 60%;
        margin-top: 5px;

        ul {
          color: black;
        }

        .search {
          display: flex;

          .search-emoji {
            line-height: 2.5;
          }
        }
        
        .special-label {
          display: none;
        }

        input {
          height: 100%;
          width: 100%;
        }

        .flag-dropdown {
          border: 0;
          background: transparent; 
          width: 12%;
        }

        .selected-flag {
          width: 100%;
        }

        li > .flag {
          width: 5%;
        }

        .country {
          display: flex;
        }
      }
    }

    .border {
        background-image: linear-gradient(to left, #ffae45, #ff428d 60%);
        width: 5%;
        height: 3px;
    }

    @media(max-width: 1100px) {
      .flag-dropdown {
          width: 20% !important;
        }
    }

    @media(max-width: 800px) {
        label {
            font-size: 10px;
        }

        .data {
            width: 100%;
            height: 100%;
        }

        ul {
          width: 230px !important;
        }
    }

    @media(max-width: 400px) {

        .birth, .phone, .genre {
            width: 32% !important;
        }
    }

    @media(max-width: 500px) {
        .border {
          width: 35% !important;
        }

        .email {
          flex-direction: column;
          height: 34%;
          justify-content: space-between;

          > div {
            width: 100% !important;

            &:last-of-type {
              margin: 0 !important;
            }
          }
        }

        .name {
          height: 16%;
        }

        .info {
          flex-direction: column;
          height: 50%;
          justify-content: space-between;

          select {
            height: 45px !important;
          }

          .birth, .phone, .genre {
            width: 100% !important;
          }

          .middle-col-3 {
            margin: 0 !important;
          }
        }

        .phone {
          height: 85px;

          .selected-flag {
            width: 60% !important;
          }

          ul {
            width: 300px !important;
          }
        }

        .data {
          height: 100% !important;
        }

        form {
          height: 100%;

          label {
            margin-top: 20px;
          }
        }
    }
`;
