import styled from 'styled-components';
import Tour from 'reactour';

export const Onboarding = styled(Tour)`
    background-color: #1b1b32;
    color: #ffffff;

    svg {
        color: #f0478d;

        .onboardingOverlay {
            color: red;
        }
    }

    h1 {
        margin-bottom: 10px;
        font-size: 24px;
        text-transform: uppercase;
    }

    h2 {
        margin-bottom: 15px;
    }

    p {
        line-height: 1.6;
    }

    .btn {
        padding: 5px;
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin-top: 35px;

        > button {
            margin-bottom: 10px;
        }
    }

    .ignore-btn {
        border: 2px solid #ffac47;
        color: #ffac47;
        background: transparent;
    }

    .complete-btn {
        width: 100%;
        margin-top: 10px;
        border: none;
        background: linear-gradient(90deg, #ff438d 0%, #ffac47 100%);
        color: white;
    }

    .continue-btn {
        border: none;
        background: linear-gradient(90deg, #ff438d 0%, #ffac47 100%);
        color: white;
    }
`;
