import React from 'react';
import { useTranslation } from 'react-i18next';
import networkFailed from '../../assets/networkFailed.svg';
import { Container, Image, Problem, Message, ExitButton } from './styles';

const NetworkFailed = () => {
    const { t } = useTranslation();

    function returnHomePage() {
        window.location.replace('/');
    }

    return (
        <Container>
            <Image src={networkFailed} alt="networkFailed" />
            <Problem>{t('NETWORK_FAILED')}</Problem>
            <Message>{t('NETWORK_FAILED_MESSAGE')}</Message>
            <ExitButton onClick={returnHomePage}>
                {t('BACK_INITIAL_PAGE')}
            </ExitButton>
        </Container>
    );
};

export default NetworkFailed;
