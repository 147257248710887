import React from 'react';
import { Table, TableHead, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import podiumIcon from '../../../assets/podium-icon.svg';
import i18n from '../../../assets/i18n/i18n';
import { Header, TableContainer } from './styles';
import useRanking from '../../../hooks/useRanking';
import NoRanking from './NoRanking';

export default function Top10({ isOpen, handleClose, title, featuredTitle }) {
    const { t } = useTranslation();
    const { firstTen: top10, user: me } = useRanking();

    return (
        <Modal width={550} title={title} featuredTitle={featuredTitle} isOpen={isOpen} handleModal={handleClose} customClasses="ranking-modal">
            {
                top10.length > 0 ? (
                    <>
                        <Header>
                            <img src={podiumIcon} alt="Pódio" />
                            <h5>
                                <span>{t('YOU')}</span> {t('ARE_IN')} <span>{`${me?.rank || '?'}`}{me?.rank ? i18n.language === 'pt-BR' ? 'º' : '' : ''} {t('RANKING_POSITION')}</span> {t('WITH')} <span>{me?.points} PTS</span>
                            </h5>
                        </Header>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <tr>
                                        <th align="left">{t('PLACE')}</th>
                                        <th align="left">{t('NAME')}</th>
                                        <th align="left">{t('TOTAL_POINTS')}</th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    {
                                        top10.map(user => (
                                            <tr key={user.username}>
                                                <td align="center"><span>{user.rank}{i18n.language === 'pt-BR' ? 'º' : ''}</span>
                                                </td>
                                                <td align="left">{user.name}</td>
                                                <td align="left">
                                                    {user.points}{' '}
                                                    {t('TOTAL_POINTS').split(' ').length === 2 ? t('TOTAL_POINTS').split(' ')[1] : t('TOTAL_POINTS').split(' ')[2].toLowerCase()}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : <NoRanking />
            }
        </Modal>
    );
}
