import React, { forwardRef } from 'react';

import { Container, Input } from './styles';

const CodeInput = ({
    type,
    autoComplete,
    value,
    onChange,
    alertMessage,
    onKeyDown,
}, ref) => (
    <Container
        alertMessage={alertMessage}
    >
        <Input
            ref={ref}
            type={type}
            value={value}
            onChange={onChange}
            autoComplete={autoComplete}
            maxLength="1"
            onKeyDown={onKeyDown}
            min="0"
            max="9"
        />
    </Container>
);

export default forwardRef(CodeInput);
