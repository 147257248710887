import { useSelector, shallowEqual } from 'react-redux';

function offersSelector({ offers }) {
    return {
        offers: offers.offers || [],
        loading: offers.loading,
        isOffersEnabled: offers.isOfferEnabled,
        qrcodeUrl: offers.qrcodeUrl,
    };
}

export default function useOffers() {
    const {
        offers,
        loading,
        isOffersEnabled,
        qrcodeUrl,
    } = useSelector(offersSelector, shallowEqual);
    return {
        offers,
        loading,
        isOffersEnabled,
        qrcodeUrl,
    };
}
