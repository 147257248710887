import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';

const BreadcrumbsContext = createContext();

export const BreadcrumbsProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([{ to: '', label: '' }]);

    const setItems = useCallback((items) => {
        localStorage.setItem('@KopaliveBreadcrumbs', JSON.stringify(items));
        setBreadcrumbs(items);
    }, []);

    useEffect(() => {
        const items = localStorage.getItem('@KopaliveBreadcrumbs');
        if (items) {
            setBreadcrumbs(JSON.parse(items));
        }
    }, []);

    return (
        <BreadcrumbsContext.Provider
            value={{ breadcrumbs, setItems }}
        >
            {children}
        </BreadcrumbsContext.Provider>
    );
};

export function useBreadcrumbs() {
    const context = useContext(BreadcrumbsContext);
    return context;
}
