import { all, put, takeLatest, takeLeading } from 'redux-saga/effects';
import actions from '../../../utils/actions';
import api from '../../../services/api';
import { defaultErrorHandler } from '../../utilities';
import history from '../../../services/history';
import Notify from '../../../utils/notification';
import { translate } from '../../../utils/functions';

function* verifyVoucher({ payload }) {
    const { id, redirect } = payload;
    try {
        const data = yield api.verifyVoucher(id);
        yield put({ type: actions.VOUCHER_SET_VERIFICATION, payload: data });
    } catch (error) {
        if(error.response){
            const message = defaultErrorHandler(error, { default: error.response.data.error_description });
            console.log('verifyVoucher error', error, message);
        }
        if (error.response.status === 404 && redirect) {
            history.push('/voucher/');
        }
    }
}

function* registerVoucherUser({ payload }) {
    try {
        yield api.registerVoucherUser(payload);
        yield put({ type: actions.VOUCHER_VERIFY, payload: { id: payload.voucherId, redirect: true } });
    } catch (error) {
        const message = defaultErrorHandler(error, {
            default: translate('VOUCHER_ERROR_REGISTERDATA'),
            409: translate('VOUCHER_ERROR_USERCREATED'),
        });
        console.log('verifyVoucher error', error, message);
    }
}

function* verifyEmail({ payload }) {
    try {
        const { voucherId, code } = payload;
        yield api.verifyEmail(voucherId, code);
        yield put({ type: actions.VOUCHER_VERIFY, payload: { id: payload.voucherId, redirect: true } });
    } catch (error) {
        if(error.response){
            switch (error.response.status) {
                case 404:
                    Notify.warn(translate('VOUCHER_ERROR_REGISTERDATA'));
                    break;
                case 406:
                    Notify.warn(translate('VOUCHER_ERROR_EXPIREDCODE'));
                    break;
                default:
                    defaultErrorHandler(error, { default: translate('VOUCHER_ERROR_INVALIDCODE') });
                    break;
                }
        } else {
            defaultErrorHandler(error, { default: translate('VOUCHER_ERROR_INVALIDCODE') });
        }
        console.log('verifyVoucher error', error, 'Falha ao verificar código');
    }
}

function* resendEmail({ payload }) {
    try {
        yield api.resendEmail(payload);
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('VOUCHER_ERROR_RESENDEMAIL') });
        console.log('verifyVoucher error', error, message);
    }
}

function* confirmPassword({ payload }) {
    try {
        const { username, password, voucherId } = payload;
        yield api.verifyPassword(voucherId, password);
        yield put({ type: actions.VOUCHER_ASSOCIATE, payload: { voucherId, username, password } });
    } catch (error) {
        const message = defaultErrorHandler(error, { default: translate('VOUCHER_ERROR_CONFIRMPASSWORD') });
        console.log('verifyVoucher error', error, message);
    }
}

function* associateVoucher({ payload }) {
    try {
        const { username, password, voucherId } = payload;
        yield api.associateVoucher(voucherId);
        yield put({ type: actions.VOUCHER_SET_ACTIVATED, payload: true });
        yield put({ type: 'LOGIN_REQUEST', payload: { username, password } });
        Notify.success(translate('VOUCHER_REGISTERED'));
    } catch (error) {
        yield put({ type: actions.VOUCHER_SET_ACTIVATED, payload: false });
        const message = defaultErrorHandler(error, {
            default: translate('VOUCHER_ERROR_REGISTERDATA'),
            409: translate('VOUCHER_ERROR_COURSE'),
        });
        console.log('verifyVoucher error', error, message);
    }
}

function* associateVoucherRegistered({ payload }) {
    try {
        const { voucherId, renew } = payload;
        yield api.associateVoucherRegistered(voucherId, renew);
        yield put({ type: actions.VOUCHER_SET_ACTIVATED, payload: true });
        Notify.success(translate('VOUCHER_REGISTERED'));
        history.push('/home');
    } catch (error) {
        yield put({ type: actions.VOUCHER_SET_ACTIVATED, payload: false });
        const message = defaultErrorHandler(error, {
            default: translate('VOUCHER_ERROR_REGISTER'),
            409: translate('VOUCHER_ERROR_COURSE'),
        });
        console.log('verifyVoucher error', error, message);
    }
}

export default all([
    takeLatest(actions.VOUCHER_ASSOCIATE, associateVoucher),
    takeLatest(actions.VOUCHER_ASSOCIATE_REGISTERED, associateVoucherRegistered),
    takeLatest(actions.VOUCHER_VERIFY, verifyVoucher),
    takeLeading(actions.VOUCHER_CONFIRM_PASSWORD, confirmPassword),
    takeLeading(actions.VOUCHER_REGISTER_USER, registerVoucherUser),
    takeLeading(actions.VOUCHER_VERIFY_EMAIL, verifyEmail),
    takeLeading(actions.VOUCHER_RESEND_VERIFY, resendEmail),
]);
