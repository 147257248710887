import React from 'react';

import { ButtonMediaConfigContainer } from './styles';

const ButtonMediaConfig = ({ onClick, icon, title }) => (
    <ButtonMediaConfigContainer>
        <button
            type="button"
            onClick={onClick}
        >
            <small>
                <img alt="config" src={icon} />
            </small>
            <p>{title}</p>
        </button>
    </ButtonMediaConfigContainer>
);

export default ButtonMediaConfig;
