import styled from 'styled-components';

export const CustomRoutingPromptContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-height: 90vh;
    flex: 1;
    padding: 1.5rem;
`;

export const ContentText = styled.h1`
    color: #fff;
    font-weight: bold;
    text-align: center;
`;

export const ButtonArea = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-top: 15px;

    button {
        outline: 0;
        border: 0;
        width: 120px;
        padding: .5rem;
        background-color: #FF8F63;
        color: #fff;
        font-weight: bold;
        border-radius: 6px;

        &:hover {
            transform: scale(1.05);
        }
    }
`;
