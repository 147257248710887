import actions from '../../../utils/actions';

export const getWatchedVideosProgress = (payload) => ({
    type: actions.ROOM_GET_PROGRESS,
    payload,
});

export const setWatchedVideosProgress = (payload) => ({
    type: actions.ROOM_SET_PROGRESS,
    payload,
});
