import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: #282b3a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const Image = styled.img`
    width: 70px;
    margin-bottom: 10px;

    @media (min-width: 650px) {
        width: 150px;
        margin-bottom: 30px;
    }
`;

export const Ops = styled.span`
    font-weight: 800;
    color: white;
`;

export const Message = styled.span`
    color: white;
`;

export const ExitButton = styled.button`
    display: flex;
    align-items: center;
    justify-content:center;        
    height: 40px;
    border: 0;
    width: 30%;
    margin-top: 30px;
    color:#fff;
    padding: 8px;
    background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 0.21px;
    border-radius: 8px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    &:hover {
        box-shadow: 0px 0px 10px -4px #000000;
        transform: scale(1.03);
    } 

    @media(max-width: 800px){
        width: 90%; 
    }
`;
