import React from 'react';
import MultipleQuiz from './MultipleQuiz';
import SimpleQuiz from './SimpleQuiz';

export default function Quiz({ values, handleSelect, chatMode = false }) {
    const { groups: quizInGroups } = values;

    if (quizInGroups) {
        return <MultipleQuiz values={values} chatMode={chatMode} />;
    }

    return <SimpleQuiz values={values} chatMode={chatMode} handleSelect={handleSelect} />;
}
