import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Onboarding } from './styles';
import { setOnBoarding } from '../../store/modules/auth/actions';
import history from '../../services/history';

export default function OnboardingVideos() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const path = history.location.pathname;
    const room = path.split('/')[2];

    function handleCloseOnboarding(key, skipped, global_skip) {
        dispatch(setOnBoarding(key, skipped, global_skip));
        history.push(`/course/${room}`);
    }

    useLayoutEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);

    const steps = {
        id: 'onBoardingRoom',
        steps: [
            {
                selector: '[data-tour="tour__video"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_VIDEO_AREA_TITLE')}</h2>
                        <p>{t('ONBOARDING_VIDEO_AREA')}</p>
                    </>
                ),
            },
            {
                selector: '[data-tour="tour__ranking"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_VIDEO_RANKING_TITLE')}</h2>
                        <p>{t('ONBOARDING_VIDEO_RANKING')}</p>
                    </>
                ),
            },
            {
                selector: '[data-tour="tour__description"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_VIDEO_INFORMATIONS_TITLE')}</h2>
                        <p>{t('ONBOARDING_VIDEO_INFORMATIONS')}</p>
                    </>
                ),
            },
            {
                selector: '[data-tour="tour__attachments"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_VIDEO_ATTACHMENTS_TITLE')}</h2>
                        <p>{t('ONBOARDING_VIDEO_ATTACHMENTS')}</p>
                    </>
                ),
            },
            {
                selector: '[data-tour="tour__chat"]',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_VIDEO_CHAT_TITLE')}</h2>
                        <p>{t('ONBOARDING_VIDEO_CHAT')}</p>
                    </>
                ),
            },
            {
                selector: '',
                content: () => (
                    <>
                        <h2>{t('ONBOARDING_VIDEO_END_TOUR_TITLE')}</h2>
                        <p>{t('ONBOARDING_VIDEO_END_TOUR')}</p>
                        <button type="button" className="btn complete-btn" onClick={() => handleCloseOnboarding(steps.id)}>{t('ONBOARDING_COMPLETE')}</button>
                    </>
                ),
            },
        ],
    };

    return (
        <>
            <Onboarding
                isOpen
                steps={steps.steps}
                className="onboardingHome"
                maskClassName="onboardingOverlay"
                accentColor="#ff438d"
                disableInteraction
                closeWithMask={false}
                rounded={4}
                onRequestClose={() => handleCloseOnboarding(steps.id, true)}
                closeButtonAriaLabel={t('ONBOARDING_COMPLETE')}
            />
        </>
    );
}
