import styled from 'styled-components';
import arrowIcon from '../../assets/arrow-down.svg';

export const Container = styled.div`
    width: 100%;
    height: calc(100% - 11%);
    padding: 0 40px;
    display: flex;
    justify-content: flex-start;
    flex-flow: column nowrap;
    align-items: center;
    align-content: center;
    position: relative;

    @media(max-width: 500px){
        padding: 0 20px;  
    }

    @media(max-width: 990px) and (orientation: landscape) {
        height: calc(100% - 18%);
      }

    @media(min-width: 990px) {
        flex-flow: row wrap;
        justify-content: space-between;
    }     
`;

export const ContainerVideo = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;   
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    /* overflow: auto;  */
`;

export const LeftContainer = styled.div`
    width: 100%;
    display: flex;
    height: 30%;
    flex-direction: column;
    justify-content: center;
    margin: 0px 0 10px;
   
    @media(min-width: 650px) {
        margin: 40px 0 40px;
    }

    @media(min-width: 990px) {
        width: 40%;
        height: 100%;
        margin: 0;
    }
`;

export const RightContainer = styled.div`
    width: 100%;
    height: 70%;
    display: flex;
    overflow-y: auto;
    overflow-x: none;
    justify-content: space-between;
    flex-flow: row wrap;


    @media(min-width: 990px) {
        width: 40%;
        height: 100%;
    }

`;

export const Title = styled.span`
    color: white;
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 25px;
    text-align: center;
    
    &:after {
        content: '';
        width: 60%;
        height: 4px;
        display: block;
        position:relative;
        top: 10px;
        background: linear-gradient(90deg, #FF438D 0%, #FFAC47 100%);
        margin: 0 auto;

        @media(min-width: 990px) {
            margin: 0;
        }
    }

    @media(min-width: 650px) {
        font-size: 30px;
        text-align: center;
    }

    @media(min-width: 990px) {
        font-size: 50px;
        text-align: left;
    }
`;

export const Description = styled.span`
    color: white;
    font-size: 14px;
    text-align: center;

    @media(min-width: 650px) {
        font-size: 18px;
        text-align: center;
    }

    @media(min-width: 990px) {
        font-size: 21px;
        text-align: left;
    }
`;

export const RoomList = styled.div`
   width: 100%;
   margin-bottom: 10px;

   .page-slider {
     display: flex !important;
     padding: 5px;
     @media(max-width: 650px) {
      justify-content: center;
    }
   }

   .title-category {
       margin-bottom: 7px;
       font-size: clamp(14px, 0.3em + 1vw, 1.2em);
       color: #fff;
       font-weight: 900;
   }

   .channel-header {     
      text-transform: uppercase;  
      display: flex;
      justify-content: space-between;

     .iconsNext {
      margin: 0 1em;
      width: 28px;
      height: 19px;
      cursor: pointer;
     }
     .iconsPrev {
      margin: 0 1em;
      width: 28px;
      height: 19px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      cursor: pointer;
     }
     .buttons {
        h1 {
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.34px;
          color: #ff8f63;
        }
        display: flex;
        @media(max-width: 650px) {
          opacity: 0
        }
     }
   }

   .slick-track {
        margin-left: unset ;
    }

   .slick-prev, .slick-next {       
       top: 0;
       bottom: 0;
       display: flex;
       justify-content: center;
       align-items: center;
       position: absolute;
       height: 99%;
       width: 25px;       
       transform: none;     
       background-size: contain;
       transition: all .2s ease-in-out;
       background: #282b3aa6;
       z-index: 1;
       &:hover {
            color: rgba(0, 0, 0, 0.1);
       }
   }

    .slick-disabled {
        opacity: 0;
    }

    .slick-prev:before {    
        content: ' ';
        height: 20px;
        width: 20px;
        background: url(${arrowIcon}) no-repeat;
        position: absolute;
        transform: rotate(90deg);
        background-size: contain;
        left: -5px;
        top: 48%;
    }
    .slick-next:before {      
        content: ' ';
        height: 20px;
        width: 20px;
        background: url(${arrowIcon}) no-repeat;
        position: absolute;
        transform: rotate(-90deg);
        background-size: contain;
        right: -6px;
        top: 48%;    
    }

    .slick-list {
        margin: 0 -5px;
    }
    .slick-slide > div {
        padding: 0 5px;
        
    }
    @media(max-width: 500px){
       
        .slick-next {
            right: -13px;
        }
        .slick-prev {
            left: -13px;
        }
    }
`;

export const CenterContainer = styled.div`
    display:flex;
    justify-content:center;
    align-content:center;
    width: 100%;
    height: calc(100% - 11%);
    padding: 1rem;
    flex-flow: column nowrap;
    align-items: center;
    align-content: center;
    position: relative;


    h1{
        display:flex;
        align-self:center;
        color: white;
        font-size: 18px;

        @media(min-width: 650px) {
            font-size: 22px;
            text-align: center;
        }

        @media(min-width: 990px) {
            font-size: 25px;
            text-align: left;
        }
    }
`;
export const MessageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-top: 30px;

    h1{
        font-size: 25px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: 0.09px;
        text-align: center;
        color: #ffffff;
    }

`;
