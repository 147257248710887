import styled from 'styled-components';

export const ValidationContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

    h1 {
      @media(max-width: 500px) {
          font-size: 18px;
      }
    }

    .border {
        background-image: linear-gradient(to left, #ffae45, #ff428d 60%);
        width: 5%;
        height: 3px;
    }

    .validation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;


        p {
            width: 40%;
            text-align: center;
            margin-top: 10px;
            font-weight: 600;

            span {
                word-break: break-all;
                color: #ff9e50;
                margin-left: 5px;
            }
        }

        .code {
            width: 100%;
            margin: 5% 0;
            padding: 0 1rem;

            input {
                padding: 0;
                background-color: #393737;
                border: 0;
                border-radius: 0;
                width: 80px;
                height: 80px;
                margin: 0 1%;
                color: white;
                text-align: center;
                font-size: 24px;
                font-weight: 800;
                text-transform: uppercase;

                @media(max-width: 800px) {
                  width: 16.33%;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: space-around;
            width: 60%;

            button {
              width: 200px;
              height: auto;
              padding: 15px 0;
              border: 2px solid;
              border-image-source: linear-gradient(to left, #ff468b, #ffa54b 60%);
              border-image-slice: 1;
          
              &:hover {
                background: transparent;
                border: 2px solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
                color: #FFF;
              } 

              &:last-child {
                @media(max-width: 500px) {
                  margin-bottom: 50px;
                }
              }
            }

            .resend {
              width: 200px;
              height: auto;
              padding: 15px 0;
              background: transparent;
              border-style: solid;
              border-width: 2px;
              border-image-source: linear-gradient(to left, #ffa54b, #ff468b 60%);
              border-image-slice: 1;
        
              &:hover {
                  background: transparent;
                  border: 2px solid #FFAE45;
                  
                  color: #FFAE45;
              }

              @media(max-width: 800px) {
                width: 100%;
                margin-bottom: 15px;
              }
            }
        }

        @media(max-width: 800px) {
            .buttons {
              width: 100%;
              padding: 0 1rem;
              flex-flow: column wrap;
              
              button {
                width: 100%;
              }
            }

            p {
              width: 90%;

              span {
                overflow-wrap: break-word;
              }
            }

            .code { 
              display: flex;
              width: 100%;
              justify-content: space-evenly;
      
            }
        }
    @media(max-width: 800px) {

      justify-content: space-evenly;
    }
  }

  @media(max-width: 800px) {
    .border {
      width: 35%;
    }
  }

`;