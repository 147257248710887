/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Chat from '../../components/Chat';
import {
    Container,
    VideoPreview,
    StartStopTransmission,
    AccessCameraDenied,
    StartTransmissionHeading,
    RecordingWarning,
    WarnIconContainer,
    WarnText,
    NoConnectionWarning,
} from './styles';

import { fetchRoom, exitRoom } from '../../store/modules/rooms/actions';
import useRooms from '../../hooks/useRooms';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import webRTCSocket from '../../services/WebRTCSocket';
import api from '../../services/api';
import mediaSoupService from '../../services/MediaSoupProducerService';
import useQuery from '../../hooks/useQuery';
import noVideoIcon from '../../assets/no-video.svg';
import startTransmissionIcon from '../../assets/start-transmission-icon.svg';
import alertIcon from '../../assets/alert-white.svg';
import StartTransmissionContainer from './StartTransmissionContainer';
import ModalConfirm from '../../components/ModalConfirm';
import VideoPlayerSkeleton from '../../components/VideoPlayerSkeleton';
import Notify from '../../utils/notification';

import useSize from '../../hooks/useSize';
import ChatSocket from '../../services/ChatSocket';
import { isSafari } from '../../utils/functions';
import CustomRoutingPrompt from '../../components/CustomRoutingPrompt';
import MediaControls from './MediaControls';
import languageConstants from '../../assets/i18n/constants';

function selector({ room, login }) {
    return {
        room: room.selectedRoom,
        user: login.user,
        isChatEnabled: room.isChatEnabled,
        saleEnabled: room.saleEnabled,
    };
}

const constraints = {
    video: true,
    audio: {
        noiseSuppression: { exact: false },
        autoGainControl: { exact: false },
        echoCancellation: { exact: false },
    },
};
const RECONNECTION_INTERVAL = 3000;

export function Presenter() {
    const { isChatEnabled } = useSelector(selector, shallowEqual);
    const { t } = useTranslation();
    const { selectedRoom, videoUrl, socketPath } = useRooms();
    const { setItems } = useBreadcrumbs();
    const { width } = useSize();
    const query = useQuery();
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const history = useHistory();

    const videoWrapper = useRef(null);
    const videoElement = useRef(null);
    const audioElement = useRef(null);
    const videoStream = useRef(null);
    const audioStream = useRef(null);
    const selectedCam = useRef(null);
    const selectedMic = useRef(null);
    const reconnectTimer = useRef(null);

    const [isLive, setIsLive] = useState(false);

    const [audioInputs, setAudioInputs] = useState([]);
    const [videoInputs, setVideoInputs] = useState([]);

    const [microfonePermissionDenied, setMicrofonePermissionDenied] = useState(true);
    const [cameraPermissionDenied, setCameraPermissionDenied] = useState(true);
    const [startingLive, setStartingLive] = useState(false);
    const [showEndLiveQuestion, setShowEndLiveQuestion] = useState(false);
    const [producerPermissionVerified, setProducerPermissionVerified] = useState(false);
    const [browserOnline, setBrowserOnline] = useState(true);
    const [loading, setLoading] = useState(true);
    const [defaultAudioConstraints, setDefaultAudioConstraints] = useState(constraints.audio);

    const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    const accessToken = api.getAccessToken();
    const producerToken = query.get('token');

    function stopWebSocketReconnection() {
        if (reconnectTimer.current) {
            clearInterval(reconnectTimer.current);
        }
    }

    function handleStopTransmission() {
        stopWebSocketReconnection();
        mediaSoupService.stopStreaming();
        webRTCSocket.disconnect();
        setShowEndLiveQuestion(false);
        setIsLive(false);
    }

    const handleProduce = useCallback(async () => {
        if (mediaSoupService._transport) {
            mediaSoupService._transport.close();
            mediaSoupService._transport = null;
        }
        mediaSoupService.setSocket(webRTCSocket.socket);
        mediaSoupService.setAudioStream(audioStream.current);
        mediaSoupService.setVideoStream(videoStream.current);
        mediaSoupService.setKinds(constraints);
        await mediaSoupService.connect();
    }, []);

    useEffect(() => {
        // Atualiza a sala quando socket é reconectado
        const removeListener = ChatSocket.addConnectedListener(() => {
            dispatch(fetchRoom(roomId, true));
        });

        return function cleanup() {
            removeListener();
            dispatch(exitRoom());
        };
    }, [dispatch, roomId]);

    useEffect(() => {
        if (!socketPath || !videoUrl) {
            dispatch(fetchRoom(roomId));
        }
    }, [dispatch, roomId, socketPath, videoUrl]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: '/home', label: t(languageConstants.WELCOME_TO_KOPA) },
            { to: `/producer/${selectedRoom?.id}`, label: selectedRoom?.name },
        ];
        setItems(width >= 450 ? breadcrumbsItems : breadcrumbsItems.splice(1, 1));
    }, [selectedRoom, setItems, t, width]);

    const getUserMediaAudio = useCallback(async () => {
        const audioConstraints = { audio: { ...defaultAudioConstraints } };

        if (audioStream.current) {
            audioStream.current.getTracks().forEach(track => track.stop());
        }

        if (selectedMic.current) {
            audioConstraints.deviceId = { exact: selectedMic.current };
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            const defaultAudioDevice = audioDevices[0];
            audioStream.current = stream;
            setMicrofonePermissionDenied(false);
            setAudioInputs(audioDevices);

            if (audioElement.current) {
                audioElement.current.srcObject = stream;
            }

            if (!selectedMic.current) {
                selectedMic.current = defaultAudioDevice.deviceId;
            }

            // change audio device
            if (audioInputs.length > 1
                && mediaSoupService._stream.audio
                && mediaSoupService._stream.audio !== stream
                && mediaSoupService._stream.video !== stream
                && mediaSoupService._transport && !mediaSoupService._transport?.closed) {
                mediaSoupService._audioProducer.replaceTrack({ track: audioStream.current.getAudioTracks()[0] });
            }
        } catch (error) {
            setMicrofonePermissionDenied(true);
            console.log(error);
        }
    }, [audioInputs.length, defaultAudioConstraints]);

    /**
     * Changes userMedia audio constraints
     * @param {('noiseSuppression' | 'autoGainControl' | 'echoCancellation') } constraint default 'noiseSuppression'
     * @param {boolean} state default false
     */
    async function changeAudionConstraints(constraint = 'noiseSuppression', state = false) {
        const audioConstraints = {
            audio: {
                ...defaultAudioConstraints,
                [constraint]: { exact: state },
            },
        };
        if (audioStream.current) {
            audioStream.current.getTracks().forEach(track => track.stop());
        }
        if (selectedMic.current) {
            audioConstraints.deviceId = { exact: selectedMic.current };
        }
        const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter(device => device.kind === 'audioinput');
        const defaultAudioDevice = audioDevices[0];
        audioStream.current = stream;
        setMicrofonePermissionDenied(false);
        setAudioInputs(audioDevices);
        setDefaultAudioConstraints(audioConstraints.audio);
        if (audioElement.current) {
            audioElement.current.srcObject = stream;
        }
        if (!selectedMic.current) {
            selectedMic.current = defaultAudioDevice.deviceId;
        }
        if (mediaSoupService._audioProducer) {
            mediaSoupService._audioProducer.replaceTrack(
                { track: audioStream.current.getAudioTracks()[0] },
            );
        }
    }

    const getUserMediaVideo = useCallback(async () => {
        let videoConstraints;

        if (videoStream.current) {
            videoStream.current.getTracks().forEach(track => track.stop());
        }

        if (selectedCam.current) {
            videoConstraints = {
                video: {
                    deviceId: { exact: selectedCam.current },
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                },
            };
        } else {
            videoConstraints = { video: true };
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setCameraPermissionDenied(false);

            const defaultVideoDevice = videoDevices[0];
            videoStream.current = stream;
            setVideoInputs(videoDevices);

            if (!selectedCam.current) {
                selectedCam.current = defaultVideoDevice.deviceId;
            }

            // change video device
            if (videoInputs.length > 1
                && mediaSoupService._stream.video
                && mediaSoupService._stream.video !== stream
                && mediaSoupService._transport
                && !mediaSoupService._transport?.closed) {
                mediaSoupService._videoProducer.replaceTrack(
                    { track: videoStream.current.getVideoTracks()[0] },
                );
            }
            if (videoElement.current) {
                videoElement.current.srcObject = stream;
            }
        } catch (error) {
            setCameraPermissionDenied(true);
            console.log(error);
        }
    }, [videoInputs.length]);

    useEffect(() => {
        if (!loading && producerPermissionVerified) {
            if (!audioStream.current) {
                getUserMediaAudio();
            }
        }
    }, [getUserMediaAudio, loading, producerPermissionVerified]);

    useEffect(() => {
        if (!loading && producerPermissionVerified) {
            if (!videoStream.current) {
                getUserMediaVideo();
            }
        }
    }, [getUserMediaVideo, loading, producerPermissionVerified]);

    const handleConnectWebSocket = useCallback(() => {
        if (cameraPermissionDenied) {
            Notify.error(t(languageConstants.CAM_PERMISSION_MESSAGE));
            return;
        }
        if (microfonePermissionDenied) {
            Notify.error(t(languageConstants.MIC_PERMISSION_MESSAGE));
            return;
        }

        if (!webRTCSocket.connected) {
            setStartingLive(true);
            webRTCSocket.authenticate(videoUrl, socketPath, accessToken, producerToken);
        }
    }, [
        t,
        accessToken,
        cameraPermissionDenied,
        microfonePermissionDenied,
        producerToken,
        socketPath,
        videoUrl,
    ]);

    function getStartButtonText() {
        if (!socketPath) {
            return t(languageConstants.PREPARING);
        } if (startingLive) {
            return t(languageConstants.STARTING_LIVE);
        }
        return t(languageConstants.START_TRANSMISSION);
    }

    function stopTransmissionQuestion() {
        setShowEndLiveQuestion(true);
    }

    useEffect(() => {
        const producerRedirectPage = sessionStorage.getItem('@kopaRedirect');
        if (producerRedirectPage) {
            sessionStorage.removeItem('@kopaRedirect');
        }
    }, []);

    function handleStartTransmission() {
        if (webRTCSocket.socket?.connected) {
            handleProduce();
            setIsLive(true);
        } else {
            handleConnectWebSocket();
            handleProduce();
            setIsLive(true);
        }
    }

    const startWebSocketReconnection = useCallback(() => {
        reconnectTimer.current = setInterval(() => {
            if (!webRTCSocket.socket.connected && isLive) {
                handleConnectWebSocket();
            } else if (
                webRTCSocket.socket.connected
                    && mediaSoupService.state === 'disconnected'
                    && isLive
            ) {
                handleProduce();
            }
        }, RECONNECTION_INTERVAL);
    }, [handleConnectWebSocket, handleProduce, isLive]);

    useEffect(() => () => {
        if (mediaSoupService._transport) {
            mediaSoupService._transport.close();
        }
        if (videoStream.current) {
            videoStream.current.getTracks().forEach(track => track.stop());
        }
        if (audioStream.current) {
            audioStream.current.getTracks().forEach(track => track.stop());
        }
        webRTCSocket.disconnect();
    }, []);

    useEffect(() => {
        mediaSoupService.onConnectListener = () => {
            setStartingLive(false);
            stopWebSocketReconnection();
        };
        mediaSoupService.onDisconnectListener = () => {
            startWebSocketReconnection();
        };
    }, [startWebSocketReconnection]);

    useEffect(() => {
        setLoading(true);

        if (producerToken) {
            api.checkProducerPermission(roomId, producerToken)
                .then(() => {
                    setProducerPermissionVerified(true);
                    setLoading(false);
                })
                .catch(() => {
                    setProducerPermissionVerified(false);
                    setLoading(false);
                    history.push('/noPermission');
                });
        }
    }, [history, producerToken, roomId]);

    useEffect(() => {
        function updateOnlineStatus() {
            setBrowserOnline(navigator.onLine);
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return function cleanupListener() {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    if (!loading && producerPermissionVerified) {
        return (
            <Container className={isSafari() ? 'is-safari' : ''}>
                <ModalConfirm
                    description={t(languageConstants.END_LIVE_QUESTION)}
                    title={t(languageConstants.END_LIVE)}
                    visible={showEndLiveQuestion}
                    handleCancel={() => setShowEndLiveQuestion(false)}
                    handleConfirm={handleStopTransmission}
                    handleModal={() => setShowEndLiveQuestion(false)}
                />
                <CustomRoutingPrompt
                    contentText={t(languageConstants.BROADCASTING_ROUTING_LEAVE)?.toLocaleUpperCase()}
                    when={isLive}
                    navigateCallback={path => {
                        history.push(path);
                    }}
                />
                <VideoPreview>
                    <section className="video-container">
                        <div className="video-header">
                            {isLive ? <span>{t(languageConstants.LIVE)}</span> : <div />}
                            <h5>{selectedRoom?.name}</h5>
                            <div>
                                {' '}
                            </div>
                        </div>
                        <div className="video-player" ref={videoWrapper}>
                            {
                                cameraPermissionDenied && (
                                    <AccessCameraDenied>
                                        <img src={noVideoIcon} alt="No video available" />
                                        <h2>OOps!</h2>
                                        <h3>{t(languageConstants.ALLOW_USER_MEDIA_ACCESS)}</h3>
                                    </AccessCameraDenied>
                                )
                            }
                            {
                                !cameraPermissionDenied && (
                                    <video ref={videoElement} autoPlay playsInline />
                                )
                            }
                            {
                                !microfonePermissionDenied && (
                                    <audio ref={audioElement} autoPlay muted />
                                )
                            }
                        </div>
                        <div className="video-details">
                            <MediaControls
                                audioConstraintsState={defaultAudioConstraints}
                                changeAudionConstraints={changeAudionConstraints}
                                supportedConstraints={supportedConstraints}
                                audioInputs={audioInputs}
                                audioStream={audioStream}
                                cameraPermissionDenied={cameraPermissionDenied}
                                getUserMediaAudio={getUserMediaAudio}
                                getUserMediaVideo={getUserMediaVideo}
                                microfonePermissionDenied={microfonePermissionDenied}
                                selectedCam={selectedCam}
                                selectedMic={selectedMic}
                                startingLive={startingLive}
                                stopTransmissionQuestion={stopTransmissionQuestion}
                                videoInputs={videoInputs}
                                videoStream={videoStream}
                                isLive={isLive}
                            />
                        </div>
                    </section>

                </VideoPreview>
                {
                    (isLive && isChatEnabled && !startingLive)
                        && (
                            <Chat quizComponent={null}>
                                <h1>{t(languageConstants.CHAT)}</h1>
                            </Chat>
                        )

                }
                {
                    (!isLive || startingLive) && (
                        (
                            <StartTransmissionContainer>
                                <StartTransmissionHeading>
                                    <img src={startTransmissionIcon} alt="start transmission icon" />

                                    <h2>{t(languageConstants.READY_TO_START_QUESTION)}</h2>
                                    <p> {t(languageConstants.CHECK_MEDIA_SETTINGS)} </p>
                                    {browserOnline
                                        ? (
                                            <StartStopTransmission
                                                disabled={!socketPath || startingLive || !browserOnline}
                                                onClick={handleStartTransmission}
                                            >
                                                {getStartButtonText()}
                                            </StartStopTransmission>
                                        )
                                        : (
                                            <NoConnectionWarning>
                                                <span>&#9888;</span>
                                                <p className="error-network">
                                                    {t(languageConstants.ERROR_NONETWORK)}
                                                </p>
                                            </NoConnectionWarning>
                                        )}
                                </StartTransmissionHeading>
                            </StartTransmissionContainer>
                        )
                    )
                }

            </Container>
        );
    }
    return (
        <Container>
            <VideoPlayerSkeleton />
        </Container>
    );
}

export default function PresenterWrapper() {
    const { t } = useTranslation();
    return (
        <>
            {
                isSafari() && (
                    <RecordingWarning>
                        <WarnIconContainer>
                            <img src={alertIcon} alt="icon-warning" />
                        </WarnIconContainer>
                        <WarnText>
                            {t(languageConstants.SAFARI_RECORDING_WARN)}
                        </WarnText>
                    </RecordingWarning>
                )
            }
            <Presenter />
        </>
    );
}
