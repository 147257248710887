import React from 'react';

import {
    VideoAttach,
    VideoAttachItems,
    VideoAttachItem,
    AttachIcon,
    AttachDescription,
    AttachDownload,
} from './styles';

import AttachIconPdf from '../../../assets/file-pdf.svg';
import AttachIconZip from '../../../assets/file-zip.svg';
import AttachIconCsv from '../../../assets/file-csv.svg';
import AttachIconDoc from '../../../assets/file-doc-2.svg';
import AttachIconJpg from '../../../assets/file-jpg.svg';
import AttachIconPng from '../../../assets/file-png.svg';
import AttachIconPpt from '../../../assets/file-ppt.svg';
import AttachIconXls from '../../../assets/file-xls.svg';

export default function VideoAttachment({ attachments }) {
    const ATTACH_ICON = {
        pdf: AttachIconPdf,
        zip: AttachIconZip,
        csv: AttachIconCsv,
        doc: AttachIconDoc,
        jpg: AttachIconJpg,
        png: AttachIconPng,
        ppt: AttachIconPpt,
        xls: AttachIconXls,
    };

    return (
        <>
            {
                attachments && attachments.length > 0 && (
                    <VideoAttach>
                        <VideoAttachItems>
                            {
                                attachments.map((attach, index) => (
                                    <VideoAttachItem key={index}>
                                        <AttachIcon src={ATTACH_ICON[attach.attachment_extension]} />
                                        <AttachDescription>
                                            <abbr title={attach.attachment_name}>
                                                {attach.attachment_name}
                                            </abbr>
                                        </AttachDescription>
                                        <AttachDownload href={attach.attachment_url} download target="_blank" rel="noopener noreferrer" />
                                    </VideoAttachItem>
                                ))
                            }
                        </VideoAttachItems>
                    </VideoAttach>
                )
            }
        </>
    );
}
