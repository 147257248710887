import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import KopaLogo from '../../assets/kopa-logo.svg';
import {
    Container,
    Logo,
    VoucherContainer,
    Header,
    InputContainer,
} from './styles';
import { verifyVoucher } from '../../store/modules/vouchers/actions';
import useVouchers from '../../hooks/useVoucher';

import Notify from '../../utils/notification';

const Voucher = () => {
    const [voucher, setVoucher] = useState('');
    const dispatch = useDispatch();
    const { id } = useParams();
    const { voucherVerification } = useVouchers();
    const { t } = useTranslation();
    const history = useHistory();
    const input1 = useRef(null);
    const input2 = useRef(null);
    const input3 = useRef(null);
    const input4 = useRef(null);

    const handleVoucher = () => {
        dispatch(verifyVoucher(voucher, true));
    };

    const typeCode = (position, keyEvent) => {
        const { key } = keyEvent;

        if (key !== 'Backspace') {
            switch (position) {
            case 1:
                if (input1.current.value.length === 4) {
                    input2.current.focus();
                }
                break;
            case 2:
                if (input2.current.value.length === 4) {
                    input3.current.focus();
                }
                break;
            case 3:
                if (input3.current.value.length === 4) {
                    input4.current.focus();
                }
                break;
            case 4:
                if (key === 'Enter' && input3.current.value.length === 4) {
                    handleVoucher();
                }
                break;
            default:
                input1.current.focus();
                break;
            }
        }
    };


    const handlePaste = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const data = event.clipboardData.getData('Text');
        setVoucher(data);
        for (let index = 0; index < Math.floor(data.length / 4); index += 1) {
            switch (index + 1) {
            case 1:
                input1.current.value = data.substring(0, 4);
                input1.current.focus();
                break;
            case 2:
                input2.current.value = data.substring(4, 8);
                input2.current.focus();
                break;
            case 3:
                input3.current.value = data.substring(8, 12);
                input3.current.focus();
                break;
            case 4:
                input4.current.value = data.substring(12, 16);
                input4.current.focus();
                break;
            default:
                break;
            }
        }
    };

    const handleBackspace = (position, keyEvent) => {
        const { key } = keyEvent;
        if (key === 'Backspace') {
            switch (position) {
            case 2:
                if (input2.current.value === '') {
                    input1.current.focus();
                }
                break;
            case 3:
                if (input3.current.value === '') {
                    input2.current.focus();
                }
                break;
            case 4:
                if (input4.current.value === '') {
                    input3.current.focus();
                }
                break;
            default:
                break;
            }
        }
    };

    const handleInputChange = () => {
        const code = input1.current.value.toUpperCase() + input2.current.value.toUpperCase()
        + input3.current.value.toUpperCase() + input4.current.value.toUpperCase();
        setVoucher(code);
    };

    useEffect(() => {
        if (id) {
            setVoucher(id);
            dispatch(verifyVoucher(id, true));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (voucherVerification && voucherVerification.ableToUse) {
            history.push(`/voucher/${voucher.toUpperCase()}/login`);
        } else if (voucherVerification && voucherVerification.ableToUse === false) {
            Notify.warn(t('VOUCHER', { context: 'INVALID' }));
        }
    // eslint-disable-next-line
    }, [voucherVerification, history, t])

    return (
        <Container>
            <Logo><img src={KopaLogo} alt="kopalogo" /></Logo>
            <VoucherContainer>
                <Header>
                    <h1>{t('VOUCHER', { context: 'CODE_TITLE' })}</h1>
                    <h3>{t('VOUCHER', { context: 'CODE_SUBTITLE' })}</h3>
                </Header>
                <InputContainer>
                    <p>{t('VOUCHER', { context: 'CODE_TITLE' })}</p>
                    <div className="input-border" />
                    <div className="code">
                        <input
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            ref={input1}
                            onKeyDown={(key) => handleBackspace(1, key)}
                            onKeyUp={(key) => typeCode(1, key)}
                            onPaste={handlePaste}
                            type="text"
                            maxLength="4"
                            onChange={handleInputChange}
                        />
                        <input
                            ref={input2}
                            onKeyDown={(key) => handleBackspace(2, key)}
                            onKeyUp={(key) => typeCode(2, key)}
                            type="text"
                            maxLength="4"
                            onChange={handleInputChange}
                        />
                        <input
                            ref={input3}
                            onKeyDown={(key) => handleBackspace(3, key)}
                            onKeyUp={(key) => typeCode(3, key)}
                            type="text"
                            maxLength="4"
                            onChange={handleInputChange}
                        />
                        <input
                            ref={input4}
                            onKeyDown={(key) => handleBackspace(4, key)}
                            onKeyUp={(key) => typeCode(4, key)}
                            type="text"
                            maxLength="4"
                            onChange={handleInputChange}
                        />
                    </div>
                    <button
                        type="button"
                        disabled={!(voucher && voucher.length === 16)}
                        onClick={handleVoucher}
                    >
                        {t('VOUCHER', { context: 'BUTTON_SUBMIT' })}
                    </button>
                </InputContainer>
            </VoucherContainer>
        </Container>
    );
};

export default Voucher;
