import React from 'react';
import { useTranslation } from 'react-i18next';

import Chat from '../../components/Chat';
import AccordionVideoDetails from '../../components/AccordionVideoDetails';
import CONSTANTS from '../../assets/i18n/constants';
import RankingPlace from './RankingPlace';
import playButton from '../../assets/play.svg';

import { DescriptionContainer, VideoPlayer, MockVideo, Container } from './styles';
import OnboardingVideos from '../../components/Onboardings/onBoardingVideos';

const Course = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <OnboardingVideos />
            <VideoPlayer data-tour="tour__video" isOnboarding>
                <section className="video-container">
                    <div className="video-header">
                        <h5>{t('ONBOARDING_PRESENTATION_VIDEO')}</h5>
                        <RankingPlace />
                    </div>
                    <MockVideo>
                        <img src={playButton} alt="Play video" />
                    </MockVideo>
                    <div className="video-details">
                        <AccordionVideoDetails tour="tour__description" isOpen={false} title={t('ONBOARDING_VIDEO_INFORMATIONS_TITLE')}>
                            <DescriptionContainer>
                                {t('ONBOARDING_VIDEO_INFORMATIONS')}
                            </DescriptionContainer>
                        </AccordionVideoDetails>
                        <AccordionVideoDetails isOpen={false} title={t(CONSTANTS.ATTACHMENTS)} tour="tour__attachments" />
                    </div>
                </section>
            </VideoPlayer>
            <Chat tour="tour__chat" isOnboarding>
                <h1>{t('CHAT')}</h1>
            </Chat>
        </Container>
    );
};

export default Course;
