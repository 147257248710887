import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 3rem;
    background-color: #24243e !important;
    margin: .3rem 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
`;

export const SocialInput = styled.input.attrs({ color: '#fff' })`
    height: 100%;
    width: 88%;
    background-color: #24243e !important;    
    border: 0;
    padding: 0;
    color: #fff;
    font-weight: bold;
    font-size: 1.3rem;
`;

export const ImgContainer = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    background-color: #2d2d4c;
    margin: 0 .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;
