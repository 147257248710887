import styled from 'styled-components';

export const TermContainer = styled.div`
    width: 100%;
    height: 88%; 
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    embed {
        width: 100%;
        height: 100%;
    }    

    .MuiSvgIcon-root {
        color: white;
    }

    .pdf-container {
        max-width: 100%;
        max-height: 70vh;
        display: flex;
        justify-content: center;
        overflow: auto;

        .controls {
            display: flex; 
            justify-content: space-evenly;                        
            font-size: 12px;
            button {
                margin: 5px 0;
            }

            p {
                line-height: 3.5;
                color: white;                
            }
        }

        #right {
            float: right
        }
        
    }

    @media(max-width: 610px){
        .pdf-container {
            /* max-height: calc(100% - 62px); */
            .react-pdf__Page__canvas {
                /* width: 100%; */
            }
        }
    }
`;

export const TermsControl = styled.div`
    color: white;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 10px;

    button {
        display: flex;
        align-items: center;
        justify-content:center;        
        height: 40px;
        border: 0;
        width: 30%;        
        color:#fff;
        padding: 8px;
        background: linear-gradient(90deg, rgba(255,66, 141,1) 0%, rgba(255,174,70,1) 100%);
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: 0.21px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        &:hover {
            box-shadow: 0px 0px 10px -4px #000000;
            transform: scale(1.03);
        } 
        &:disabled {
            opacity: 0.2
        }
    }
`;
