import React, { useState } from 'react';
// import Searchbar from '../Searchbar'; vai ser implementado
import ProfileSection from '../ProfileSection';
import {
    HeaderContainer,
    HeaderContent,
    HeaderLeft,
    HeaderMiddle,
    HeaderRight,
} from './styles';
import MenuMobile from '../MenuMobile';
import Breadcrumbs from '../Breadcrumbs';
import api from '../../services/api';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import ProfileModal from './ProfileModal';

export function forcedLogoutExit() {
    localStorage.removeItem('KopaLive');
    api.clear();
    window.location.replace('/');
}

function Header() {
    const [confirmBlock, setConfirmBlock] = useState(false);
    const { breadcrumbs } = useBreadcrumbs();

    return (
        <HeaderContainer>
            <HeaderContent>
                <HeaderLeft>
                    <Breadcrumbs
                        items={breadcrumbs}
                    />
                </HeaderLeft>
                <HeaderMiddle>
                    {/* <Searchbar /> vai ser implementado */}
                </HeaderMiddle>
                <HeaderRight data-tour="tour__user">
                    <ProfileSection
                        setConfirmBlock={setConfirmBlock}
                    />
                </HeaderRight>
            </HeaderContent>
            <MenuMobile
                setConfirmBlock={setConfirmBlock}
            />
            <ProfileModal
                confirmBlock={confirmBlock}
                setConfirmBlock={setConfirmBlock}
            />
        </HeaderContainer>
    );
}

export default Header;
