/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import {
    Container,
    VideoPreview,
    DevicesContainer,
    DeviceList,
    DeviceType,
    Device,
    StartTransmission,
    AccessCameraDenied,
    StartTransmissionHeading,
} from './styles';

import useRooms from '../../../hooks/useRooms';
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs';
import webRTCSocket from '../../../services/WebRTCSocket';
import api from '../../../services/api';
import mediaSoupService from '../../../services/MediaSoupProducerService';
import noVideoIcon from '../../../assets/no-video.svg';
import producerMicIcon from '../../../assets/producer-mic-icon.svg';
import producerMicMuteIcon from '../../../assets/producer-mic-mute-icon.svg';
import producerCamIcon from '../../../assets/producer-cam-icon.svg';
import producerCamMuteIcon from '../../../assets/producer-cam-mute-icon.svg';
import producerDownIcon from '../../../assets/arrow-down-icon.svg';
import startTransmissionIcon from '../../../assets/start-transmission-icon.svg';
import StartTransmissionContainer from './StartTransmissionContainer';

import useSize from '../../../hooks/useSize';
import ConcurrentUser from '../ConcurrentUser';
import { getRoomPriorityVideoUrl } from '../../../store/modules/rooms/actions';

const constraints = { video: true, audio: true };
const VIDEO_AVAILABLE = 'available';
const VIDEO_AVAILABLE_ANY = 'any';
const VIDEO_PLAYING_ELSEWHERE = 'playing_elsewhere';
const RECONNECTION_INTERVAL = 3000;

export default function UserMediaTest({ isTransmitting, setIsTransmitting }) {
    const { t } = useTranslation();
    const { selectedRoom, videoUrl, socketPath, liveStatus } = useRooms();
    const { video_availability, id: roomId } = selectedRoom;
    const dispatch = useDispatch();
    const { setItems } = useBreadcrumbs();
    const { width } = useSize();

    const videoWrapper = useRef();
    const videoElement = useRef();
    const audioElement = useRef();
    const videoStream = useRef();
    const audioStream = useRef();
    const selectedCam = useRef(null);
    const selectedMic = useRef(null);
    const reconnectTimer = useRef(null);

    const [isCamMute, setIsCamMute] = useState(false);
    const [isMicMute, setIsMicMute] = useState(false);
    const [isLive, setIsLive] = useState(false);

    const [showMicDevices, setShowMicDevices] = useState(false);
    const [showCamDevices, setShowCamDevices] = useState(false);

    const [audioInputs, setAudioInputs] = useState([]);
    const [videoInputs, setVideoInputs] = useState([]);

    const [microfonePermissionDenied, setMicrofonePermissionDenied] = useState(true);
    const [cameraPermissionDenied, setCameraPermissionDenied] = useState(true);
    const [startingLive, setStartingLive] = useState(false);

    const accessToken = api.getAccessToken();

    function stopWebSocketReconnection() {
        if (reconnectTimer.current) {
            clearInterval(reconnectTimer.current);
        }
    }

    const handleProduce = useCallback(async () => {
        if (mediaSoupService._transport) {
            mediaSoupService._transport.close();
            mediaSoupService._transport = null;
        }
        mediaSoupService.setSocket(webRTCSocket.socket);
        mediaSoupService.setAudioStream(audioStream.current);
        mediaSoupService.setVideoStream(videoStream.current);
        mediaSoupService.setKinds(constraints);
        await mediaSoupService.connect();
    }, []);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: '/home', label: t('WELCOME_TO_KOPA') },
            { to: `/course/${selectedRoom?.id}`, label: selectedRoom?.name },
        ];
        setItems(width >= 450 ? breadcrumbsItems : breadcrumbsItems.splice(1, 1));
    }, [selectedRoom, setItems, t, width]);

    const getUserMediaAudio = useCallback(async () => {
        let audioConstraints;

        if (audioStream.current) {
            audioStream.current.getTracks().forEach(track => track.stop());
        }

        if (selectedMic.current) {
            audioConstraints = { audio: { deviceId: { exact: selectedMic.current } } };
        } else {
            audioConstraints = { audio: true };
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter(device => device.kind === 'audioinput');
            setMicrofonePermissionDenied(false);

            audioStream.current = stream;
            const defaultAudioDevice = audioDevices[0];
            setAudioInputs(audioDevices);

            if (audioElement.current) {
                audioElement.current.srcObject = stream;
            }

            if (!selectedMic.current) {
                selectedMic.current = defaultAudioDevice.deviceId;
            }

            // change audio device
            if (audioInputs.length > 1
                && mediaSoupService._stream.audio
                && mediaSoupService._stream.audio !== stream
                && mediaSoupService._stream.video !== stream
                && !mediaSoupService._transport?.closed) {
                mediaSoupService._audioProducer.replaceTrack({ track: audioStream.current.getAudioTracks()[0] });
            }
        } catch (error) {
            setMicrofonePermissionDenied(true);
            console.log(error);
        }
    }, [audioInputs.length]);

    const getUserMediaVideo = useCallback(async () => {
        let videoConstraints;

        if (videoStream.current) {
            videoStream.current.getTracks().forEach(track => track.stop());
        }

        if (selectedCam.current) {
            videoConstraints = { video: { deviceId: { exact: selectedCam.current } } };
        } else {
            videoConstraints = { video: true };
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setCameraPermissionDenied(false);

            const defaultVideoDevice = videoDevices[0];
            videoStream.current = stream;
            setVideoInputs(videoDevices);

            if (!selectedCam.current) {
                selectedCam.current = defaultVideoDevice.deviceId;
            }

            // change video device
            if (videoInputs.length > 1
                && mediaSoupService._stream.video
                && mediaSoupService._stream.video !== stream
                && !mediaSoupService._transport?.closed) {
                mediaSoupService._videoProducer.replaceTrack({ track: videoStream.current.getVideoTracks()[0] });
            }
            if (videoElement.current) {
                videoElement.current.srcObject = stream;
            }
        } catch (error) {
            setCameraPermissionDenied(true);
            console.log(error);
        }
    }, [videoInputs.length]);

    useEffect(() => {
        if (!audioStream.current) {
            getUserMediaAudio();
        }
    }, [getUserMediaAudio, video_availability]);

    useEffect(() => {
        if (!videoStream.current) {
            getUserMediaVideo();
        }
    }, [getUserMediaVideo, video_availability]);

    async function dismissUserMedia() {
        if (videoElement.current) videoElement.current.pause();
        if (audioElement.current) audioElement.current.pause();
        if (videoStream.current) videoStream.current.getTracks().forEach(track => track.stop());
        if (audioStream.current) audioStream.current.getTracks().forEach(track => track.stop());
        videoStream.current = null;
        audioStream.current = null;
    }

    useEffect(() => () => dismissUserMedia(), []);

    async function handleMuteCamera() {
        const track = videoStream.current?.getVideoTracks()[0];
        if (track) {
            track.enabled = !track.enabled;
            setIsCamMute(!track.enabled);

            if (!mediaSoupService._videoProducer) {
                console.log('No video producer found!');
                return;
            }

            if (mediaSoupService._videoProducer.paused) {
                mediaSoupService._videoProducer.resume();
            } else {
                mediaSoupService._videoProducer.pause();
            }
        }
    }

    async function handleMuteMicrofone() {
        const track = audioStream.current?.getAudioTracks()[0];

        if (track) {
            track.enabled = !track.enabled;
            setIsMicMute(!track.enabled);

            if (!mediaSoupService._audioProducer) {
                console.log('No audio producer found!');
                return;
            }

            if (mediaSoupService._audioProducer.paused) {
                mediaSoupService._audioProducer.resume();
            } else {
                mediaSoupService._audioProducer.pause();
            }
        }
    }

    const handleConnectWebSocket = useCallback(() => {
        if (!webRTCSocket.connected) {
            setStartingLive(true);
            webRTCSocket.authenticate(videoUrl, socketPath, accessToken);
        }
    }, [accessToken, socketPath, videoUrl]);

    function handleStartTransmission() {
        if (cameraPermissionDenied) {
            setIsTransmitting(true);
        } else if (webRTCSocket.socket?.connected) {
            handleProduce();
            setIsLive(true);
        } else {
            handleConnectWebSocket();
            handleProduce();
            setIsLive(true);
        }
    }

    useEffect(() => () => {
        if (mediaSoupService._transport) mediaSoupService._transport.close();
        if (videoStream.current) videoStream.current.getTracks().forEach(track => track.stop());
        if (audioStream.current) audioStream.current.getTracks().forEach(track => track.stop());
        webRTCSocket.disconnect();
    }, []);

    const startWebSocketReconnection = useCallback(() => {
        reconnectTimer.current = setInterval(() => {
            if (!webRTCSocket.socket?.connected && isLive) {
                handleConnectWebSocket();
            } else if (webRTCSocket.socket?.connected && mediaSoupService.state === 'disconnected' && isLive) {
                handleProduce();
            }
        }, RECONNECTION_INTERVAL);
    }, [handleConnectWebSocket, handleProduce, isLive]);

    useEffect(() => {
        mediaSoupService.onConnectListener = () => {
            stopWebSocketReconnection();
            setIsTransmitting(true);
        };
        mediaSoupService.onDisconnectListener = () => {
            startWebSocketReconnection();
        };
    }, [setIsTransmitting, startWebSocketReconnection]);

    window.handleConnectWebSocket = handleConnectWebSocket;
    window.handleProduce = handleProduce;
    window.startWebSocketReconnection = startWebSocketReconnection;
    window.setIsTransmitting = setIsTransmitting;

    async function handleChangeCamera(deviceId) {
        selectedCam.current = deviceId;
        getUserMediaVideo();
    }

    async function handleChangeMicrofone(deviceId) {
        selectedMic.current = deviceId;
        getUserMediaAudio();
    }

    function handleShowMicDevices() {
        setShowMicDevices(!showMicDevices);
        if (showCamDevices) {
            setShowCamDevices(false);
        }
    }

    function handleShowCamDevices() {
        setShowCamDevices(!showCamDevices);
        if (showMicDevices) {
            setShowMicDevices(false);
        }
    }

    const getPriorityVideoUrl = async () => {
        dismissUserMedia();
        dispatch(getRoomPriorityVideoUrl(roomId));
    };

    if (isTransmitting) {
        return null;
    }

    return (
        <Container>
            <VideoPreview>

                <section className="video-container">
                    {video_availability === VIDEO_PLAYING_ELSEWHERE && (
                        <ConcurrentUser
                            getPriorityVideoUrl={getPriorityVideoUrl}
                        />
                    )}
                    {(video_availability === VIDEO_AVAILABLE || video_availability === VIDEO_AVAILABLE_ANY) && (
                        <>
                            <div className="video-header">
                                {isLive ? <span>{t('LIVE')}</span> : <div />}
                                <h5>{t('SETTINGS_AUDIO_VIDEO')}</h5>
                                <div>
                                    {' '}
                                </div>
                            </div>
                            <div className="video-player" ref={videoWrapper}>
                                {
                                    cameraPermissionDenied && (
                                        <AccessCameraDenied>
                                            <img src={noVideoIcon} alt="No video available" />
                                            <h2>{t('OPS')}</h2>
                                            <h3>{t('ALLOW_USER_MEDIA_ACCESS')}</h3>
                                        </AccessCameraDenied>
                                    )
                                }
                                {
                                    !cameraPermissionDenied && (
                                        <video ref={videoElement} autoPlay playsInline />
                                    )
                                }
                                {
                                    !microfonePermissionDenied && (
                                        <audio ref={audioElement} autoPlay muted />
                                    )
                                }
                            </div>
                            <div className="video-details">
                                <DevicesContainer className={isLive ? 'is-live' : ''}>
                                    <div>
                                        <OutsideClickHandler onOutsideClick={() => setShowMicDevices(false)}>
                                            <div className="device-wrapper">
                                                {showMicDevices && (
                                                    <DeviceList className="device-list">
                                                        <DeviceType>{t('MICROFONES')}</DeviceType>
                                                        <ul>
                                                            {
                                                                audioInputs?.map((device, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className={selectedMic.current === device.deviceId ? 'active' : ''}
                                                                        onClick={() => handleChangeMicrofone(device.deviceId)}
                                                                    > {device?.label}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </DeviceList>
                                                )}

                                                <button
                                                    type="button"
                                                    disabled={microfonePermissionDenied}
                                                    className={showMicDevices ? 'list-devices up' : 'list-devices'}
                                                    onClick={handleShowMicDevices}
                                                >

                                                    <img className="show-device-arrow" src={producerDownIcon} alt="show device" />
                                                </button>

                                                <Device onClick={handleMuteMicrofone}>

                                                    {microfonePermissionDenied || isMicMute ? (
                                                        <img src={producerMicMuteIcon} alt="Microfone" />
                                                    ) : (
                                                        <img src={producerMicIcon} alt="Microfone" />
                                                    )}
                                                </Device>
                                            </div>
                                        </OutsideClickHandler>

                                        {/* camera */}
                                        <OutsideClickHandler onOutsideClick={() => setShowCamDevices(false)}>
                                            <div className="device-wrapper">
                                                {showCamDevices && (
                                                    <DeviceList className="device-list">
                                                        <DeviceType>{t('CAMERAS')}</DeviceType>
                                                        <ul>
                                                            {
                                                                videoInputs?.map((device, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className={selectedCam.current === device.deviceId ? 'active' : ''}
                                                                        onClick={() => handleChangeCamera(device.deviceId)}
                                                                    > {device?.label}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </DeviceList>
                                                )}

                                                <button
                                                    type="button"
                                                    disabled={cameraPermissionDenied}
                                                    className={showCamDevices ? 'list-devices up' : 'list-devices'}
                                                    onClick={handleShowCamDevices}
                                                >

                                                    <img className="show-device-arrow" src={producerDownIcon} alt="show device" />
                                                </button>

                                                <Device className={isCamMute ? 'mute-device' : ''} onClick={handleMuteCamera}>
                                                    {cameraPermissionDenied || isCamMute ? (
                                                        <img src={producerCamMuteIcon} alt="Camera" />
                                                    ) : (
                                                        <img src={producerCamIcon} alt="Camera" />
                                                    )}
                                                </Device>
                                            </div>
                                        </OutsideClickHandler>
                                    </div>

                                </DevicesContainer>
                            </div>

                        </>
                    )}
                </section>
            </VideoPreview>

            {(video_availability === VIDEO_AVAILABLE || video_availability === VIDEO_AVAILABLE_ANY) && (
                <StartTransmissionContainer>
                    <StartTransmissionHeading>
                        <img src={startTransmissionIcon} alt="start transmission icon" />
                        <h2>{t('WELCOME_TO_ROOM_INTERACTIVE')}</h2>
                        <p>{t('ROOM_INTERACTIVE_INFORMATIONS')}</p>
                        <StartTransmission disabled={!socketPath || microfonePermissionDenied || cameraPermissionDenied || startingLive || liveStatus !== t('on')} onClick={handleStartTransmission}>
                            {liveStatus !== t('on') ? t('OFFLINE_ROOM') : t('ENTER_ROOM')}
                        </StartTransmission>
                    </StartTransmissionHeading>
                </StartTransmissionContainer>
            )}

        </Container>
    );
}
