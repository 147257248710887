import { useSelector, shallowEqual } from 'react-redux';

function roomsProgressSelector({ roomsProgress }) {
    return { videos: roomsProgress.videos };
}

export default function useRoomsProgress() {
    const { videos } = useSelector(roomsProgressSelector, shallowEqual);
    return { videos };
}
