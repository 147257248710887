import React, { useState, useEffect } from 'react';
import {    Container, 
            Logo, 
            HeaderContainer, 
            Divider, 
            Step, 
            FinishContainer } from './styles';
import KopaLogo from '../../../assets/kopa-logo.svg';
import CheckedIcon from '../../../assets/checked-step.svg';
import CorrectIcon from '../../../assets/correct2.svg';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { verifyVoucher } from '../../../store/modules/vouchers/actions';
import useVouchers from '../../../hooks/useVoucher';
import VoucherTerms from '../../../components/Vouchers/Terms';
import VoucherData from '../../../components/Vouchers/UserData';
import VoucherValidation from '../../../components/Vouchers/ValidateVoucher';
import VoucherPassword from '../../../components/Vouchers/Password';
import { useHistory } from 'react-router-dom';

const VoucherFinish = () => {
    const { t } = useTranslation();

    return (
        <FinishContainer>
            <div className='finish'>
                <div>
                    <img alt="Correct Icon" src={CorrectIcon} />
                    <h1>{t('VOUCHER', {context: 'REGISTER_TITLE'})}</h1>
                    <h4>{t('VOUCHER', {context: 'REGISTER_MESSAGE_SUCCESS'})}</h4>
                </div>
            </div>  
        </FinishContainer>
    )
}

const Header = ({currentStep}) => {
    const {t} = useTranslation();
    const stepNames = t('VOUCHER', {context: 'STEPS', returnObjects: true })

    return (
        <HeaderContainer >
            {stepNames.map((step, index) => {
                return (
                    <React.Fragment key={index}>
                        <Step  stepSrc={CheckedIcon} stepIndex={currentStep} index={index}>{currentStep > index ? 
                            <img alt="Checked Icon" src={CheckedIcon}></img> : 
                            index + 1 + '. '}
                            {step}
                        </Step>
                        {index < 3 ? <Divider index={index} stepIndex={currentStep}/> : ''}
                    </React.Fragment>
                )
            })}
        </HeaderContainer>
    )
}

const VoucherRegister = () => {
    // 0 - Terms, 1 - Data, 2 - Validation, 3 - Password, 4 - Finished
    const [currentStep, setCurrentStep] = useState(0)
    const { voucherVerification } = useVouchers();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        renderRegister();
    //eslint-disable-next-line
    }, [currentStep])

    useEffect(() => {
      if (voucherVerification && voucherVerification.step) {
        setCurrentStep(voucherVerification.step)
      } else if (voucherVerification && voucherVerification.ableToUse === false) {
        history.push('/voucher/')
      }

    }, [voucherVerification, history])

    useEffect(() => {
      if (id) {
        dispatch(verifyVoucher(id, true))          
      }
    //eslint-disable-next-line
    }, [dispatch, id])

    function renderRegister() {
        switch (currentStep) {
            case 0: return <VoucherTerms setCurrentStep={setCurrentStep}/>
            case 1: return <VoucherData />
            case 2: return <VoucherValidation 
                                        voucherId={id}
                                        emailResend={voucherVerification.userInfo.username} 
                                        setCurrentStep={setCurrentStep}/>
            case 3: return <VoucherPassword username={voucherVerification.userInfo.username} 
                                        voucherId={id}
                                        setCurrentStep={setCurrentStep}/>
            case 4: return <VoucherFinish voucherId={id} />
            default: return <VoucherTerms setCurrentStep={setCurrentStep}/>
        }
    }

    return (
        <Container>
            <Logo><img src={KopaLogo} alt="kopalogo" /></Logo>
            {currentStep < 4 ? <Header currentStep={currentStep}/> : ''}
            { renderRegister(currentStep) }
        </Container>
    )
}

export default VoucherRegister;