import history from '../services/history';
import { translate } from '../utils/functions';
import Notify from '../utils/notification';

/**
 * @param {any} error
 * @param {{default: string, [number]: string}} handlers [status]: message, default: message
 */
export function defaultErrorHandler(error, handlers) {
    let message = translate('ERROR_NONETWORK');

    if (error.response) {
        const { status } = error.response;
        message = handlers[status];
        if (!message) {
            message = handlers.default ? handlers.default : translate('ERROR_DEFAULT');
        }

        Notify.warn(message);

        if (status === 401) {
            history.push('/forcedLogout');
        }
    } else {
        Notify.warn(message);
    }

    return message;
}
