import styled from 'styled-components';

export const RankingPlaceContainer = styled.button`
    display: flex;    
    height: 100%;
    align-items: center;
    margin: 0 10px;
    background: none;
    border: none;

    img {
        height: 100%;
        margin-right: 10px;
    }

    .info {
        display: flex;
        flex-direction: column;
    }

    h4 {
        font-size: clamp(10px, 0.4em + 0.6vw, 1em);
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
    }

    small {
        text-transform: uppercase;
        color: #ff8f63;
        font-weight: bold;
        font-size: clamp(8px,0.4em + 0.5vw,0.8em);
    }

    @media (max-width: 400px){
        img {
            display: none;
        }
    }
`;
