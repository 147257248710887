import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery() {
    const [query, setQuery] = useState({ get: () => { } });
    const { search } = useLocation();
    useEffect(() => {
        setQuery(new URLSearchParams(search));
    }, [search]);

    return query;
}
