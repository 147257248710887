import React, { useState } from 'react';

import { Container, AInput, ImageIco } from './styles';

const AuthInput = ({
    type,
    autoComplete,
    value,
    onChange,
    onKeyDown,
    passwordIcon,
    onKeyUp,
    placeholder,
    onBlur,
    alertMessage,
}) => {
    const [hide, setHide] = useState(true);
    return (
        <Container>
            {
                passwordIcon
                    && (
                        <ImageIco
                            onClick={() => setHide(!hide)}
                            src={require(`../../assets/${hide ? 'eye-color' : 'eye-hide-color'}.svg`)}
                            alt="ico"
                        />
                    )
            }

            {
                alertMessage
                    && (
                        <ImageIco
                            onClick={() => setHide(!hide)}
                            src={require('../../assets/alert.svg')}
                            alt="ico"
                        />
                    )
            }

            <AInput
                placeholder={placeholder}
                type={type === 'password' && hide ? 'password' : type === 'password' && !hide ? 'text' : type === 'email' && 'email'}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                onKeyUp={onKeyUp}
                onBlur={onBlur}
            />
        </Container>
    );
};

export default AuthInput;
