import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pinIcon from '../../../assets/pinned.svg';
import loadingIcon from '../../../assets/loading-white.svg';
import {
    MessageContainer,
    AuthorIdentificationSection,
    MessageName,
    TextContainer,
    MessageText,
    Options,
    BlockedUserText,
    BlockUserButton,
} from './styles';
import { pinMessage } from '../../../store/modules/rooms/actions';
import useRooms from '../../../hooks/useRooms';
import useAuth from '../../../hooks/useAuth';
import useUserType from '../../../hooks/useUserType';
import Notify from '../../../utils/notification';
import blockIcon from '../../../assets/block-icon.svg';
import { user_permissions } from '../../../utils/constants';

const Message = ({ message, colorIndex, requestBlockUser }) => {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { pinnedMessages } = useRooms();
    const { userPermissions } = useUserType();
    const [loadingPin, setLoadingPin] = useState(false);

    const [userWithPrivileges, setUserWithPrivileges] = useState(false);
    const [userOrganizer, setUserOrganizer] = useState(false);
    const [userMonitor, setUserMonitor] = useState(false);
    const { t } = useTranslation();

    // Polyfill para browsers que nao suportam nativamente
    if (!Array.isArray) {
        Array.isArray = function (arg) {
            return Object.prototype.toString.call(arg) === '[object Array]';
        };
    }

    useEffect(() => {
        if (Array.isArray(message.permissions)) {
            const isOrganizer = message.permissions?.find((item) => item === user_permissions.ORGANIZER);
            setUserOrganizer(isOrganizer);
            const isMonitor = message.permissions?.find((item) => item === user_permissions.MONITOR);
            setUserMonitor(isMonitor);
            const isAdmin = message.permissions?.find((item) => item === user_permissions.ADMIN);
            const hasPrivileges = isOrganizer || isMonitor || isAdmin;
            setUserWithPrivileges(hasPrivileges);
        }
    }, [message.permissions]);

    const pinHandler = () => {
        if (pinnedMessages?.length >= 2) {
            Notify.warn(t('MAX_PIN_MESSAGE_LIMIT'));
            return;
        }
        setLoadingPin(true);
        if (!pinnedMessages
            || (pinnedMessages.length < 2 && !pinnedMessages.some((element) => element.message === message.messageText))) {
            dispatch(pinMessage(message.roomId, message.messageId));
        }
    };

    useEffect(() => {
        setLoadingPin(false);
    }, [pinnedMessages]);

    function handleRequestBlockUser(message) {
        requestBlockUser(message, true);
    }

    return (
        <MessageContainer colorIndex={colorIndex}>
            <TextContainer>
                <AuthorIdentificationSection>
                    <MessageName isMonitor={userMonitor}>{message.messageSender}</MessageName>
                    <span className="--monitor">{userMonitor && !userOrganizer && 'MONITOR'}</span>
                </AuthorIdentificationSection>
                <MessageText>{message.messageText}</MessageText>
                {
                    userPermissions && userPermissions?.organizer && user.id !== message.userId && !userWithPrivileges && (
                        message.blocked
                            ? (<BlockedUserText>{t('BLOCKED_USER_CHAT')}</BlockedUserText>)
                            : (
                                <BlockUserButton onClick={() => handleRequestBlockUser(message)}>
                                    <img src={blockIcon} className="block-icon" height={10} alt="bloquear usuário" />
                                    {t('BLOCK')} {t('USER')}
                                </BlockUserButton>
                            )
                    )
                }
            </TextContainer>
            {
                userPermissions
                && userPermissions.organizer
                && !pinnedMessages.some(pinnedMessage => pinnedMessage.messageId === message.messageId)
                && (
                    <Options>
                        {loadingPin
                            ? <img alt="Loading Icon" className="loading" src={loadingIcon} />
                            : <img alt="Pin Icon" onClick={pinHandler} src={pinIcon} />}
                    </Options>
                )
            }
        </MessageContainer>
    );
};

export default React.memo(Message);
