import React from 'react';
import { useTranslation } from 'react-i18next';
import noVideo from '../../../assets/no-video.svg';
import { ExtraUserInfoContainer, NoVideoImage, TextArea } from './styles';

export default function ExtraUserInfo({ quizComponent = null }) {
    const { t } = useTranslation();
    return (
        <ExtraUserInfoContainer>
            {
                quizComponent || (
                    <>
                        <NoVideoImage>
                            <img src={noVideo} alt="Câmera" />
                        </NoVideoImage>
                        <TextArea>
                            <h1>{t('VIDEO_UNAVAILABLE')}</h1>
                            <p>{t('LIVE_ACCESS_DENIED')}</p>
                        </TextArea>
                    </>

                )
            }
        </ExtraUserInfoContainer>
    );
}
