import styled from 'styled-components';

export const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

    h1 {
        @media(max-width: 500px) {
            font-size: 18px;
        }
    }

    .border {
        background-image: linear-gradient(to left, #ffae45, #ff428d 60%);
        width: 5%;
        height: 3px;
    }

    .password {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50%;
        margin: 3% 0 0;

        @media(max-width: 800px) {
            width: 80%; 
        }

        .password-input {
            width: 100%;
            display: flex;
            width: 100%;
            justify-content: space-between;

            div {
                width: 49%;
                display: flex;
                flex-direction: column;
                text-align: left;
                font-weight: 800;

                label {
                    margin-bottom: 2%;
                }
            }
        }

        input {
            background-color: #393737;
            border: 0;
            border-radius: 0;
            height: 60px;
            color: white;
            font-size: 20px;
            font-weight: 800;
        }

        button {
            width: 200px;
            height: auto;
            margin: 40px auto 50px;
            padding: 15px;
            border: 2px solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
    
            &:hover {
                background: transparent;
                border: 2px solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(to left, #FF428D, #FFAE45);
                color: #FFF;
            }
        }

    }

    @media(max-width: 500px) {
        button { 
            width: 100% !important;
        }

        .password {
            width: 90%;

            .password-input {
                flex-direction: column;

                div {
                    width: 100%;
                }
            }
        }
    }
  @media(max-width: 800px) {
    .border {
      width: 35%;
    }
  }
`;