import { all, put, takeLatest } from 'redux-saga/effects';

import api from '../../../services/api';
import actions from '../../../utils/actions';
import { defaultErrorHandler } from '../../utilities';
import { translate } from '../../../utils/functions';

function* getOffers({ payload: roomId }) {
    try {
        const data = yield api.getOffers(roomId);
        yield put({ type: actions.SET_OFFERS, payload: data });
    } catch (error) {
        yield put({ type: actions.FINISH_OFFER_REQUEST });
        defaultErrorHandler(error, { default: translate('FAIL_GETOOFFER')});
    }
}

function* getOfferUrl({ payload: roomId }) {
    try {
        const { personal_url } = yield api.getOfferUrl(roomId);
        yield put({ type: actions.SET_OFFER_URL, payload: personal_url });
    } catch (error) {
        yield put({ type: actions.FINISH_OFFER_REQUEST });
        defaultErrorHandler(error, { default: translate('FAIL_GETOFFER') });
    }
}

function* setOffersStatus( {payload} ) {
    const { status, roomId } = payload;
    try {
        yield api.setOffersStatus(status, roomId);

        if (status) {
            yield put({ type: actions.GET_OFFERS, payload: roomId });
        }
    } catch (error) {
        yield put({ type: actions.FINISH_OFFER_REQUEST });
        defaultErrorHandler(error, { default: translate('FAIL_CHANGEOFFERSTATUS') });
    }
}

function* createOffer({ payload }) {
    const { roomId, templateUrl, useSalesforce } = payload;
    try {
        yield api.createOffer(roomId, templateUrl, useSalesforce);
        const data = yield api.getOffers(roomId);
        yield put({ type: actions.SET_OFFERS, payload: data });
    } catch (error) {
        yield put({ type: actions.FINISH_OFFER_REQUEST });
        defaultErrorHandler(error, { default: translate('FAIL_GETOFFER') });
    }
}

export default all([
    takeLatest(actions.GET_OFFERS, getOffers),
    takeLatest(actions.GET_OFFER_URL, getOfferUrl),
    takeLatest(actions.SET_OFFERS_STATUS, setOffersStatus),
    takeLatest(actions.CREATE_OFFER, createOffer),
]);
