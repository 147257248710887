/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef, Suspense, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { VideoPlayer as TokyoPlayer } from 'react-tokyo';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import screenfull from 'screenfull';
import { useInterval } from 'react-use';

import CONSTANTS from '../../assets/i18n/constants';
import Chat from '../../components/Chat';
import { Container, DescriptionContainer, VideoPlayer } from './styles';
import {
    fetchRoom, exitRoom, getQuizInfo, answerQuiz, setQuiz, getRoomPriorityVideoUrl,
} from '../../store/modules/rooms/actions';
import { getOfferUrl } from '../../store/modules/offers/actions';
import useOffers from '../../hooks/useOffers';
import { TIMERS } from '../../utils/constants';
import ChatSocket from '../../services/ChatSocket';
import useRooms from '../../hooks/useRooms';
import Quiz from '../../components/Quiz';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import AccordionVideoDetails from '../../components/AccordionVideoDetails';
import RankingPlace from './RankingPlace';
import VideoAttachment from './VideoAttachment';
import ExtraUserInfo from './ExtraUserInfo';
import Top10 from './Top10';
import {
    getPersonalRanking,
    getGamificationCourseStatus,
    cleanGamificationCourseStatus,
} from '../../store/modules/ranking/actions';
import useSize from '../../hooks/useSize';
import useRanking from '../../hooks/useRanking';
import ConcurrentUser from './ConcurrentUser';
import Loading from '../../components/Loading';
import VideoPlayerSkeleton from '../../components/VideoPlayerSkeleton';
import { isIOS } from '../../utils/functions';
import QuizContainer from '../../components/QuizContainer';
import UserMediaSettings from './UserMediaSettings';
import Modal from '../../components/Modal';
import UserMediaTest from './UserMediaTest';
import mediaSoup from '../../services/MediaSoupProducerService';
import ButtonMediaConfig from '../../components/ButtonMediaConfig';
import configIco from '../../assets/config-icon-white.svg';
import NoVideo from './NoVideo';
import OverlayVideoControls from '../../components/OverlayVideoControls';

const WebRTCPlayer = React.lazy(() => import('../../components/WebRTCPlayer'));

function selector({ room, login }) {
    return {
        room: room.selectedRoom,
        user: login.user,
        isChatEnabled: room.isChatEnabled,
        saleEnabled: room.saleEnabled,
    };
}

function getRoomId(history) {
    const path = history.location.pathname;
    return path.split('/')[2];
}

const quizPlacement = {
    SCREEN: 'SCREEN',
    CHAT: 'CHAT',
};

const webRtcTechnologyType = 1;
const idleTime = 1500;

const Course = () => {
    const [collapsedDescription, setCollapsedDescription] = useState(false);
    const [collapsedAttachments, setCollapsedAttachments] = useState(false);
    const [isPlayerInFullScreenMode, setIsPlayerInFullScreenMode] = useState(false);
    const [showTop10, setShowTop10] = useState(false);
    const [quizMode, setQuizMode] = useState(quizPlacement.CHAT);
    const [qrcodeSource, setQrcodeSource] = useState();
    const [showInteractiveMediaSettings, setShowInteractiveMediaSettings] = useState(false);
    const [isInteractiveUserLive, setIsInteractiveUserLive] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { roomId } = useParams();
    const { t } = useTranslation();
    const { room, user, isChatEnabled, saleEnabled } = useSelector(selector, shallowEqual);
    const videoWrapper = useRef();

    const { videoUrl, live, description, attachments, video_availability } = room || {};
    const { userId } = user || {};
    const { qrcodeUrl } = useOffers();
    const {
        quiz, selectedRoom, technology, videoUrl: webRtcVideoUrl, socketPath, interactive,
    } = useRooms();
    const { hasGamification, loadingGamificationStatus } = useRanking();
    const { setItems } = useBreadcrumbs();
    const { width } = useSize();

    const [show, setShow] = useState(false);
    const timeoutRef = useRef();

    const player = document.getElementById('player-container');
    const iframe = document.querySelector('#player_ifp');
    const holder = document.querySelector('.video-container');
    const SelectedCourseId = selectedRoom?.courseId;
    const showQuizOverPlayerScreen = live
     && quiz?.release
     && quizMode === quizPlacement.SCREEN
     && quiz?.roomId === selectedRoom?.id
     && !quiz?.is_answered;

    useEffect(() => {
        // Atualiza a sala quando socket é reconectado
        const removeListener = ChatSocket.addConnectedListener(() => {
            dispatch(fetchRoom(getRoomId(history)));
        });

        return function cleanup() {
            removeListener();
        };
    }, [dispatch, history]);

    useEffect(() => {
        dispatch(fetchRoom(getRoomId(history)));

        return function cleanup() {
            dispatch(exitRoom());
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (saleEnabled) {
            dispatch(getOfferUrl(getRoomId(history)));
        }
    }, [saleEnabled, room, dispatch, history]);

    useEffect(() => {
        if (saleEnabled && qrcodeUrl) {
            QRCode.toDataURL(qrcodeUrl, {}, (err, url) => {
                setQrcodeSource(url);
            });
        } else {
            setQrcodeSource('');
        }
    }, [qrcodeUrl, saleEnabled]);

    function maintainTimeout() {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setShow(false), idleTime);
    }

    const onMouseMove = useCallback(() => {
        setShow(true);
        maintainTimeout();
    }, []);

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    useEffect(() => {
        if (technology !== webRtcTechnologyType) {
            const watermarkChecker = videoUrl && setInterval(() => {
                if (player) {
                    const watermarkNode = document.getElementById('watermark');
                    if (!watermarkNode || watermarkNode.getAttribute('hidden') || watermarkNode.style.display === 'none') {
                        history.push('/forcedLogout');
                    }
                }
            }, TIMERS.WATERMARK_CHECK);

            return () => {
                clearInterval(watermarkChecker);
            };
        }
        return () => null;
        // eslint-disable-next-line
    }, [videoUrl, technology])

    useEffect(() => {
        if (room?.ActiveQuestionId) {
            dispatch(getQuizInfo(room?.ActiveQuestionId));
        }
    }, [dispatch, room]);

    function handleSelectAnswer({ id: questionId, answer }) {
        dispatch(answerQuiz(questionId, answer));
    }

    const getGamificationInfo = useCallback((courseId, hasGamification) => {
        dispatch(getGamificationCourseStatus(courseId));

        if (hasGamification) {
            dispatch(getPersonalRanking(courseId));
        }
    }, [dispatch]);

    useEffect(() => {
        if (selectedRoom?.courseId) {
            getGamificationInfo(selectedRoom.courseId, hasGamification);
        }
    }, [getGamificationInfo, hasGamification, selectedRoom]);

    useEffect(() => {
        if (quiz?.duration || quiz?.duration === 0) {
            const closeQuizTimer = setTimeout(() => {
                dispatch(setQuiz(null));
            }, (quiz?.duration * 1000));
            return () => clearTimeout(closeQuizTimer);
        }
        return undefined;
    }, [dispatch, quiz]);

    useEffect(() => {
        if (isPlayerInFullScreenMode) {
            setQuizMode(quizPlacement.SCREEN);
        } else {
            setQuizMode(quizPlacement.CHAT);
        }
    }, [isPlayerInFullScreenMode]);

    useEffect(() => {
        const breadcrumbsItems = [
            { to: '/home', label: t('WELCOME_TO_KOPA') },
            { to: `/course/${selectedRoom?.id}`, label: selectedRoom?.name },
        ];
        setItems(width >= 450 ? breadcrumbsItems : breadcrumbsItems.splice(1, 1));
    }, [selectedRoom, setItems, t, width]);

    useInterval(() => {
        if (selectedRoom?.courseId) {
            getGamificationInfo(selectedRoom.courseId, hasGamification);
        }
    }, TIMERS.REFRESH_RANKING);

    useEffect(() => {
        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                if (screenfull.isFullscreen) {
                    setIsPlayerInFullScreenMode(true);
                } else {
                    setIsPlayerInFullScreenMode(false);
                }
            });
        }
    }, []);

    if (player && isIOS()) {
        const observer = new MutationObserver((() => {
            if (player?.style?.position === 'fixed') {
                setIsPlayerInFullScreenMode(true);
            } else {
                setIsPlayerInFullScreenMode(false);
            }
        }));
        observer.observe(player, { attributes: true, attributeFilter: ['style'] });
    }

    useEffect(() => {
        if (SelectedCourseId) {
            dispatch(getGamificationCourseStatus(SelectedCourseId));
        }
        return () => dispatch(cleanGamificationCourseStatus());
    }, [dispatch, SelectedCourseId]);

    useEffect(() => () => {
        if (mediaSoup._transport && !mediaSoup._transport.closed) {
            mediaSoup._transport.close();
        }

        if (mediaSoup._stream.audio) {
            mediaSoup._stream.audio.getTracks().forEach(track => {
                track.stop();
                mediaSoup._stream.audio.removeTrack(track);
            });
        }

        if (mediaSoup._stream.video) {
            mediaSoup._stream.video.getTracks().forEach(track => {
                track.stop();
                mediaSoup._stream.video.removeTrack(track);
            });
        }
    }, []);

    async function getPriorityVideoUrl() {
        dispatch(getRoomPriorityVideoUrl(room.id));
    }

    function toggleInteractiveProducerModal() {
        setShowInteractiveMediaSettings(state => !state);
    }

    useEffect(() => {
        let videoHolder = null;
        if (iframe) {
            videoHolder = iframe.contentWindow.document.querySelector('.videoHolder');
            if (videoHolder) {
                videoHolder.addEventListener('mousemove', onMouseMove);
            }
        }

        if (videoHolder) {
            return () => videoHolder.removeEventListener('mousemove', onMouseMove);
        }
        return () => null;
    }, [iframe, onMouseMove]);

    useEffect(() => {
        if (holder) {
            holder.addEventListener('mousemove', onMouseMove);
        }
        return () => {
            if (holder) {
                holder.removeEventListener('mousemove', onMouseMove);
            }
        };
    }, [onMouseMove, holder]);

    function renderPlayer() {
        if (room?.extra_user) {
            return (
                <ExtraUserInfo
                    quizComponent={() => (showQuizOverPlayerScreen ? <Quiz values={quiz} handleSelect={handleSelectAnswer} /> : null)}
                />
            );
        }

        if (videoUrl === null && video_availability === 'playing_elsewhere') {
            const quizComponent = showQuizOverPlayerScreen ? <Quiz values={quiz} handleSelect={handleSelectAnswer} /> : null;
            return (
                <ConcurrentUser
                    quizComponent={quizComponent}
                    getPriorityVideoUrl={getPriorityVideoUrl}
                />
            );
        }

        if (videoUrl && technology !== webRtcTechnologyType) {
            return (
                <TokyoPlayer
                    overlayVideoControls={(handles) => !selectedRoom.live && <OverlayVideoControls {...handles} show={show} maintainTimeout={maintainTimeout} />}
                    sendProgressPosition
                    disableNativePlayerFullScreenControl
                    decodeUrl={videoUrl}
                    watermark={userId}
                    watermarkTimeOn={TIMERS.WATERMARK_TIMER_ON}
                    watermarkTimeOff={TIMERS.WATERMARK_TIMER_OFF}
                    offerUrl={qrcodeSource !== '' ? qrcodeUrl : ''}
                    imageUrl={qrcodeSource}
                    quizComponent={() => (showQuizOverPlayerScreen ? <Quiz values={quiz} handleSelect={handleSelectAnswer} /> : null)}
                />
            );
        }

        if (videoUrl && technology === webRtcTechnologyType) {
            return (
                <Suspense fallback={<Loading />}>
                    <WebRTCPlayer
                        collectStats
                        roomId={roomId}
                        isChatEnabled={isChatEnabled}
                        socketPath={socketPath}
                        videoUrl={webRtcVideoUrl}
                        imageUrl={qrcodeSource}
                        offerUrl={qrcodeSource !== '' ? qrcodeUrl : ''}
                        quizComponent={() => (showQuizOverPlayerScreen ? <Quiz values={quiz} handleSelect={handleSelectAnswer} /> : null)}
                        waitingTransmissionText={t('WAITING_TRANSMISSION')}
                        loadingComponent={<Loading />}
                        noVideoComponent={<NoVideo title="OOps!" subtitle="Câmera Desativada" />}
                    />
                </Suspense>
            );
        }

        return null;
    }

    const handleToggleDescription = () => {
        setCollapsedDescription(!collapsedDescription);
        setCollapsedAttachments(false);
    };

    const handleToggleAttachment = () => {
        setCollapsedAttachments(!collapsedAttachments);
        setCollapsedDescription(false);
    };

    function classicCourseSection() {
        return (
            <>
                {(hasGamification && !loadingGamificationStatus) && (
                    <Top10
                        title="TOP 10 |"
                        featuredTitle={selectedRoom?.name}
                        isOpen={showTop10}
                        handleClose={() => setShowTop10(false)}
                    />
                )}

                <VideoPlayer hasAttachments={attachments && attachments.length > 0} isLive={live} isChatEnabled={isChatEnabled}>
                    {
                        (videoUrl || room?.extra_user || videoUrl === null) && (
                            <>
                                <section className="video-container">
                                    <div className="video-header">
                                        {room?.live ? <span>{t('LIVE')}</span> : <div>{' '}</div>}
                                        <h5>{selectedRoom?.name}</h5>
                                        {hasGamification ? <RankingPlace onClick={() => setShowTop10(!showTop10)} /> : <div>{' '}</div>}
                                        {interactive && <ButtonMediaConfig title={t('CONTROLS')} icon={configIco} onClick={toggleInteractiveProducerModal} />}
                                    </div>
                                    <div className="video-player" ref={videoWrapper}>
                                        {renderPlayer()}
                                    </div>
                                    <div className="video-details">
                                        {
                                            description && (
                                                <AccordionVideoDetails isOpen={collapsedDescription} toggle={() => handleToggleDescription()} title={t('ADDITIONAL_INFORMATION')}>
                                                    <DescriptionContainer>
                                                        <h5>{description}</h5>
                                                    </DescriptionContainer>
                                                </AccordionVideoDetails>
                                            )
                                        }
                                        {
                                            attachments && attachments.length > 0 && (
                                                <AccordionVideoDetails isOpen={collapsedAttachments} toggle={() => handleToggleAttachment()} title={t(CONSTANTS.ATTACHMENTS)}>
                                                    <VideoAttachment attachments={attachments} />
                                                </AccordionVideoDetails>
                                            )
                                        }
                                    </div>
                                </section>
                            </>
                        )
                    }
                </VideoPlayer>
                {
                    (live && isChatEnabled) ? (
                        <Chat quizComponent={(quiz?.release && quizMode === quizPlacement.CHAT && quiz?.roomId === selectedRoom?.id && !quiz?.is_answered) ? <Quiz chatMode values={quiz} handleSelect={handleSelectAnswer} /> : null}>
                            <h1>{t('CHAT')}</h1>
                        </Chat>
                    ) : (live && !isChatEnabled) && (quiz?.release && quiz?.roomId === selectedRoom?.id && !quiz?.is_answered) && <QuizContainer quizComponent={<Quiz chatMode values={quiz} handleSelect={handleSelectAnswer} />} />
                }
            </>
        );
    }

    function showVideoPlayer() {
        if (interactive) {
            return (
                <>
                    <Modal
                        width={550}
                        title="Configuração de"
                        featuredTitle="Mídia"
                        isOpen={showInteractiveMediaSettings}
                        handleModal={toggleInteractiveProducerModal}
                        customClasses=""
                    >
                        <UserMediaSettings />
                    </Modal>

                    <UserMediaTest
                        setIsTransmitting={setIsInteractiveUserLive}
                        isTransmitting={isInteractiveUserLive}
                    />

                    {isInteractiveUserLive && classicCourseSection()}
                </>
            );
        }

        return classicCourseSection();
    }

    return (
        <Container>
            {!room?.extra_user && videoUrl === undefined ? <VideoPlayerSkeleton /> : showVideoPlayer()}
        </Container>
    );
};

export default Course;
