import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
  width:100%
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2rem;

  h1{
    margin-top: 25px;
    width: 285px;
    height: 33px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    text-align: center;
    color: #ffffff;
  }

  @media screen and (max-width: 375px){
      .logo {
        width: 280px;
        max-width: 100%;
        display: block;
      }
  }
`;

export const MessageCointaine = styled.div`
    max-width: 37.60rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 2rem;

    h3 {
        color: #fff;
        text-align: center;
        margin: 2rem;
    }

    button{
        width: 100%;
        margin-top: 25px;
    }

    img{
        width:150px;
        height:150px;
        margin-right:10px;
    }

    .alert-title {
        font-size: 48px;
        height: 100%;
    }

    .alert-subtitle {
        font-size: 30px;
    }

`;

export const InstructionsArea = styled.div`
    max-width: 37.60rem;
    width: 90%;
    text-align: center;
    margin: 2rem 0;

    h3{
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.23px;
        text-align: center;
        color: #ffffff;
        margin: 1.3rem;

    }

    p {
        font-size: 18px;
        color: #fff;
    }
`;

export const Form = styled.div`
  max-width: 37.60rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
      height: 22px;
      margin-bottom: 8px;
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.18px;
      color: #ffffff;
  } 

  input{
      background: #393737;
      border: 0;
      height: 40px;
      border-radius: 4px;
      padding: 0 15px;
      margin: 0 0 30px;
      color:#fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      color: #ffffff;
  }

  button {
      margin-top: 2rem;
  }


  #submitButton {
      opacity: 1;
  }

  #submitButtonDisabled {
      opacity: .5;
      cursor: default;

      &:hover{
        background: linear-gradient(90deg, rgba(255,66,141,1)  0%, rgba(255,174,70,1) 100%);
      }
  }

  .emailMessageError {
        margin-left: 0;
        color: #ff6363;
        margin-left: 0.5rem;
        font-weight: bold;
        font-size: .8rem
  }



  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-color: transparent;
  }

`;

export const NewPassInstructionsArea = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  margin: 2rem 0;

`;

export const ErrorMessage = styled.span`
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    color: #ff6363;
    font-size: 18px;

`;

export const ErrorContaineir = styled.div`
  margin-top:18px;
  display: flex;
  align-self: center;

  img{
    width:25px;
    height:25px;
    margin-right:10px;
  }
`;
