import styled from 'styled-components';

function getMargin() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);

    if (isSafari) return '0 0.35em';
    if (isFirefox) return '0 8px';
    return '0 16px';
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display:flex; 
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  background-color: #212032;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .--confirm-modal {
        padding-top: 18px;
        display: flex;
        padding: 15px;
        flex-direction: column;
        padding-top: 18px;
        align-items: center;

        h5 {
            color: #fff;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;

            span {
                color: #fe8d62;
            }
        }

        button {
            border: none;
            color: #fff;
            background: linear-gradient(90deg, #ffac47 0%, #ff438d 100%);
            width: 300px;
            min-height: 37px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-weight: 600;
            border-radius: 8px;
            font-weight: bold;
            text-transform: uppercase;
            opacity: 0.8;
            transition: all 0.1s ease-in-out;

            &.--cancel-button {
                color: #ff5b7d;
                background: transparent;
                border: solid 1px #ff5a7d;
            }

            &:hover {
                box-shadow: 0px 0px 10px -4px #000000;
                opacity: 1;
                transform: scale(1.02);
            }
        }
    }
  
  @media(min-width: 800px) {
    width: 30%;
    height: 100%;
    .emoji-picker-react {
        width: 25%;
    }
  }

  @media(max-width: 800px) and (min-width: 650px) {
    width: 95%;
    margin: 0 auto;
  }

  @media(max-width: 650px) and (orientation: landscape) {
    height: 100%;
  }
  
  @media(max-width: 600px) {
      height: ${props => (props.isOnboarding && '110px')};
      margin-top: ${props => (props.isOnboarding && '10px')};
  }

  @media(max-width: 650px) {
    max-height: 300px;
  }

  @media(max-width: 400px) {
    max-height: 250px;
  }

  button img {
    width: 20px;
  }

  header{
    width:100%;
    height: 50px;
    padding: 0.5rem;
    display:flex; 
    justify-content:center; 
    align-items:center;
    border-bottom: 2px solid #403e3e;

    img{
      margin-right:10px;
    }

    span{
      width: 57px;
      height: 28px;
      font-size: 21px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.04px;
      color: #ffffff;
    }
  }
  
  textarea {
      display:flex;
      background: #2d2c44;
      width:100%;
      border: 0;
      height: 40px;
      padding: 10px 75px 6px 8px;
      color:#fff;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      color: #ffffff;
      resize: none;
      border-radius: 0;

      ::placeholder {
        font-size: 14px;
      }
  }

  .chat-header {   
    width: 100%;
    height: 62px;        
    border-radius: 8px;
    background-color: #1c1b2d;
    display:flex; 
    justify-content:center; 
    align-items:center;

    h4 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.04px;
        color: #ffffff;
    }

    @media (max-width: 650px) {
        display: none;
    }

    @media screen and (min-width: 48.75rem) and (max-width: 64rem ) {
        & h4 {
            font-size: 1rem;
        }
    }
  }

  

  .reply-section {
    padding-left: 16px; 
    padding-right: 2px; 
    padding-bottom: 6px;
    position: absolute;
    height: fit-content;
    max-height: 86%; 
    overflow: auto;
    bottom: 6%;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba(57,57,57, .6);
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(156, 156, 156, .6);
    }

    @media(max-width: 650px) {
      bottom: 10%;
      padding-right: 16px;
    }
  }

    .emoji-wrapper {
        position: absolute;
        right: 46px;
        top: 0;
        bottom: 3px;
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        span {
            font-size: 20px;
        }
    }

    .emoji-picker-react {
        background: #2d2c44;    
        height: 280px;
        width: 100%;    
        border: none;
        box-shadow: none;  
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;  
        bottom: 40px;     
        position: absolute; 
        z-index: 1;
        margin: ${getMargin()} ;  

        @media (max-width: 650px) {
            margin: 0;  
            width: 100%;
            bottom: 40px;  
            left:0;
            right: 0;
            max-height: 200px;
        }
        
        @media (max-width: 320px) {
            max-height: 150px;
        }
    }

    .emoji-picker-react .emoji-group:before {
        color: #fff;
        background: #2d2c44; 
        line-height: 27px;
    }

    .emoji-picker-react .emoji-categories {
        filter: invert(1);
    }

    aside.emoji-picker-react .content-wrapper:before {
        display: none;
    }

    li.emoji {
        color: #1c1b2d !important;
    }

`;

export const InputMessage = styled.section`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    position: relative;

    textarea {
        border-radius: ${props => (props.emojiExpanded ? 0 : 8)}px;
    }

    .send-button-wrapper {
        position: absolute; 
        right: 3px;
        bottom: 0;
        top:0;   
        display:flex;
        justify-content:center;
        align-items:center;


        button {
        padding: 3px;
        border-radius: 6px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        border:0;
        font-size: 10px;
            background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
        }
            button:disabled {
                opacity: 0.3;
            }
        }
        @media (max-width: 650px) {
           bottom: 0;
        }  

        @media(max-width: 600px) {
            position: fixed;
        }
    
`;

export const ContainScrollMessage = styled.div`
    width: 100%;
    height: calc(100% - 50px);
    display: grid;
    grid-template-columns: 1fr;
    justify-content: flex-end;
    overflow-y: auto;

    @media (max-width: 500px) {
        margin-bottom: 1.6rem;
    }
`;

export const MessageList = styled.div`
    display: flex; 
    width: 100%;
    flex-direction: ${p => (p.activeSafari ? 'column' : 'column-reverse')};
    justify-content: ${p => (p.activeSafari ? 'flex-end' : '')};
    -webkit-overflow-scrolling: touch;
    padding: 1rem;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(57,57,57, .6);
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(156, 156, 156, .6);
    }
`;

export const TabContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 2.5rem;
  min-height: 2.5rem;

  @media screen and (min-width: 57.14rem) and (max-width: 80rem ) {
        font-size: 0.66rem;
    }
`;

export const FixedMessage = styled.div`
    padding: 20px 5px 5px 5px;
    display: block !important;
    background-color: #212032;
    width: 100%;
    text-align: center;
    
    span {
        width: 100%;
        display: block;
        padding: 6px 10px;

        color: #fff;
        font-weight: 600;
    }

    img {
        cursor: pointer;
    }

    .loading {
        animation: rotate 1s linear infinite;
        border:none;
        cursor: none;
        height: 10px;

        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        };      
    }

    .pinIcon {
        height: 10px;
        float: right;
    }

    .container {
        width: 100%;
        display: flex;
        margin-top: 5px;

        background: #e85477;
        border-radius: 4px;

        &:first-child {
            margin-top: 0;
        }
    }

    .container > div {
        flex-grow: 1;
        white-space: pre-wrap;
        word-break: break-word;
    }

    .messageWrapper {
        width: 90%;
        display: block;
        padding: 6px 10px;

        color: #fff;
        font-weight: 600;
    }

    .pinWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 5px;

        img {
            display: block;
            margin: 0 auto;
            flex-shrink: 1;
        }
    }
`;

export const Tab = styled.button`
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    width: ${({ size }) => `${100 / size}%`};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-size: 0.9rem;
    line-height: normal;
    letter-spacing: 0.04px;
    color: ${({ active }) => (active ? '#ff985f' : '#fff')};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid ${({ active }) => (active ? '#ff985f' : 'inherit')};
    text-transform: uppercase;
    &:hover {
      background: #1c1b2d;
      color: #da6321;
    }

    @media screen and (min-width: 62.1rem) and (max-width: 91.42rem ) {
        font-size: 0.66rem;
    }

    @media screen and (min-width: 48.75rem) and (max-width: 64rem) {
        font-size: 0.5rem;
        
    }

    
`;

export const ReplyContainer = styled.section`
    flex-direction: 'column';
    width: '100%';
    border-radius: 8px;
    background-color: ${({ selected }) => (selected ? '#f28f5a' : '#4b4646')};
    padding: 10px;
    color: white;
    margin-bottom: 6px;
`;

export const AskContainer = styled.section`
    width: '100%';
    word-break: break-word;
    border-radius: 13.5px;
    background-color: ${({ selected }) => (selected ? '#cd6b36' : '#e95e6f')};
    color: white;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;
    margin-bottom: 6px;
`;

export const TimeContainer = styled.section`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 5px;
    
    p {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
    }
`;

export const AnswerContainer = styled.section`
    width: '100%';
    word-break: break-word;
    border-radius: 13.5px;
    background-color: ${({ selected }) => (selected ? '#d26757' : '#846e72')};
    color: white;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;
`;

export const CloseMessageSelected = styled.section` 
    width: 100%;
    height: 20;
    display: flex;
    flex-direction: row; 
    justify-content: flex-end; 
    align-items: center;
    
    button {
        width: 20;
        height: 20;
        justify-content: center;
        align-items: center;
        color: white;
        background: transparent;
        border: none;
    }
`;

export const TeamHeader = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    color: #FFF;
    
    padding: 1rem;
    h4 {
      font-size: 14px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.03px;
    }

    span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.03px;
    }
`;
