import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import ArrowDown from '../../assets/arrow-down.svg';
import attentiondark from '../../assets/attentiondark.svg';
import langIco from '../../assets/lang-ico.svg';
import i18n from '../../assets/i18n/i18n';
import KopaLogo from '../../assets/kopa-logo.svg';
import DropDown from '../../components/DropDown';
import { loginRequest } from '../../store/modules/auth/actions';
import AuthInput from '../../components/AuthInput';
import {
    Container,
    Form,
    ErrorMessage,
    ErrorContaineir,
    LinkButton,
    LanguageContainer,
    InputArea,
} from './styles';

function selector({ login }) {
    return { error: login.error };
}

export default function Login() {
    const dispatch = useDispatch();
    const { error } = useSelector(selector, shallowEqual);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [messageFailEmail, setMessageFailEmail] = useState(false);
    const loading = useSelector((state) => state.login.loading);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showDropDownLanguage, setShowDropDownLanguage] = useState(false);
    const [currentLang, setCurrentLang] = useState(0);
    const { t } = useTranslation();
    const languagesDescription = ['PT-BR', 'EN-US', 'ES-ES'];
    const languages = ['pt-BR', 'en-US', 'es-ES'];

    useEffect(() => {
        const langIndex = languages.findIndex((lang) => lang === i18n.language);
        setCurrentLang(langIndex >= 0 ? langIndex : 0);
    }, [languages]);

    function validateEmail(email) {
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
        if (emailRegex.test(email)) {
            setIsEmailValid(true);
            return true;
        }
        if (email.length > 0) {
            setIsEmailValid(false);
            return false;
        }
        return false;
    }

    function checkInvalidEmail() {
        if (!isEmailValid) {
            setMessageFailEmail(true);
        } else {
            setMessageFailEmail(false);
        }
    }

    function handleChange(e) {
        setEmail(e);
        setIsEmailValid(validateEmail(e));
        if (validateEmail(e)) {
            setMessageFailEmail(false);
        }
    }

    function checkEmptyFields() {
        if (!email || !password || !isEmailValid) {
            return true;
        }
        if (email && password && isEmailValid) {
            return false;
        }

        return true;
    }

    function handleLogin() {
        setMessageFailEmail(false);
        if (password !== '' || email !== '' || isEmailValid) {
            dispatch(loginRequest(email, password));
        }
    }

    function keyHandler(evt) {
        if (!isEmailValid || password.length < 1) {
            return;
        }
        if (evt.keyCode === 13 && !loading) {
            handleLogin();
        }
    }

    function keyHandlerPassword(evt) {
        if (!isEmailValid || password.length < 1) {
            return;
        }
        if (evt.keyCode === 13 && !loading) {
            handleLogin();
        } else if (evt.keyCode === 9 && document.getElementById('recover')) {
            document.getElementById('recover').focus();
        }
    }

    function languageHandler(e, index) {
        setShowDropDownLanguage((currentShowDropDown) => !currentShowDropDown);
        setCurrentLang(index);
        i18n.changeLanguage(languages[index]);
    }

    return (
        <Container>
            <LanguageContainer>
                <img className="langIco" src={langIco} alt="ico" />
                <a
                    href="#/"
                    onClick={() => setShowDropDownLanguage(!showDropDownLanguage)}
                >
                    <div className="language">
                        <h3>{languagesDescription[currentLang]}</h3>
                        <img
                            className="arrow-down"
                            src={ArrowDown}
                            alt="arrow down"
                        />
                    </div>
                </a>
                <DropDown
                    title={t('LANGUAGE')}
                    items={languagesDescription}
                    selected={currentLang}
                    handler={languageHandler}
                    visible={showDropDownLanguage}
                />
            </LanguageContainer>
            <img className="logo" src={KopaLogo} alt="KopaLogo" />
            <Form>
                <div className="loginInputLabel">
                    <span>{t('LOGIN', { context: 'EMAIL' })}</span>
                </div>
                <InputArea>
                    <AuthInput
                        type="email"
                        value={email}
                        autoComplete={window.chrome ? 'no' : 'off'}
                        onChange={(e) => handleChange(e.target.value)}
                        onKeyUp={keyHandler}
                        onBlur={() => checkInvalidEmail()}
                        alertMessage={messageFailEmail && messageFailEmail}
                    />
                    {messageFailEmail && (
                        <div className="emailMessageError">
                            <p>{t('INVALID_EMAIL')}</p>
                        </div>
                    )}
                    <div className="loginInputLabel">
                        <span>{t('LOGIN', { context: 'PASSWORD' })}</span>
                    </div>
                    <AuthInput
                        passwordIcon
                        type="password"
                        autoComplete={window.chrome ? 'no' : 'off'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={keyHandlerPassword}
                    />
                </InputArea>
                <LinkButton id="recover" to="/recoverPassword">
                    {t('FORGETPASSWORD')}
                </LinkButton>
                {error && (
                    <ErrorContaineir>
                        <img src={attentiondark} alt="att" />
                        <ErrorMessage>{error}</ErrorMessage>
                    </ErrorContaineir>
                )}

                <button
                    id={
                        checkEmptyFields()
                            ? 'submitButtonDisabled'
                            : 'submitButton'
                    }
                    disabled={
                        loading || checkEmptyFields() || !isEmailValid
                    }
                    type="submit"
                    onClick={handleLogin}
                >
                    {loading
                        ? `${t('LOADINGCAPS')}...`
                        : t('LOGIN', { contexty: 'SIGNIN' })}
                </button>
            </Form>
        </Container>
    );
}
