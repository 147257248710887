import styled from 'styled-components';

export const QuestionContainer = styled.div`
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom:0;    
    z-index: 50;    
    margin: 1rem 1rem 3rem;
`;

export const QuestionContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;    
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;    
    color: #f27f36;

    .question-list-holder {
        width: 90%;
        max-width: 800px;
        margin: 2rem auto;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;   
    }

    .fix-padding {
        padding: 0.5rem;        
    }

    small {        
        font-size: 14px;
        font-weight: 800;       
        letter-spacing: 0.03px;
        text-align: center;
        text-transform: uppercase;
    }
`;

export const QuestionTitle = styled.h2`    
    font-size: clamp(24px, 0.5em + 0.8vw, 1.8em);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.05px;
    text-align: center;
    color: #524a44;
    max-width: 578px;
    margin-top: 1rem;
`;

export const QuestionList = styled.div`
    width: 100%;
    max-width: 80%;
    margin-top: 15px;    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > button {     
        flex: 40%;
        margin: 10px;
        max-width: 40%;
    }
`;

export const QuestionItem = styled.button`
    &.answer-button {
        font-size: clamp(18px,0.5em + 1.6vw,1.8em);
        font-weight: 600;
        letter-spacing: 0.04px;
        color: #8c3f09;
        padding: 0 20px;
        background-color: rgba(255,152,84, 0.2);
        position: relative;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        text-align: center;
        width: 100%;
        word-break: break-word;
        border: none;
    }

    &:disabled {
        opacity: 0.5;
    }

    &.selected {
        position: relative;
        background-color: #ff5085;
        color: #fff;
        font-weight: 800;
        border-radius: 8px;
    }

`;

export const QuestionFooter = styled.div`
    width: 100%;
    border-radius: 8px;
    background-color: #fff0f5;
    display: flex ;
    justify-content: space-around;
    font-size: 1.1rem;
    padding: 3rem 1rem;
`;

export const QuestionDuration = styled.div`    
    width: 100%;   
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    background: linear-gradient(to right, #ff4a89 0%, #ff9f50 100%);        
    transform: scaleX(-1);

    .round-time-bar {        
        width: 100%;   
        height: 7px;
        background: #fff;
        @keyframes roundTime {
            to {            
                transform: scaleX(0);
            }
        }        
        animation: roundTime calc(${props => props.duration} * 1s) steps(${props => props.duration}) forwards;
        transform-origin: left center;

    }
    .round-time-bar[data-style="smooth"] {
        animation: roundTime calc(${props => props.duration} * 1s) linear forwards;
    }
`;

// ------------------ Chat Mode

export const QuestionContainerChatMode = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;  
    display: flex;
    flex-direction: column;  
`;

export const QuestionContainerHeader = styled.div`
    position: relative;
    z-index: 2;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(to right, #ff4a89 0%, #ff9f50 100%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;    
    
    h5 {
        font-size: 14px;
        font-weight: 800;
        letter-spacing: 0.03px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
    }
`;

export const QuestionTitleWrapper = styled.div`
    text-align: center;
    
    small {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }
`;

export const QuestionTitleChatMode = styled.h2`    
    font-size: clamp(12px, 0.5em + 0.8vw, 1.8em);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.05px;
    text-align: center;
    color: #524a44;
    max-width: 578px;
    /* margin-top: 1rem; */
`;

export const QuestionContentChatMode = styled.div`
    padding-top: 5px;
    position: relative;
    width: 100%;
    height: 100%;        
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    color: #f27f36;
    text-transform: uppercase ;
    
    .fix-padding {
        width: 100%;
        padding: 0.5rem 24px;        
    }

    small {        
        font-size: 14px;
        font-weight: 800;       
        letter-spacing: 0.03px;
        text-align: center;
        text-transform: uppercase;
    }
`;

export const QuestionListChatMode = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
`;

export const QuestionItemChatMode = styled.button`
    border: none ;
    font-size: clamp(12px,0.5em + 0.8vw,1.8em);
    font-weight: 600;
    letter-spacing: 0.04px;
    color: #8c3f09;
    padding: 0 20px;        
    background-color: rgba(255,152,84, 0.2);
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center; 
    width: 100%;
    margin: 1rem 0;

    &:disabled{
        opacity: 0.5;
    }
  
    &.selected {
        position: relative;
        background-color: #ff5085;
        color: #fff;
        font-weight: 800;
        border-radius: 8px;
    }
    @media only screen and (max-width: 600px) {
        border: none ;
        font-size: clamp(12px,0.5em + 0.8vw,1.8em);
        font-weight: 600;
        letter-spacing: 0.04px;
        color: #8c3f09;
        padding: 0 20px;        
        background-color: rgba(255,152,84, 0.2);
        position: relative;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        text-align: center; 
        width: 100%;
        margin: 1rem 0;
    
        &:disabled{
            opacity: 0.5;
        }
      
        &.selected {
            position: relative;
            background-color: #ff5085;
            color: #fff;
            font-weight: 800;
            border-radius: 8px;
        }
    } 
`;

export const QuestionDurationChatMode = styled.div`    
    width: 100%;   
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    background: linear-gradient(to right, #ff4a89 0%, #ff9f50 100%);        
    transform: scaleX(-1);

    .round-time-bar {        
        width: 100%;   
        height: 7px;
        background: #fff;
        @keyframes roundTime {
            to {            
                transform: scaleX(0);
            }
        }        
        animation: roundTime calc(${props => props.duration} * 1s) steps(${props => props.duration}) forwards;
        transform-origin: left center;

    }
    .round-time-bar[data-style="smooth"] {
        animation: roundTime calc(${props => props.duration} * 1s) linear forwards;
    }
`;

export const QuestionFooterChatMode = styled.div`
    width: 100%;
    border-radius: 8px;
    background-color: #fff0f5;
    display: flex ;
    justify-content: space-between ;
    padding: 1rem;
`;

export const FooterButton = styled.button`
    background: none;
    border: none;
    color: rgba(82, 74, 68, 0.38);
    font-weight: bold;
    padding: 0 1rem ;
    text-transform: uppercase;

    &:not(:disabled):active{
        filter: brightness(0.5);
    }
    &.skip{
        color: #ff4c88;
    }
    @media only screen and (max-width: 600px) {
        background: none;
        border: none;
        color: rgba(82, 74, 68, 0.38);
        font-weight: bold;
        padding:1rem ;
        text-transform: uppercase;
    
        &:not(:disabled):active{
            filter: brightness(0.5);
        }
        &.skip{
            color: #ff4c88;
        }

    } 
`;
