import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useSize from '../../hooks/useSize';

export default function VideoPlayerSkeleton() {
    const { width, height } = useSize();

    if (!width || !height) {
        return null;
    }

    return (
        <SkeletonTheme color="#242339" highlightColor="#1c1b2d" className="skeletonVideoDesktop">
            <Skeleton
                width={width - 120}
                height={height - 170}
                count={1}
            />
        </SkeletonTheme>
    );
}
