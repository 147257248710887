import styled from 'styled-components';

export const DevicesContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;

    > div {
        display: flex;
    }

    .device-wrapper {
        position: relative;
        margin: 0 10px;
    }

    .list-devices {
        position: absolute;
        border-radius: 50%;
        border: none;
        height: 1.2rem;
        width: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c35a36;
        padding: 3px;
        z-index: 4;
        bottom: -2px;
        right: -5px;
        transition: all 100ms cubic-bezier(0.075, 0.82, 0.165, 1);
        

        &.up {
            transform: rotate(180deg);
        }
    }
    .show-device-arrow {        
        width: 80%;        
    }

    @media(max-width: 799px){
        &.is-live {
            justify-content: space-between;
            .device-wrapper {
                .device-list {
                   left: 0;
                   right: unset;
                   &::before {
                       right: unset;
                   }
                }
            }
        }
    }
`;

export const DeviceList = styled.div`
    position: absolute;
    background-color: #282b3a;
    border-radius: 5px;
    z-index: 5;
    bottom: 60px;
    right: -112px;
    padding: 10px;  
    
    &::before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #282b3a;
        border-bottom: 10px solid transparent;
        right: calc(50% - 10px);
        bottom: -20px;
    }
        
    
    ul {
        width: 270px;
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            padding: 2px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            min-width: 100px;
            margin: 5px;
            p {
                padding: 5px;
                height: 29px;
            }
            button {
                background: transparent;
                color: #fff;
                border: none;
                outline: none;
                text-align: inherit;
                padding: 5px;
                border-radius: 5px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.active {
                   background-color: #1c1b2d;
                }
            }
            span {
                font-size: 0.6rem;
                color: #ff5b7d;
                font-weight: bold;
            }
        }
    }
    .mantine-Switch-root {
       
    }

    .mantine-Switch-input {
        cursor: pointer;
        background-color: #333360;
        border: none;
        &::before {                
                background-color: #21213e;
                border: none;
            }
        &:checked {
            background-color: #31a6a1;
            &::before {
                background-color: #126a67;
            }   
        }
    }

    .mantine-Switch-label {

    }
`;

export const Device = styled.button`
    border-radius: 4px;
    border: none;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    background: #312f48;
    opacity: 0.60;      
    
    img {
        height: 60%;
        width: 60%;
        object-fit: contain;
    }
`;

export const DeviceType = styled.button`
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    background: none;
    border: none;
    outline: none;
    font-size: .7rem;
    margin-bottom: 5px;
    width: 50%;
`;

export const EndTransmissionButton = styled.button`
    border: none;
    background: #ff6363;
    height: 40px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    position: absolute;
    right: 15px;    
    border-radius: 3px;
    padding: 0 15px;

    &:hover {
        transform: scale(1.02);
    }
`;

export const DeviceTabTypeContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    position: relative;
    button.--active-tab {
        color: #ea742a;
        border-bottom: 2px solid #ea742a;
        padding-bottom: 5px;
        margin: 0;
    }

    button {
        &.--active-tab {
            color: #ea742a;
            border-bottom: 2px solid #ea742a;
            padding-bottom: 5px;
            margin: 0;
        }
        &.--single {
            width: 100%;
            pointer-events: none;
        }
    }
`;
