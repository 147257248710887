import actions from '../../../utils/actions';

const DEFAULT_STATE = {
    loading: true,
    user: null,
    firstTen: [],
    hasGamification: false,
    loadingGamificationStatus: true,
};

export default function roomReducer(state = DEFAULT_STATE, { type, payload }) {
    switch (type) {
        case actions.PERSONAL_RANKING_REQUEST:
            return { ...state, loading: true };

        case actions.PERSONAL_RANKING_SUCCESS:
            return { ...state, loading: false, user: payload.user, firstTen: payload.firstTen };

        case actions.PERSONAL_RANKING_FAILURE:
            return { ...state, loading: false };

        case actions.GAMIFICATION_COURSE_STATUS_REQUEST:
            return { ...state, loadingGamificationStatus: true };

        case actions.GAMIFICATION_COURSE_STATUS_SUCCESS:
            return { ...state, hasGamification: payload, loadingGamificationStatus: false };

        case actions.GAMIFICATION_COURSE_STATUS_FAILURE:
            return { ...state, hasGamification: false, loadingGamificationStatus: false };

        case actions.GAMIFICATION_COURSE_STATUS_CLEAN:
            return { ...state, hasGamification: false, loadingGamificationStatus: false };

        default:
            return state;
    }
}
