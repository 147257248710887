import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ArrowDown from '../../../assets/arrow-down.svg';
import attention from '../../../assets/attention.svg';
import i18n from '../../../assets/i18n/i18n';
import KopaLogo from '../../../assets/kopa-logo.svg';
import DropDown from '../../../components/DropDown';
import useVoucher from '../../../hooks/useVoucher';
import { loginRequest } from '../../../store/modules/auth/actions';
import { verifyVoucher } from '../../../store/modules/vouchers/actions';
import {
    Container, Form, ErrorMessage, ErrorContaineir, LinkButton, LanguageContainer,
} from './styles';

function selector({ login }) {
    return { error: login.error };
}

const VoucherLogin = () => {
    const dispatch = useDispatch();
    const { error } = useSelector(selector, shallowEqual);
    const loading = useSelector(state => state.login.loading);
    const { id } = useParams();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showDropDownLanguage, setShowDropDownLanguage] = useState(false);
    const [currentLangVoucher, setCurrentLangVoucher] = useState(0);
    const { t } = useTranslation();
    const languagesDescription = [
        `PT-BR - ${t('PORTUGUESE')}`,
        `EN-US - ${t('ENGLISH')}`,
        `ES-ES - ${t('SPANISH')}`,
    ];
    const languages = ['pt-BR', 'en-US', 'es-ES'];
    const { voucherVerification } = useVoucher();
    const historyVoucher = useHistory();
    function handleLogin() {
        if (password !== '' || email !== '') {
            dispatch(loginRequest(email, password, id));
        }
    }
    function handleRegister() {
        historyVoucher.push(`/voucher/${id}/register`);
    }

    function keyHandler(evt) {
        if (evt.keyCode === 13 && !loading) {
            handleLogin();
        }
    }

    function keyHandlerPassword(evt) {
        if (evt.keyCode === 13 && !loading) {
            handleLogin();
        } else if (evt.keyCode === 9 && document.getElementById('recover')) {
            document.getElementById('recover').focus();
        }
    }

    function languageHandler(e, index) {
        setShowDropDownLanguage(currentLanguage => !currentLanguage);
        setCurrentLangVoucher(index);
        i18n.changeLanguage(languages[index]);
    }

    useEffect(() => {
        if (id) {
            dispatch(verifyVoucher(id, true));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (voucherVerification && voucherVerification.ableToUse) {
            historyVoucher.push(`/voucher/${id}/login`);
        } else if (voucherVerification && voucherVerification.ableToUse === false) {
            historyVoucher.push('/voucher/');
        }
    }, [voucherVerification, historyVoucher, id]);

    return (
        <Container>
            <img
                className="logo"
                src={KopaLogo}
                alt="KopaLogo"
            />
            <Form>
                <div>
                    <span>{t('LOGIN', { context: 'EMAIL' })}</span>
                </div>
                <input type="email" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} onKeyUp={keyHandler} />
                <div>
                    <span>{t('LOGIN', { context: 'PASSWORD' })}</span>
                </div>
                <input type="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={keyHandlerPassword} />
                <LinkButton id="recover" to={`/recoverPassword/${id}`}>{t('FORGETPASSWORD')}</LinkButton>
                <button
                    id="submitButton"
                    disabled={loading}
                    type="submit"
                    onClick={handleLogin}
                >{loading ? `${t('LOADINGCAPS')}...` : t('LOGIN', { context: 'SIGNIN' })}
                </button>
                <button
                    id="submitButton"
                    className="register-button"
                    disabled={loading}
                    type="submit"
                    onClick={handleRegister}
                >{loading ? `${t('LOADINGCAPS')}...` : t('VOUCHER', { context: 'LOGIN' })}
                </button>
                <LanguageContainer>
                    <h3>{t('LANGUAGE')}:</h3>
                    <a href="#/" onClick={() => setShowDropDownLanguage(currentLang => !currentLang)}>
                        <div className="language">
                            <h3>{languagesDescription[currentLangVoucher]}</h3>
                            <img className="arrow-down" src={ArrowDown} alt="arrow down" />
                        </div>
                    </a>
                    <DropDown
                        title={t('LANGUAGE')}
                        items={languagesDescription}
                        selected={currentLangVoucher}
                        handler={languageHandler}
                        visible={showDropDownLanguage}
                    />
                </LanguageContainer>
            </Form>
            {error && (
                <ErrorContaineir>
                    <img src={attention} alt="att" />
                    <ErrorMessage>{error}</ErrorMessage>
                </ErrorContaineir>
            )}
        </Container>

    );
};

export default VoucherLogin;
