import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { acceptTermOfUsage } from '../../store/modules/rooms/actions';
import { TermsControl } from './styles';

export default function AcceptForm({ courseId, user, roomId }) {
    const [acceptTerms, setAcceptTerms] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleTerms = () => {
        if (acceptTerms) {
            dispatch(acceptTermOfUsage(courseId, user.userId, roomId));
        }
    };
    return (
        <TermsControl className="terms-control">
            <FormControlLabel
                control={(
                    <Checkbox
                        checked={acceptTerms}
                        onChange={() => setAcceptTerms(acceptTerms => !acceptTerms)}
                        name="acceptTerms"
                        color="primary"
                    />
                )}
                label={t('ACCEPTTERMS')}
            />
            <Button disabled={!acceptTerms} variant="contained" onClick={handleTerms}>{t('CONFIRM')}</Button>
        </TermsControl>
    );
}
