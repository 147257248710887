import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    SidebarWrapper,
    SidebarContainer,
    LogoContainer,
    SidebarMenu,
    SidebarMenuTop,
    SidebarMenuBottom,
} from './styles';
import logoIcon from '../../assets/logo-kopa.svg';
import homeIcon from '../../assets/home-icon.svg';
import signOutIcon from '../../assets/sign-out-icon.svg';
import configIcon from '../../assets/config-icon.svg';
import Tooltip from '../Tooltip';
import { logout } from '../../store/modules/auth/actions';
import useAuth from '../../hooks/useAuth';

function Sidebar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useAuth();

    function handleLogout() {
        dispatch(logout());
    }

    function handleSingleMenuClick(path = null, blank = false) {
        if (path) {
            if (blank) {
                window.open(path, '_blank');
                return;
            }
            history.push(path);
        }
    }

    return (
        <SidebarWrapper>
            <SidebarContainer>
                <LogoContainer onClick={() => window.location.replace('/')}>
                    <img src={logoIcon} alt="Kopa Live" />
                </LogoContainer>
                <SidebarMenu>
                    <SidebarMenuTop>
                        <ul>
                            <li>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className="--menu-item"
                                    onClick={() => handleSingleMenuClick('/')}
                                >
                                    <Tooltip
                                        content={t('HOMEPAGE')}
                                        direction="right"
                                    >
                                        <img
                                            src={homeIcon}
                                            alt={t('HOMEPAGE')}
                                            data-tour="tour__home"
                                        />
                                    </Tooltip>
                                </div>
                            </li>
                            {
                                user?.organizer && (
                                    <li>
                                        <div
                                            role="button"
                                            tabIndex={0}
                                            className="--menu-item"
                                            onClick={() => handleSingleMenuClick('/organizer', true)}
                                        >
                                            <Tooltip
                                                content={t('ONBOARDING_HOME_ORGANIZER_TITLE')}
                                                direction="right"
                                            >
                                                <img
                                                    src={configIcon}
                                                    alt={t('ONBOARDING_HOME_ORGANIZER_TITLE')}
                                                    data-tour="tour__organizer"
                                                />
                                            </Tooltip>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </SidebarMenuTop>
                    <SidebarMenuBottom>
                        <ul>
                            <li>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className="--menu-item emphasis"
                                    onClick={handleLogout}
                                >
                                    <Tooltip
                                        content={t('LOGOUT')}
                                        direction="right"
                                    >
                                        <img src={signOutIcon} alt={t('LOGOUT')} data-tour="tour__logout" />
                                    </Tooltip>
                                </div>
                            </li>
                        </ul>
                    </SidebarMenuBottom>
                </SidebarMenu>
            </SidebarContainer>
        </SidebarWrapper>
    );
}

export default Sidebar;
