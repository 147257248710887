import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 5rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: #212032 !important;
    border-radius: 10px;
    margin-bottom: 1.5rem;

    &:hover{
        border: 1px solid #cf8b4c
    }

    &:focus-within {
        border: 1px solid #cf8b4c
    }

    ${props => css`
        border: ${props.alertMessage && '1px solid #b41212'};
        `}

    @media screen and (max-width: 630px){
        margin: 0 .3rem;
    }

    @media screen and (max-width: 375px){
        margin: 0 .2rem;
    }
    
`;

export const Input = styled.input.attrs({ color: '#fff' })`
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #212032 !important;
    border-radius: 10px !important;
    filter: none;
    text-align: center;
   
    

    @media(max-width: 769px){
        width: 90% !important;
    }

    @media(max-width: 375px){
        width: 88% !important;
    }   

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }


`;

export const ImageIco = styled.img`
    position: absolute;
    right: 0;
    cursor: pointer;
    margin: 0 1rem
    

`;
