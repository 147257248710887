import styled from 'styled-components';

export const NoRankingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
`;

export const NoRankingTitle = styled.h1`
    color: #fff;
    margin-top: 50px;
    font-weight: bold;
`;

export const NoRankingText = styled.p`
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 8px;
    font-weight: bold;
`;
