import styled from 'styled-components';

export const UserSectionContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
`;

export const ImageFrame = styled.div`
    background-image: linear-gradient(to bottom, #ff9460, #ff617a 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    padding-right: 4px;
    padding-top: 4px;
    position: absolute;
    left: -25px;
    img {
        width: 42px;
        height: 42px;
        border-radius: 7px;
    }
`;

export const ProfileId = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
    

    p {
        font-size: clamp(12px, 0.2em + 1vw, 1em);
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
    }

    strong {
        color: #ff8f63;
        cursor: pointer;
        
        &:hover{
            color: rgba(255,174,70,1);
        }
    }

`;
